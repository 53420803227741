/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface VerificationTaskResolutionTypesDtoAvailableResolutions { 
    DateMissing?: string;
    NameMismatch?: string;
    RedundantMarks?: string;
    PageTorn60Percent?: string;
    BrokenLamination?: string;
    PhotoIsPasted?: string;
    ImageEdited?: string;
    ClientSignMissing?: string;
    ExecutiveSignMissing?: string;
    StampNotReadable?: string;
    NotReadable?: string;
    NotCertified?: string;
    PagesCut?: string;
    Incomplete?: string;
    InnMismatch?: string;
    OgrnMismatch?: string;
    NoData?: string;
    WrongDocumentType?: string;
    DocumentNeedsReUpload?: string;
    SeriesMismatch?: string;
    NumberMismatch?: string;
    IssueDateMismatch?: string;
    BirthdateMismatch?: string;
    Other?: string;
    Pass_Missing?: string;
    Pass_FieldsNotReadable?: string;
    Pass_PersonalMarksMissing?: string;
    Pass_AddressNotReadable?: string;
    Pass_StreetOrBuildingMissing?: string;
    Pass_NoActiveRegistration?: string;
    Pass_FMSStampMissing?: string;
    Pass_IssueDateFieldNotReadable?: string;
    Pass_ScanIsOverexposed?: string;
    IS_BankRequirementsNotMet?: string;
    IS_DataIncomplete?: string;
    IS_60DaysDelta?: string;
    InsufficientInformationToCalculate?: string;
    IS_BorrowerNameCannotEqualSignerName?: string;
    IS_DuplicateInquery?: string;
    TK_SeriesMismatch?: string;
    TK_EmploymentDateMissing?: string;
    TK_EmploymentDateNotReadable?: string;
    TK_CurrentPlaceOfWorkMissing?: string;
    MainPageMissing?: string;
    TK_NotSertifiedPagesPresent?: string;
    TK_CompanyRegistrationDateMismatch?: string;
    TD_EmployerNameMismatch?: string;
    TD_DateMissing?: string;
    TD_DateError?: string;
    TD_InnMissing?: string;
    ETK_ExperienceLessThan3Years?: string;
    ETK_CopyExpired?: string;
    ETK_WrongType?: string;
    ETK_CurrentPlaceOfWorkMissing?: string;
    SOPD_ConsentMissing?: string;
    SOPD_IncorrectPassportData?: string;
    SOPD_AgreementSignedMoreThanSixMonthsAgo?: string;
    SOPD_FormPageCut?: string;
    PTS_NumberMismatch?: string;
    PTS_VinMismatch?: string;
    PTS_MakeMismatch?: string;
    PTS_ModelMismatch?: string;
    HeadAccNameMissing?: string;
    HeadAccSignMissing?: string;
    HeadAccPositionMissing?: string;
    PostMissing?: string;
    BicMismatch?: string;
    TaxNumberMismatch?: string;
    RecipientNameMismatch?: string;
    BorrowerSignAllPageMissing?: string;
    SellerSignAllPageMissing?: string;
    SellerPostMissing?: string;
    SC_Incomplete?: string;
    SC_LastOwnerNotSeller?: string;
    PersonMismatch?: string;
    UnrecognizableFace?: string;
    PC_PhotoWrongPlace?: string;
    Pass_StreetMissing?: string;
    Pass_MainPageMissing?: string;
    Pass_VerificationPageMissing?: string;
    Pass_PersonalFieldsNotReadable?: string;
    Pass_IssuerFieldsNotReadable?: string;
    Pass_PreviousPassportNotReadable?: string;
    Pass_CorruptedDocument?: string;
    Pass_NotReadable?: string;
    DL_BlurryImage?: string;
    NameIsWrongOrMissing?: string;
    DateIsWrongOrMissing?: string;
    SignatureIsMissing?: string;
    PoorScanQuality?: string;
    ScanDoesNotFits?: string;
    SignatureDoesNotMatch?: string;
    DateIsIncorrect?: string;
    ExtraDate?: string;
    InsurerAndPolicyholderSignatureMissing?: string;
    ReadsBadly?: string;
    BorrowerSignatureMissing?: string;
    RepresentativeSignatureMissing?: string;
    NoInsurerStamp?: string;
    DateOfDocumentAndTaskDifferent?: string;
    NoFullnameAndSignatureOfBankEmployee?: string;
    NoClientSignatureOnLastPage?: string;
    ExtraPage?: string;
    ApplicationForTransferOfFundsForVehicle?: string;
    NoLenderSignatureOnScan?: string;
    DateFullnamePositionAndSignatureOfBankEmployeeLastPage?: string;
    AgreementInClauses131417?: string;
    NoConsentToContractAgreementsAndReceiptLoan?: string;
    NotAllPassportPages?: string;
}

