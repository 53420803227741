import { NgIf, NgTemplateOutlet } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  Output,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TuiLetModule } from '@taiga-ui/cdk';
import { TuiHintModule, TuiHostedDropdownModule, TuiSvgModule } from '@taiga-ui/core';
import { ExecuteWithPipeModule, FileInfo, FileInfoState, Nullable } from '@lib-utils';
import { FileInputAppearance } from '@lib-utils';
import { ButtonModule, DividerModule } from '@lib-widgets/core';
import { LoaderModule } from '@lib-widgets/request-wrapper';
import {
  defaultThumbnailIcon,
  downloadIcon,
  pdfThumbnailIcon,
  previewIcon,
  tuiIconLoading,
  tuiIconWarning,
  zipThumbnailIcon,
} from '../icons';
import { getPreviewFileUrl, isFilePdf } from '../utils';

@Component({
  selector: 'fnip-file',
  templateUrl: 'file.component.html',
  styleUrls: ['file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ButtonModule,
    ExecuteWithPipeModule,
    LoaderModule,
    NgIf,
    TuiHintModule,
    TuiLetModule,
    TuiSvgModule,
    TuiHostedDropdownModule,
    DividerModule,
    NgTemplateOutlet,
  ],
})
export class FileComponent {
  @Input()
  @HostBinding('class')
  appearance: FileInputAppearance = 'default';

  @Input({ required: true }) state!: FileInfoState;

  @Input() name?: Nullable<string>;

  @Input() thumbnailSrc?: Nullable<string>;

  @Input() contentType?: Nullable<string>;

  @Input() rejectedReason?: string;

  @Input() cacheBlob?: HttpResponse<Blob>;

  @Input() isPending?: boolean;

  @Input() isPreviewLoading?: boolean;

  @Input() progress?: number;

  @Input() size?: number;

  @Input() allowRemove = false;

  @Input() allowDownload = false;

  @Input() allowPreview = false;

  @Input({ transform: booleanAttribute }) allowSignature = false;

  @Input() showSize = false;

  @Input() signatureFile?: FileInfo;

  @Input({ transform: booleanAttribute }) disableIcon = false;

  @Output() fileRemove = new EventEmitter<void>();

  @Output() fileDownload = new EventEmitter<void>();

  @Output() filePreview = new EventEmitter<boolean>();

  @Output() fileSignatureUpload = new EventEmitter<void>();

  @Output() fileSignatureDownload = new EventEmitter<void>();

  @Output() fileSignatureRemove = new EventEmitter<void>();

  horizontalPreviewIcon = previewIcon;

  horizontalDownloadIcon = downloadIcon;

  FileInfoState = FileInfoState;

  isMouseOnEye = false;
  isMouseOnTooltip = false;

  sanitizer = inject(DomSanitizer);

  changeDetectorRef = inject(ChangeDetectorRef);

  isFilePdf = isFilePdf;

  getPreviewFileUrl = getPreviewFileUrl;

  getStateIcon = (state: FileInfoState) =>
    state &&
    {
      ready: 'tuiIconCheckLarge',
      uploading: tuiIconLoading,
      rejected: tuiIconWarning,
    }[state];

  getThumbnailIcon = (name: Nullable<string>, state: FileInfoState) => {
    if (state === 'uploading') return tuiIconLoading;
    if (name?.toLowerCase().endsWith('.pdf')) return pdfThumbnailIcon;
    if (name?.toLowerCase().endsWith('.zip')) return zipThumbnailIcon;

    return defaultThumbnailIcon;
  };

  getReadableSize = (size = 0): string =>
    `${Intl.NumberFormat('en', { notation: 'compact' }).format(size).replace('B', 'G')}B`;

  formatFileProgress = (progress: number) => (progress * 100).toFixed(0);

  openFullPreview = () => {
    this.changeMouseFlag('tooltip', false);
    this.onFilePreview();
  };

  // Чтобы юзер успел перейти на тултип и он не закрылся
  changeMouseFlag = (type: 'eye' | 'tooltip', value: boolean) => {
    if (type === 'tooltip') {
      this.isMouseOnTooltip = value;
      this.changeDetectorRef.markForCheck();
      return;
    }

    setTimeout(() => {
      this.isMouseOnEye = value;
      this.changeDetectorRef.markForCheck();
    }, 300);
  };

  onFileRemove = () => {
    this.fileRemove.emit();
  };

  onFileDownload = () => {
    this.fileDownload.emit();
  };

  onFilePreview = (isModalView = true) => {
    this.filePreview.emit(isModalView);
  };

  onFileSignatureUpload = () => {
    this.fileSignatureUpload.emit();
  };
}
