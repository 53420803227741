/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BankAccountType } from './bankAccountType';


export interface CreateOrUpdateOwnerRequisitesRequest { 
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    type: BankAccountType;
    accountNumber: string;
    bankName: string;
    bankBik: string;
    bankAddress: string;
    bankCorAccount: string;
    taxNumber: string;
    bankKpp: string;
    letterOfCreditAmount?: number | null;
    ownerId?: number | null;
}
export namespace CreateOrUpdateOwnerRequisitesRequest {
}


