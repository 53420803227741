import { FromGeneratedEnum } from '@lib-utils';
import {
  AdditionalIncomeType,
  AgreementType,
  AppraisalCompanyStatus,
  BlacklistCheckStatus,
  ClientFileInfoDto,
  ConditionType,
  CreditPurpose,
  DealRegistrationType,
  DealVerificationTaskType,
  DocumentSigningType,
  DocumentType,
  Education,
  FileType,
  InseparableImprovementsType,
  InsuranceForm,
  InsuranceScheme,
  JobType,
  LandCategory,
  MaritalContract,
  MaritalStatus,
  MaterialType,
  MaternalCapitalType,
  ObjectType,
  OrganizationLifetime,
  OwnershipType,
  OwnerType,
  PaymentType,
  ReasonForResidence,
  ReceivingType,
  RegistrationType,
  RepresentativeDocumentType,
  Sex,
  SpouseSocialStatus,
  StaffNumber,
  TransferType,
  VerificationCallResult,
  VerificationTaskType,
  VerificatorStatus,
} from '@lib-mortgage/api';
import { VerificationAllDocumentStatus, VerificationGeneralTaskType } from '@lib-mortgage/api-middleware';

export const AgreementTypeMap = new Map<AgreementType, string>([
  [AgreementType.NoTerm, 'Бессрочный договор'],
  [AgreementType.FixedTerm, 'Срочный договор'],
]);

export const CreditPurposeMap: Record<CreditPurpose, string> = {
  PrimaryMarket: 'Новостройка',
  SecondaryMarket: 'Готовое жильё',
  RealEstatePledge: 'Под залог недвижимости',
  GeneralPurpose: 'Нецелевой кредит',
};

export const DocumentTypeMap: Record<DocumentType, string> = {
  EmploymentHistory: 'Трудовая книжка',
  PreviousPassportRu: 'Паспорт гражданина РФ (неактивный, выданный ранее)',
  PreviousPassportRu2: 'Паспорт гражданина РФ (неактивный, выданный ранее)',
  PreviousPassportRu3: 'Паспорт гражданина РФ (неактивный, выданный ранее)',
  PassportRu: 'Паспорт гражданина РФ',
  DriverLicense: 'Водительское удостоверение',
  ForeignPassport: 'Заграничный паспорт',
  MilitaryId: 'Военный билет',
  BirthCertificate: 'Свидетельство о рождении',
};

export const EducationMap = new Map<Education, string>([
  [Education.AcademicDegree, 'Ученая степень'],
  [Education.HigherEducation, 'Высшее'],
  [Education.TwoAndMoreHigherEducations, 'Два высших и более'],
  [Education.IncompleteHigherEducation, 'Неоконченное высшее'],
  [Education.SecondaryEducation, 'Среднее'],
  [Education.SpecialSecondaryEducation, 'Cреднее специальное'],
  [Education.LowerThanSecondaryEducation, 'Ниже среднего'],
  [Education.RussianMba, 'Российское МВА'],
  [Education.ForeignMba, 'Иностранное МВА'],
  [Education.Other, 'Иное'],
]);

export const FileTypeMap = new Map<ClientFileInfoDto['type'], string>([
  [FileType.PassportRu, 'Паспорт гражданина РФ'],
  [FileType.AssessmentReport, 'Отчёт об оценке'],
  [FileType.AssessmentReportWithSignature, 'Отчёт об оценке с УКЭП'],
  [FileType.Application, 'Заявление'],
  [FileType.ApplicationFull, 'Полная анкета'],
  [FileType.Snils, 'СНИЛС'],
  [FileType.IncomeStatement, '2-НДФЛ'],
  [FileType.IncomeStatementPartTime, '2-НДФЛ'],
  [FileType.IncomeStatementPartTime2, '2-НДФЛ'],
  [FileType.IncomeStatementPartTime3, '2-НДФЛ'],
  [FileType.IncomeStatementBankFormat, 'Справка о доходах по форме банка'],
  [FileType.IncomeStatementBankFormatPartTime, 'Справка о доходах по форме банка'],
  [FileType.IncomeStatementBankFormatPartTime2, 'Справка о доходах по форме банка'],
  [FileType.IncomeStatementBankFormatPartTime3, 'Справка о доходах по форме банка'],
  [FileType.AccountStatementMainJob, 'Выписка по счету'],
  [FileType.AccountStatementPartTime, 'Выписка по счету'],
  [FileType.AccountPartStatementTime2, 'Выписка по счету'],
  [FileType.AccountPartStatementTime3, 'Выписка по счету'],
  [FileType.EmploymentHistory, 'Трудовая книжка'],
  [FileType.EmploymentContractMainJob, 'Трудовой договор (Основное место работы)'],
  [FileType.EmploymentContract, 'Трудовой договор'],
  [FileType.EmploymentContract2, 'Трудовой договор'],
  [FileType.EmploymentContract3, 'Трудовой договор'],
  [FileType.Other, 'Другие документы'],
  [FileType.OtherMainJob, 'Другие документы (Основное место работы)'],
  [FileType.OtherPartTime, 'Другие документы'],
  [FileType.OtherPartTime2, 'Другие документы'],
  [FileType.OtherPartTime3, 'Другие документы'],
  [FileType.PensionCertificate, 'Справка о пенсии'],
  [FileType.TaxReturn, 'Налоговая декларация'],
  [FileType.TaxReturnPartTime, 'Налоговая декларация'],
  [FileType.TaxReturnPartTime2, 'Налоговая декларация'],
  [FileType.TaxReturnPartTime3, 'Налоговая декларация'],
  [FileType.SelfEmployedCertificate, 'Справка о постановке на учёт самозанятого'],
  [FileType.SelfEmployedCertificatePartTime, 'Справка о постановке на учёт самозанятого'],
  [FileType.SelfEmployedCertificatePartTime2, 'Справка о постановке на учёт самозанятого'],
  [FileType.SelfEmployedCertificatePartTime3, 'Справка о постановке на учёт самозанятого'],
  [FileType.ProfessionalIncomeTaxCertificate, 'Справка по налогу на профессиональный доход'],
  [FileType.ProfessionalIncomeTaxCertificatePartTime, 'Справка по налогу на профессиональный доход'],
  [FileType.ProfessionalIncomeTaxCertificatePartTime2, 'Справка по налогу на профессиональный доход'],
  [FileType.ProfessionalIncomeTaxCertificatePartTime3, 'Справка по налогу на профессиональный доход'],
  [FileType.MarriageCertificate, 'Свидетельство о браке'],
  [FileType.DivorceCertificate, 'Свидетельство о расторжении брака'],
  [FileType.BirthCertificate, 'Свидетельство о рождении'],
  [FileType.PassportFirstPage, 'Первый разворот паспорта'],
  [FileType.PassportRegistrationPage, 'Второй разворот паспорта'],
  [FileType.Passport6And7Page, 'Третий разворот паспорта'],
  [FileType.Passport8And9Page, 'Четвертый разворот паспорта'],
  [FileType.Passport10And11Page, 'Пятый разворот паспорта'],
  [FileType.Passport12And13Page, 'Шестой разворот паспорта'],
  [FileType.Passport14And15Page, 'Седьмой разворот паспорта'],
  [FileType.Passport16And17Page, 'Восьмой разворот паспорта'],
  [FileType.Passport18And19Page, 'Девятый разворот паспорта'],
  [FileType.Inn, 'ИНН'],
  [FileType.DriverLicense, 'Водительское удостоверение'],
  [FileType.ForeignPassport, 'Заграничный паспорт'],
  [FileType.MilitaryId, 'Военный билет'],
  [FileType.Archive, 'Файлы в архиве'],
  [FileType.FinancialAnalysisConclusion, 'Заключение - фин. анализ'],
  [FileType.Sopd, 'Согласие на обработку персональных данных (СОПД)'],
  [FileType.Passport, 'Паспорт'],
  [FileType.ApplicationBank, 'Анкета заёмщика по форме банка'],
  [FileType.LifeInsuranceApplication, 'Заявление на страхование жизни'],
  [FileType.DeclarationOfNonMarriage, 'Заявление об отсутствии брака'],
  [FileType.LifeInsurancePaymentConfirmation, 'Подтверждение оплаты страхования жизни'],
  [FileType.InsuranceQuestionnaire, 'Анкета по страхованию'],
  [FileType.CreditAgreement, 'Кредитный договор'],
  [FileType.PaymentSchedule, 'График платежей'],
  [FileType.ExtractFromSmallCreditCommittee, 'Выписка из протокола Малого Кредитного Комитета'],
  [FileType.Other, 'Прочие документы'],
  [FileType.SellersReceipt, 'Расписка продавца(ов) в получении денежных средств'],
  [FileType.AssessmentReport, 'Отчёт об оценке'],
  [FileType.PaymentOrderToseller, 'Платежное поручение на перечисление средств продавцу'],
  [FileType.CollectionConsent, 'Согласие клиента для Collection'],
  [FileType.ShareParticipationContract, 'Договор долевого участия (ДДУ)'],
  [FileType.AgencyContract, 'Агентский договор'],
  [FileType.IncomesVerification, 'Справка о доходах'],
  [FileType.ComplexPdf, 'Составной PDF'],
  [FileType.EquityAgreement, 'Договор долевого участия'],
  [FileType.PartialEquityAgreement, 'Соглашение о частичном капитале'],
  [FileType.EquityAgreementWithAd, 'Договор Долевого участия с АД'],
  [FileType.ContractOfSale, 'Договор купли-продажи'],
  [FileType.BeforeContractOfSale, 'Предварительный договор купли-продажи'],
  [FileType.BeforeContractOfSaleWithAd, 'Предварительный договор купли-продажи с рекламой'],
  [FileType.AgreementOnClaimsAssignment, 'Соглашение об уступке прав требования'],
  [FileType.PartnerFile, 'Файл застройщика'],
  [FileType.ClientInsuranceApplication, 'Заявление на страхование жизни Заёмщика(ов)'],
  [FileType.ClientInsuranceContract, 'Договор страхования жизни Заёмщика(ов)'],
  [FileType.PropertyAndTitleInsuranceContract, 'Договор страхования имущества и титула'],
  [FileType.PropertyAndTitleInsuranceApplication, 'Заявление на страхование имущества и титула'],
  [FileType.OpeningLetterOfCredit, 'Заявление на открытие аккредитива'],
  [FileType.HouseBookExtract, 'Выписка из домовой книги'],
  [FileType.CadastralPassport, 'Технический паспорт/Кадастровый паспорт'],
  [FileType.TitleDocuments, 'Правоустанавливающие документы'],
  [FileType.EgrnExtract, 'Свидетельство о собственности или выписка из ЕГРН'],
  [FileType.OtherObject, 'Прочие документы по объекту'],
  [FileType.Mortgage, 'Закладная'],
  [FileType.BeforeContractOfSale, 'Предварительный договор купли-продажи (ПредДКП)'],
  [FileType.Mkk, 'Выписка из протокола МКК'],
  [FileType.ConclusionOnApplication, 'Заключение по заявке на ипотечный кредит'],
  [
    FileType.ConclusionOnApplicationWithoutPersonalData,
    'Заключение по заявке на ипотечный кредит без персональных данных',
  ],
  [FileType.PartialEquityAgreement, 'ДУ по ДДУ'],
  [FileType.PrimaryEquityAgreement, 'Первичный ДДУ'],
  [FileType.EquityAgreementPaymentConfirmation, 'Документы подтверждающие оплату по ДДУ (ДУ и ДДУ)'],
  [FileType.TitleDossier, 'Титульное досье'],
  [FileType.TransferAcceptanceAct, 'Акт ПП'],
  [FileType.ApplicationBank, 'Анкета заемщика(ов)'],
  [FileType.ElectronicEmploymentHistory, 'Электронная трудовая книжка'],
  [FileType.NoticeOfTheRightToRefuseAdditionalServices, 'Уведомление о праве отказаться от дополнительных услуг'],
  [FileType.Pfr, 'Выписка из ПФР'],
]);

export const MaritalContractMap = new Map<MaritalContract, string>([
  [MaritalContract.Yes, 'Есть'],
  [MaritalContract.No, 'Нет'],
  [MaritalContract.WillBeSigned, 'Будет заключен до сделки'],
]);

export const MaritalStatusMap = new Map<MaritalStatus, string>([
  [MaritalStatus.Married, 'Женат/замужем'],
  [MaritalStatus.CivilMarriage, 'Гражданский брак'],
  [MaritalStatus.Single, 'Холост/не замужем'],
  [MaritalStatus.Divorced, 'Разведен(-а)'],
  [MaritalStatus.Widow, 'Вдовец/вдова'],
]);

export const ObjectTypeMap = new Map<ObjectType, string>([
  [ObjectType.Flat, 'Квартира'],
  [ObjectType.Apartments, 'Апартаменты'],
  [ObjectType.Townhouse, 'Таунхаус'],
  [ObjectType.PrivateHouse, 'Дом/участок'],
  [ObjectType.CommercialRooms, 'Коммерческое помещение'],
  [ObjectType.ParkingPlace, 'Машино-место'],
  [ObjectType.PantryAndOther, 'Кладовые и прочее'],
  [ObjectType.Refinancing, 'Рефинансирование'],
  [ObjectType.Perspective, 'Перспектива'],
]);

export const OrganizationLifetimeMap = new Map<OrganizationLifetime, string>([
  [OrganizationLifetime.UnderTwoYears, 'До 2 лет'],
  [OrganizationLifetime.BetweenTwoAndFiveYears, 'От 2 до 5 лет'],
  [OrganizationLifetime.MoreThanFiveYears, 'Более 5 лет'],
]);

export const ReasonForResidencesMap = new Map<ReasonForResidence, string>([
  [ReasonForResidence.Property, 'Собственность'],
  [ReasonForResidence.SocialHousing, 'Социальный найм'],
  [ReasonForResidence.Rent, 'Аренда'],
  [ReasonForResidence.MilitaryUnit, 'Воинская часть'],
  [ReasonForResidence.RelativesProperty, 'Жилье родственников'],
]);

export const RepresentativeDocumentTypeMap = new Map<RepresentativeDocumentType, string>([
  [RepresentativeDocumentType.PowerOfAttorney, 'Доверенность'],
  [RepresentativeDocumentType.OrderOfAppointment, 'Приказ о назначении (руководителя или иного уполномоченного лица)'],
  [RepresentativeDocumentType.OtherDocument, 'Иной документ'],
  [RepresentativeDocumentType.CourtRuling, 'Определение суда'],
  [RepresentativeDocumentType.Judgment, 'Решение суда'],
  [RepresentativeDocumentType.ProtocolOfGeneralMeeting, 'Протокол общего собрания'],
  [
    RepresentativeDocumentType.ProtocolOfElection,
    'Протокол об избрании (единоличного исполнительного органа юридического лица)',
  ],
  [
    RepresentativeDocumentType.ExtractFromProtocolOfElection,
    'Выписка из протокола об избрании (единоличного исполнительного органа юридического лица)',
  ],
  [
    RepresentativeDocumentType.ActOfAuthorityConfirmingAuthority,
    'Акт органа государственной власти или органа местного самоуправления,\n' +
      'подтверждающий полномочия представителя заявителя.',
  ],
]);

export const SexMap = new Map<Sex, string>([
  [Sex.Male, 'Мужской'],
  [Sex.Female, 'Женский'],
]);

export const SpouseSocialStatusMap = new Map<SpouseSocialStatus, string>([
  [SpouseSocialStatus.Working, 'Работает'],
  [SpouseSocialStatus.NotWorking, 'Не работает'],
  [SpouseSocialStatus.Retired, 'На пенсии'],
]);

export const StaffNumberMap = new Map<StaffNumber, string>([
  [StaffNumber.LessThan10, 'менее 10'],
  [StaffNumber.Between10And50, '10–50'],
  [StaffNumber.Between50And100, '50–100'],
  [StaffNumber.Between100And200, '100-200'],
  [StaffNumber.Between200And500, '200-500'],
  [StaffNumber.MoreThan500, 'более 500'],
]);

export const VerificatorStatusNameMap: Record<VerificatorStatus, string> = {
  NotReady: 'Не готов',
  Ready: 'Готов',
  Lunch: 'Обед',
  Break: 'Перерыв',
  InProgress: 'В обработке',
  OutgoingCall: 'Исходящий звонок',
};

export const VerificatorTaskTypeNameMap: Partial<Record<VerificationTaskType, string>> = {
  Passport: 'Паспорт (2-3 страница)',
  EmploymentHistory: 'Трудовая книжка',
  ElectronicEmploymentHistory: 'Электронная трудовая книжка',
  EmploymentContract: 'Трудовой договор',
  PreviousPassportsRu: 'Ранее выданные паспорта',
  Snils: 'СНИЛС',
  TaxNumber: 'ИНН',
  IncomeStatement: '2-НДФЛ',
  IncomeStatementBankFormat: 'Справка о доходах по форме банка',
  PassportRegistration: 'Паспорт (4-13 страницы)',
  SOPD: 'Согласие на обработку персональных данных / Полная анкета',
  SOPDFinalization: 'Согласие на обработку персональных данных',
  EmploymentContract2: 'Трудовой договор',
  EmploymentContract3: 'Трудовой договор',
  EmploymentContractMainJob: 'Трудовой договор',
  IncomeStatementPartTime: '2-НДФЛ',
  IncomeStatementPartTime2: '2-НДФЛ',
  IncomeStatementPartTime3: '2-НДФЛ',
  IncomeStatementBankFormatPartTime: 'Справка о доходах по форме банка',
  IncomeStatementBankFormatPartTime2: 'Справка о доходах по форме банка',
  IncomeStatementBankFormatPartTime3: 'Справка о доходах по форме банка',
  TaxReturn: 'Налоговая декларация',
  TaxReturnPartTime: 'Налоговая декларация',
  TaxReturnPartTime2: 'Налоговая декларация',
  TaxReturnPartTime3: 'Налоговая декларация',
  PensionCertificate: 'Справка о пенсии',
  SelfEmployedCertificate: 'Справка о постановке на учёт самозанятого',
  SelfEmployedCertificatePartTime: 'Справка о постановке на учёт самозанятого',
  SelfEmployedCertificatePartTime2: 'Справка о постановке на учёт самозанятого',
  SelfEmployedCertificatePartTime3: 'Справка о постановке на учёт самозанятого',
  ProfessionalIncomeTaxCertificate: 'Справка по налогу на профессиональный доход',
  ProfessionalIncomeTaxCertificatePartTime: 'Справка по налогу на профессиональный доход',
  ProfessionalIncomeTaxCertificatePartTime2: 'Справка по налогу на профессиональный доход',
  ProfessionalIncomeTaxCertificatePartTime3: 'Справка по налогу на профессиональный доход',
  Call: 'Верификация номера',
  FileTypification: 'Типизация документа',
  PensionFund: 'Выписка из ПФР',

  // https://tracker.yandex.ru/FNIP-2271
  // DriverLicense, 'Водительское удостоверение'],
  // ForeignPassport, 'Заграничный паспорт'],
  // MilitaryId, 'Военный билет'],
};

export const VerificationDocumentStatusNameMap: Record<VerificationAllDocumentStatus, string> = {
  AllTasks: 'Все задания',
  ForRevision: 'Доработка',
  Pending: 'Не проверено',
  Processed: 'Обработано',
  RedirectedToSupervisor: 'Направлено на супервайзера',
  ApplicationArchived: 'Заявка отправлена в архив',
  Archived: 'Архивирована',
  Postponed: 'Отложено',
  Pause: 'Пауза',
  MyTasks: 'Мои задания',
};

export const LandCategoryNameMap = new Map<LandCategory, string>([
  [LandCategory.AgriculturalLand, 'Земли сельскохозяйственного назначения'],
  [LandCategory.PopulatedLand, 'Земли населенных пунктов'],
  [LandCategory.IndustrialLand, 'Земли промышленности и иного специального назначения'],
  [LandCategory.ProtectedLand, 'Земли особо охраняемых территорий и объектов'],
  [LandCategory.ForestLand, 'Земли лесного фонда'],
  [LandCategory.WaterLand, 'Земли водного фонда'],
  [LandCategory.ReserveLand, 'Земли запаса'],
]);

export const OwnershipDwellingNameMap = new Map<OwnershipType, string>([
  [OwnershipType.Joint, 'Общая совместная собственность'],
  [OwnershipType.Share, 'Долевая собственность'],
  [OwnershipType.Individual, 'Единоличная собственность'],
]);

export const OwnershipLandNameMap = new Map<OwnershipType, string>([
  [OwnershipType.Joint, 'Общая совместная собственность'],
  [OwnershipType.Share, 'Долевая собственность'],
  [OwnershipType.Individual, 'Единоличная собственность'],
  [OwnershipType.Use, 'Постоянное (бессрочное) пользование'],
  [OwnershipType.Possession, 'Пожизненное наследуемое владение'],
  [OwnershipType.Gratuitous, 'Безвозмездное срочное пользование'],
  [OwnershipType.Rent, 'Аренда'],
]);

export const ConditionTypeMap = new Map<ConditionType, string>([
  [ConditionType.Common, 'Общее условие'],
  [ConditionType.Seller, 'Условие продавца'],
  [ConditionType.Buyer, 'Условие покупателя'],
]);

export const PaymentTypesPVNameMap = new Map<PaymentType, string>([
  [PaymentType.CashMoneyToSeller, 'Наличными продавцу'],
  [PaymentType.MoneyTransferToSellerFromAb, 'Перевод денег на счет продавца со счета в АБ'],
  [PaymentType.MoneyTransferToSellerNotFromAb, 'Перевод денег на счет продавца не со счета в АБ'],
  [PaymentType.InclusionToLetterOfCredit, 'Включение в аккредитив'],
  [PaymentType.IndividualBankSafe, 'ИБС'],
  [PaymentType.StateSubsidyIncludingMaternalCapital, 'Гос.субсидия (включая средства Материнского капитала)'],
  [PaymentType.Escrow, 'Перевод денег на эскроу-счет заемщика'],
  [PaymentType.UsingANominalAccount, 'Номинальный счет'],
]);

export const RegistrationTypeNameMap = new Map<RegistrationType, string>([
  [RegistrationType.StandartRegistration, 'Стандартная регистрация'],
  [RegistrationType.KdPlusDduDkp, 'КД бумага + ДДУ/ДКП ЭЦП + бумажная закладная'],
  [RegistrationType.EcpPlusPaperElectricRegistrationPlusPaperContract, 'Электронная регистрация + бумажная закладная'],
  [
    RegistrationType.EcpPlusPaperElectricRegistrationPlusElectricContract,
    'Электронная регистрация + электронная закладная',
  ],
]);

export const InsuranceSchemeStatusMap = new Map<InsuranceScheme, string>([
  [InsuranceScheme.Collective, 'Коллективное'],
  [InsuranceScheme.Agency, 'Агентское'],
]);

export const BlacklistCheckStatusMap = new Map<BlacklistCheckStatus, string>([
  [BlacklistCheckStatus.Refusal, 'Отказ'],
  [BlacklistCheckStatus.Authorizer2, 'Авторизатор 2'],
  [BlacklistCheckStatus.RemovedFromBlacklist, 'Исключен из черного списка'],
]);

export const ContractTypeMap = new Map<DealRegistrationType, string>([
  [DealRegistrationType.EquityAgreement, 'ДДУ'],
  [DealRegistrationType.EquityAgreementWithAd, 'ДДУ с АД'],
  [DealRegistrationType.ContractOfSale, 'ДКП'],
  [DealRegistrationType.PartialEquityAgreement, 'ДУ по ДДУ'],
  [DealRegistrationType.BeforeContractOfSale, 'ПредДКП'],
  [DealRegistrationType.BeforeContractOfSaleWithAd, 'ПредДКП с АД'],
]);

export const OwnerTypeMap = new Map<OwnerType, string>([
  [OwnerType.Organization, 'Юридическое лицо'],
  [OwnerType.PhysicalPerson, 'Физическое лицо'],
]);

export const ReceivingTypeMap = new Map<ReceivingType, string>([
  [ReceivingType.BeforeRegistrationWithoutNote, 'До регистрации без расписки'],
  [ReceivingType.BeforeRegistrationWithNote, 'До регистрации по расписке'],
  [ReceivingType.AfterRegistration, 'После регистрации'],
]);

export const TransferTypeMap: Partial<Record<TransferType, string>> = {
  LetterOfCredit: 'Аккредитив',
  ToTheSellerAccount: 'На счет продавца',
  IndividualBankSafe: 'Индивидуальный банковский сейф (ИБС)',
  SecurityAccount: 'Залоговый счет',
  NominalAccount: 'Номинальный счет',
  Undefined: 'Не указано',
};

export const MaterialTypeMap = new Map<MaterialType, string>([
  [MaterialType.Panel, 'Панель'],
  [MaterialType.Brick, 'Кирпич'],
  [MaterialType.Monolith, 'Монолит'],
]);

export const JobTypeMap = new Map<JobType, string>([
  [JobType.MainJob, 'Основная занятость'],
  [JobType.PartTimeJob, 'Занятость по совместительству'],
  [JobType.SecondPartTimeJob, 'Вторая занятость по совместительству'],
  [JobType.ThirdPartTimeJob, 'Третья занятость по совместительству'],
]);

export const PreviousPassportMap = new Map<DocumentType, string>([
  [DocumentType.PreviousPassportRu, 'Предыдущий паспорт'],
  [DocumentType.PreviousPassportRu2, 'Предыдущий паспорт'],
  [DocumentType.PreviousPassportRu3, 'Предыдущий паспорт'],
]);

export const VerificationCallResultMap = new Map<VerificationCallResult, string>([
  [VerificationCallResult.NonExistingNumber, 'Номер не существует или нет гудков'],
  [VerificationCallResult.BlockedNumber, 'Номер заблокирован'],
  [VerificationCallResult.Unavailable, 'Абонент недоступен'],
  [VerificationCallResult.NoAnswer, 'Абонент сбрасывает и/или не отвечает'],
  [VerificationCallResult.RefusedToTalk, 'Отказ разговаривать'],
  [VerificationCallResult.WrongNumber, 'Номер принадлежит другому человеку / компании'],
  [VerificationCallResult.LoanNotNeeded, 'Клиент отказался от кредита'],
  [VerificationCallResult.CallBack, 'Перезвонить'],
]);

export const VerificatorTaskTypeToFileTypeMap = new Map<FileType | VerificationTaskType, VerificationTaskType>([
  [FileType.ApplicationFull, VerificationTaskType.Sopd],
  [FileType.PassportFirstPage, VerificationTaskType.Passport],
  [FileType.EmploymentHistory, VerificationTaskType.EmploymentHistory],
  [FileType.ElectronicEmploymentHistory, VerificationTaskType.ElectronicEmploymentHistory],
  [FileType.EmploymentContract, VerificationTaskType.EmploymentContract],
  [FileType.Passport18And19Page, VerificationTaskType.PreviousPassportsRu],
  [FileType.Snils, VerificationTaskType.Snils],
  [FileType.Inn, VerificationTaskType.TaxNumber],
  [FileType.IncomeStatement, VerificationTaskType.IncomeStatement],
  [FileType.IncomeStatementBankFormat, VerificationTaskType.IncomeStatementBankFormat],
  [VerificationTaskType.PassportRegistration, VerificationTaskType.PassportRegistration],
  [FileType.Sopd, VerificationTaskType.Sopd],
  [FileType.EmploymentContract2, VerificationTaskType.EmploymentContract2],
  [FileType.EmploymentContract3, VerificationTaskType.EmploymentContract3],
  [FileType.EmploymentContractMainJob, VerificationTaskType.EmploymentContractMainJob],
  [FileType.IncomeStatementPartTime, VerificationTaskType.IncomeStatementPartTime],
  [FileType.IncomeStatementPartTime2, VerificationTaskType.IncomeStatementPartTime2],
  [FileType.IncomeStatementPartTime3, VerificationTaskType.IncomeStatementPartTime3],
  [FileType.IncomeStatementBankFormatPartTime, VerificationTaskType.IncomeStatementBankFormatPartTime],
  [FileType.IncomeStatementBankFormatPartTime2, VerificationTaskType.IncomeStatementBankFormatPartTime2],
  [FileType.IncomeStatementBankFormatPartTime3, VerificationTaskType.IncomeStatementBankFormatPartTime3],
  [FileType.TaxReturn, VerificationTaskType.TaxReturn],
  [FileType.TaxReturnPartTime, VerificationTaskType.TaxReturnPartTime],
  [FileType.TaxReturnPartTime2, VerificationTaskType.TaxReturnPartTime2],
  [FileType.TaxReturnPartTime3, VerificationTaskType.TaxReturnPartTime3],
  [FileType.PensionCertificate, VerificationTaskType.PensionCertificate],
  [FileType.SelfEmployedCertificate, VerificationTaskType.SelfEmployedCertificate],
  [FileType.SelfEmployedCertificatePartTime, VerificationTaskType.SelfEmployedCertificatePartTime],
  [FileType.SelfEmployedCertificatePartTime2, VerificationTaskType.SelfEmployedCertificatePartTime2],
  [FileType.SelfEmployedCertificatePartTime3, VerificationTaskType.SelfEmployedCertificatePartTime3],
  [FileType.ProfessionalIncomeTaxCertificate, VerificationTaskType.ProfessionalIncomeTaxCertificate],
  [FileType.ProfessionalIncomeTaxCertificatePartTime, VerificationTaskType.ProfessionalIncomeTaxCertificatePartTime],
  [FileType.ProfessionalIncomeTaxCertificatePartTime2, VerificationTaskType.ProfessionalIncomeTaxCertificatePartTime2],
  [FileType.ProfessionalIncomeTaxCertificatePartTime3, VerificationTaskType.ProfessionalIncomeTaxCertificatePartTime3],
  [FileType.Pfr, VerificationTaskType.PensionFund],

  // https://tracker.yandex.ru/FNIP-2271
  // [FileType.DriverLicense, VerificationTaskType.DriverLicense],
  // [FileType.ForeignPassport, VerificationTaskType.ForeignPassport],
  // [FileType.MilitaryId, VerificationTaskType.MilitaryId],
]);

export const FileTypeToVerificatorTaskTypeMap = new Map<VerificationTaskType, FileType>([
  [VerificationTaskType.Sopd, FileType.ApplicationFull],
  [VerificationTaskType.Passport, FileType.PassportFirstPage],
  [VerificationTaskType.EmploymentHistory, FileType.EmploymentHistory],
  [VerificationTaskType.ElectronicEmploymentHistory, FileType.ElectronicEmploymentHistory],
  [VerificationTaskType.EmploymentContract, FileType.EmploymentContract],
  [VerificationTaskType.Passport14And15Page, FileType.Passport14And15Page],
  [VerificationTaskType.Passport16And17Page, FileType.Passport16And17Page],
  [VerificationTaskType.PreviousPassportsRu, FileType.Passport18And19Page],
  [VerificationTaskType.Snils, FileType.Snils],
  [VerificationTaskType.TaxNumber, FileType.Inn],
  [VerificationTaskType.IncomeStatement, FileType.IncomeStatement],
  [VerificationTaskType.IncomeStatementBankFormat, FileType.IncomeStatementBankFormat],
  [VerificationTaskType.PassportRegistration, FileType.PassportRegistrationPage],
  [VerificationTaskType.Sopd, FileType.Sopd],
  [VerificationTaskType.EmploymentContract2, FileType.EmploymentContract2],
  [VerificationTaskType.EmploymentContract3, FileType.EmploymentContract3],
  [VerificationTaskType.EmploymentContractMainJob, FileType.EmploymentContractMainJob],
  [VerificationTaskType.IncomeStatementPartTime, FileType.IncomeStatementPartTime],
  [VerificationTaskType.IncomeStatementPartTime2, FileType.IncomeStatementPartTime2],
  [VerificationTaskType.IncomeStatementPartTime3, FileType.IncomeStatementPartTime3],
  [VerificationTaskType.IncomeStatementBankFormatPartTime, FileType.IncomeStatementBankFormatPartTime],
  [VerificationTaskType.IncomeStatementBankFormatPartTime2, FileType.IncomeStatementBankFormatPartTime2],
  [VerificationTaskType.IncomeStatementBankFormatPartTime3, FileType.IncomeStatementBankFormatPartTime3],
  [VerificationTaskType.TaxReturn, FileType.TaxReturn],
  [VerificationTaskType.TaxReturnPartTime, FileType.TaxReturnPartTime],
  [VerificationTaskType.TaxReturnPartTime2, FileType.TaxReturnPartTime2],
  [VerificationTaskType.TaxReturnPartTime3, FileType.TaxReturnPartTime3],
  [VerificationTaskType.PensionCertificate, FileType.PensionCertificate],
  [VerificationTaskType.SelfEmployedCertificate, FileType.SelfEmployedCertificate],
  [VerificationTaskType.SelfEmployedCertificatePartTime, FileType.SelfEmployedCertificatePartTime],
  [VerificationTaskType.SelfEmployedCertificatePartTime2, FileType.SelfEmployedCertificatePartTime2],
  [VerificationTaskType.SelfEmployedCertificatePartTime3, FileType.SelfEmployedCertificatePartTime3],
  [VerificationTaskType.ProfessionalIncomeTaxCertificate, FileType.ProfessionalIncomeTaxCertificate],
  [VerificationTaskType.ProfessionalIncomeTaxCertificatePartTime, FileType.ProfessionalIncomeTaxCertificatePartTime],
  [VerificationTaskType.ProfessionalIncomeTaxCertificatePartTime2, FileType.ProfessionalIncomeTaxCertificatePartTime2],
  [VerificationTaskType.ProfessionalIncomeTaxCertificatePartTime3, FileType.ProfessionalIncomeTaxCertificatePartTime3],
]);

export const DocumentSigningTypeMap = new Map<DocumentSigningType, string>([
  [DocumentSigningType.Himself, 'Продавец собственноручно'],
  [DocumentSigningType.LegalRepresentative, 'Законный представитель продавца'],
  [DocumentSigningType.NotarizedRepresentative, 'Представитель по нотариальной доверенности'],
]);

export const InseparableImprovementTypeNameMap = new Map<InseparableImprovementsType, string>([
  [InseparableImprovementsType.RoughFinish, 'Черновая отделка'],
  [InseparableImprovementsType.InteriorDecoration, 'Внутренняя отделка'],
]);

export const VerificatorGeneralTaskTypeMap = new Map<VerificationGeneralTaskType, string>([
  [VerificationGeneralTaskType.All, 'Все задания'],
  [VerificationGeneralTaskType.Call, 'Верификация номера'],
  [VerificationGeneralTaskType.Document, 'Верификация документа'],
]);

export const AdditionalIncomeTypeLabelMap = new Map<AdditionalIncomeType, string>([
  [AdditionalIncomeType.Pension, 'Пенсия'],
  [AdditionalIncomeType.Rent, 'Аренда'],
  [AdditionalIncomeType.Other, 'Иной доход'],
]);

export const InsuranceFormMap = new Map<InsuranceForm, string>([
  [InsuranceForm.Ass, 'АСС'],
  [InsuranceForm.Kss, 'КСС'],
  [InsuranceForm.KssAndKssPlus, 'КСС+'],
  [InsuranceForm.WithoutInsurance, 'Без страховки'],
]);

export const MaternalCapitalTypeMap: FromGeneratedEnum<MaternalCapitalType> = {
  [MaternalCapitalType.IncreasingLoanAmount]: 'Увеличение суммы кредита на сумму материнского капитала (в счет ЧДП)',
  [MaternalCapitalType.InitialPayment]: 'Уплата материнского капитала первоначальным взносом (в счет оплаты продавцу)',
};

export const DealVerificationTaskTypeMap: Record<DealVerificationTaskType, string> = {
  Delivery: 'Выдача',
  Documents: 'Сделка',
};

export const AppraisalCompanyStatusMap = new Map<AppraisalCompanyStatus, string>([
  [AppraisalCompanyStatus.Accredited, 'Аккредитована'],
  [AppraisalCompanyStatus.AccreditedWithCondition, 'Аккредитована с условиями'],
  [AppraisalCompanyStatus.NotAccredited, 'Не аккредитована'],
  [AppraisalCompanyStatus.Other, 'Другое'],
]);
