import { TuiDay, TuiTime } from '@taiga-ui/cdk';
import { TuiLinkComponent } from '@taiga-ui/core';
import { FromGeneratedEnum, Nullable } from '@lib-utils';
import {
  ApplicationDecisionType,
  ClientCreditDebtLoadInfoDto,
  CreditInfoShortDto,
  CreditPurpose,
  ManualPti,
  ManualReviewDecisionType,
  ManualReviewTaskDto,
  ManualReviewTaskType,
  MaternalCapitalType,
  ObjectType,
  OccupationType,
  PhoneVerificationServiceResolution,
  ProofOfIncome,
  TextSpecialConditionDto,
} from '@lib-mortgage/api';
import { FeApplicationTaskDto } from './api-middleware';
import { FeCalculationsDto, FeClientDto } from './api-middleware-calculation';

// Интерфейс, обслуживающий форму на странице авторизатора/андеррайтера
export interface FeAuthorizerUnderwriterDto {
  manualReviewTask?: FeManualReviewTaskDto;
  creditInfo?: FeCreditInfoDto;
  specialConditions?: FeClientCreditDebtLoadInfoDto[];
  textSpecialConditions?: TextSpecialConditionDto[];
  clients?: FeClientDto[];
  tasks?: FeApplicationTaskDto[];
  calculation?: FeCalculationsDto;
}

export interface ApplicationMenuItemDto {
  sectionName: Nullable<string>;
  sectionId: AAASectionId;
  mode?: TuiLinkComponent['mode'];
  sectionIcon?: Nullable<string>;
  subsections?: ApplicationMenuItemDto[];
}

export interface FeManualReviewTaskDto extends ManualReviewTaskDto {
  isApplicationReturned?: boolean;
  postponeTimeTui?: [TuiDay, TuiTime];
  // rejectionTypes?: AuthorizerRejectionType[];
}

export interface FeCreditInfoDto extends CreditInfoShortDto {
  occupationType?: OccupationType;
  proofOfIncome?: ProofOfIncome;
  creditProductName?: string;
  objectType?: ObjectType;
  creditPurpose?: CreditPurpose;
  maternalCapitalEnabled?: boolean;
  maternalCapitalType?: MaternalCapitalType;
  maternalCapital?: number;
  stateSubsidyEnabled?: boolean;
  stateSubsidy?: number;
  creditMax?: number;
  creditMin?: number;
  periodMax?: number;
  periodMin?: number;
}

export interface FeClientCreditDebtLoadInfoDto extends Omit<ClientCreditDebtLoadInfoDto, 'client'> {
  client: {
    id?: number;
    fullName?: string;
    isIncomePart?: boolean;
  };
}

export const UnderwriterDecisionTypeMap = new Map<ManualReviewDecisionType, string>([
  [ManualReviewDecisionType.Approved, 'Проверено'],
  [ManualReviewDecisionType.Postpone, 'Отложить'],
  [ManualReviewDecisionType.ForManagerRevision, 'На доработку'],
]);

export const FirstAuthorizerDecisionTypeMap = new Map<ManualReviewDecisionType, string>([
  [ManualReviewDecisionType.Approved, 'Одобрено'],
  [ManualReviewDecisionType.Rejection, 'Отклонено'],
  [ManualReviewDecisionType.Postpone, 'Отложить'],
  [ManualReviewDecisionType.ForManagerRevision, 'На доработку менеджеру'],
  [ManualReviewDecisionType.ForUnderwriterRevision, 'На доработку Андеррайтеру'],
  [ManualReviewDecisionType.RedirectToAnotherAuthorizer, 'Перевести на Авторизатора 2'],
]);

export const SecondAuthorizerDecisionTypeMap = new Map<ManualReviewDecisionType, string>([
  [ManualReviewDecisionType.Approved, 'Одобрить'],
  [ManualReviewDecisionType.Rejection, 'Отклонено'],
  [ManualReviewDecisionType.Postpone, 'Отложить'],
  [ManualReviewDecisionType.ForManagerRevision, 'На доработку менеджеру'],
  [ManualReviewDecisionType.ForUnderwriterRevision, 'На доработку Андеррайтеру'],
  [ManualReviewDecisionType.RedirectToAnotherAuthorizer, 'Перевести на Авторизатора 1'],
]);

export const PhoneVerificationResolutionMap = new Map<PhoneVerificationServiceResolution, string>([
  [PhoneVerificationServiceResolution.Negative, 'негативное'],
  [PhoneVerificationServiceResolution.Risk, 'риск'],
  [PhoneVerificationServiceResolution.Positive, 'позитивное'],
]);

export const ManualPtiMap: Record<ManualPti, number> = {
  FortyFive: 45,
  Fifty: 50,
  FiftyFive: 55,
  Sixty: 60,
  SixtyFive: 65,
};

export const ManualReviewTaskTypeMap: FromGeneratedEnum<ManualReviewTaskType> = {
  [ManualReviewTaskType.FirstAuthorizer]: 'Авторизатор 1',
  [ManualReviewTaskType.SecondAuthorizer]: 'Авторизатор 2',
  [ManualReviewTaskType.Underwriter]: 'Андеррайтер',
};

export const ApplicationDecisionTypeMap: FromGeneratedEnum<ApplicationDecisionType> = {
  [ApplicationDecisionType.Approval]: 'Одобрено',
  [ApplicationDecisionType.Rejection]: 'Отклонено',
  [ApplicationDecisionType.MovingToManagerRevision]: 'На доработку менеджеру',
  [ApplicationDecisionType.MovingToUnderwriterRevision]: 'На доработку Андеррайтеру',
  [ApplicationDecisionType.RedirectingToAnotherAuthorizer]: 'Перевести на другого Авторизатора',
};

export const AAAPageSection = {
  Tasks: 'Tasks',
  DecisionHistory: 'DecisionHistory',
  Decision: 'Decision',
  Comments: 'Comments',
  CreditInfo: 'CreditInfo',
  Calculation: 'Calculation',
  SpecialConditions: 'SpecialConditions',
  Pdn1: 'Pdn1',
  Pdn2: 'Pdn2',
  Clients: 'Clients',
  Upload: 'Upload',
} as const;

export type AAAPageSection = (typeof AAAPageSection)[keyof typeof AAAPageSection];

export const AAAPageSectionName: FromGeneratedEnum<AAAPageSection> = {
  [AAAPageSection.Tasks]: 'Задачи',
  [AAAPageSection.DecisionHistory]: 'История решений',
  [AAAPageSection.Decision]: 'Примите решение по заявке',
  [AAAPageSection.Comments]: 'Комментарии к заявке',
  [AAAPageSection.CreditInfo]: 'Информация о кредите',
  [AAAPageSection.Calculation]: 'Расчёт',
  [AAAPageSection.SpecialConditions]: 'Специальные условия',
  [AAAPageSection.Pdn1]: 'Расчёт ПДН1',
  [AAAPageSection.Pdn2]: 'Расчёт ПДН2',
  [AAAPageSection.Clients]: 'Информация о заёмщике',
  [AAAPageSection.Upload]: 'Заключение - фин.анализ',
};

export const AAAClientSection = {
  Employments: 'Employments',
  AdditionalIncomes: 'AdditionalIncomes',
  AdditionalExpenses: 'AdditionalExpenses',
  Scoring: 'Scoring',
  Documents: 'Documents',
} as const;

export type AAAClientSection = (typeof AAAClientSection)[keyof typeof AAAClientSection];

export const AAAClientSectionName: FromGeneratedEnum<AAAClientSection> = {
  [AAAClientSection.Employments]: 'Занятость',
  [AAAClientSection.AdditionalIncomes]: 'Дополнительные доходы',
  [AAAClientSection.AdditionalExpenses]: 'Дополнительные расходы',
  [AAAClientSection.Scoring]: 'Скоринг',
  [AAAClientSection.Documents]: 'Документы',
};

export type AAASectionId = AAAPageSection | `Clients${number}` | `Clients${number}${AAAClientSection}`;
