import { createOptionsForLocalizedEnum, Nullable, SelectOption } from '@lib-utils';
import { CreditPurpose, LoanCalculatorOptionsDto, ObjectType } from '@lib-mortgage/api';
import { FeLoanCalculatorOptionsDto, NonStandardTypeMap } from '@lib-mortgage/api-middleware';
import { CreditPurposeMap, InsuranceFormMap, MaternalCapitalTypeMap, ObjectTypeMap, ProofOfIncomeMap } from '../maps';

export function getLoanCalculatorOptions(options: Nullable<LoanCalculatorOptionsDto>): FeLoanCalculatorOptionsDto {
  const creditPurposeOptionsMap: Partial<Record<ObjectType, SelectOption<unknown, CreditPurpose>[]>> = {};
  options?.objectTypeAndCreditPurposesCombinations?.forEach(
    ({ objectType, creditPurposes }) =>
      (creditPurposeOptionsMap[objectType!] = creditPurposes?.map((purpose) => ({
        label: CreditPurposeMap[purpose] ?? '',
        value: purpose,
      }))),
  );
  return {
    objectTypeOptions: (options?.objectTypes ?? []).map((type) => ({
      label: ObjectTypeMap.get(type) ?? '',
      value: type,
    })),
    defaultCreditPurposeOptions: (options?.creditPurposes ?? []).map((purpose) => ({
      label: CreditPurposeMap[purpose],
      value: purpose,
    })),
    creditPurposeOptionsMap,
    insuranceFormOptions: (options?.insuranceForms ?? []).map((insurance) => ({
      label: InsuranceFormMap.get(insurance) ?? '',
      value: insurance,
    })),
    proofOfIncomeOptions: (options?.proofOfIncomes ?? []).map((proof) => ({
      label: ProofOfIncomeMap[proof] ?? '',
      value: proof,
    })),
    nonStandardTypeOptions: createOptionsForLocalizedEnum(NonStandardTypeMap).map((option) => ({
      ...option,
      // при отсутствии становится недоступной для выбора в выпадающем списке
      disabled: !options?.nonStandardTypes?.includes(option.value),
    })),
    maternalCapitalTypeOptions: (options?.maternalCapital?.types ?? []).map((type) => ({
      label: MaternalCapitalTypeMap[type] ?? '',
      value: type,
    })),

    creditPeriodOptions: options?.creditPeriod,
    flexPriceOptions: options?.flexPrice,
    maternalCapitalOptions: options?.maternalCapital,
  };
}
