import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isAfter, startOfDay, subYears } from 'date-fns';
import {
  INN_10_LENGTH_ERR_MSG,
  INN_10_OR_12_LENGTH_ERR_MSG,
  INN_12_LENGTH_ERR_MSG,
  INN_INVALID_ERR_MSG,
  INN_OWN_BUSINESS_ERR_MSG,
  INN_SELF_EMPLOYED_ERR_MSG,
  INN_SOLE_PROPRIETOR_ERR_MSG,
  Nullable,
  ORGANIZATION_INN_LENGTH,
  PERSONAL_INN_LENGTH,
} from '@lib-utils';
import { OccupationType, OrganizationLifetime } from '@lib-mortgage/api';

type InnValidatorType = 'organization' | 'personal' | 'both';

export class IpotekaValidators {
  static validateAmountAndFirstPayment(controlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const val = control?.parent?.get(controlName)?.value;
      return control.value > val ? { customMessage: 'Первоначальный взнос не может быть больше стоимости' } : null;
    };
  }

  static innByOccupation(type: InnValidatorType, occupationTypeControl: AbstractControl): ValidatorFn {
    return (c) => IpotekaValidators.inn(type, occupationTypeControl.value)(c);
  }

  static startDateLessThanEqualToOrganizationLifetime(
    organizationLifetimeControl: FormControl<Nullable<OrganizationLifetime>>,
  ): ValidatorFn {
    return (startDateControl) => {
      if (!startDateControl.value || !organizationLifetimeControl.value) return null;

      const startDateValue: Date = startDateControl.value;
      const organizationLifetimeValue = organizationLifetimeControl.value;

      const startOfDate = startOfDay(new Date());
      const twoYearsAgo = subYears(startOfDate, 2);
      const fiveYearsAgo = subYears(startOfDate, 5);

      // Ораганизация существует более 5 лет, дата начала трудовой деятельности может быть любой
      if (organizationLifetimeValue === OrganizationLifetime.MoreThanFiveYears) return null;

      // Ораганизация существует <= 2 лет, дата начала трудовой деятельности не может быть больше 2 лет
      if (organizationLifetimeValue === OrganizationLifetime.UnderTwoYears && isAfter(startDateValue, twoYearsAgo))
        return null;

      // Ораганизация существует <= 5 лет, дата начала трудовой деятельности не может быть больше 5 лет
      if (
        organizationLifetimeValue === OrganizationLifetime.BetweenTwoAndFiveYears &&
        isAfter(startDateValue, fiveYearsAgo)
      )
        return null;

      return {
        startDateLessThanEqualToOrganizationLifetime:
          'Дата начала трудовой деятельности не может быть больше срока существования компании',
      };
    };
  }

  static inn(type: InnValidatorType = 'both', occupationType?: Nullable<OccupationType>): ValidatorFn {
    return (control) => {
      const value = control.value as string | null;
      if (!value) return null;
      if (/\D/.test(value)) return { inn: INN_INVALID_ERR_MSG };

      const innLengthErrors = checkInnLength(value, type);
      if (innLengthErrors) return innLengthErrors;

      const validationResult = validateInnControlSum(value);
      if (!validationResult) return { inn: INN_INVALID_ERR_MSG };

      return occupationType ? validateInnByOccupationType(value, occupationType) : null;
    };
  }

  static snils: ValidatorFn = (control) => {
    const testVal = '00000000000';
    const value = (control.value as string | null)?.replace(/\D+/g, '');
    if (!value) {
      return null;
    }

    if (/[^0-9]/.test(value) || value === testVal) {
      return {
        snils: 'Некорректный СНИЛС',
      };
    }

    if (value.length !== 11) {
      return {
        snilsLength: 'СНИЛС должен содержать 11 цифр',
      };
    }
    return validateSnilsControlSum(value)
      ? null
      : {
          snils: 'Некорректный СНИЛС',
        };
  };

  static ogrnValidLength: ValidatorFn = (control) => {
    const value = control.value as string | null;
    if (!value) return null;
    const ogrnValidLengths = [11, 13, 15];
    return ogrnValidLengths.includes(value?.length) ? null : { ogrnLength: 'ОГРН должен содержать 11, 13 или 15 цифр' };
  };

  static accountNumber: ValidatorFn = (control) => {
    if (!control?.value) return null;
    const replacedVal = control?.value?.replaceAll(' ', '').replaceAll('-', '');
    if (control?.value && replacedVal.length < 20) {
      return {
        accountNumber: 'Неверный номер счёта',
      };
    }
    return null;
  };
}

function checkInnLength(value: string, type: InnValidatorType): ValidationErrors | null {
  if (type === 'personal') {
    return value.length === PERSONAL_INN_LENGTH ? null : { innLength: INN_12_LENGTH_ERR_MSG };
  }
  if (type === 'organization') {
    return value.length === ORGANIZATION_INN_LENGTH ? null : { innLength: INN_10_LENGTH_ERR_MSG };
  }
  return value.length === PERSONAL_INN_LENGTH || value.length === ORGANIZATION_INN_LENGTH
    ? null
    : { innLength: INN_10_OR_12_LENGTH_ERR_MSG };
}

function validateInnControlSum(value: string): boolean {
  if (value === '000000000000' || value === '0000000000') {
    return false;
  }

  const numbers = new Array(value.length);

  for (let i = 0; i < value.length; i += 1) {
    const symbol = value.charAt(i);
    numbers[i] = parseInt(symbol, 10);
  }
  let valid;

  if (value.length === 10) {
    valid =
      numbers[9] ===
      ((2 * numbers[0] +
        4 * numbers[1] +
        10 * numbers[2] +
        3 * numbers[3] +
        5 * numbers[4] +
        9 * numbers[5] +
        4 * numbers[6] +
        6 * numbers[7] +
        8 * numbers[8]) %
        11) %
        10;
  } else {
    valid =
      numbers[10] ===
        ((7 * numbers[0] +
          2 * numbers[1] +
          4 * numbers[2] +
          10 * numbers[3] +
          3 * numbers[4] +
          5 * numbers[5] +
          9 * numbers[6] +
          4 * numbers[7] +
          6 * numbers[8] +
          8 * numbers[9]) %
          11) %
          10 &&
      numbers[11] ===
        ((3 * numbers[0] +
          7 * numbers[1] +
          2 * numbers[2] +
          4 * numbers[3] +
          10 * numbers[4] +
          3 * numbers[5] +
          5 * numbers[6] +
          9 * numbers[7] +
          4 * numbers[8] +
          6 * numbers[9] +
          8 * numbers[10]) %
          11) %
          10;
  }

  return valid;
}

function validateInnByOccupationType(value: string, type: OccupationType): ValidationErrors | null {
  if (!value) return null;
  if (value.length !== ORGANIZATION_INN_LENGTH && type === OccupationType.OwnBusiness) {
    return { inn: INN_OWN_BUSINESS_ERR_MSG };
  }
  if (value.length !== PERSONAL_INN_LENGTH && type === OccupationType.SoleProprietor) {
    return { inn: INN_SOLE_PROPRIETOR_ERR_MSG };
  }
  if (value.length !== PERSONAL_INN_LENGTH && type === OccupationType.SelfEmployed) {
    return { inn: INN_SELF_EMPLOYED_ERR_MSG };
  }
  return null;
}

function validateSnilsControlSum(value: string): boolean {
  let result = false;

  let sum = 0;
  for (let i = 0; i < value.length - 2; i++) {
    sum += Number.parseInt(value[i], 10) * (9 - i);
  }
  let controlSum;
  if (sum < 100) {
    controlSum = sum;
  } else if (sum > 101) {
    controlSum = sum % 101;
    if (controlSum === 100) {
      controlSum = 0;
    }
  } else {
    controlSum = 0;
  }
  const controlNumber = Number.parseInt(value.substring(value.length - 2), 10);
  if (controlSum === controlNumber) {
    result = true;
  }
  return result;
}
