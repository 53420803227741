export enum GridCompactType {
  compact = 'compact',
  full = 'full',
}

export const GridCompactTypeTranslateMap: Record<GridCompactType, string> = {
  [GridCompactType.compact]: 'BASE.GRID_COMPACT_TYPE.COMPACT',
  [GridCompactType.full]: 'BASE.GRID_COMPACT_TYPE.FULL',
};

export const GridCompactTypeMap: Record<GridCompactType, string> = {
  [GridCompactType.compact]: 'Компактный вид',
  [GridCompactType.full]: 'Полный вид',
};
