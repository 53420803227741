/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type IncomeSource = 'Authorizer' | 'Underwriter' | 'HeadHunter' | 'Document' | 'Pfr' | 'Form';

export const IncomeSource = {
    Authorizer: 'Authorizer' as IncomeSource,
    Underwriter: 'Underwriter' as IncomeSource,
    HeadHunter: 'HeadHunter' as IncomeSource,
    Document: 'Document' as IncomeSource,
    Pfr: 'Pfr' as IncomeSource,
    Form: 'Form' as IncomeSource
};

