import { FileInfo, Nullable } from '@lib-utils';
import {
  AddressDto,
  DealRegistrationType,
  DocumentSigningType,
  DocumentType,
  DueDiligenceInfoDto,
  EncumbranceDto,
  EvaluateReportDto,
  LegalEntityType,
  MaritalStatus,
  OrganizationDto,
  OwnershipType,
  OwnerType,
  PhysicalPersonDto,
  RealEstateDto,
  RealEstateOwnerDto,
  ReceivingType,
  RepresentativeDto,
  TransferType,
} from '@lib-mortgage/api';

export interface FeDealInfoOnRealEstateStateDto {
  deal: FeDeal;
  evaluationReport: FeEvaluateReport;
  realEstate: FeRealEstate;
  additionalRealEstate: FeRealEstate[];
  individualSellers: FeIndividualSeller[];
  legalSeller: FeLegalSeller;
  builder: FeBuilderDto;
}

export interface FeEvaluateReport extends Omit<EvaluateReportDto, 'evaluationDate' | 'reportDate'> {
  AssessmentReport: FileInfo[];
  AssessmentReportWithSignature: FileInfo[];
  reportDate?: Date | null;
  evaluationDate?: Date | null;
  estimatedValues?: FeEstimatedValue[];
}

export interface FeEstimatedValue extends Pick<RealEstateDto, 'estimatedValue' | 'type'> {
  realEstateId?: number;
}

export interface FeDeal {
  ownerType?: OwnerType;
  agreementType?: DealRegistrationType;
  receivingType?: ReceivingType;
  transferType?: TransferType;
}

export interface FeEncumbranceDto extends EncumbranceDto {
  files: FileInfo<number>[] | null;
}

export interface FeRealEstate extends Omit<RealEstateDto, 'encumbrance' | 'dateTransfer' | 'dateMortgage' | 'owners'> {
  resComplexId?: number;
  buildingAddressId?: number;
  ownershipType?: OwnershipType;
  additionalAddresses?: FeRealEstateAdditionalAddress[];
  dateTransfer?: Date | null;
  dateMortgage?: Date | null;
  encumbrance?: FeEncumbranceDto;
  conclusionContractOfSalePlanningDate?: string | null;
  personOwners?: FeRealEstateOwnerDto[] | null;
  organizationOwner?: FeRealEstateOwnerDto | null;

  ShareParticipationContract?: FileInfo<number>[] | null;
  AgencyContract?: FileInfo<number>[] | null;
  OtherObject?: FileInfo<number>[] | null;
  TitleDocuments?: FileInfo<number>[] | null;
  HouseBookExtract?: FileInfo<number>[] | null;
  CadastralPassport?: FileInfo<number>[] | null;
  EgrnExtract?: FileInfo<number>[] | null;
  BeforeContractOfSale?: FileInfo<number>[] | null;
  PartialEquityAgreement?: FileInfo<number>[] | null;
  EquityAgreementPaymentConfirmation?: FileInfo<number>[] | null;
  PrimaryEquityAgreement?: FileInfo<number>[] | null;
}

export interface FeRealEstateOwnerDto extends RealEstateOwnerDto {
  share?: string | null;
}

export interface FeDueDiligenceInfoDto extends DueDiligenceInfoDto {
  dealDocuments: Array<FeDealDocument>;
}

export interface FeDealDocument {
  id?: number;
  document?: string | null;
}

export interface FeRealEstateAdditionalAddress {
  address?: Nullable<string>;
  cadastral?: Nullable<string>;
  taxNumber?: Nullable<string>;
}

export interface FeIndividualSeller extends FePhysicalPersonDto {
  documentSigningType?: DocumentSigningType | null;
  representative?: FeRepresentativeDto | null;
  sellerDocuments?: FeIndividualSellerDocuments | null;
}

export interface FeLegalSeller extends OrganizationDto {
  ownerId?: number;
  representative?: FeRepresentativeDto | null;
  sellerDocuments?: FeLegalSellerDocuments | null;
}

export interface FePhysicalPersonDto extends Omit<PhysicalPersonDto, 'birthday'> {
  documentType?: DocumentType;
  series?: string | null;
  number?: string | null;
  issueDate?: Date | null;
  issueDepCode?: string | null;
  birthplace?: string | null;
  birthday?: Date | null;
  registrationAddress?: AddressDto;
  maritalStatus?: MaritalStatus;
  phone?: string | null;
  email?: string | null;
}

export interface FeRepresentativeDto extends Omit<RepresentativeDto, 'physicalPerson' | 'issueDate'> {
  physicalPerson?: FePhysicalPersonDto | null;
  issueDate?: Date | null;
}

export interface FeIndividualSellerDocuments {
  RealEstateSellerPassport: FileInfo<number>[];
  RealEstateSellerBirthCertificate: FileInfo<number>[];
  RepresentativePassport: FileInfo<number>[];
  PowerOfAttorneyOfRepresentative: FileInfo<number>[];
  RealEstateSellerOther: FileInfo<number>[];
}

export interface FeLegalSellerDocuments {
  Other: FileInfo<number>[];
}

export interface FeBuilderDto {
  id?: number;
  name?: string | null;
  inn?: string | null;
  address?: AddressDto;
  files: FileInfo<number>[];
  legalEntityType?: LegalEntityType;
}

export type FeRealEstateShortData = Pick<RealEstateDto, 'id' | 'type'>;

export enum FePhysicalPersonType {
  Seller = 'Seller',
  Representative = 'Representative',
}
