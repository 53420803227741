/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OwnershipType } from './ownershipType';


export interface UpdateBuyerInfoOnPrepareForDealStateRequest { 
    id?: number | null;
    clientId: number;
    ownershipType: OwnershipType;
    numerator: number;
    denominator: number;
}
export namespace UpdateBuyerInfoOnPrepareForDealStateRequest {
}


