import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  DestroyRef,
  EventEmitter,
  HostBinding,
  inject,
  input,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DomSanitizer } from '@angular/platform-browser';
import { TuiPreviewDialogModule, TuiPreviewDialogService, TuiPreviewModule } from '@taiga-ui/addon-preview';
import { TuiSwipe, TuiSwipeModule } from '@taiga-ui/cdk';
import { TuiDialogContext } from '@taiga-ui/core';
import isNumber from 'lodash-es/isNumber';
import { EMPTY, finalize, map, of, ReplaySubject, switchMap, tap } from 'rxjs';
import { ExecuteWithPipeModule, FileInfo, FileInputAppearance, MimeTypes, Nullable, saveFile } from '@lib-utils';
import { ButtonModule } from '@lib-widgets/core';
import { FileComponent } from '../file';
import { FilePreviewTriggerEvent, GetFileBlobCallback, getPreviewFileUrl, isFilePdf } from '../utils';

@Component({
  selector: 'fnip-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    ButtonModule,
    ExecuteWithPipeModule,
    FileComponent,
    NgFor,
    NgIf,
    TuiPreviewModule,
    TuiPreviewDialogModule,
    TuiSwipeModule,
  ],
})
export class FileListComponent<TFileId extends string | number = string | number> {
  @Input()
  @HostBinding('class')
  appearance: FileInputAppearance = 'default';

  fileList = input.required<FileInfo<TFileId>[]>();

  @Input() getFileBlob$: Nullable<GetFileBlobCallback<TFileId>>;

  @Input({ transform: booleanAttribute }) allowRemove = false;

  @Input({ transform: booleanAttribute }) allowDownload = false;

  @Input({ transform: booleanAttribute }) allowPreview = false;

  @Input({ transform: booleanAttribute }) allowSignature = false;

  @Input({ transform: booleanAttribute }) showSize = false;

  @Output() fileRemove = new EventEmitter<FileInfo<TFileId>>();
  @Output() fileDownload = new EventEmitter<FileInfo<TFileId>>();
  @Output() fileSignatureUpload = new EventEmitter<FileInfo<TFileId>>();

  @ViewChild('previewTempl')
  readonly previewTempl?: TemplateRef<TuiDialogContext<void>>;

  private cdr = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);
  private previewService = inject(TuiPreviewDialogService);
  protected sanitizer = inject(DomSanitizer);

  isAllowPreview = (file: FileInfo<TFileId>) => {
    if (!this.allowPreview || !file.id) return false;
    const fileExt = file.name?.toLocaleLowerCase().slice(file.name.lastIndexOf('.'));

    return (
      [MimeTypes.Jpg, MimeTypes.Pdf, MimeTypes.Png, MimeTypes.Jpeg, MimeTypes.Tiff, MimeTypes.Tif].includes(
        file.contentType as MimeTypes,
      ) ||
      (!!fileExt && ['.pdf', '.jpg', '.jpeg', '.png', '.tiff', '.tif'].includes(fileExt))
    );
  };

  getPreviewFileUrl = getPreviewFileUrl;

  isFilePdf = isFilePdf;

  previewFile$ = new ReplaySubject<FilePreviewTriggerEvent<TFileId>>();

  previewFileRequest$ = this.previewFile$.pipe(
    tap(({ fileInfo }) => {
      this.fileList().forEach((item) => (item.isPreviewLoading = false));
      fileInfo.isPreviewLoading = true;
      this.cdr.detectChanges();
    }),
    switchMap((event) => this.getFileBlob(event.fileInfo).pipe(map((blob) => [event, blob] as const))),
    switchMap(([{ fileInfo, isModalView }, fileBlob]) => {
      fileInfo.isPreviewLoading = false;
      this.cdr.detectChanges();
      if (!fileBlob.body || !this.previewTempl) return EMPTY;

      return isModalView ? this.previewService.open(this.previewTempl) : of(null);
    }),
  );

  fileListWithoutSignatures = computed(() => {
    if (!this.allowSignature) return this.fileList();
    return this.fileList().filter((fileInfo) => !fileInfo.clientFileForSigningId);
  });

  signatureFileByFileIdMap = computed(() => {
    if (!this.allowSignature) return {} as Record<TFileId, FileInfo<TFileId>>;
    return this.fileList()
      .filter((file) => file.clientFileForSigningId)
      .reduce(
        (acc, file) => ({ ...acc, [file.clientFileForSigningId!]: file }),
        {} as Record<TFileId, FileInfo<TFileId>>,
      );
  });

  downloadFile(fileInfo: FileInfo<TFileId>) {
    if (!this.getFileBlob$ || !fileInfo.id) return;

    fileInfo.isPending = true;
    this.getFileBlob(fileInfo)
      .pipe(
        tap(saveFile),
        finalize(() => {
          fileInfo.isPending = false;
          this.cdr.detectChanges();
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  onNewWindowFileOpenClick = (fileInfo: FileInfo<TFileId>) => () => {
    const url = window.URL.createObjectURL(fileInfo.cacheBlob?.body as Blob);
    window.open(url, '_blank');
  };

  getPreviewFileIndex = (currentFile: FileInfo<TFileId>, fileList: Nullable<FileInfo<TFileId>[]>) =>
    fileList?.findIndex((item) => item === currentFile || item.id === currentFile.id);

  onPreviewSwipe(swipe: TuiSwipe, currentFile: FileInfo<TFileId>, fileList: Nullable<FileInfo<TFileId>[]>) {
    const currentFileIndex = this.getPreviewFileIndex(currentFile, fileList);
    if (!fileList || !isNumber(currentFileIndex)) return;
    if (swipe.direction === 'left' && currentFileIndex > 0) {
      this.onPreviewIndexChange(currentFileIndex - 1, fileList);
    }

    if (swipe.direction === 'right' && currentFileIndex + 1 < fileList.length) {
      this.onPreviewIndexChange(currentFileIndex + 1, fileList);
    }
  }

  onPreviewIndexChange = (index: number, fileList: Nullable<FileInfo<TFileId>[]>) => {
    if (!fileList?.[index]) return;
    const nextFile = fileList[index];
    this.previewFile$.next({
      fileInfo: nextFile,
      isModalView: true,
    });
  };

  private getFileBlob = (fileInfo: FileInfo<TFileId>) => {
    if (!this.getFileBlob$ || !fileInfo.id) return EMPTY;
    if (fileInfo.cacheBlob) return of(fileInfo.cacheBlob);

    return this.getFileBlob$(fileInfo.id).pipe(tap((blob) => (fileInfo.cacheBlob = blob)));
  };
}
