import { DateOnly, Nullable } from '@lib-utils';
import {
  BankDecisionStatus,
  CalculationType,
  CreditPurpose,
  InitialPaymentType,
  InsuranceForm,
  MaternalCapitalType,
  NonStandardType,
  ObjectType,
  ProofOfIncome,
  RealEstateRegion,
  TextSpecialConditionDto,
} from '@lib-mortgage/api';
import { FeClientCreditDebtLoadInfoDto } from './api-middleware-aaa';
import { FeCalculationsDto } from './api-middleware-calculation';

export interface FeDecisionComplexDto {
  residentialComplexId?: Nullable<number>;
  creditProductId?: Nullable<number>;
  decision?: Nullable<BankDecisionStatus>;
  approvalExpiration?: Nullable<DateOnly>;
  objectType?: Nullable<ObjectType>;
  creditPurpose?: Nullable<CreditPurpose>;
  realEstateRegion?: Nullable<RealEstateRegion>;
  residentialComplexRegion?: Nullable<string>;
  requestedAmount?: Nullable<number>;
  borrowerId?: Nullable<number>;
}

export interface FeDecisionAdditionalParametersDto {
  proofOfIncome?: Nullable<ProofOfIncome>;
  insuranceEnabled?: Nullable<boolean>;
  insuranceForm?: Nullable<InsuranceForm>;
  insuranceName?: Nullable<string>;
  maternalCapitalEnabled?: Nullable<boolean>;
  maternalCapitalType?: Nullable<MaternalCapitalType>;
  maternalCapital?: Nullable<number>;
  flexPriceEnabled?: Nullable<boolean>;
  flexPrice?: Nullable<number>;
  nonStandardEnabled?: Nullable<boolean>;
  nonStandardType?: Nullable<NonStandardType>;
  nonStandardComment?: Nullable<string>;
  stateSubsidyEnabled?: Nullable<boolean>;
  stateSubsidy?: Nullable<number>;
}

export interface FeDecisionPartnerConditionsDto {
  signed?: Nullable<boolean>;
  partnerPercent?: Nullable<number>;
  partnerDiscount?: Nullable<number>;
}

export interface FeClientParticipationDto {
  participation?: Nullable<number>;
  fullName?: Nullable<string>;
}

export interface FeDecisionDto {
  complex?: FeDecisionComplexDto;
  additionalParameters?: FeDecisionAdditionalParametersDto;
  partnerConditions?: FeDecisionPartnerConditionsDto;
  calculation?: FeCalculationsDto;
  savedRate?: Nullable<number>;
  specialConditions?: FeClientCreditDebtLoadInfoDto[];
  textSpecialConditions?: TextSpecialConditionDto[];
}

export interface FeCalculationRequestPart {
  calculationType?: CalculationType;
  calculationValue?: Nullable<number>;
  creditPeriod?: Nullable<number>;
  initialPaymentOwnFunds?: Nullable<number>;
  initialPaymentOwnFundsType?: Nullable<InitialPaymentType>;
}
