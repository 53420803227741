/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VerificationStatus } from './verificationStatus';
import { VerificationCallResult } from './verificationCallResult';
import { DocumentResolutionDto } from './documentResolutionDto';
import { PaymentScheduleTaskDto } from './paymentScheduleTaskDto';


export interface PaymentScheduleTaskDtoBaseUpdateVerificationTask { 
    documentStatus?: VerificationStatus;
    documentResolutions?: Array<DocumentResolutionDto> | null;
    falsificationFlags?: Array<DocumentResolutionDto> | null;
    callResult?: VerificationCallResult;
    data?: PaymentScheduleTaskDto;
}
export namespace PaymentScheduleTaskDtoBaseUpdateVerificationTask {
}


