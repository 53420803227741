import { AgGridAngular } from '@ag-grid-community/angular';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  CellValueChangedEvent,
  ColDef,
  ColGroupDef,
  ColumnMovedEvent,
  GetRowIdFunc,
  GridOptions,
  GridReadyEvent,
  Module,
  RowClassRules,
  RowDataUpdatedEvent,
  SelectionChangedEvent,
  SortChangedEvent,
} from '@ag-grid-community/core';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { createId } from '@paralleldrive/cuid2';
import PerfectScrollbar from 'perfect-scrollbar';
import { Nullable } from '@lib-utils';
import { GridTooltipComponent } from '../grid-tooltip';
import { getGridScrollbar, GridTheme } from '../utils';

const AG_GRID_ANIMATION_TIME = 400;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'fnip-grid-base',
  templateUrl: './grid-base.component.html',
  styleUrls: [`./grid-base.component.scss`],
})
export class GridBaseComponent<T> implements OnChanges, OnDestroy {
  @Input() gridClass: Nullable<GridTheme> | Nullable<GridTheme>[];
  @Input({ required: true }) gridOptions: Nullable<GridOptions>;
  @Input() colDefs: Nullable<(ColDef | ColGroupDef)[]>;
  @Input() rowClassRules?: RowClassRules<T>;
  @Input() rowData: Nullable<T[]>;

  @Output() selectionChanged = new EventEmitter<SelectionChangedEvent<T>>();
  @Output() sortChanged = new EventEmitter<SortChangedEvent<T>>();
  @Output() gridReady = new EventEmitter<GridReadyEvent<T>>();
  @Output() cellValueChanged = new EventEmitter<CellValueChangedEvent>();
  @Output() columnMoved = new EventEmitter<ColumnMovedEvent>();
  @Output() rowDataUpdated = new EventEmitter<RowDataUpdatedEvent<T>>();

  @ViewChild(AgGridAngular, { read: ElementRef }) agGridElementRef: Nullable<ElementRef<Element>>;

  public modules: Module[] = [ClientSideRowModelModule, CsvExportModule];

  defaultColDef: ColDef<T> = {
    resizable: true,
    enableCellChangeFlash: true,
    // Убираем текст в ... и добавляем тултип
    // wrapText: true,
    // autoHeight: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    comparator: () => 0, // необходимо для server-side сортировки и фильтрации
    unSortIcon: true,
    singleClickEdit: true,
    tooltipComponent: GridTooltipComponent,
    tooltipValueGetter: (value) => value,
    minWidth: 80,
  };

  localeText = {
    noRowsToShow: 'Нет данных для отображения',
  };

  defaultTooltipDelay = 500;

  scrollbarTimer: Nullable<ReturnType<typeof setTimeout>>;
  scrollbarY: Nullable<PerfectScrollbar>;
  scrollbarX: Nullable<PerfectScrollbar>;

  defaultGetRowId: GetRowIdFunc = (params) => {
    if (params.data?.id?.toString()) return params.data?.id?.toString();
    else if (!params.data?.gridRowId) params.data.gridRowId = createId();

    return params.data?.gridRowId;
  };

  ngOnChanges(simpleChanges: SimpleChanges) {
    if ('colDefs' in simpleChanges) this.gridOptions?.api?.setColumnDefs(simpleChanges['colDefs'].currentValue);
  }

  ngOnDestroy() {
    if (this.scrollbarTimer) clearTimeout(this.scrollbarTimer);
    this.scrollbarX?.destroy();
    this.scrollbarY?.destroy();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  assign<O extends Record<string, any>>(obj: Nullable<O>, defaultObj: O): O {
    return Object.assign(defaultObj, obj || {});
  }

  getGridClass = (value: Nullable<string> | Nullable<string>[]) =>
    Array.isArray(value) ? value?.filter(Boolean).join(' ') : value;

  updateScrollbar = () => {
    if (this.scrollbarTimer) clearTimeout(this.scrollbarTimer);
    this.scrollbarTimer = setTimeout(() => {
      if (this.scrollbarX) this.scrollbarX.update();
      else this.scrollbarX = getGridScrollbar(this.agGridElementRef, '.ag-body-horizontal-scroll-viewport');
      if (this.scrollbarY) this.scrollbarY.update();
      // Vertical scrolling should not happen for autoHeight
      else this.scrollbarY = getGridScrollbar(this.agGridElementRef, '.ag-body-viewport:not(.ag-layout-auto-height)');
    }, AG_GRID_ANIMATION_TIME);
  };
}
