/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VerificationSupervisionDataDto } from './verificationSupervisionDataDto';
import { SourceType } from './sourceType';
import { DateOnly } from '@lib-utils';
import { FileInfoDto } from './fileInfoDto';


export interface EmploymentDocumentVerificationTaskDto { 
    source?: SourceType;
    files?: Array<FileInfoDto> | null;
    relevantFiles?: Array<FileInfoDto> | null;
    verificationSupervisionDataList?: Array<VerificationSupervisionDataDto> | null;
    companyType?: string | null;
    taxNumber?: string | null;
    name?: string | null;
    position?: string | null;
    startDate?: DateOnly | null;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    startWorkingAgreement?: DateOnly | null;
    employerFirstName?: string | null;
    employerMiddleName?: string | null;
    employerLastName?: string | null;
    tkSeriesNumber?: string | null;
    birthdate?: DateOnly | null;
    salary?: number | null;
    passportSeries?: string | null;
    passportNumber?: string | null;
    isNotStandardContract?: boolean | null;
}
export namespace EmploymentDocumentVerificationTaskDto {
}


