import { CallTaskType } from '@lib-verification/api';

export const CallTaskTypeMap: Record<CallTaskType, string> = {
  Borrower: 'Заемщик',
  Coworker: 'Коллега',
  Employer: 'Работодатель',
  Living: 'Место жительства',
  Registration: 'Регистрация',
  Spouse: 'Супруг(-а)',
  Ndfl: 'Налог на доходы физических лиц',
};
