import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TuiPrimitiveCheckboxModule } from '@taiga-ui/core';
import { CheckboxCellComponent } from './checkbox-cell.component';

@NgModule({
  imports: [CommonModule, TuiPrimitiveCheckboxModule],
  declarations: [CheckboxCellComponent],
  exports: [CheckboxCellComponent],
})
export class CheckboxCellModule {}
