import { ColumnState } from '@ag-grid-community/core/dist/esm/es6/columns/columnModel';
import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TuiSvgModule } from '@taiga-ui/core';
import { TuiTilesModule } from '@taiga-ui/kit';
import { BehaviorSubject, debounceTime } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { DEBOUNCE_TIME_DEFAULT, Nullable } from '@lib-utils';
import { ReactiveCheckboxModule } from '@lib-widgets/reactive-fields';
import { GridColumn } from '../../grid-state-toolbar-utils';

@Component({
  selector: 'fnip-grid-column-list',
  standalone: true,
  imports: [ReactiveCheckboxModule, TuiSvgModule, TuiTilesModule, AsyncPipe, NgIf],
  templateUrl: './grid-column-list.component.html',
  styleUrls: ['./grid-column-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridColumnListComponent {
  @Input({ required: true }) columns!: Nullable<GridColumn[]>;
  @Input({ required: true }) columnsOrder!: Map<number, number>;

  @Output() columnVisibilityChange = new EventEmitter<ColumnState>();
  @Output() columnOrderChange = new EventEmitter<Map<number, number>>();

  columnsOrder$ = new BehaviorSubject<Nullable<Map<number, number>>>(null);

  columnsOrderChanged$ = this.columnsOrder$.pipe(
    filter(Boolean),
    debounceTime(DEBOUNCE_TIME_DEFAULT),
    tap((columnsOrder) => this.columnOrderChange.next(columnsOrder)),
  );
}
