import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TuiProgressModule } from '@taiga-ui/kit';
import { ProgressCellParams } from './progress-cell-params';

@Component({
  selector: 'fnip-progress-cell',
  standalone: true,
  imports: [TuiProgressModule],
  templateUrl: './progress-cell.component.html',
  styleUrls: ['./progress-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressCellComponent implements ICellRendererAngularComp {
  currentValue = 0;
  maxValue = 0;

  agInit(params: ICellRendererParams & ProgressCellParams) {
    this.refreshView(params);
  }

  refresh(params: ICellRendererParams & ProgressCellParams): boolean {
    this.refreshView(params);
    return true;
  }

  private refreshView(params: ICellRendererParams & ProgressCellParams) {
    this.currentValue = params.getCurrentValue(params.data);
    this.maxValue = params.getMaxValue(params.data);
  }
}
