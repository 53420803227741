import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TuiSvgModule } from '@taiga-ui/core';
import { TuiTagModule } from '@taiga-ui/kit';
import { TagCellComponent } from './tag-cell.component';

@NgModule({
  imports: [CommonModule, TuiSvgModule, TuiTagModule],
  declarations: [TagCellComponent],
  exports: [TagCellComponent],
})
export class TagCellModule {}
