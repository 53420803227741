import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveButtonCheckboxModule } from '@lib-widgets/reactive-fields';
import { ReactiveButtonCheckboxCellComponent } from './reactive-button-checkbox-cell.component';

@NgModule({
  declarations: [ReactiveButtonCheckboxCellComponent],
  imports: [CommonModule, ReactiveButtonCheckboxModule],
})
export class ReactiveButtonCheckboxCellModule {}
