import { ColDef } from '@ag-grid-community/core';
import { ReactiveCheckboxCellComponent, ReactiveCheckboxCellParams } from '../renderers/reactive-checkbox-cell';

export const reactiveCheckboxCellDef = (
  config: ReactiveCheckboxCellParams,
): Pick<ColDef, 'cellRenderer' | 'cellRendererParams' | 'valueFormatter'> => ({
  cellRenderer: ReactiveCheckboxCellComponent,
  cellRendererParams: config,
  valueFormatter: ({ value }) => (value ? 'Да' : 'Нет'),
});
