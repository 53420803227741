/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SprClientDto } from './sprClientDto';
import { CallTaskType } from './callTaskType';
import { QuestionDto } from './questionDto';


export interface CallTaskDto { 
    uid: string;
    type: CallTaskType;
    phone: string | null;
    questions: Array<QuestionDto> | null;
    client?: SprClientDto;
}
export namespace CallTaskDto {
}


