/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface VerificationPagedResultDtoStatusesCount { 
    AllTasks?: number;
    Processed?: number;
    Pending?: number;
    ForRevision?: number;
    RedirectedToSupervisor?: number;
    ApplicationArchived?: number;
    Archived?: number;
    Postponed?: number;
    MyTasks?: number;
    Pause?: number;
}

