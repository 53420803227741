import { TuiStatus } from '@taiga-ui/kit';
import { FromGeneratedEnum } from '@lib-utils';
import { ManualReviewTaskStatus, ManualReviewTaskTab } from '@lib-mortgage/api';

export const MANUAL_REVIEW_TASK_TAB_MAP: FromGeneratedEnum<ManualReviewTaskTab> = {
  [ManualReviewTaskTab.All]: 'Все заявки',
  [ManualReviewTaskTab.PrimaryAuthorization]: 'Первичная авторизация',
  [ManualReviewTaskTab.SecondaryAuthorization]: 'Вторичная авторизация',
  [ManualReviewTaskTab.Underwriting]: 'Андеррайтинг',
  [ManualReviewTaskTab.InProgress]: 'В работе',
  [ManualReviewTaskTab.Suspended]: 'Отложено',
  [ManualReviewTaskTab.DepartureToClient]: 'Выезд к клиенту',
};

export const MANUAL_REVIEW_TASK_STATUS_MAP: FromGeneratedEnum<ManualReviewTaskStatus> = {
  [ManualReviewTaskStatus.New]: 'Новая',
  [ManualReviewTaskStatus.InProgress]: 'В работе',
  [ManualReviewTaskStatus.Suspended]: 'Отложена',
  [ManualReviewTaskStatus.Done]: 'Выполнена',
};

export const MANUAL_REVIEW_TASK_STATUS_TAG_MAP: Partial<Record<ManualReviewTaskStatus, TuiStatus>> = {
  [ManualReviewTaskStatus.New]: 'default',
  [ManualReviewTaskStatus.InProgress]: 'primary',
  [ManualReviewTaskStatus.Suspended]: 'warning',
  [ManualReviewTaskStatus.Done]: 'success',
};
