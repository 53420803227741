import { HttpResponse } from '@angular/common/http';

interface FileInfoDto<T extends string | number = string | number> {
  id?: T;
  name?: string | null;
  type?: string;
  size?: number;
  contentType?: string | null;
}

/**
 * Lifecycle example
 *
 * 1.   User drag-&-drop file       [void -> uploading]
 * 2.1  Upload was successful       [uploading -> ready]
 * 2.2  Upload was rejected         [uploading -> rejected]
 *
 * void means the FileInfo object does not exist in the list
 *
 * null - for future refactoring to make state optional in FileInfo
 */
export type FileInfoState = 'ready' | 'uploading' | 'rejected' | null;

export const FileInfoState = {
  ready: 'ready' as FileInfoState,
  uploading: 'uploading' as FileInfoState,
  rejected: 'rejected' as FileInfoState,
};

export interface FileInfo<T extends string | number = string | number, TExtra = unknown> extends FileInfoDto<T> {
  state: FileInfoState;

  rejectedReason?: string;

  /**
   * Progress of operation while file in uploading state
   * Will show as percentage
   *
   * expected value from 0.0 to 1.0
   */
  progress?: number;

  /**
   * Blob for preview or download
   */
  cacheBlob?: HttpResponse<Blob>;

  /**
   * Pending state of file occurs when components are waiting for a file to be deleted
   *
   * When file is pending, no user interaction allowed
   *
   * Preview:
   * `false -> (user clicks to preview) -> true -> (blob arrived) -> false`
   */
  isPending?: boolean;

  isPreviewLoading?: boolean;

  /**
   * Extra data from server (not used in component)
   * For example can be used as server filetype
   */
  extra?: TExtra;

  /**
   * Local file
   * Used in case when form will get control value and send separate request with files
   * uploadFile$ will not be used
   */
  file?: File;

  /**
   * Url to small thumbnail image
   */
  thumbnailSrc?: string;

  /**
   * Link to client file for Signature
   */
  clientFileForSigningId?: T | null;
}
