export * from './admin.service';
import { AdminApiService } from './admin.service';
export * from './application.service';
import { ApplicationApiService } from './application.service';
export * from './applicationChanges.service';
import { ApplicationChangesApiService } from './applicationChanges.service';
export * from './authorizer.service';
import { AuthorizerApiService } from './authorizer.service';
export * from './bank.service';
import { BankApiService } from './bank.service';
export * from './blacklist.service';
import { BlacklistApiService } from './blacklist.service';
export * from './client.service';
import { ClientApiService } from './client.service';
export * from './dealVerification.service';
import { DealVerificationApiService } from './dealVerification.service';
export * from './directory.service';
import { DirectoryApiService } from './directory.service';
export * from './documentTemplate.service';
import { DocumentTemplateApiService } from './documentTemplate.service';
export * from './dueDiligence.service';
import { DueDiligenceApiService } from './dueDiligence.service';
export * from './electronicAchive.service';
import { ElectronicAchiveApiService } from './electronicAchive.service';
export * from './externalFile.service';
import { ExternalFileApiService } from './externalFile.service';
export * from './file.service';
import { FileApiService } from './file.service';
export * from './formula.service';
import { FormulaApiService } from './formula.service';
export * from './gridState.service';
import { GridStateApiService } from './gridState.service';
export * from './insurance.service';
import { InsuranceApiService } from './insurance.service';
export * from './loanCalculator.service';
import { LoanCalculatorApiService } from './loanCalculator.service';
export * from './multiRole.service';
import { MultiRoleApiService } from './multiRole.service';
export * from './notification.service';
import { NotificationApiService } from './notification.service';
export * from './permission.service';
import { PermissionApiService } from './permission.service';
export * from './realEstate.service';
import { RealEstateApiService } from './realEstate.service';
export * from './report.service';
import { ReportApiService } from './report.service';
export * from './residentialComplex.service';
import { ResidentialComplexApiService } from './residentialComplex.service';
export * from './smartDeal.service';
import { SmartDealApiService } from './smartDeal.service';
export * from './suggest.service';
import { SuggestApiService } from './suggest.service';
export * from './underwriter.service';
import { UnderwriterApiService } from './underwriter.service';
export * from './user.service';
import { UserApiService } from './user.service';
export * from './verification.service';
import { VerificationApiService } from './verification.service';
export const APIS = [AdminApiService, ApplicationApiService, ApplicationChangesApiService, AuthorizerApiService, BankApiService, BlacklistApiService, ClientApiService, DealVerificationApiService, DirectoryApiService, DocumentTemplateApiService, DueDiligenceApiService, ElectronicAchiveApiService, ExternalFileApiService, FileApiService, FormulaApiService, GridStateApiService, InsuranceApiService, LoanCalculatorApiService, MultiRoleApiService, NotificationApiService, PermissionApiService, RealEstateApiService, ReportApiService, ResidentialComplexApiService, SmartDealApiService, SuggestApiService, UnderwriterApiService, UserApiService, VerificationApiService];
