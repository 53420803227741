import { AuthorizerRejectionType } from '@lib-mortgage/api';

export const AuthorizerRejectionTypeMap = new Map<AuthorizerRejectionType, string>([
  [AuthorizerRejectionType.SignificantNegativeFromCronos, 'Существенный негатив Кронос'],
  [AuthorizerRejectionType.PrecariousEmployment, 'Низкоквалифицированная / нестабильная / непрозрачная занятость'],
  [AuthorizerRejectionType.SuspicionOfFalsificationOfDocuments, 'Подозрение на фальсификацию документов'],
  [AuthorizerRejectionType.SuspicionOfFraud, 'Подозрение на мошенничество'],
  [AuthorizerRejectionType.SignificantNegativeFromBki, 'Существенный негатив по БКИ'],
  [AuthorizerRejectionType.NegativeFromVerusOrGriffid, 'Негатив по проверке Верус / Грифид'],
  [AuthorizerRejectionType.SpecificBusinessArea, 'Специфическая сфера бизнеса / Разовый доход'],
  [
    AuthorizerRejectionType.UnableToVerifyBusinessIncome,
    'Невозможно достоверно подтвердить доход собственника бизнеса / Мелкий бизнес',
  ],
  [AuthorizerRejectionType.AnotherReason, 'Иное'],
]);
