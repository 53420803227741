/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VerificationSupervisionDataDto } from './verificationSupervisionDataDto';
import { SourceType } from './sourceType';
import { EngineType } from './engineType';
import { VehicleCategory } from './vehicleCategory';
import { FileInfoDto } from './fileInfoDto';


export interface VehiclePassportVerificationTaskDto { 
    source?: SourceType;
    files?: Array<FileInfoDto> | null;
    relevantFiles?: Array<FileInfoDto> | null;
    verificationSupervisionDataList?: Array<VerificationSupervisionDataDto> | null;
    number?: string | null;
    vehicleIdentificationNumber?: string | null;
    make?: string | null;
    model?: string | null;
    isDuplicate?: boolean | null;
    year?: number | null;
    engineType?: EngineType;
    engineDisplacement?: number | null;
    engineNumbers?: string | null;
    vehicleCategory?: VehicleCategory;
    enginePowers?: Array<number> | null;
}
export namespace VehiclePassportVerificationTaskDto {
}


