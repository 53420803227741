import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TuiTagModule } from '@taiga-ui/kit';
import { MultiRowCellComponent } from './multi-row-cell.component';

@NgModule({
  imports: [CommonModule, TuiTagModule],
  declarations: [MultiRowCellComponent],
  exports: [MultiRowCellComponent],
})
export class MultiRowCellModule {}
