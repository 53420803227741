import { SelectOption } from '@lib-utils';
import { RealEstateRegion } from '@lib-mortgage/api';

export const regionSelectOptions: SelectOption<unknown, RealEstateRegion>[] = [
  { label: 'Москва', value: RealEstateRegion.Moscow },
  { label: 'Санкт-Петербург', value: RealEstateRegion.SaintPetersburg },
  { label: 'Московская область', value: RealEstateRegion.MoscowRegion },
  { label: 'Ленинградская область', value: RealEstateRegion.LeningradRegion },
  { label: 'Иное', value: RealEstateRegion.Other },
];
