<ng-container *ngIf="remoteFiles$ | async" />
<ng-container *ngIf="removeFileRequest$ | async" />
<ng-container *ngIf="controlValueChanges$ | async" />
<ng-container *ngIf="fetchFilesThumbnails$ | executeWith: getFileThumbnail$ : (fileList$ | async) | async" />

<div *ngIf="!!uploadFile$">
  <ng-container *ngIf="label && (isLabelShown | executeWith: appearance : max | async)">
    <p class="my-3 title">
      {{ label }}
      <span *ngIf="hasRequiredValidator" class="title__asterisk">*</span>
      <span *ngIf="warningMessage" class="d-block color-primary font-weight-normal">{{ warningMessage }}</span>
    </p>
  </ng-container>

  <!--Block component when loading remote files, cuz' max files validation relate on remote files count-->
  <fnip-drag-n-drop
    [appearance]="appearance"
    [label]="(isRemoteLoaded$ | async) ? text ?? 'Выберите файл или перетяните его сюда' : loadingMessage"
    [accept]="accept"
    [multiple]="multiple"
    [isDisabled]="isDisabled"
    [max]="multiple ? max : 1"
    [maxSizeMb]="maxSizeMb"
    [fileList]="(fileList$ | async) ?? []"
    [isPending]="(control?.statusChanges | async) === 'PENDING' || (isRemoteLoaded$ | async) === false"
    [isMobile]="isMobile"
    [showAcceptCaption]="showAcceptCaption"
    (fileDropped)="onFileDropped($event)"
    (maxLimitReached)="maxLimitReached.emit()"
    (maxSizeReached)="maxSizeReached.emit()"
  ></fnip-drag-n-drop>
</div>

<fnip-file-list
  *ngIf="fileList$ | async as fileList"
  [appearance]="appearance"
  [fileList]="fileList"
  [getFileBlob$]="getFileBlob$"
  [allowRemove]="!!removeFile$ && !isDisabled"
  [allowPreview]="allowPreview && !!getFileBlob$"
  [allowDownload]="allowDownload && !!getFileBlob$"
  [allowSignature]="allowSignature && !!uploadSigFile$"
  [showSize]="showSize"
  (fileRemove)="onFileRemoved($event)"
  (fileSignatureUpload)="onFileSignatureUpload($event)"
></fnip-file-list>
