import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { Params } from '@angular/router';
import { TuiAppearance } from '@taiga-ui/core';
import { Observable } from 'rxjs';
import { LinkTarget, Nullable } from '@lib-utils';
import { ActionCellParams } from './action-cell-params';
import { CustomActionAppearance } from './custom-action-appearance';

@Component({
  selector: 'fnip-action-cell',
  templateUrl: './action-cell.component.html',
  styleUrls: ['./action-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionCellComponent implements ICellRendererAngularComp {
  appearance: TuiAppearance | CustomActionAppearance = CustomActionAppearance.ActionButton;

  callback: Nullable<() => Observable<unknown> | void>;

  link: Nullable<Array<number | string> | string>;

  target: LinkTarget = '_self';

  isDisabled$: Nullable<Observable<boolean>>;

  queryParams: Nullable<Params>;

  actionLabel = '';

  label = '';

  icon = '';

  hint = '';

  cdr = inject(ChangeDetectorRef);

  agInit(params: ICellRendererParams & ActionCellParams): void {
    this.refreshView(params);
  }

  refresh(params: ICellRendererParams & ActionCellParams): boolean {
    this.refreshView(params);
    return true;
  }

  private refreshView(params: ICellRendererParams & ActionCellParams) {
    const data = params.data;

    this.appearance = params.getAppearance?.(data) || params.appearance || CustomActionAppearance.ActionButton;
    this.callback = params.getAction$?.bind(params.context)(data);
    this.link = params.getLink?.(data);
    this.target = params.linkTarget ?? '_self';
    this.queryParams = params.getLinkParams?.(data);
    this.actionLabel = params.getActionLabel?.(data) || params.actionLabel || '';
    this.label = params.getLabel?.(data) || params.label || '';
    this.icon = params.getIcon?.(data) || params.icon || '';
    this.hint = params.getHint?.(data) || params.hint || '';
    this.isDisabled$ = params.isDisabledCallback$?.(data);
    this.cdr.markForCheck();
  }
}
