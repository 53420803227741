<ng-container *ngFor="let tag of value?.items; let isLast = last">
  <tui-tag
    class="text-ellipsis m-1"
    [hoverable]="!!tag.hint"
    [editable]="false"
    [removable]="false"
    [status]="tag.status ?? 'default'"
    [tuiHint]="tag.hint"
    [value]="tag.value"
  />
  <span *ngIf="joinBy && !isLast">{{ joinBy }}</span>
</ng-container>
<tui-tag
  *ngIf="value?.additionItem"
  class="text-ellipsis m-1"
  [hoverable]="false"
  [editable]="false"
  [removable]="false"
  [status]="value?.additionItem?.status ?? 'default'"
  [value]="value?.additionItem?.value ?? ''"
/>
