import { ApplicationStatus } from '@lib-utils';
import { ApplicationState } from '@lib-mortgage/api';

export const ApplicationStateMap = new Map<ApplicationState | 'All', string>([
  ['All', 'Все'],
  [ApplicationState.Form, 'Анкета'],
  [ApplicationState.InProgress, 'В работе'],
  [ApplicationState.OnDecision, 'На рассмотрении'],
  [ApplicationState.RealEstate, 'Объект'],
  [ApplicationState.RealEstateAnalyze, 'Анализ объекта'],
  [ApplicationState.DealPrepare, 'Подготовка к сделке'],
  [ApplicationState.Deal, 'Сделка'],
  [ApplicationState.DealVerification, 'Верификация сделки'],
  [ApplicationState.OrderRegistration, 'Оформление закладной'],
  [ApplicationState.EncumbranceRegistration, 'Регистрация обременения'],
]);

export const ApplicationStatusMap = new Map<ApplicationStatus, string>([
  [ApplicationStatus.Draft, 'Черновик'],
  [ApplicationStatus.Deny, 'Отклонено'],
  [ApplicationStatus.Verification, 'Верификация'],
  [ApplicationStatus.FinalizationOfDocuments, 'Доработка документов'],
  [ApplicationStatus.DocumentsApproved, 'Документы в порядке'],
  [ApplicationStatus.FinalizationOfForm, 'Доработка анкеты'],
  [ApplicationStatus.FinalizationOfApprovedForm, 'Доработка одобренной анкеты'],
  [ApplicationStatus.InternallSourceChecks, 'Проверки внутренних источников'],
  [ApplicationStatus.PreScoring, 'Пре скоринг'],
  [ApplicationStatus.AnalyzeCreditHistory, 'Анализ кредитной истории'],
  [ApplicationStatus.CalculatePerMonthPayment, 'Рассчет среднемесячного дохода'],
  [ApplicationStatus.CalculateMaxSumOfCredit, 'Рассчет максимальной суммы кредита'],
  [ApplicationStatus.Scoring, 'Скоринг'],
  [
    ApplicationStatus.CheckDocumentsThatApproveEmploymentsAndIncome,
    'Проверки документов, подтверждающих трудовую занятость и доход',
  ],
  [ApplicationStatus.PhoneVerificationServiceRequest, 'Запрос в Verus/Grifid'],
  [ApplicationStatus.VerificationsPhonesAndDocsOfEmployee, 'Верификация телефонов и документов работодателя'],
  [ApplicationStatus.ApproveEmploymentAndIncome, 'Подтверждение занятости и дохода'],
  [ApplicationStatus.MakeFinancialAnalyze, 'Проведение финансового анализа'],
  [
    ApplicationStatus.AnalyzeResultOfVerificatorsAndUnderwritersChecks,
    'Анализ результатов проверок Верификаторов и Андеррайтеров',
  ],
  [ApplicationStatus.CalculatePnd, 'Расчет ПДН'],
  [ApplicationStatus.CheckAuthorizerOne, 'Проверки Авторизатора 1'],
  [ApplicationStatus.CheckAuthorizerTwo, 'Проверки Авторизатора 2'],
  [ApplicationStatus.Approve, 'Одобрено'],
  [ApplicationStatus.SearchRealEstate, 'Поиск объекта'],
  [ApplicationStatus.GetEGRN, 'Получение ЕГРН'],
  [ApplicationStatus.EGRNIsGetting, 'ЕГРН получен'],
  [ApplicationStatus.FinalizeDecision, 'Доработка решения'],
  [ApplicationStatus.FinalizeDecisionAndRealEstate, 'Доработка решения и объекта'],
  [ApplicationStatus.FinalizeRealEstate, 'Доработка объекта'],
  [ApplicationStatus.ScoringSellerCompany, 'Скоринг продавца(ов) ФЛ'],
  [ApplicationStatus.JuristicInvestigation, 'Юридическая экспертиза'],
  [ApplicationStatus.RequestUPRID, 'Запрошен УПРИД'],
  [ApplicationStatus.CheckUPRID, 'Проверка УПРИД'],
  [ApplicationStatus.CheckGozo, 'Оценка ГОЗО'],
  [ApplicationStatus.PrepareDocuments, 'Подготовка документов'],
  [ApplicationStatus.FinalizeDocuments, 'Доработка документов'],
  [ApplicationStatus.CompleteFormState, 'В работе'],
  [ApplicationStatus.ProcessingFinal, 'В обработке'],
  [ApplicationStatus.CompleteInProgressState, 'Направление на рассмотрение'],
  [ApplicationStatus.CheckBlacklists, 'Проверка черных списков'],
  [ApplicationStatus.CompleteOnDecisionState, 'Направление на поиск объекта'],
  [ApplicationStatus.Verified, 'Верифицирована'],
  [ApplicationStatus.FinalizeDeal, 'Доработка сделки'],
  [ApplicationStatus.RequestAssessmentReport, 'Запрос отчета об оценке'],
  [ApplicationStatus.AssessmentReportReceive, 'Отчет об оценке получен'],
  [ApplicationStatus.EncumbranceSigned, 'Закладная подписана'],
  [ApplicationStatus.RequestEGRN, 'Запрос выписки из ЕГРН'],
  [ApplicationStatus.AutomaticCheckEncumbrance, 'Автоматическая проверка обременения'],
  [ApplicationStatus.ManualCheckEncumbrance, 'Ручная проверка обременения'],
  [ApplicationStatus.EncumbranceRegistared, 'Обременение зарегистрировано'],
  [ApplicationStatus.VerificationDocumentsForDeal, 'Проверка документов'],
  [ApplicationStatus.DeliveredCredits, 'Выдан'],
  [ApplicationStatus.UndeliveredCredits, 'Не выдан'],
]);
