/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EmploymentStatus = 'Employee' | 'Soldier' | 'Unemployed' | 'Pensioner' | 'WorkingPensioner' | 'Student';

export const EmploymentStatus = {
    Employee: 'Employee' as EmploymentStatus,
    Soldier: 'Soldier' as EmploymentStatus,
    Unemployed: 'Unemployed' as EmploymentStatus,
    Pensioner: 'Pensioner' as EmploymentStatus,
    WorkingPensioner: 'WorkingPensioner' as EmploymentStatus,
    Student: 'Student' as EmploymentStatus
};

