import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Nullable } from '@lib-utils';
import { ReactiveCheckboxModule } from '@lib-widgets/reactive-fields';
import { ReactiveCheckboxHeaderParams } from './reactive-checkbox-header-params';

type CheckboxHeaderParams = IHeaderParams & ReactiveCheckboxHeaderParams;

@Component({
  selector: 'fnip-reactive-checkbox-header',
  standalone: true,
  imports: [CommonModule, ReactiveCheckboxModule],
  templateUrl: './reactive-checkbox-header.component.html',
  styleUrls: ['./reactive-checkbox-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReactiveCheckboxHeaderComponent implements IHeaderAngularComp {
  params: Nullable<CheckboxHeaderParams>;

  agInit(params: CheckboxHeaderParams): void {
    this.params = params;
  }

  refresh(params: CheckboxHeaderParams): boolean {
    this.params = params;
    return true;
  }
}
