import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import isFunction from 'lodash-es/isFunction';
import { catchError, map, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { ConfigService } from '@lib-config';
import { defaultTransformErrorMessage, FeHttpError, getBeApiModule, NotificationService } from '@lib-utils';
import { ErrorCheckMessage, ErrorInterceptorConfig } from './error-interceptor.models';
import {
  ERROR_INTERCEPTOR_CONFIG_INJECTOR,
  ERROR_MAPPERS_INJECTOR,
  ERROR_MESSAGES_INJECTOR,
} from './error-interceptor.tokens';
import { ErrorMapperAbstract } from './error-mappers';
import { mapBeError } from './utils/index';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private messageChecks = inject<ErrorCheckMessage[][]>(ERROR_MESSAGES_INJECTOR).flat(2);
  private mappers = inject<ErrorMapperAbstract[]>(ERROR_MAPPERS_INJECTOR, { optional: true }) ?? [];
  private config = inject<ErrorInterceptorConfig>(ERROR_INTERCEPTOR_CONFIG_INJECTOR);

  private notificationService = inject(NotificationService);
  private configService = inject(ConfigService);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) =>
        of(error).pipe(
          switchMap((e) => this.parseBlobError(e)),
          switchMap((e) => throwError(() => this.handleError(e))),
          tap({
            error: (e) => {
              if (this.config.showErrors)
                this.notificationService.showError(
                  this.config.transformError?.(e) ?? defaultTransformErrorMessage(e.messages),
                );
            },
          }),
        ),
      ),
    );
  }

  private parseBlobError(err: HttpErrorResponse) {
    if (!(err.error instanceof Blob)) return of(err);

    const reader: FileReader = new FileReader();
    const obs = new Observable((observer) => {
      reader.onloadend = () => {
        // Проверка на случай невалидного JSON, отображаем как пришло
        try {
          observer.next(reader.result ? JSON.parse(reader.result.toString()) : null);
        } catch {
          observer.next(reader.result?.toString());
        }
        observer.complete();
      };
    });
    reader.readAsText(err.error);

    return obs.pipe(
      map((errorJson) => {
        // @ts-ignore
        if (errorJson) err['error'] = errorJson;
        return err;
      }),
    );
  }

  private handleError(httpError: HttpErrorResponse): FeHttpError {
    // Side effect с открытием нотификации о нехватке прав
    if (httpError.status === HttpStatusCode.Forbidden)
      this.notificationService.showError('У вашей роли не хватает прав');

    const beApi = getBeApiModule(httpError.url, this.configService);
    const feError = mapBeError(beApi, this.mappers, httpError);
    const overrideMessages: string[] = [];

    // Проверка на существование переопределенных сообщений об ошибках
    for (const { message, check } of this.messageChecks) {
      if (check(httpError.url, feError, httpError)) {
        const errorMessage = isFunction(message) ? message(feError, httpError) : message;
        overrideMessages.push(errorMessage);
      }
    }

    // Если есть переопределенные фронтом сообщения, то возвращаем ошибку с ними
    if (overrideMessages.length) feError.messages = overrideMessages.map((message) => ({ message, code: 'CUSTOM' }));

    return feError;
  }
}
