import { ApplicationModulesMeta } from '@lib-mortgage/widgets/default';

export const ApplicationModules = {
  Verificator: 'Verificator',
  VerificatorManager: 'VerificatorManager',
} as const;

export type ApplicationModules = (typeof ApplicationModules)[keyof typeof ApplicationModules];

export const ApplicationModulesMetaMap: Record<ApplicationModules, ApplicationModulesMeta> = {
  [ApplicationModules.Verificator]: {
    name: 'Кабинет верификатора',
    link: 'verificator',
    description: 'Верификация загруженных документов',
    permissionOperation: 'VerificatorModuleAvailable',
  },
  [ApplicationModules.VerificatorManager]: {
    name: 'Кабинет администратора верификаторов',
    link: 'verificator-manager',
    description: 'Управление сотрудниками-верификаторами',
    permissionOperation: 'VerificatorManagerModuleAvailable',
  },
};
