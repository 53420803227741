/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ApplicationAction = 'Create' | 'Edit' | 'Delete' | 'SendToBank' | 'PlanDeal' | 'CreateSignature' | 'Print' | 'Accept' | 'Deny' | 'ForRevision' | 'Sign' | 'Filled' | 'AddClientRelation' | 'LoadDocumentsForVerification' | 'CompleteDocumentVerification' | 'SendToDecision' | 'Update' | 'CompleteCurrentState' | 'SendToRealEstate' | 'ForRevisionFromUnderwriterOrAuthorizer' | 'SendToJuristicInvestigation' | 'SendToCheckGozo' | 'SendToDealPreparation' | 'SendToInsurance' | 'SendToDocumentsFinalize' | 'SendToDeal' | 'SendToFinalizeDeal' | 'SendToDealVerification' | 'SendToRealEstateFinalize' | 'SendToFinalizationOfForm' | 'Zip' | 'UnZip' | 'MoveToDraft' | 'SendToFinalizeDecision' | 'SendToFinalizeDecisionAndRealEstate' | 'SendToInProgress';

export const ApplicationAction = {
    Create: 'Create' as ApplicationAction,
    Edit: 'Edit' as ApplicationAction,
    Delete: 'Delete' as ApplicationAction,
    SendToBank: 'SendToBank' as ApplicationAction,
    PlanDeal: 'PlanDeal' as ApplicationAction,
    CreateSignature: 'CreateSignature' as ApplicationAction,
    Print: 'Print' as ApplicationAction,
    Accept: 'Accept' as ApplicationAction,
    Deny: 'Deny' as ApplicationAction,
    ForRevision: 'ForRevision' as ApplicationAction,
    Sign: 'Sign' as ApplicationAction,
    Filled: 'Filled' as ApplicationAction,
    AddClientRelation: 'AddClientRelation' as ApplicationAction,
    LoadDocumentsForVerification: 'LoadDocumentsForVerification' as ApplicationAction,
    CompleteDocumentVerification: 'CompleteDocumentVerification' as ApplicationAction,
    SendToDecision: 'SendToDecision' as ApplicationAction,
    Update: 'Update' as ApplicationAction,
    CompleteCurrentState: 'CompleteCurrentState' as ApplicationAction,
    SendToRealEstate: 'SendToRealEstate' as ApplicationAction,
    ForRevisionFromUnderwriterOrAuthorizer: 'ForRevisionFromUnderwriterOrAuthorizer' as ApplicationAction,
    SendToJuristicInvestigation: 'SendToJuristicInvestigation' as ApplicationAction,
    SendToCheckGozo: 'SendToCheckGozo' as ApplicationAction,
    SendToDealPreparation: 'SendToDealPreparation' as ApplicationAction,
    SendToInsurance: 'SendToInsurance' as ApplicationAction,
    SendToDocumentsFinalize: 'SendToDocumentsFinalize' as ApplicationAction,
    SendToDeal: 'SendToDeal' as ApplicationAction,
    SendToFinalizeDeal: 'SendToFinalizeDeal' as ApplicationAction,
    SendToDealVerification: 'SendToDealVerification' as ApplicationAction,
    SendToRealEstateFinalize: 'SendToRealEstateFinalize' as ApplicationAction,
    SendToFinalizationOfForm: 'SendToFinalizationOfForm' as ApplicationAction,
    Zip: 'Zip' as ApplicationAction,
    UnZip: 'UnZip' as ApplicationAction,
    MoveToDraft: 'MoveToDraft' as ApplicationAction,
    SendToFinalizeDecision: 'SendToFinalizeDecision' as ApplicationAction,
    SendToFinalizeDecisionAndRealEstate: 'SendToFinalizeDecisionAndRealEstate' as ApplicationAction,
    SendToInProgress: 'SendToInProgress' as ApplicationAction
};

