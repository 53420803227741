import { TuiSizeL, TuiSizeM, TuiSizeS } from '@taiga-ui/core';
import { SelectOptions } from '@lib-utils';

export enum ConfirmationDialogType {
  TextArea,
  Select,
  MultiSelect,
  Input,
  Question,
  Files,
}

export interface DialogFieldOptions {
  size: TuiSizeS | TuiSizeM | TuiSizeL;
}

export interface ConfirmationDialogData<T, TVal> {
  type: ConfirmationDialogType;
  fieldOptions?: DialogFieldOptions;
  title?: string;
  text?: string;
  okText?: string;
  cancelText?: string;
  resetText?: string;
  label?: string;
  value?: TVal;
  resetValue?: TVal;
  focusOnConfirm?: boolean;
  focusOnCancel?: boolean;
  focusOnReset?: boolean;
  showCancel?: boolean;
  showReset?: boolean;
  options?: SelectOptions<T, TVal>;
  hasSearch?: boolean;
}
