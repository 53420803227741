import { TuiTime } from '@taiga-ui/cdk';
import { Nullable } from '@lib-utils';
import {
  BuyerInfoDto,
  CalculationResultDto,
  CreateOrUpdateOwnerRequisitesRequest,
  InseparableImprovementsType,
  OwnershipType,
  PaymentInfoDto,
  RealEstateType,
  UpdateOnPrepareForDealStateRequest,
  UpdateOwnerNominalAccountOnPrepareForDealStateRequest,
} from '@lib-mortgage/api';
import { ClientIdName } from './api-middleware';

export interface FePrepareForDealDto {
  creditAgreement?: FeCreditAgreementDto;
  ownership?: FeOwnershipDto;
  calculator?: FePrepareForDealCalculationResultDto;
  requisites?: CreateOrUpdateOwnerRequisitesRequest;
}

export interface FeCreditAgreementDto
  extends Pick<
    Omit<UpdateOnPrepareForDealStateRequest, 'date'>,
    | 'cftDepartmentId'
    | 'dealDepartmentId'
    | 'bankSignerId'
    | 'creditTransferPeriod'
    | 'registrationType'
    | 'electronicRegistrationCost'
  > {
  // Разделяем поле `data` на две части: `dealTime` - время, `date` - дата
  dealTime?: TuiTime | null;
  date?: Date | null;
  // Переопределяем поле `paymentDate`
  paymentDate?: Date | null;
  // Справочные поля для `term`
  cftDepartmentName?: string | null;
  dealDepartmentName?: string | null;
  bankSignerName?: string | null;
  // Определяем поле для резервации номера счета
  currentAccountNumber?: string | null;
}

export interface FeOwnershipDto {
  propertyType: OwnershipType;
  buyers?: Array<FeBuyerInfoDto> | null;
}

export interface FeBuyerInfoDto extends Omit<ClientIdName, 'clientId' | 'physicalPerson' | 'role'>, BuyerInfoDto {
  share?: string | null;
  selected?: boolean | null;
}

export interface FePrepareForDealCalculationResultDto
  extends Pick<CalculationResultDto, 'realEstatePrice' | 'creditAmount' | 'initialPaymentSum'> {
  paymentMethods?: Array<FePaymentInfoDto> | null;
  realEstatePrices?: Nullable<FeRealEstatePriceDataDto[]>;
  inseparableImprovements?: InseparableImprovementsType;
  inseparableImprovementsCost?: number | null;
  contractOfSaleCost?: number | null;
}

export interface FeRealEstatePriceDataDto {
  realEstateId?: number;
  type?: RealEstateType;
  price?: number | null;
}

export interface FeOwnerNominalAccountDto
  extends Omit<UpdateOwnerNominalAccountOnPrepareForDealStateRequest, 'offerDate'> {
  offerDate?: Date | null;
  nominalAccountNumber?: string | null;
}

export interface FePaymentInfoDto extends Omit<PaymentInfoDto, 'requisites' | 'nominalAccount'> {
  requisites?: CreateOrUpdateOwnerRequisitesRequest;
  nominalAccount?: FeOwnerNominalAccountDto;
}
