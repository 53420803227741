import { AsyncPipe, NgIf } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TuiLetModule } from '@taiga-ui/cdk';
import { filter, skip, Subject, take } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { BreakpointService, FeHttpError, LOGO_SRC, NEW_LOGO_SRC, Nullable, SharedValidators } from '@lib-utils';
import { ButtonModule } from '@lib-widgets/core';
import {
  ReactiveFieldErrorModule,
  ReactiveInputModule,
  ReactiveInputPasswordModule,
} from '@lib-widgets/reactive-fields';
import { AuthorizationStorageService } from '../../services';
import { COOKIE_CHANGED, CURRENT_USER, getLogin, TOKEN_STORE } from '../../utils';

@Component({
  selector: 'fnip-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  imports: [
    AsyncPipe,
    NgIf,
    ReactiveInputModule,
    ReactiveInputPasswordModule,
    ButtonModule,
    ReactiveFieldErrorModule,
    TuiLetModule,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BreakpointService],
})
export class LoginComponent {
  private readonly authorizationStorageService = inject(AuthorizationStorageService);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly cookieChanged$ = inject(COOKIE_CHANGED);
  private readonly tokenStore = inject(TOKEN_STORE);
  private readonly currentUser = inject(CURRENT_USER);

  readonly isMobile$ = inject(BreakpointService).isMobile$;

  from = new FormGroup({
    login: new FormControl(null, [Validators.email]),
    password: new FormControl(null, [
      SharedValidators.password({
        upper: true,
        lower: true,
        number: true,
        maxLength: 50,
        minLength: 5,
      }),
    ]),
  });

  invalidData: Nullable<string>;

  isNew = this.activatedRoute.snapshot.data.isNew;

  logo = this.isNew ? NEW_LOGO_SRC : LOGO_SRC;

  login = getLogin();

  readonly redirectBackCb = () => {
    const { returnPath } = this.authorizationStorageService;
    if (returnPath) {
      this.authorizationStorageService.resetReturnPath();
      return this.router.navigateByUrl(returnPath).then((isSuccess) => {
        if (!isSuccess) {
          this.router.navigateByUrl('/');
        }
      });
    }

    return this.router.navigateByUrl('/');
  };

  // Обрабатываем изменения токена в другой вкладке
  cancelCookieChanged$ = new Subject<void>();
  cookieChangedLogin$ = this.cookieChanged$.pipe(
    takeUntil(this.cancelCookieChanged$),
    filter((cookieToken): cookieToken is string => !!cookieToken),
    switchMap((token) => {
      const user = this.currentUser.pipe(skip(1), take(1));
      this.tokenStore.next(token ?? null);
      return user;
    }),
    tap(this.redirectBackCb),
  );

  submitFormCallback$ = () => {
    if (this.from.invalid) {
      this.from.markAllAsTouched();
      return null;
    }

    this.cancelCookieChanged$.next();
    return this.login(this.from.value.login!, this.from.value.password!).pipe(
      tap(this.redirectBackCb),
      tap({
        error: (err: Error | HttpErrorResponse) => {
          if (
            ((err instanceof HttpErrorResponse || err instanceof FeHttpError) && err.status === 400) ||
            err.message === 'Role is cant authorize'
          )
            this.invalidData = 'Неправильный логин или пароль';
        },
      }),
    );
  };
}
