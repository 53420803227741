/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MaritalStatus } from './maritalStatus';
import { Sex } from './sex';
import { DateOnly } from '@lib-utils';


export interface PhysicalPersonDto { 
    id?: number;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    sex?: Sex;
    taxNumber?: string | null;
    birthdate?: DateOnly | null;
    snils?: string | null;
    readonly fullName?: string | null;
    birthplace?: string | null;
    registrationAddress?: string | null;
    factualAddress?: string | null;
    residence?: string | null;
    phone?: string | null;
    email?: string | null;
    maritalStatus?: MaritalStatus;
    birthCountryCode?: string | null;
    birthCountry?: string | null;
}
export namespace PhysicalPersonDto {
}


