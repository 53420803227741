import { ColDef } from '@ag-grid-community/core';
import {
  ReactiveButtonCheckboxCellComponent,
  ReactiveButtonCheckboxCellParams,
} from '../renderers/reactive-button-checkbox-cell';
import { exactWidth } from './exact-width';

export const reactiveButtonCheckboxCellDef = (
  config: ReactiveButtonCheckboxCellParams,
): Pick<ColDef, 'cellRenderer' | 'cellRendererParams' | 'cellStyle'> & ReturnType<typeof exactWidth> => ({
  cellRenderer: ReactiveButtonCheckboxCellComponent,
  cellRendererParams: config,
  ...exactWidth(80),
  cellStyle: { padding: '0 5px' },
});
