/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ClientType } from './clientType';
import { DocumentFileDto } from './documentFileDto';
import { PaymentInvoiceDto } from './paymentInvoiceDto';
import { DriverLicenseDto } from './driverLicenseDto';
import { SopdDto } from './sopdDto';
import { VehicleInformationLetterDto } from './vehicleInformationLetterDto';
import { VehiclePassportDto } from './vehiclePassportDto';
import { SaleContractVehicleDto } from './saleContractVehicleDto';
import { PassportDto } from './passportDto';
import { PaymentReceiptDto } from './paymentReceiptDto';
import { VerificationTaskType } from './verificationTaskType';


export interface RequestItemDto { 
    documentType?: VerificationTaskType;
    clientId?: string | null;
    clientType?: ClientType;
    documentKey?: string | null;
    passport?: PassportDto;
    driverLicense?: DriverLicenseDto;
    vehiclePassport?: VehiclePassportDto;
    vehicleInformationLetter?: VehicleInformationLetterDto;
    sopd?: SopdDto;
    paymentInvoice?: PaymentInvoiceDto;
    paymentReceipt?: PaymentReceiptDto;
    saleContractVehicle?: SaleContractVehicleDto;
    documentFiles?: Array<DocumentFileDto> | null;
}
export namespace RequestItemDto {
}


