import { VerificationStatus } from '@lib-verification/api';

export type VerificationAllDocumentStatus = VerificationStatus | 'AllTasks' | 'MyTasks';
export const VerificationAllDocumentStatus = {
  ...VerificationStatus,
  AllTasks: 'AllTasks' as VerificationAllDocumentStatus,
  MyTasks: 'MyTasks' as VerificationAllDocumentStatus,
};

export type VerificationGeneralTaskType = 'All' | 'Document' | 'Call';
export const VerificationGeneralTaskType = {
  All: 'All' as VerificationGeneralTaskType,
  Document: 'Document' as VerificationGeneralTaskType,
  Call: 'Call' as VerificationGeneralTaskType,
};
