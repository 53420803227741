import { ColDef } from '@ag-grid-community/core';
import { MultiRowCellParams } from './multi-row-cell-params';
import { MultiRowCellComponent } from './multi-row-cell.component';

export const getMultiRowCellDef = <TData = unknown>(params: MultiRowCellParams<TData>) =>
  ({
    cellRenderer: MultiRowCellComponent,
    cellRendererParams: params,
    suppressCellFlash: true,
    sortable: false,
  }) as const satisfies ColDef<TData>;
