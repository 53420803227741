import { inject } from '@angular/core';
import { filter, Observable, switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { beToFeOptions, NotificationService, Nullable } from '@lib-utils';
import { injectDialogService } from '@lib-widgets/dialog';
import { CustomActionAppearance, getActionCellDef } from '@lib-widgets/grid';
import { ManualReviewApplicationItemDto, ManualReviewTaskStatus, ManualReviewTaskType } from '@lib-mortgage/api';
import { PermissionOperationMapKeys, PermissionService } from '@lib-mortgage/features/authorization';
import { ExecutorChangeDialogComponent } from './executor-change-dialog/executor-change-dialog.component';
import { ExecutorChangeDialogModel } from './executor-change-dialog/executor-change-dialog.model';

export interface ExecutorCellDefParams<TData = unknown> {
  operationPermission: PermissionOperationMapKeys;
  taskType: ManualReviewTaskType;
  hint?: string;
  role?: string;

  getLabel: (data?: TData) => Nullable<string>;
  getApplicationId: (data?: TData) => Nullable<number>;
  getTaskId: (data?: TData) => Nullable<number>;

  selectOptions$: Observable<{ data?: Array<{ id?: number; fullName?: string | null }> | null }>;

  assignExecutor$: (taskId: number, executorId: number) => Observable<unknown>;
}

export const getExecutorCellDef = <TData extends ManualReviewApplicationItemDto>({
  hint,
  role,
  operationPermission,
  taskType,
  getApplicationId,
  getTaskId,
  getLabel,
  selectOptions$,
  assignExecutor$,
}: ExecutorCellDefParams<TData>) => {
  const permissionService = inject(PermissionService);
  const dialogService = injectDialogService();
  const notificationService = inject(NotificationService);

  return getActionCellDef<TData>({
    appearance: CustomActionAppearance.ActionButton,
    hint: hint ?? 'Изменить исполнителя',
    getLabel: (data) => getLabel(data),
    getIcon: () => 'tuiIconExternalLink',
    getAction$: (data) => {
      const hasPermission = permissionService.checkExactPermissionFor(operationPermission);

      if (
        data?.actualTask?.taskType !== taskType ||
        !hasPermission ||
        data?.actualTask.status === ManualReviewTaskStatus.Done
      )
        return;

      const applicationId = getApplicationId(data);
      const taskId = getTaskId(data);
      const roleName = role ?? 'исполнитель';

      if (!applicationId || !taskId) return;

      return () =>
        dialogService
          .open<Nullable<number>, ExecutorChangeDialogModel>(ExecutorChangeDialogComponent, {
            contextData: {
              role: roleName,
              applicationId,
              selectOptions$: selectOptions$.pipe(map(({ data }) => beToFeOptions(data, { labelPath: 'fullName' }))),
            },
          })
          .pipe(
            filter((executorId): executorId is number => !!executorId),
            switchMap((executorId) => assignExecutor$(taskId, executorId)),
            tap(notificationService.onSuccess(`${roleName} назначен`)),
            tap(notificationService.onError(`Ошибка назначения ${roleName}`)),
          );
    },
  });
};
