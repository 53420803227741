/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DocumentType = 'BirthCertificate' | 'PassportRu' | 'DriverLicense' | 'ForeignPassport' | 'MilitaryId' | 'PreviousPassportRu' | 'PreviousPassportRu2' | 'PreviousPassportRu3' | 'EmploymentHistory' | 'Sopd' | 'VehiclePassport' | 'PaymentInvoice' | 'PaymentReceipt' | 'SaleContractVehicle' | 'ClientPhoto' | 'LifeInsurance' | 'AdditionalServicesRefusal' | 'ApplicationAndConsentForm' | 'RoadAccidentAssistancePolicy' | 'ApplicationForJoiningDKO' | 'ApplicationForTransferOfFunds' | 'PaymentSchedule' | 'IndividualConditions' | 'PassportFull' | 'ApplicationForTransferForVehicle' | 'VehicleInformationLetter';

export const DocumentType = {
    BirthCertificate: 'BirthCertificate' as DocumentType,
    PassportRu: 'PassportRu' as DocumentType,
    DriverLicense: 'DriverLicense' as DocumentType,
    ForeignPassport: 'ForeignPassport' as DocumentType,
    MilitaryId: 'MilitaryId' as DocumentType,
    PreviousPassportRu: 'PreviousPassportRu' as DocumentType,
    PreviousPassportRu2: 'PreviousPassportRu2' as DocumentType,
    PreviousPassportRu3: 'PreviousPassportRu3' as DocumentType,
    EmploymentHistory: 'EmploymentHistory' as DocumentType,
    Sopd: 'Sopd' as DocumentType,
    VehiclePassport: 'VehiclePassport' as DocumentType,
    PaymentInvoice: 'PaymentInvoice' as DocumentType,
    PaymentReceipt: 'PaymentReceipt' as DocumentType,
    SaleContractVehicle: 'SaleContractVehicle' as DocumentType,
    ClientPhoto: 'ClientPhoto' as DocumentType,
    LifeInsurance: 'LifeInsurance' as DocumentType,
    AdditionalServicesRefusal: 'AdditionalServicesRefusal' as DocumentType,
    ApplicationAndConsentForm: 'ApplicationAndConsentForm' as DocumentType,
    RoadAccidentAssistancePolicy: 'RoadAccidentAssistancePolicy' as DocumentType,
    ApplicationForJoiningDko: 'ApplicationForJoiningDKO' as DocumentType,
    ApplicationForTransferOfFunds: 'ApplicationForTransferOfFunds' as DocumentType,
    PaymentSchedule: 'PaymentSchedule' as DocumentType,
    IndividualConditions: 'IndividualConditions' as DocumentType,
    PassportFull: 'PassportFull' as DocumentType,
    ApplicationForTransferForVehicle: 'ApplicationForTransferForVehicle' as DocumentType,
    VehicleInformationLetter: 'VehicleInformationLetter' as DocumentType
};

