/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type VerificationTaskType = 'Passport' | 'PassportMainPage' | 'PassportRegistration' | 'Snils' | 'TaxNumber' | 'IncomeStatement' | 'IncomeStatementBankFormat' | 'EmploymentHistory' | 'ElectronicEmploymentHistory' | 'EmploymentContract' | 'Passport14And15Page' | 'Passport16And17Page' | 'DriverLicense' | 'ForeignPassport' | 'MilitaryId' | 'Sopd' | 'SopdFinalization' | 'EmploymentContract2' | 'EmploymentContract3' | 'IncomeStatementPartTime' | 'IncomeStatementPartTime2' | 'IncomeStatementPartTime3' | 'IncomeStatementBankFormatPartTime' | 'IncomeStatementBankFormatPartTime2' | 'IncomeStatementBankFormatPartTime3' | 'TaxReturn' | 'TaxReturnPartTime' | 'TaxReturnPartTime2' | 'TaxReturnPartTime3' | 'SelfEmployedCertificate' | 'SelfEmployedCertificatePartTime' | 'SelfEmployedCertificatePartTime2' | 'SelfEmployedCertificatePartTime3' | 'ProfessionalIncomeTaxCertificate' | 'ProfessionalIncomeTaxCertificatePartTime' | 'ProfessionalIncomeTaxCertificatePartTime2' | 'ProfessionalIncomeTaxCertificatePartTime3' | 'PensionCertificate' | 'PreviousPassportsRu' | 'Call' | 'EmploymentContractMainJob' | 'VehiclePassport' | 'PaymentInvoice' | 'PaymentReceipt' | 'SaleContractVehicle' | 'ClientPhoto' | 'LifeInsurance' | 'AdditionalServicesRefusal' | 'ApplicationAndConsentForm' | 'RoadAccidentAssistancePolicy' | 'ApplicationForJoiningDKO' | 'ApplicationForTransferOfFunds' | 'PaymentSchedule' | 'IndividualConditions' | 'PassportFull' | 'ApplicationForTransferForVehicle' | 'VehicleInformationLetter';

export const VerificationTaskType = {
    Passport: 'Passport' as VerificationTaskType,
    PassportMainPage: 'PassportMainPage' as VerificationTaskType,
    PassportRegistration: 'PassportRegistration' as VerificationTaskType,
    Snils: 'Snils' as VerificationTaskType,
    TaxNumber: 'TaxNumber' as VerificationTaskType,
    IncomeStatement: 'IncomeStatement' as VerificationTaskType,
    IncomeStatementBankFormat: 'IncomeStatementBankFormat' as VerificationTaskType,
    EmploymentHistory: 'EmploymentHistory' as VerificationTaskType,
    ElectronicEmploymentHistory: 'ElectronicEmploymentHistory' as VerificationTaskType,
    EmploymentContract: 'EmploymentContract' as VerificationTaskType,
    Passport14And15Page: 'Passport14And15Page' as VerificationTaskType,
    Passport16And17Page: 'Passport16And17Page' as VerificationTaskType,
    DriverLicense: 'DriverLicense' as VerificationTaskType,
    ForeignPassport: 'ForeignPassport' as VerificationTaskType,
    MilitaryId: 'MilitaryId' as VerificationTaskType,
    Sopd: 'Sopd' as VerificationTaskType,
    SopdFinalization: 'SopdFinalization' as VerificationTaskType,
    EmploymentContract2: 'EmploymentContract2' as VerificationTaskType,
    EmploymentContract3: 'EmploymentContract3' as VerificationTaskType,
    IncomeStatementPartTime: 'IncomeStatementPartTime' as VerificationTaskType,
    IncomeStatementPartTime2: 'IncomeStatementPartTime2' as VerificationTaskType,
    IncomeStatementPartTime3: 'IncomeStatementPartTime3' as VerificationTaskType,
    IncomeStatementBankFormatPartTime: 'IncomeStatementBankFormatPartTime' as VerificationTaskType,
    IncomeStatementBankFormatPartTime2: 'IncomeStatementBankFormatPartTime2' as VerificationTaskType,
    IncomeStatementBankFormatPartTime3: 'IncomeStatementBankFormatPartTime3' as VerificationTaskType,
    TaxReturn: 'TaxReturn' as VerificationTaskType,
    TaxReturnPartTime: 'TaxReturnPartTime' as VerificationTaskType,
    TaxReturnPartTime2: 'TaxReturnPartTime2' as VerificationTaskType,
    TaxReturnPartTime3: 'TaxReturnPartTime3' as VerificationTaskType,
    SelfEmployedCertificate: 'SelfEmployedCertificate' as VerificationTaskType,
    SelfEmployedCertificatePartTime: 'SelfEmployedCertificatePartTime' as VerificationTaskType,
    SelfEmployedCertificatePartTime2: 'SelfEmployedCertificatePartTime2' as VerificationTaskType,
    SelfEmployedCertificatePartTime3: 'SelfEmployedCertificatePartTime3' as VerificationTaskType,
    ProfessionalIncomeTaxCertificate: 'ProfessionalIncomeTaxCertificate' as VerificationTaskType,
    ProfessionalIncomeTaxCertificatePartTime: 'ProfessionalIncomeTaxCertificatePartTime' as VerificationTaskType,
    ProfessionalIncomeTaxCertificatePartTime2: 'ProfessionalIncomeTaxCertificatePartTime2' as VerificationTaskType,
    ProfessionalIncomeTaxCertificatePartTime3: 'ProfessionalIncomeTaxCertificatePartTime3' as VerificationTaskType,
    PensionCertificate: 'PensionCertificate' as VerificationTaskType,
    PreviousPassportsRu: 'PreviousPassportsRu' as VerificationTaskType,
    Call: 'Call' as VerificationTaskType,
    EmploymentContractMainJob: 'EmploymentContractMainJob' as VerificationTaskType,
    VehiclePassport: 'VehiclePassport' as VerificationTaskType,
    PaymentInvoice: 'PaymentInvoice' as VerificationTaskType,
    PaymentReceipt: 'PaymentReceipt' as VerificationTaskType,
    SaleContractVehicle: 'SaleContractVehicle' as VerificationTaskType,
    ClientPhoto: 'ClientPhoto' as VerificationTaskType,
    LifeInsurance: 'LifeInsurance' as VerificationTaskType,
    AdditionalServicesRefusal: 'AdditionalServicesRefusal' as VerificationTaskType,
    ApplicationAndConsentForm: 'ApplicationAndConsentForm' as VerificationTaskType,
    RoadAccidentAssistancePolicy: 'RoadAccidentAssistancePolicy' as VerificationTaskType,
    ApplicationForJoiningDko: 'ApplicationForJoiningDKO' as VerificationTaskType,
    ApplicationForTransferOfFunds: 'ApplicationForTransferOfFunds' as VerificationTaskType,
    PaymentSchedule: 'PaymentSchedule' as VerificationTaskType,
    IndividualConditions: 'IndividualConditions' as VerificationTaskType,
    PassportFull: 'PassportFull' as VerificationTaskType,
    ApplicationForTransferForVehicle: 'ApplicationForTransferForVehicle' as VerificationTaskType,
    VehicleInformationLetter: 'VehicleInformationLetter' as VerificationTaskType
};

