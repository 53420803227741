import { Observable } from 'rxjs';
import { Nullable } from '@lib-utils';

export const OrderByOptions = {
  Asc: 'ASC',
  Desc: 'DESC',
} as const;

export type OrderByOptions = (typeof OrderByOptions)[keyof typeof OrderByOptions];

export interface PageInfo {
  page?: number;
  perPage?: number;
  pages?: number;
  items?: number;
}

export interface GridPagination {
  page: number;
  perPage: number;
}

export interface GridOrder<TModelFields = string> {
  orderBy: OrderByOptions;
  fieldBy: TModelFields;
}

export interface PaginatedResult<T> {
  data?: Nullable<T[]>;
  page?: PageInfo;
}

export interface ColumnsCheckVisibleParams<TModel = unknown> {
  colId: string;
  updateWithFetch?: boolean;
  isVisible: (data: Nullable<TModel[]>) => boolean;
}

export type GridGetDataCallback<TModel = unknown, TModelFields = string> = (
  pagination: GridPagination,
  order?: GridOrder<TModelFields>,
) => Observable<Nullable<PaginatedResult<TModel> | TModel[]>>;

export type RowDataSource<TModel = unknown, TModelFields = unknown> =
  | GridGetDataCallback<TModel, TModelFields>
  | Observable<Nullable<PaginatedResult<TModel> | TModel[]>>
  | PaginatedResult<TModel>
  | TModel[];
