import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveCheckboxModule } from '@lib-widgets/reactive-fields';
import { ReactiveCheckboxCellComponent } from './reactive-checkbox-cell.component';

@NgModule({
  declarations: [ReactiveCheckboxCellComponent],
  imports: [CommonModule, ReactiveCheckboxModule],
})
export class ReactiveCheckboxCellModule {}
