import { TuiStatus } from '@taiga-ui/kit/types';
import { Nullable } from '@lib-utils';

type DataFn<TData, TReturn> = (data: TData | undefined) => Nullable<TReturn>;

export enum MultiRowType {
  Text,
  Tag,
}

export interface MultiRowConfig<TData = unknown> {
  type: MultiRowType;
  getValue?: DataFn<TData, string>;
  value?: Nullable<string>;
  getClass?: DataFn<TData, string>;
  class?: Nullable<string>;
  getStyles?: DataFn<TData, { [prop: string]: string | number }>;
  styles?: Nullable<{ [prop: string]: string | number }>;
  getTagStatus?: DataFn<TData, TuiStatus>;
  tagStatus?: Nullable<TuiStatus>;
}

export interface MultiRowCellParams<TData = unknown> {
  rows: Nullable<MultiRowConfig<TData>[]>;
  rowsContainerClass?: Nullable<string>;
}
