import { ColumnState, GridOptions } from '@ag-grid-community/core';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import isEqual from 'lodash-es/isEqual';
import { of } from 'rxjs';
import { filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { ExecuteWithPipeModule, Nullable } from '@lib-utils';
import { ButtonModule, DividerModule } from '@lib-widgets/core';
import { RequestWrapperModule } from '@lib-widgets/request-wrapper';
import { injectSidebar } from '@lib-widgets/sidebar';
import { GridStateService } from '../grid-state.service';
import { getGridState, GridId, GridSavedState, GridState } from '../utils';
import { GridColumn } from './grid-state-toolbar-utils';
import { GridColumnListComponent } from './widgets/grid-column-list';

@Component({
  selector: 'fnip-grid-state-toolbar',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    DividerModule,
    ExecuteWithPipeModule,
    RequestWrapperModule,
    GridColumnListComponent,
  ],
  templateUrl: './grid-state-toolbar.component.html',
  styleUrls: ['./grid-state-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridStateToolbarComponent {
  @Input({ required: true }) gridId!: GridId;
  @Input() gridOptions: Nullable<GridOptions>;
  @Input() initialColumnState: Nullable<ColumnState[]>;
  @Input() initialFilterModel: Nullable<{ [key: string]: unknown }>;
  @Output() closed = new EventEmitter<void>();

  private readonly sidebarService = injectSidebar();
  readonly gridStateService = inject(GridStateService, { optional: true });

  columnsOrder = new Map<number, number>();

  columns: Nullable<GridColumn[]>;
  resetState: Nullable<GridState>;

  getToolbarData$ = this.getToolbarDataCb$();

  closeToolbar = () => {
    this.closed.next();
  };

  setColumnVisibility = (data: ColumnState) =>
    this.gridOptions?.columnApi?.applyColumnState({
      state: [{ ...data }],
    });

  saveGridState$ = (savedState: Nullable<GridSavedState>) => () => {
    // Текущая реализация, обсуденная в рамках FNCOL-131: если делаем сохранение сразу после reset - удаляем сохраненное состояние таблицы
    const newState = getGridState(this.gridOptions);
    const action$ =
      isEqual(newState, this.resetState) && savedState
        ? this.gridStateService?.removeGridState$(this.gridId)
        : this.gridStateService?.saveGridState$(this.gridId, newState);
    return action$?.pipe(
      tap(() => {
        this.getToolbarData$ = this.getToolbarDataCb$();
        this.resetState = null;
      }),
    );
  };

  resetGridState = () => {
    if (!this.gridOptions?.api || !this.gridOptions.columnApi) return;
    this.gridOptions.columnApi.applyColumnState({ state: this.initialColumnState ?? [], applyOrder: true });
    this.gridOptions.api.setFilterModel(this.initialFilterModel);
    this.columns = this.getColumns(this.gridOptions);
    this.resetState = getGridState(this.gridOptions);
  };

  setColumnOrder(columnOrder: Map<number, number>) {
    this.columnsOrder = columnOrder;

    if (!this.columns) return;

    const orderedColumns: GridColumn[] = [];

    Array.from(columnOrder.entries()).forEach(
      ([initialPos, orderedPos]) => (orderedColumns[orderedPos] = this.columns![initialPos]),
    );

    this.gridOptions?.columnApi?.applyColumnState({
      state: orderedColumns.map(({ colId }) => ({ colId })),
      applyOrder: true,
    });
  }

  private getToolbarDataCb$() {
    return (gridId: Nullable<GridId>, gridOptions: Nullable<GridOptions>) => {
      if (!gridId || !gridOptions) return null;
      return this.sidebarService.sidebarVisible$(gridId).pipe(
        filter(Boolean),
        switchMap(() => this.gridStateService?.getGridState$(gridId) || of(null)),
        switchMap((state) =>
          (this.gridStateService?.getOnStateChange$(gridId) ?? of(gridId)).pipe(
            startWith(gridId),
            map(() => state),
          ),
        ),
        tap(() => (this.columns = this.getColumns(gridOptions))),
      );
    };
  }

  private getColumns(gridOptions: Nullable<GridOptions>): Nullable<GridColumn[]> {
    const columnOrder = (gridOptions?.columnApi?.getColumnState() ?? []).map((column) => column.colId);

    this.columnsOrder.clear();

    // Обработать случай изменения порядка столбцов при открытом сайдбаре
    return gridOptions?.columnApi
      ?.getColumns()
      ?.filter((c) => !!c.getColDef().headerName && (!c.getColDef().lockVisible || c.isVisible()))
      ?.sort((a, b) => columnOrder.indexOf(a.getColId()) - columnOrder.indexOf(b.getColId()))
      .map((c) => ({
        colId: c.getColId(),
        lockPinned: c.getColDef().lockPinned,
        lockVisible: c.getColDef().lockVisible,
        name: c.getColDef().headerName,
        control: new FormControl(c.isVisible()),
      }));
  }
}
