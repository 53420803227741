/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserModel } from './userModel';
import { DateOnly } from '@lib-utils';
import { NominalAccountModel } from './nominalAccountModel';
import { DateTime } from '@lib-utils';


export interface OwnerNominalAccountModel { 
    id?: number;
    creatorId?: number;
    creator?: UserModel;
    created?: DateTime;
    updatorId?: number;
    updator?: UserModel;
    updated?: DateTime;
    nominalAccountId?: number | null;
    nominalAccount?: NominalAccountModel;
    uin?: string | null;
    offerDate?: DateOnly | null;
    offerNumber?: string | null;
    isActive?: boolean;
}

