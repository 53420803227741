import { inject, Provider } from '@angular/core';
import { NotificationSource } from '@lib-notifications/notification-list';
import { ConfigService } from '@lib-config';
import { MortgageConfiguration, NotificationApiService } from '@lib-mortgage/api';
import { createBearerCredentials } from '@lib-mortgage/features/authorization';
import { MortgageNotificationSource } from './mortgage.notification-source';

/**
 * Фабрика для провайда реализации NotificationListSource с использованием API ипотеки
 */
export const provideMortgageNotificationSource = (): Provider[] => {
  return [
    NotificationApiService,
    {
      provide: MortgageConfiguration,
      useFactory: () => {
        return new MortgageConfiguration({
          basePath: inject(ConfigService).apiUrl,
          credentials: createBearerCredentials(),
        });
      },
    },
    {
      provide: NotificationSource,
      useClass: MortgageNotificationSource,
    },
  ];
};
