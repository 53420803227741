import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { TuiStatus } from '@taiga-ui/kit/types';
import { Nullable } from '@lib-utils';
import { MultiRowCellParams, MultiRowConfig, MultiRowType } from './multi-row-cell-params';

export interface RowData extends TagRowData {
  type: MultiRowType;
  value: Nullable<string>;
  class: Nullable<string>;
  styles?: Nullable<{ [prop: string]: string | number }>;
}

export interface TagRowData {
  tagStatus?: Nullable<TuiStatus>;
  tagIcon?: Nullable<TuiStatus>;
}

@Component({
  selector: 'fnip-multi-row-cell',
  templateUrl: './multi-row-cell.component.html',
  styleUrls: ['./multi-row-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiRowCellComponent<TData = unknown> implements ICellRendererAngularComp {
  rows: Nullable<RowData[]>;
  rowsContainerClass: Nullable<string>;

  cdr = inject(ChangeDetectorRef);

  readonly MultiRowType = MultiRowType;

  agInit(params: ICellRendererParams & MultiRowCellParams) {
    this.refreshView(params);
  }

  refresh(params: ICellRendererParams & MultiRowCellParams): boolean {
    this.refreshView(params);
    return true;
  }

  private refreshView(params: ICellRendererParams & MultiRowCellParams) {
    this.rows = params.rows?.map((rowData: MultiRowConfig<unknown>) => this.getRowData(rowData, params.data));
    this.rowsContainerClass = params.data.rowsContainerClass;

    this.cdr.markForCheck();
  }

  private getRowData = (data: MultiRowConfig, cellParams: TData | undefined): RowData => ({
    type: data.type,
    value: data.getValue?.(cellParams) ?? data.value,
    class: data.getClass?.(cellParams) ?? data.class ?? '',
    styles: data.getStyles?.(cellParams) ?? data.styles,
    tagStatus: data.getTagStatus?.(cellParams) ?? data.tagStatus,
  });
}
