import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { createProvider, ValueOrFactoryProvider } from '@lib-utils';
import { ErrorInterceptor } from './error-interceptor.interceptor';
import { ErrorCheckMessage } from './error-interceptor.models';
import {
  ERROR_INTERCEPTOR_CONFIG_INJECTOR,
  ERROR_MAPPERS_INJECTOR,
  ERROR_MESSAGES_INJECTOR,
} from './error-interceptor.tokens';
import { DEFAULT_CONFIG } from './error-interceptor.utils';
import { ErrorMapperAbstract } from './error-mappers';

interface ErrorInterceptorForRootOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  messages: ErrorCheckMessage<any>[][];
  mappers: Type<ErrorMapperAbstract>[];
  config?: ValueOrFactoryProvider;
}

@NgModule({
  providers: [{ provide: HTTP_INTERCEPTORS, multi: true, useClass: ErrorInterceptor }],
})
export class ErrorInterceptorModule {
  static forRoot(options: ErrorInterceptorForRootOptions): ModuleWithProviders<ErrorInterceptorModule> {
    return {
      ngModule: ErrorInterceptorModule,
      providers: [
        { provide: ERROR_MESSAGES_INJECTOR, multi: true, useValue: options.messages },
        createProvider(ERROR_INTERCEPTOR_CONFIG_INJECTOR, options.config, DEFAULT_CONFIG),
        ...options.mappers.map((m) => ({ provide: ERROR_MAPPERS_INJECTOR, multi: true, useClass: m })),
      ],
    };
  }
}
