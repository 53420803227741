/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type VehicleCategory = 'A' | 'A1' | 'B' | 'B1' | 'C' | 'C1' | 'D' | 'D1' | 'E';

export const VehicleCategory = {
    A: 'A' as VehicleCategory,
    A1: 'A1' as VehicleCategory,
    B: 'B' as VehicleCategory,
    B1: 'B1' as VehicleCategory,
    C: 'C' as VehicleCategory,
    C1: 'C1' as VehicleCategory,
    D: 'D' as VehicleCategory,
    D1: 'D1' as VehicleCategory,
    E: 'E' as VehicleCategory
};

