import { TuiDay } from '@taiga-ui/cdk';
import { SelectOption } from '@lib-utils';
import { PercentageInputMode } from '@lib-widgets/reactive-fields';
import {
  CalculationResultDto,
  CalculationType,
  CreditPeriodOptionDto,
  CreditPurpose,
  FlexPriceOptionDto,
  InsuranceForm,
  ManualReviewClientDto,
  MaternalCapitalOptionDto,
  MaternalCapitalType,
  NonStandardType,
  ObjectType,
  ProofOfIncome,
  PtiInfoDto,
} from '@lib-mortgage/api';

// Интерфейс, обслуживающий форму Расчет в ААА, Решении и калькулятор в Проверке документов
export interface FeCalculationResultDto
  extends Omit<
    CalculationResultDto,
    | 'creator'
    | 'creditInfo'
    | 'creditProduct'
    | 'updator'
    | 'residentialComplex'
    | 'partner'
    | 'partnerManager'
    | 'additionalInfo'
  > {
  productRate?: number; // значение из creditProduct.rate
  fullName?: string; // значение из updator.fullName
  role?: string; // значение из updator.role.name
  initialPaymentOwnFundsType?: PercentageInputMode; // в каком режиме указывается ПВ собственными средствами в форме
}
// Интерфейс, обслуживающий форму клиента в ААА
export type FeClientDto = Omit<ManualReviewClientDto, 'scoringSummary'> & {
  index?: number; // порядковый номер в массиве (для отображения на UI)
  birthdayTui?: TuiDay;
  scoringSummary?: FeClientScoringDto;
};

export interface FeClientScoringDto {
  pdn1?: number;
  ptiInfo?: PtiInfoDto;
}

export interface FeCalculationsDto {
  actualCalculation?: FeCalculationResultDto;
  calculationType?: CalculationType;
}

// Интерфейс для преобразованных к использованию на FE опций калькулятора
export interface FeLoanCalculatorOptionsDto {
  objectTypeOptions: SelectOption<unknown, ObjectType>[];
  defaultCreditPurposeOptions: SelectOption<unknown, CreditPurpose>[];
  creditPurposeOptionsMap: Partial<Record<ObjectType, SelectOption<unknown, CreditPurpose>[]>>;
  insuranceFormOptions: SelectOption<unknown, InsuranceForm>[];
  proofOfIncomeOptions: SelectOption<unknown, ProofOfIncome>[];
  nonStandardTypeOptions: SelectOption<unknown, NonStandardType>[];
  maternalCapitalTypeOptions: SelectOption<unknown, MaternalCapitalType>[];
  flexPriceOptions?: FlexPriceOptionDto;
  creditPeriodOptions?: CreditPeriodOptionDto;
  maternalCapitalOptions?: MaternalCapitalOptionDto;
}
