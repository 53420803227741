import { DateOnly, FileInfo, Nullable } from '@lib-utils';
import { InsuranceDto, InsuranceLifeDto } from '@lib-mortgage/api';

export interface FeInsuranceClientDto {
  participation: Nullable<number>;
  rate: Nullable<number>;
  amount: Nullable<number>;
  id: Nullable<number>;
  clientId: Nullable<number>;
}

export interface FeInsurancePageDto {
  lifeInsurance: Nullable<FeInsuranceDto>;
  kssInsurance?: Nullable<FeInsuranceDto>;
  propertyInsurance?: Nullable<FeInsuranceDto>;
  titleInsurance?: Nullable<FeInsuranceDto>;
  landPlotTitleInsurance?: Nullable<FeInsuranceDto>;
  insuranceAmount?: Nullable<number>;
  insuranceDocuments?: Nullable<FeInsuranceDocuments>;
}

export interface FeInsuranceDto extends InsuranceDto {
  life?: FeInsuranceLifeDto[];
}

export interface FeInsuranceLifeDto extends InsuranceLifeDto {
  participation?: Nullable<number>;
  fullName?: Nullable<string>;
  birthday?: Nullable<DateOnly>;
}

export interface FeInsuranceDocuments {
  ClientInsuranceApplication: FileInfo[];
  ClientInsuranceContract: FileInfo[];
  PropertyTitleInsuranceContract: FileInfo[];
  PropertyAndTitleInsuranceContract: FileInfo[];
  PropertyAndTitleInsuranceApplication: FileInfo[];
}
