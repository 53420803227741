import { OccupationType } from '@lib-mortgage/api';

export const OccupationTypeMap: Record<OccupationType, string> = {
  Commercial: 'Коммерческая',
  Budget: 'Бюджетная',
  OwnBusiness: 'Собственник бизнеса',
  Employment: 'По найму',
  Pensioner: 'Пенсионер',
  SoleProprietor: 'ИП',
  SelfEmployed: 'Самозанятый',
  IsNotIncomePart: 'Не участвует доходами',
};

export const OccupationTypeForEmploymentMap = new Map<OccupationType, string>([
  [OccupationType.OwnBusiness, 'Собственник бизнеса'],
  [OccupationType.Employment, 'По найму'],
  // https://tracker.yandex.ru/FNIP-2833
  // [OccupationType.Pensioner, 'Пенсионер'],
  [OccupationType.SoleProprietor, 'ИП'],
  [OccupationType.SelfEmployed, 'Самозанятый'],
  [OccupationType.IsNotIncomePart, 'Не участвует доходами'],
]);
