import { FromGeneratedEnum } from '@lib-utils';
import { FileType, SourceType, VerificationTaskType, VerifierStatus } from '@lib-verification/api';
import { VerificationAllDocumentStatus, VerificationGeneralTaskType } from '@lib-verification/api-middleware';

export const VerificatorStatusNameMap: Record<VerifierStatus, string> = {
  NotReady: 'Не готов',
  Ready: 'Готов',
  Lunch: 'Обед',
  Break: 'Перерыв',
  InProgress: 'В обработке',
  OutgoingCall: 'Исходящий звонок',
};

export const VerificationSourceTypeMap: FromGeneratedEnum<SourceType> = {
  [SourceType.Auto]: 'Авто',
  [SourceType.Spr]: 'СПР Авто',
  [SourceType.Mortgage]: 'Ипотека',
  [SourceType.Other]: 'Иное',
};

export const VerificatorTaskTypeNameMap: Partial<Record<VerificationTaskType, string>> = {
  Passport: 'Паспорт (основное)',
  PassportFull: 'Паспорт (полный)',
  VehiclePassport: 'ПТС',
  Sopd: 'Согласие на обработку персональных данных',
  DriverLicense: 'Водительское удостоверение',
  PaymentReceipt: 'Квитанция об оплате ПВ за ТС',
  PaymentInvoice: 'Счет на оплату',
  SaleContractVehicle: 'Верификация ДКП',
  ClientPhoto: 'Фото заемщика',
  Call: 'Верификация номера',
  LifeInsurance: 'Полис страхование жизни',
  RoadAccidentAssistancePolicy: 'Полис помощи при ДТП',
  AdditionalServicesRefusal: 'Отказ от дополнительных услуг',
  ApplicationAndConsentForm: 'Анкета заявления и согласия',
  ApplicationForJoiningDKO: 'Заявление на присоединение к условиям ДКО',
  ApplicationForTransferForVehicle: 'Заявление на перевод денежных средств за ТС',
  ApplicationForTransferOfFunds: 'Заявление на перевод денежных средств за доп. продукты',
  IndividualConditions: 'Индивидуальные условия',
  PaymentSchedule: 'График платежей',
  VehicleInformationLetter: 'Информационное письмо',

  // FIXME: Скрываем для демо авто 22.12.2023
  // PassportMainPage: 'Паспорт (2-3 страница)',
  // EmploymentHistory: 'Трудовая книжка',
  // ElectronicEmploymentHistory: 'Электронная трудовая книжка',
  // EmploymentContract: 'Трудовой договор',
  // PreviousPassportsRu: 'Ранее выданные паспорта',
  // Snils: 'СНИЛС',
  // TaxNumber: 'ИНН',
  // IncomeStatement: '2-НДФЛ',
  // IncomeStatementBankFormat: 'Справка о доходах по форме банка',
  // PassportRegistration: 'Паспорт (4-13 страницы)',

  // Раскомментировать при выносе ипотеки в новы верификатор
  // SOPDFinalization: 'Согласие на обработку персональных данных',

  // FIXME: Скрываем для демо авто 22.12.2023
  // EmploymentContract2: 'Трудовой договор',
  // EmploymentContract3: 'Трудовой договор',
  // EmploymentContractMainJob: 'Трудовой договор',
  // IncomeStatementPartTime: '2-НДФЛ',
  // IncomeStatementPartTime2: '2-НДФЛ',
  // IncomeStatementPartTime3: '2-НДФЛ',
  // IncomeStatementBankFormatPartTime: 'Справка о доходах по форме банка',
  // IncomeStatementBankFormatPartTime2: 'Справка о доходах по форме банка',
  // IncomeStatementBankFormatPartTime3: 'Справка о доходах по форме банка',
  // TaxReturn: 'Налоговая декларация',
  // TaxReturnPartTime: 'Налоговая декларация',
  // TaxReturnPartTime2: 'Налоговая декларация',
  // TaxReturnPartTime3: 'Налоговая декларация',
  // PensionCertificate: 'Справка о пенсии',
  // SelfEmployedCertificate: 'Справка о постановке на учёт самозанятого',
  // SelfEmployedCertificatePartTime: 'Справка о постановке на учёт самозанятого',
  // SelfEmployedCertificatePartTime2: 'Справка о постановке на учёт самозанятого',
  // SelfEmployedCertificatePartTime3: 'Справка о постановке на учёт самозанятого',
  // ProfessionalIncomeTaxCertificate: 'Справка по налогу на профессиональный доход',
  // ProfessionalIncomeTaxCertificatePartTime: 'Справка по налогу на профессиональный доход',
  // ProfessionalIncomeTaxCertificatePartTime2: 'Справка по налогу на профессиональный доход',
  // ProfessionalIncomeTaxCertificatePartTime3: 'Справка по налогу на профессиональный доход',

  // https://tracker.yandex.ru/FNIP-2271
  // ForeignPassport, 'Заграничный паспорт'],
  // MilitaryId, 'Военный билет'],
};

export const VerificationDocumentStatusNameMap: Record<VerificationAllDocumentStatus, string> = {
  AllTasks: 'Все задания',
  ForRevision: 'Доработка',
  Pending: 'Не проверено',
  Processed: 'Обработано',
  RedirectedToSupervisor: 'Направлено на супервайзера',
  ApplicationArchived: 'Заявка отправлена в архив',
  Archived: 'Архивирована',
  Postponed: 'Отложено',
  Cancel: 'Отменено',
  Pause: 'Пауза',
  MyTasks: 'Мои задания',
};

export const VerificatorGeneralTaskTypeMap = new Map<VerificationGeneralTaskType, string>([
  [VerificationGeneralTaskType.All, 'Все задания'],
  [VerificationGeneralTaskType.Call, 'Верификация номера'],
  [VerificationGeneralTaskType.Document, 'Верификация документа'],
]);

export const VerificatorTaskTypeToFileTypeMap = new Map<FileType | VerificationTaskType, VerificationTaskType>([
  [FileType.ApplicationFull, VerificationTaskType.Sopd],
  [FileType.PassportFirstPage, VerificationTaskType.Passport],
  [FileType.EmploymentHistory, VerificationTaskType.EmploymentHistory],
  [FileType.ElectronicEmploymentHistory, VerificationTaskType.ElectronicEmploymentHistory],
  [FileType.EmploymentContract, VerificationTaskType.EmploymentContract],
  [FileType.Passport14And15Page, VerificationTaskType.Passport14And15Page],
  [FileType.Passport16And17Page, VerificationTaskType.Passport16And17Page],
  [FileType.Passport18And19Page, VerificationTaskType.PreviousPassportsRu],
  [FileType.Snils, VerificationTaskType.Snils],
  [FileType.Inn, VerificationTaskType.TaxNumber],
  [FileType.IncomeStatement, VerificationTaskType.IncomeStatement],
  [FileType.IncomeStatementBankFormat, VerificationTaskType.IncomeStatementBankFormat],
  [VerificationTaskType.PassportRegistration, VerificationTaskType.PassportRegistration],
  [FileType.Sopd, VerificationTaskType.Sopd],
  [FileType.EmploymentContract2, VerificationTaskType.EmploymentContract2],
  [FileType.EmploymentContract3, VerificationTaskType.EmploymentContract3],
  [FileType.EmploymentContractMainJob, VerificationTaskType.EmploymentContractMainJob],
  [FileType.IncomeStatementPartTime, VerificationTaskType.IncomeStatementPartTime],
  [FileType.IncomeStatementPartTime2, VerificationTaskType.IncomeStatementPartTime2],
  [FileType.IncomeStatementPartTime3, VerificationTaskType.IncomeStatementPartTime3],
  [FileType.IncomeStatementBankFormatPartTime, VerificationTaskType.IncomeStatementBankFormatPartTime],
  [FileType.IncomeStatementBankFormatPartTime2, VerificationTaskType.IncomeStatementBankFormatPartTime2],
  [FileType.IncomeStatementBankFormatPartTime3, VerificationTaskType.IncomeStatementBankFormatPartTime3],
  [FileType.TaxReturn, VerificationTaskType.TaxReturn],
  [FileType.TaxReturnPartTime, VerificationTaskType.TaxReturnPartTime],
  [FileType.TaxReturnPartTime2, VerificationTaskType.TaxReturnPartTime2],
  [FileType.TaxReturnPartTime3, VerificationTaskType.TaxReturnPartTime3],
  [FileType.PensionCertificate, VerificationTaskType.PensionCertificate],
  [FileType.SelfEmployedCertificate, VerificationTaskType.SelfEmployedCertificate],
  [FileType.SelfEmployedCertificatePartTime, VerificationTaskType.SelfEmployedCertificatePartTime],
  [FileType.SelfEmployedCertificatePartTime2, VerificationTaskType.SelfEmployedCertificatePartTime2],
  [FileType.SelfEmployedCertificatePartTime3, VerificationTaskType.SelfEmployedCertificatePartTime3],
  [FileType.ProfessionalIncomeTaxCertificate, VerificationTaskType.ProfessionalIncomeTaxCertificate],
  [FileType.ProfessionalIncomeTaxCertificatePartTime, VerificationTaskType.ProfessionalIncomeTaxCertificatePartTime],
  [FileType.ProfessionalIncomeTaxCertificatePartTime2, VerificationTaskType.ProfessionalIncomeTaxCertificatePartTime2],
  [FileType.ProfessionalIncomeTaxCertificatePartTime3, VerificationTaskType.ProfessionalIncomeTaxCertificatePartTime3],

  // https://tracker.yandex.ru/FNIP-2271
  // [FileType.DriverLicense, VerificationTaskType.DriverLicense],
  // [FileType.ForeignPassport, VerificationTaskType.ForeignPassport],
  // [FileType.MilitaryId, VerificationTaskType.MilitaryId],
]);

export const FileTypeToVerificatorTaskTypeMap = new Map<VerificationTaskType, FileType>([
  [VerificationTaskType.Sopd, FileType.ApplicationFull],
  [VerificationTaskType.Passport, FileType.PassportFirstPage],
  [VerificationTaskType.EmploymentHistory, FileType.EmploymentHistory],
  [VerificationTaskType.ElectronicEmploymentHistory, FileType.ElectronicEmploymentHistory],
  [VerificationTaskType.EmploymentContract, FileType.EmploymentContract],
  [VerificationTaskType.Passport14And15Page, FileType.Passport14And15Page],
  [VerificationTaskType.Passport16And17Page, FileType.Passport16And17Page],
  [VerificationTaskType.PreviousPassportsRu, FileType.Passport18And19Page],
  [VerificationTaskType.Snils, FileType.Snils],
  [VerificationTaskType.TaxNumber, FileType.Inn],
  [VerificationTaskType.IncomeStatement, FileType.IncomeStatement],
  [VerificationTaskType.IncomeStatementBankFormat, FileType.IncomeStatementBankFormat],
  [VerificationTaskType.PassportRegistration, FileType.PassportRegistrationPage],
  [VerificationTaskType.Sopd, FileType.Sopd],
  [VerificationTaskType.EmploymentContract2, FileType.EmploymentContract2],
  [VerificationTaskType.EmploymentContract3, FileType.EmploymentContract3],
  [VerificationTaskType.EmploymentContractMainJob, FileType.EmploymentContractMainJob],
  [VerificationTaskType.IncomeStatementPartTime, FileType.IncomeStatementPartTime],
  [VerificationTaskType.IncomeStatementPartTime2, FileType.IncomeStatementPartTime2],
  [VerificationTaskType.IncomeStatementPartTime3, FileType.IncomeStatementPartTime3],
  [VerificationTaskType.IncomeStatementBankFormatPartTime, FileType.IncomeStatementBankFormatPartTime],
  [VerificationTaskType.IncomeStatementBankFormatPartTime2, FileType.IncomeStatementBankFormatPartTime2],
  [VerificationTaskType.IncomeStatementBankFormatPartTime3, FileType.IncomeStatementBankFormatPartTime3],
  [VerificationTaskType.TaxReturn, FileType.TaxReturn],
  [VerificationTaskType.TaxReturnPartTime, FileType.TaxReturnPartTime],
  [VerificationTaskType.TaxReturnPartTime2, FileType.TaxReturnPartTime2],
  [VerificationTaskType.TaxReturnPartTime3, FileType.TaxReturnPartTime3],
  [VerificationTaskType.PensionCertificate, FileType.PensionCertificate],
  [VerificationTaskType.SelfEmployedCertificate, FileType.SelfEmployedCertificate],
  [VerificationTaskType.SelfEmployedCertificatePartTime, FileType.SelfEmployedCertificatePartTime],
  [VerificationTaskType.SelfEmployedCertificatePartTime2, FileType.SelfEmployedCertificatePartTime2],
  [VerificationTaskType.SelfEmployedCertificatePartTime3, FileType.SelfEmployedCertificatePartTime3],
  [VerificationTaskType.ProfessionalIncomeTaxCertificate, FileType.ProfessionalIncomeTaxCertificate],
  [VerificationTaskType.ProfessionalIncomeTaxCertificatePartTime, FileType.ProfessionalIncomeTaxCertificatePartTime],
  [VerificationTaskType.ProfessionalIncomeTaxCertificatePartTime2, FileType.ProfessionalIncomeTaxCertificatePartTime2],
  [VerificationTaskType.ProfessionalIncomeTaxCertificatePartTime3, FileType.ProfessionalIncomeTaxCertificatePartTime3],
]);
