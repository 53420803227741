<div *tuiLet="getModulesMeta | executeWith as modules">
  <ng-container *tuiLet="pageData.hasTranslation ?? false as hasTranslation">
    <fnip-toolbar
      [application]="hasTranslation ? ('BASE.UNIFIED_SECTION' | translate) : 'Единый кабинет'"
      [hasTranslation]="hasTranslation"
      [logo]="pageData.logo"
      [openIssueUrl]="pageData.openIssueUrl"
      [videoInstructionUrl]="pageData.videoInstructionUrl"
      [logoutCallBack$]="logout$"
    />

    <fnip-breadcrumb class="py-5 pl-10" [hostUrl]="hostUrl" />

    @if (modules.length) {
      <h1 class="my-4 pt-5 text-center">{{ hasTranslation ? ('BASE.SECTIONS' | translate) : 'Кабинеты' }}</h1>
      <div class="text-center line-height-28 d-flex flex-wrap content-center">
        @for (module of modules; track module.name) {
          @if (pageData.useLinks) {
            <a
              fnip-application-module-card
              [href]="module.link"
              [name]="module.name"
              [description]="module.description"
              [hasTranslation]="hasTranslation"
            ></a>
          } @else {
            <a
              fnip-application-module-card
              [routerLink]="[module.link]"
              [name]="module.name"
              [description]="module.description"
              [hasTranslation]="hasTranslation"
            ></a>
          }
        }
      </div>
    } @else {
      <div class="text-center">
        <h1 class="my-4 pt-5">{{ hasTranslation ? ('BASE.NO_MODULES' | translate) : 'Нет доступных кабинетов' }}</h1>
        <fnip-button label="Перейти в единый кабинет" appearance="primary" btnSize="l" [btnHref]="hostUrl" />
      </div>
    }
  </ng-container>
</div>
