import { ColDef } from '@ag-grid-community/core';
import { Nullable } from '@lib-utils';
import { TagCellComponent, TagCellConfig } from './tag-cell.component';

export const tagCellDef = <TData = unknown>(config: Nullable<TagCellConfig<TData>>) =>
  ({
    cellRenderer: TagCellComponent,
    cellRendererParams: config,
    refData: config?.labelMap || undefined,
  }) as const satisfies Omit<ColDef, 'field'>;
