/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MaritalContract = 'Yes' | 'No' | 'WillBeSigned';

export const MaritalContract = {
    Yes: 'Yes' as MaritalContract,
    No: 'No' as MaritalContract,
    WillBeSigned: 'WillBeSigned' as MaritalContract
};

