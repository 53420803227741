import { AnswerType, ReferenceBookType } from '@lib-verification/api';

export function getReferenceBookType(answerType: AnswerType) {
  switch (answerType) {
    case AnswerType.AdditionalIncomeType:
      return ReferenceBookType.AdditionalIncomeType;
    case AnswerType.BusinessArea:
      return ReferenceBookType.BusinessArea;
    case AnswerType.Position:
      return ReferenceBookType.Position;
    default:
      return null;
  }
}
