/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VerificationSupervisionDataDto } from './verificationSupervisionDataDto';
import { SourceType } from './sourceType';
import { FileInfoDto } from './fileInfoDto';


export interface ApplicationAndConsentFormTaskDto { 
    source?: SourceType;
    files?: Array<FileInfoDto> | null;
    relevantFiles?: Array<FileInfoDto> | null;
    verificationSupervisionDataList?: Array<VerificationSupervisionDataDto> | null;
}
export namespace ApplicationAndConsentFormTaskDto {
}


