/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MaritalStatus = 'Married' | 'CivilMarriage' | 'Single' | 'Divorced' | 'Widow';

export const MaritalStatus = {
    Married: 'Married' as MaritalStatus,
    CivilMarriage: 'CivilMarriage' as MaritalStatus,
    Single: 'Single' as MaritalStatus,
    Divorced: 'Divorced' as MaritalStatus,
    Widow: 'Widow' as MaritalStatus
};

