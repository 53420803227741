/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface VerificationTaskFalsificationFlagsDtoAvailableFalsificationFlags { 
    InconsistentNumbering?: string;
    SeriesAndNumberMismatch?: string;
    DocumentTypeMismatch?: string;
    DocumentNotReadable?: string;
    MainPageMissing?: string;
    NumberMissing?: string;
    NumberNotReadable?: string;
    SeriesMismatch?: string;
    NameMismatch?: string;
    NamePassportMismatch?: string;
    StampMismatch?: string;
    TK_FormIssuedAfterSpecifiedDate?: string;
    TK_RecordsOnFolds?: string;
    TK_StampMismatch?: string;
    TK_StampMissing?: string;
    TK_JobTitleOrEducationMismatch?: string;
    TK_SameHandwriting?: string;
    TK_SameHandwritingAsApplication?: string;
    TK_IssuedAfter27Years?: string;
    TK_ReferencesMismatch?: string;
    TK_EmployerNameMismatch?: string;
    TK_DuplicateMismatch?: string;
    TK_CertificationMissing?: string;
    TK_Duplicate?: string;
    TK_ExecutiveSignMismatch?: string;
    TK_CurrentPlaceOfWorkMissing?: string;
    TK_NotSertifiedPagesPresent?: string;
    TK_JobTitleMIssing?: string;
    TK_JobTitleNotReadable?: string;
    IS_HasDataForTwoYears?: string;
    TD_PassportMismatch?: string;
    TD_CEOMismatch?: string;
    TD_DateMismatch?: string;
    TD_IncomeMismatch?: string;
    TD_JobTitleMismatch?: string;
    TD_CompanyRegistrationDateMismatch?: string;
    TD_ExecutiveSignMismatch?: string;
    TD_DateMissing?: string;
    TD_DateSymbolsMismatch?: string;
    TK_Missing?: string;
    TK_CompanyRegisteredAfterEmployment?: string;
    TK_InconsistentNumbering?: string;
    TK_CorrectionNotApproved?: string;
    TK_StartWorkingFieldMissing?: string;
    TK_StartWorkingFieldNotReadable?: string;
    TK_SeriesNotBankAllowedFormat?: string;
    TK_DocumentNotCorresponding?: string;
    TK_NameMismatch30?: string;
    IS_InconsistentFormTerms?: string;
    IS_HandWritten?: string;
    IS_BorrowerDataMissedOrWrongOrMismatchedOrMisspelled?: string;
    IS_TinIsWrongOrEmpty?: string;
    IS_MissedDataSinceTheStartOfYear?: string;
    IS_HeadOfOrganizationMismatchesWithSpark?: string;
    IS_IsLongTermButWithoutVacationsOrIllnesses?: string;
    IS_StableIncomeWithUnstableActivity?: string;
    IS_StatementIsGovermentInstitutionProvided?: string;
    IS_SameHandwriting?: string;
    IS_SameSertificateOnReview?: string;
    IS_DifferentApproverSignments?: string;
    IS_MissingCertificate?: string;
    IS_IncomeStatementIsSelectedButAnotherDocumentUploaded?: string;
    IS_NotIncomeStatement?: string;
    IS_ContainsDataAfterDateOfIssue?: string;
    IS_TinMismatch?: string;
    IS_LowQualityDocument?: string;
    IS_MissingTotalIncome?: string;
    IS_IndecipherableTotalIncome?: string;
    IS_DateOfIssueIsAfterCurrentDate?: string;
    IS_MainIncomeSourceIsRequired?: string;
    IS_MissingIncomeForLast12Months?: string;
    TD_Missing?: string;
    TD_TinMissingInEGRUL?: string;
    ETK_NotETK?: string;
    ETK_DateOfIssueNotReadable?: string;
    ETK_EtkOnlyAndExperienceLessThan3Months?: string;
    ETK_CopyExpired?: string;
    P_MainPageMissing?: string;
    P_SeriesAndNumberMismatch?: string;
    P_VerificationPageMissing?: string;
    P_PersonalFieldsNotReadable?: string;
    P_IssuerFieldsNotReadable?: string;
    P_SignatureMissing?: string;
    P_DoesntMeetBankRequirements?: string;
    P_PreviousPassportNotReadable?: string;
    P_ApplicationNeedsTransfer?: string;
    P_NotReadable?: string;
    SOPD_DocumentMissing?: string;
    SOPD_SignatureMissing?: string;
    SOPD_Name30Mismatch?: string;
    SOPD_DoesntMeetBankRequirements?: string;
    SOPD_HeaderNameMismatchesSignature?: string;
    SOPD_DateNotReadable?: string;
    SOPD_DateSymbolsMismatch?: string;
    SOPD_CreditRequestSignatureMissing?: string;
    SOPD_DateAfterCurrent?: string;
    SOPD_Expired?: string;
    TD_InapropriateStamp?: string;
    SOPD_WrongType?: string;
    SOPD_FioDoesNotMatchInForm?: string;
    SOPD_FioIsNotFull?: string;
    SOPD_PagesMissing?: string;
    SOPD_SignDateMissing?: string;
    SOPD_SignDateIsNotReadable?: string;
    SOPD_SignDateDigitsAreMissing?: string;
    SOPD_FormDateIsFromFuture?: string;
    SOPD_DateOverdue?: string;
    SOPD_SignDateMissingForForm?: string;
    SOPD_SopdDateOverdue?: string;
    SOPD_SopdDocsMissing?: string;
    SOPD_SignatureMissingForForm?: string;
    SOPD_WrongDocumentType?: string;
    SOPD_FioDoesNotMatchInSopd?: string;
    SOPD_DocumentNotMeetRequirements?: string;
    SOPD_FioDoesNotMatchWithSignature?: string;
    SOPD_AgreementDateIsNotRecognized?: string;
    SOPD_AgreementDateDigitsMissing?: string;
    SOPD_LoanHistorySignatureMissing?: string;
    SOPD_AgreementDateIsFromFuture?: string;
    SOPD_AgreementDateOverdue?: string;
    TD_2NdflMissing?: string;
    TD_TkTdMissing?: string;
    IS_CannotReadFio?: string;
    IS_SignatureMissing?: string;
    IS_IssueDateIsGreaterThanCurrent?: string;
    IS_IssueDateNotFullInfo?: string;
    IS_IssueDateUnreadableField?: string;
    IS_IssueDateMissingField?: string;
    IS_DoesNotBelongToBorrower?: string;
    IS_WrongOrMissingDate?: string;
    IS_CorruptedDocument?: string;
    IS_MoreThanOneDocument?: string;
    IS_DifferentInn?: string;
    IS_PfbIncueryIsCorrupted?: string;
    IS_DocumentsNotMatchingType?: string;
    IS_DocumentIsUnreadable?: string;
    IS_BankFormWrongDocumentType?: string;
    PhotoIsPasted?: string;
    ImageEdited?: string;
    RedundantMarks?: string;
    BrokenLamination?: string;
    Pass_NoActiveRegistration?: string;
}

