import { AsyncPipe } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { filter, map, startWith } from 'rxjs/operators';
import { Breadcrumb } from '@lib-utils';
import { ButtonModule } from '../button';
import { mapRouteChildren } from './breadcrumb.utils';

@Component({
  standalone: true,
  selector: 'fnip-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonModule, AsyncPipe, TranslateModule],
})
export class BreadcrumbComponent {
  hostUrl = input.required<string>();
  hasTranslations = input(false, { transform: booleanAttribute });

  private readonly router = inject(Router);
  private readonly activatedRoute = inject(ActivatedRoute);

  breadcrumbs$ = this.router.events.pipe(
    startWith(new NavigationEnd(0, '', '')),
    filter((e) => e instanceof NavigationEnd),
    map(() => mapRouteChildren(this.activatedRoute.root)),
    map((breadcrumbs) =>
      // Выводим только уникальные элементы (причем если подряд идут элементы с одинаковым url, берем только последний)
      // Это необходимо для поддержки пустых path: '' в роутинге
      breadcrumbs.reduceRight((acc, curr, index, arr) => {
        if (arr.findIndex((el) => el.url === curr.url) === index) return [curr, ...acc];
        return acc;
      }, [] as Breadcrumb[]),
    ),
  );
}
