/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Sex } from './sex';
import { DateOnly } from '@lib-utils';


export interface PersonDto { 
    firstName: string | null;
    middleName: string | null;
    lastName: string | null;
    birthdate: DateOnly;
    gender?: Sex;
    phone?: string | null;
    email?: string | null;
    factualAddressFull?: string | null;
}
export namespace PersonDto {
}


