/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, isDevMode, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { AdditionalServicesRefusalTaskDto } from '../model/additionalServicesRefusalTaskDto';
// @ts-ignore
import { AdditionalServicesRefusalTaskDtoBaseUpdateVerificationTask } from '../model/additionalServicesRefusalTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { ApplicationAndConsentFormTaskDto } from '../model/applicationAndConsentFormTaskDto';
// @ts-ignore
import { ApplicationAndConsentFormTaskDtoBaseUpdateVerificationTask } from '../model/applicationAndConsentFormTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { ApplicationForJoiningDKOTaskDto } from '../model/applicationForJoiningDKOTaskDto';
// @ts-ignore
import { ApplicationForJoiningDKOTaskDtoBaseUpdateVerificationTask } from '../model/applicationForJoiningDKOTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { ApplicationForTransferForVehicleTaskDto } from '../model/applicationForTransferForVehicleTaskDto';
// @ts-ignore
import { ApplicationForTransferForVehicleTaskDtoBaseUpdateVerificationTask } from '../model/applicationForTransferForVehicleTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { ApplicationForTransferOfFundsTaskDto } from '../model/applicationForTransferOfFundsTaskDto';
// @ts-ignore
import { ApplicationForTransferOfFundsTaskDtoBaseUpdateVerificationTask } from '../model/applicationForTransferOfFundsTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { CallEmploymentVerificationTaskDto } from '../model/callEmploymentVerificationTaskDto';
// @ts-ignore
import { CallEmploymentVerificationTaskUpdateRequest } from '../model/callEmploymentVerificationTaskUpdateRequest';
// @ts-ignore
import { CallVerificationTaskDto } from '../model/callVerificationTaskDto';
// @ts-ignore
import { CallVerificationTaskUpdateRequest } from '../model/callVerificationTaskUpdateRequest';
// @ts-ignore
import { CallVerificationTaskUpdateRequestBaseUpdateVerificationTask } from '../model/callVerificationTaskUpdateRequestBaseUpdateVerificationTask';
// @ts-ignore
import { ClientPhotoVerificationTaskDto } from '../model/clientPhotoVerificationTaskDto';
// @ts-ignore
import { ClientPhotoVerificationTaskDtoBaseUpdateVerificationTask } from '../model/clientPhotoVerificationTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { DocumentResolutionSettingsDto } from '../model/documentResolutionSettingsDto';
// @ts-ignore
import { DriverLicenseVerificationTaskDto } from '../model/driverLicenseVerificationTaskDto';
// @ts-ignore
import { DriverLicenseVerificationTaskDtoBaseUpdateVerificationTask } from '../model/driverLicenseVerificationTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { EmploymentDocumentVerificationTaskDto } from '../model/employmentDocumentVerificationTaskDto';
// @ts-ignore
import { EmploymentDocumentVerificationTaskDtoBaseUpdateVerificationTask } from '../model/employmentDocumentVerificationTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { FileType } from '../model/fileType';
// @ts-ignore
import { IdInfoDto } from '../model/idInfoDto';
// @ts-ignore
import { IncomeDocumentMonthlyIncomeVerificationDto } from '../model/incomeDocumentMonthlyIncomeVerificationDto';
// @ts-ignore
import { IncomeDocumentVerificationTaskDto } from '../model/incomeDocumentVerificationTaskDto';
// @ts-ignore
import { IncomeDocumentVerificationTaskDtoBaseUpdateVerificationTask } from '../model/incomeDocumentVerificationTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { IndividualConditionsTaskDto } from '../model/individualConditionsTaskDto';
// @ts-ignore
import { IndividualConditionsTaskDtoBaseUpdateVerificationTask } from '../model/individualConditionsTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { LifeInsuranceVerificationTaskDto } from '../model/lifeInsuranceVerificationTaskDto';
// @ts-ignore
import { LifeInsuranceVerificationTaskDtoBaseUpdateVerificationTask } from '../model/lifeInsuranceVerificationTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { OrderByTypes } from '../model/orderByTypes';
// @ts-ignore
import { PassportAddressVerificationTaskDto } from '../model/passportAddressVerificationTaskDto';
// @ts-ignore
import { PassportAddressVerificationTaskDtoBaseUpdateVerificationTask } from '../model/passportAddressVerificationTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { PassportFullVerificationTaskDtoBaseUpdateVerificationTask } from '../model/passportFullVerificationTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { PassportMainPageVerificationTaskDto } from '../model/passportMainPageVerificationTaskDto';
// @ts-ignore
import { PassportMainPageVerificationTaskDtoBaseUpdateVerificationTask } from '../model/passportMainPageVerificationTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { PassportPageUpdateVerificationTaskDtoBaseUpdateVerificationTask } from '../model/passportPageUpdateVerificationTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { PassportPageVerificationTaskDto } from '../model/passportPageVerificationTaskDto';
// @ts-ignore
import { PassportVerificationTaskDto } from '../model/passportVerificationTaskDto';
// @ts-ignore
import { PassportVerificationTaskDtoBaseUpdateVerificationTask } from '../model/passportVerificationTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { PaymentInvoiceVerificationTaskDto } from '../model/paymentInvoiceVerificationTaskDto';
// @ts-ignore
import { PaymentInvoiceVerificationTaskDtoBaseUpdateVerificationTask } from '../model/paymentInvoiceVerificationTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { PaymentReceiptVerificationTaskDto } from '../model/paymentReceiptVerificationTaskDto';
// @ts-ignore
import { PaymentReceiptVerificationTaskDtoBaseUpdateVerificationTask } from '../model/paymentReceiptVerificationTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { PaymentScheduleTaskDto } from '../model/paymentScheduleTaskDto';
// @ts-ignore
import { PaymentScheduleTaskDtoBaseUpdateVerificationTask } from '../model/paymentScheduleTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { PensionCertificateVerificationTaskDto } from '../model/pensionCertificateVerificationTaskDto';
// @ts-ignore
import { PensionCertificateVerificationTaskDtoBaseUpdateVerificationTask } from '../model/pensionCertificateVerificationTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { PreviousPassportVerificationTaskDto } from '../model/previousPassportVerificationTaskDto';
// @ts-ignore
import { PreviousPassportVerificationTaskDtoBaseUpdateVerificationTask } from '../model/previousPassportVerificationTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { ProfessionalIncomeCertificateTaskDto } from '../model/professionalIncomeCertificateTaskDto';
// @ts-ignore
import { ProfessionalIncomeCertificateTaskRequestBaseUpdateVerificationTask } from '../model/professionalIncomeCertificateTaskRequestBaseUpdateVerificationTask';
// @ts-ignore
import { ResendRequest } from '../model/resendRequest';
// @ts-ignore
import { RoadAccidentVerificationTaskDto } from '../model/roadAccidentVerificationTaskDto';
// @ts-ignore
import { RoadAccidentVerificationTaskDtoBaseUpdateVerificationTask } from '../model/roadAccidentVerificationTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { SaleContractVehicleVerificationTaskDto } from '../model/saleContractVehicleVerificationTaskDto';
// @ts-ignore
import { SaleContractVehicleVerificationTaskDtoBaseUpdateVerificationTask } from '../model/saleContractVehicleVerificationTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { SendResultDto } from '../model/sendResultDto';
// @ts-ignore
import { SnilsVerificationTaskDto } from '../model/snilsVerificationTaskDto';
// @ts-ignore
import { SnilsVerificationTaskDtoBaseUpdateVerificationTask } from '../model/snilsVerificationTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { SopdVerificationTaskDto } from '../model/sopdVerificationTaskDto';
// @ts-ignore
import { SopdVerificationTaskDtoBaseUpdateVerificationTask } from '../model/sopdVerificationTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { SupervisorUpdateTaskRequest } from '../model/supervisorUpdateTaskRequest';
// @ts-ignore
import { TaxNumberVerificationTaskDto } from '../model/taxNumberVerificationTaskDto';
// @ts-ignore
import { TaxNumberVerificationTaskDtoBaseUpdateVerificationTask } from '../model/taxNumberVerificationTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { UpdateVerificationTaskRequest } from '../model/updateVerificationTaskRequest';
// @ts-ignore
import { VehicleInformationLetterVerificationTaskDto } from '../model/vehicleInformationLetterVerificationTaskDto';
// @ts-ignore
import { VehicleInformationLetterVerificationTaskDtoBaseUpdateVerificationTask } from '../model/vehicleInformationLetterVerificationTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { VehiclePassportVerificationTaskDto } from '../model/vehiclePassportVerificationTaskDto';
// @ts-ignore
import { VehiclePassportVerificationTaskDtoBaseUpdateVerificationTask } from '../model/vehiclePassportVerificationTaskDtoBaseUpdateVerificationTask';
// @ts-ignore
import { VerificationClientDto } from '../model/verificationClientDto';
// @ts-ignore
import { VerificationPagedResultDto } from '../model/verificationPagedResultDto';
// @ts-ignore
import { VerificationStatisticDto } from '../model/verificationStatisticDto';
// @ts-ignore
import { VerificationTaskDeleteRequest } from '../model/verificationTaskDeleteRequest';
// @ts-ignore
import { VerificationTaskFalsificationFlagsDto } from '../model/verificationTaskFalsificationFlagsDto';
// @ts-ignore
import { VerificationTaskInfoDto } from '../model/verificationTaskInfoDto';
// @ts-ignore
import { VerificationTaskResolutionDto } from '../model/verificationTaskResolutionDto';
// @ts-ignore
import { VerificationTaskResolutionTypesDto } from '../model/verificationTaskResolutionTypesDto';
// @ts-ignore
import { VerificationTaskSortFields } from '../model/verificationTaskSortFields';
// @ts-ignore
import { VerificationTaskType } from '../model/verificationTaskType';
// @ts-ignore
import { VerificationTasksFilterTypeFilterValuesRequest } from '../model/verificationTasksFilterTypeFilterValuesRequest';
// @ts-ignore
import { VerifierCreateRequest } from '../model/verifierCreateRequest';
// @ts-ignore
import { VerifierInfoDto } from '../model/verifierInfoDto';
// @ts-ignore
import { VerifierUpdateRequest } from '../model/verifierUpdateRequest';
// @ts-ignore
import { VerifiersFilterTypeFilterValuesRequest } from '../model/verifiersFilterTypeFilterValuesRequest';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { VerificationConfiguration }                                     from '../configuration';


export interface ApiVerificationApplicationIdCheckIsCoborrowerTaskGetRequestParams {
    /** Идентификатор заявки. */
    applicationId: string;
    /** Идентификатор клиента. */
    clientId: string;
}

export interface ApiVerificationApplicationIdClientsGetRequestParams {
    /** Идентификатор заявки */
    applicationId: number;
}

export interface ApiVerificationCheckActiveTasksForClientGetRequestParams {
    /** Идентификатор заявки */
    applicationId: number;
    /** Идентификатор клиента */
    clientId: number;
}

export interface ApiVerificationIncomeStatementIncomesAddOrUpdatePutRequestParams {
    /** Идентификатор файла справки */
    fileId: number;
    /** Модель дохода */
    incomeDocumentMonthlyIncomeVerificationDto: IncomeDocumentMonthlyIncomeVerificationDto;
}

export interface ApiVerificationIncomeStatementIncomesDeleteDeleteRequestParams {
    /** Тип файла справки&lt;p&gt;&lt;/p&gt; */
    fileType: FileType;
    /** Идентификатор дохода */
    incomeId: number;
}

export interface ApiVerificationTaskResolutionsGetRequestParams {
    /** Список идентификаторов заявок */
    applicationIds?: Array<string>;
}

export interface ApiVerificationTaskTaskIdAdditionalServicesRefusalGetRequestParams {
    /** Идентификатор задачи */
    taskId: number;
}

export interface ApiVerificationTaskTaskIdAdditionalServicesRefusalPutRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Данные обновления задачи */
    additionalServicesRefusalTaskDtoBaseUpdateVerificationTask?: AdditionalServicesRefusalTaskDtoBaseUpdateVerificationTask;
}

export interface ApiVerificationTaskTaskIdApplicationAndConsentFormGetRequestParams {
    /** Идентификатор задачи */
    taskId: number;
}

export interface ApiVerificationTaskTaskIdApplicationAndConsentFormPutRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Данные обновления задачи */
    applicationAndConsentFormTaskDtoBaseUpdateVerificationTask?: ApplicationAndConsentFormTaskDtoBaseUpdateVerificationTask;
}

export interface ApiVerificationTaskTaskIdApplicationForJoiningDKOGetRequestParams {
    /** Идентификатор задачи */
    taskId: number;
}

export interface ApiVerificationTaskTaskIdApplicationForJoiningDKOPutRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Данные обновления задачи */
    applicationForJoiningDKOTaskDtoBaseUpdateVerificationTask?: ApplicationForJoiningDKOTaskDtoBaseUpdateVerificationTask;
}

export interface ApiVerificationTaskTaskIdApplicationForTransferForVehicleGetRequestParams {
    /** Идентификатор задачи */
    taskId: number;
}

export interface ApiVerificationTaskTaskIdApplicationForTransferForVehiclePutRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Данные обновления задачи */
    applicationForTransferForVehicleTaskDtoBaseUpdateVerificationTask?: ApplicationForTransferForVehicleTaskDtoBaseUpdateVerificationTask;
}

export interface ApiVerificationTaskTaskIdApplicationForTransferOfFundsGetRequestParams {
    /** Идентификатор задачи */
    taskId: number;
}

export interface ApiVerificationTaskTaskIdApplicationForTransferOfFundsPutRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Данные обновления задачи */
    applicationForTransferOfFundsTaskDtoBaseUpdateVerificationTask?: ApplicationForTransferOfFundsTaskDtoBaseUpdateVerificationTask;
}

export interface ApiVerificationTaskTaskIdCallEmploymentGetRequestParams {
    /** Идентификатор задачи */
    taskId: number;
}

export interface ApiVerificationTaskTaskIdCallEmploymentPutRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Данные обновления задачи */
    callEmploymentVerificationTaskUpdateRequest?: CallEmploymentVerificationTaskUpdateRequest;
}

export interface ApiVerificationTaskTaskIdCallGetRequestParams {
    /** Идентификатор задачи */
    taskId: number;
}

export interface ApiVerificationTaskTaskIdCallPostRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Запрос с ответами */
    callVerificationTaskUpdateRequest?: CallVerificationTaskUpdateRequest;
}

export interface ApiVerificationTaskTaskIdCallPutRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Данные обновления задачи */
    callVerificationTaskUpdateRequestBaseUpdateVerificationTask?: CallVerificationTaskUpdateRequestBaseUpdateVerificationTask;
}

export interface ApiVerificationTaskTaskIdClientPhotoGetRequestParams {
    /** Идентификатор задачи */
    taskId: number;
}

export interface ApiVerificationTaskTaskIdClientPhotoPutRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Данные обновления задачи */
    clientPhotoVerificationTaskDtoBaseUpdateVerificationTask?: ClientPhotoVerificationTaskDtoBaseUpdateVerificationTask;
}

export interface ApiVerificationTaskTaskIdDriverLicenseGetRequestParams {
    /** Идентификатор задачи */
    taskId: number;
}

export interface ApiVerificationTaskTaskIdDriverLicensePutRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Данные обновления задачи */
    driverLicenseVerificationTaskDtoBaseUpdateVerificationTask?: DriverLicenseVerificationTaskDtoBaseUpdateVerificationTask;
}

export interface ApiVerificationTaskTaskIdEmploymentDocumentGetRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Тип файла занятости&lt;p&gt;&lt;/p&gt; */
    fileType: FileType;
}

export interface ApiVerificationTaskTaskIdEmploymentDocumentPutRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Данные обновления задачи */
    employmentDocumentVerificationTaskDtoBaseUpdateVerificationTask?: EmploymentDocumentVerificationTaskDtoBaseUpdateVerificationTask;
}

export interface ApiVerificationTaskTaskIdEmploymentHistoryPutRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Данные обновления задачи */
    employmentDocumentVerificationTaskDtoBaseUpdateVerificationTask?: EmploymentDocumentVerificationTaskDtoBaseUpdateVerificationTask;
}

export interface ApiVerificationTaskTaskIdGetRequestParams {
    /** Идентификатор задачи */
    taskId: number;
}

export interface ApiVerificationTaskTaskIdIncomeStatementGetRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Тип файла справки о доходе&lt;p&gt;&lt;/p&gt; */
    fileType: FileType;
}

export interface ApiVerificationTaskTaskIdIncomeStatementPutRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Данные обновления задачи */
    incomeDocumentVerificationTaskDtoBaseUpdateVerificationTask?: IncomeDocumentVerificationTaskDtoBaseUpdateVerificationTask;
}

export interface ApiVerificationTaskTaskIdIndividualConditionsGetRequestParams {
    /** Идентификатор задачи */
    taskId: number;
}

export interface ApiVerificationTaskTaskIdIndividualConditionsPutRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Данные обновления задачи */
    individualConditionsTaskDtoBaseUpdateVerificationTask?: IndividualConditionsTaskDtoBaseUpdateVerificationTask;
}

export interface ApiVerificationTaskTaskIdLifeInsuranceGetRequestParams {
    /** Идентификатор задачи */
    taskId: number;
}

export interface ApiVerificationTaskTaskIdLifeInsurancePutRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Данные обновления задачи */
    lifeInsuranceVerificationTaskDtoBaseUpdateVerificationTask?: LifeInsuranceVerificationTaskDtoBaseUpdateVerificationTask;
}

export interface ApiVerificationTaskTaskIdPassportFullGetRequestParams {
    /** Идентификатор задачи */
    taskId: number;
}

export interface ApiVerificationTaskTaskIdPassportFullPutRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Данные обновления задачи */
    passportFullVerificationTaskDtoBaseUpdateVerificationTask?: PassportFullVerificationTaskDtoBaseUpdateVerificationTask;
}

export interface ApiVerificationTaskTaskIdPassportGetRequestParams {
    /** Идентификатор задачи */
    taskId: number;
}

export interface ApiVerificationTaskTaskIdPassportMainPageGetRequestParams {
    /** Идентификатор задачи */
    taskId: number;
}

export interface ApiVerificationTaskTaskIdPassportMainPagePutRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Данные обновления задачи */
    passportMainPageVerificationTaskDtoBaseUpdateVerificationTask?: PassportMainPageVerificationTaskDtoBaseUpdateVerificationTask;
}

export interface ApiVerificationTaskTaskIdPassportPageGetRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Тип задач на проверку&lt;p&gt;&lt;/p&gt; */
    type?: VerificationTaskType;
}

export interface ApiVerificationTaskTaskIdPassportPagePutRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Данные обновления задачи */
    passportPageUpdateVerificationTaskDtoBaseUpdateVerificationTask?: PassportPageUpdateVerificationTaskDtoBaseUpdateVerificationTask;
}

export interface ApiVerificationTaskTaskIdPassportPutRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Данные обновления задачи */
    passportVerificationTaskDtoBaseUpdateVerificationTask?: PassportVerificationTaskDtoBaseUpdateVerificationTask;
}

export interface ApiVerificationTaskTaskIdPassportRegistrationGetRequestParams {
    /** Идентификатор задачи */
    taskId: number;
}

export interface ApiVerificationTaskTaskIdPassportRegistrationPutRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Данные обновления задачи */
    passportAddressVerificationTaskDtoBaseUpdateVerificationTask?: PassportAddressVerificationTaskDtoBaseUpdateVerificationTask;
}

export interface ApiVerificationTaskTaskIdPausePostRequestParams {
    /** Идентификатор Задачи */
    taskId: number;
}

export interface ApiVerificationTaskTaskIdPaymentInvoiceGetRequestParams {
    /** Идентификатор задачи */
    taskId: number;
}

export interface ApiVerificationTaskTaskIdPaymentInvoicePutRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Данные обновления задачи */
    paymentInvoiceVerificationTaskDtoBaseUpdateVerificationTask?: PaymentInvoiceVerificationTaskDtoBaseUpdateVerificationTask;
}

export interface ApiVerificationTaskTaskIdPaymentReceiptGetRequestParams {
    /** Идентификатор задачи */
    taskId: number;
}

export interface ApiVerificationTaskTaskIdPaymentReceiptPutRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Данные обновления задачи */
    paymentReceiptVerificationTaskDtoBaseUpdateVerificationTask?: PaymentReceiptVerificationTaskDtoBaseUpdateVerificationTask;
}

export interface ApiVerificationTaskTaskIdPaymentScheduleGetRequestParams {
    /** Идентификатор задачи */
    taskId: number;
}

export interface ApiVerificationTaskTaskIdPaymentSchedulePutRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Данные обновления задачи */
    paymentScheduleTaskDtoBaseUpdateVerificationTask?: PaymentScheduleTaskDtoBaseUpdateVerificationTask;
}

export interface ApiVerificationTaskTaskIdPensionCertificateGetRequestParams {
    /** Идентификатор задачи */
    taskId: number;
}

export interface ApiVerificationTaskTaskIdPensionCertificatePutRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Данные обновления задачи */
    pensionCertificateVerificationTaskDtoBaseUpdateVerificationTask?: PensionCertificateVerificationTaskDtoBaseUpdateVerificationTask;
}

export interface ApiVerificationTaskTaskIdPreviousPassportGetRequestParams {
    /** Идентификатор задачи */
    taskId: number;
}

export interface ApiVerificationTaskTaskIdPreviousPassportPutRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Данные обновления задачи */
    previousPassportVerificationTaskDtoBaseUpdateVerificationTask?: PreviousPassportVerificationTaskDtoBaseUpdateVerificationTask;
}

export interface ApiVerificationTaskTaskIdProfessionalIncomeCertificateGetRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Тип файла справки о доходе&lt;p&gt;&lt;/p&gt; */
    fileType: FileType;
}

export interface ApiVerificationTaskTaskIdProfessionalIncomeCertificatePutRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Данные обновления задачи */
    professionalIncomeCertificateTaskRequestBaseUpdateVerificationTask?: ProfessionalIncomeCertificateTaskRequestBaseUpdateVerificationTask;
}

export interface ApiVerificationTaskTaskIdPutRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Данные обновления задачи */
    updateVerificationTaskRequest?: UpdateVerificationTaskRequest;
}

export interface ApiVerificationTaskTaskIdRoadAccidentGetRequestParams {
    /** Идентификатор задачи */
    taskId: number;
}

export interface ApiVerificationTaskTaskIdRoadAccidentPutRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Данные обновления задачи */
    roadAccidentVerificationTaskDtoBaseUpdateVerificationTask?: RoadAccidentVerificationTaskDtoBaseUpdateVerificationTask;
}

export interface ApiVerificationTaskTaskIdSaleContractVehicleGetRequestParams {
    /** Идентификатор задачи */
    taskId: number;
}

export interface ApiVerificationTaskTaskIdSaleContractVehiclePutRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Данные обновления задачи */
    saleContractVehicleVerificationTaskDtoBaseUpdateVerificationTask?: SaleContractVehicleVerificationTaskDtoBaseUpdateVerificationTask;
}

export interface ApiVerificationTaskTaskIdSnilsGetRequestParams {
    /** Идентификатор задачи */
    taskId: number;
}

export interface ApiVerificationTaskTaskIdSnilsPutRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Данные обновления задачи */
    snilsVerificationTaskDtoBaseUpdateVerificationTask?: SnilsVerificationTaskDtoBaseUpdateVerificationTask;
}

export interface ApiVerificationTaskTaskIdSopdGetRequestParams {
    /** Идентификатор задачи */
    taskId: number;
}

export interface ApiVerificationTaskTaskIdSopdPutRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Данные обновления задачи */
    sopdVerificationTaskDtoBaseUpdateVerificationTask?: SopdVerificationTaskDtoBaseUpdateVerificationTask;
}

export interface ApiVerificationTaskTaskIdTaxNumberGetRequestParams {
    /** Идентификатор задачи */
    taskId: number;
}

export interface ApiVerificationTaskTaskIdTaxNumberPutRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Данные обновления задачи */
    taxNumberVerificationTaskDtoBaseUpdateVerificationTask?: TaxNumberVerificationTaskDtoBaseUpdateVerificationTask;
}

export interface ApiVerificationTaskTaskIdTransitionsAssignTaskToMyselfPostRequestParams {
    /** Идентификатор задачи */
    taskId: number;
}

export interface ApiVerificationTaskTaskIdTransitionsAssignTaskToVerifierVerifierIdPostRequestParams {
    /** Идентификатор Задачи */
    taskId: number;
    /** Идентификатор Верификатора */
    verifierId: number;
}

export interface ApiVerificationTaskTaskIdTransitionsRedirectedToSupervisionReviewPutRequestParams {
    /** Идентификатор Задачи */
    taskId: number;
    /** Запрос */
    supervisorUpdateTaskRequest: SupervisorUpdateTaskRequest;
}

export interface ApiVerificationTaskTaskIdUnPausePostRequestParams {
    /** Идентификатор Задачи */
    taskId: number;
}

export interface ApiVerificationTaskTaskIdVehicleInformationLetterGetRequestParams {
    /** Идентификатор задачи */
    taskId: number;
}

export interface ApiVerificationTaskTaskIdVehicleInformationLetterPutRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Данные обновления задачи */
    vehicleInformationLetterVerificationTaskDtoBaseUpdateVerificationTask?: VehicleInformationLetterVerificationTaskDtoBaseUpdateVerificationTask;
}

export interface ApiVerificationTaskTaskIdVehiclePassportGetRequestParams {
    /** Идентификатор задачи */
    taskId: number;
}

export interface ApiVerificationTaskTaskIdVehiclePassportPutRequestParams {
    /** Идентификатор задачи */
    taskId: number;
    /** Данные обновления задачи */
    vehiclePassportVerificationTaskDtoBaseUpdateVerificationTask?: VehiclePassportVerificationTaskDtoBaseUpdateVerificationTask;
}

export interface ApiVerificationTasksDeleteDeleteRequestParams {
    /** Запрос с фильтрами */
    verificationTaskDeleteRequest?: VerificationTaskDeleteRequest;
}

export interface ApiVerificationTasksFalsificationFlagsForTaskTypeGetRequestParams {
    /** Тип задачи верификации.&lt;p&gt;&lt;/p&gt; */
    taskType?: VerificationTaskType;
}

export interface ApiVerificationTasksGetRequestParams {
    page?: number;
    perPage?: number;
    /** &lt;p&gt;&lt;/p&gt; */
    sortFieldsOrderBy?: OrderByTypes;
    /** &lt;p&gt;&lt;/p&gt; */
    sortFieldsFieldBy?: VerificationTaskSortFields;
    /** Фильтры               &#x60;&#x60;&#x60;                             Доступные фильтры                                 \&quot;filters\&quot;: {                       \&quot;taskTypes\&quot;: \&quot;passport,driverLicense\&quot; // Типы Задач, через запятую                       \&quot;documentStatuses\&quot;: \&quot;AllTasks,MyTasks\&quot; // Статусы Документов                       \&quot;verifierIds\&quot;: \&quot;1,2\&quot; // Идентификаторы Верификаторов, через запятую                       \&quot;from\&quot;: \&quot;2024-01-01\&quot; // Созданы от                       \&quot;to\&quot;: \&quot;2024-02-16\&quot; // Созданы до                       \&quot;firstName\&quot;: \&quot;Имя\&quot; // Имя Клиента                       \&quot;middleName\&quot;: \&quot;Отчество\&quot; // Отчество Клиента                       \&quot;lastName\&quot;: \&quot;Фамилия\&quot; // Фамилия Клиента                       \&quot;taskId\&quot;: \&quot;15\&quot; // Идентификатор Задачи содержит                       \&quot;applicationId\&quot;: \&quot;132\&quot; // Идентификаторы Заявки содержит                       \&quot;clientId\&quot;: \&quot;11\&quot; // Идентификатор Клиента содержит                       \&quot;GeneralTaskType\&quot;: \&quot;All\&quot;, // Общий тип Задач (All, Document, Call)                       \&quot;sources\&quot;: \&quot;spr,auto\&quot;, // Источники, через запятую                   }                             &#x60;&#x60;&#x60; */
    filters?: VerificationTasksFilterTypeFilterValuesRequest;
}

export interface ApiVerificationTasksResendAllFailedPostRequestParams {
    /** Запрос */
    resendRequest?: ResendRequest;
}

export interface ApiVerificationTasksResolutionTypesForTaskTypeGetRequestParams {
    /** Тип задачи верификации&lt;p&gt;&lt;/p&gt; */
    taskType?: VerificationTaskType;
}

export interface ApiVerificationVerifierIdTaskGetRequestParams {
    /** Идентификатор верификатора */
    verifierId: number;
}

export interface ApiVerificationVerifierPostRequestParams {
    /** Запрос на создание Верификатора */
    verifierCreateRequest?: VerifierCreateRequest;
}

export interface ApiVerificationVerifierVerifierIdGetRequestParams {
    /** Идентификатор верификатора, если не задан то текущий пользователя */
    verifierId: number;
}

export interface ApiVerificationVerifierVerifierIdPutRequestParams {
    /** Идентификатор Верификатора */
    verifierId: number;
    /** Объект обновления верификатора */
    verifierUpdateRequest?: VerifierUpdateRequest;
}

export interface ApiVerificationVerifiersGetRequestParams {
    /** Фильтры */
    filters?: VerifiersFilterTypeFilterValuesRequest;
}


const enum AuthorizationType {
  Bearer = 'Bearer',
}

const enum AuthorizationHeader {
  Authorization = 'Authorization',
}

const AcceptHeader = 'Accept';

@Injectable()
export class VerificationApiService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new VerificationConfiguration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: VerificationConfiguration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    checkAuthentication(localVarHeaders: any, authType: AuthorizationType, paramName: AuthorizationHeader) {
      // authentication
      const localVarCredential = this.configuration.lookupCredential(authType as string); // name
      if (localVarCredential) {
        return localVarHeaders.set(paramName as string, localVarCredential); // keyParamName
      }

      return localVarHeaders;
    }

    getAcceptType(localVarHttpHeaderAcceptSelected?: string): 'text' | 'json' | 'blob' {
      if (localVarHttpHeaderAcceptSelected) {
        if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
          return 'text';
        } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
          return 'json';
        } else {
          return 'blob';
        }
      }

      return 'json';
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            httpParams = httpParams.append(key!, JSON.stringify(value));
        } else {
            httpParams = httpParams.append(key!, value);
        }

        return httpParams;
    }

    /**
     * Определить, что Клиент является Созаёмщиком
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationApplicationIdCheckIsCoborrowerTaskGet(requestParameters: ApiVerificationApplicationIdCheckIsCoborrowerTaskGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<boolean>;
    public apiVerificationApplicationIdCheckIsCoborrowerTaskGet(requestParameters: ApiVerificationApplicationIdCheckIsCoborrowerTaskGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<boolean>>;
    public apiVerificationApplicationIdCheckIsCoborrowerTaskGet(requestParameters: ApiVerificationApplicationIdCheckIsCoborrowerTaskGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<boolean>>;
    public apiVerificationApplicationIdCheckIsCoborrowerTaskGet(requestParameters: ApiVerificationApplicationIdCheckIsCoborrowerTaskGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const applicationId = requestParameters.applicationId;

        const clientId = requestParameters.clientId;


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (clientId !== undefined && clientId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>clientId, 'clientId');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/${this.configuration.encodeParam({name: "applicationId", value: applicationId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/checkIsCoborrowerTask`;
        return this.httpClient.request<boolean>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить основную информацию по клиентам заявки (заемщику и созаёмщикам)
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationApplicationIdClientsGet(requestParameters: ApiVerificationApplicationIdClientsGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<VerificationClientDto>>;
    public apiVerificationApplicationIdClientsGet(requestParameters: ApiVerificationApplicationIdClientsGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<VerificationClientDto>>>;
    public apiVerificationApplicationIdClientsGet(requestParameters: ApiVerificationApplicationIdClientsGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<VerificationClientDto>>>;
    public apiVerificationApplicationIdClientsGet(requestParameters: ApiVerificationApplicationIdClientsGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const applicationId = requestParameters.applicationId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/${this.configuration.encodeParam({name: "applicationId", value: applicationId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/clients`;
        return this.httpClient.request<Array<VerificationClientDto>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Проверить наличие задач верификации дли клиента
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.L&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationCheckActiveTasksForClientGet(requestParameters: ApiVerificationCheckActiveTasksForClientGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<boolean>;
    public apiVerificationCheckActiveTasksForClientGet(requestParameters: ApiVerificationCheckActiveTasksForClientGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<boolean>>;
    public apiVerificationCheckActiveTasksForClientGet(requestParameters: ApiVerificationCheckActiveTasksForClientGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<boolean>>;
    public apiVerificationCheckActiveTasksForClientGet(requestParameters: ApiVerificationCheckActiveTasksForClientGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const applicationId = requestParameters.applicationId;

        const clientId = requestParameters.clientId;


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (applicationId !== undefined && applicationId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>applicationId, 'applicationId');
        }
        if (clientId !== undefined && clientId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>clientId, 'clientId');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/checkActiveTasksForClient`;
        return this.httpClient.request<boolean>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Добавить доход по справке 2НДФЛ или справке по форме банка
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationIncomeStatementIncomesAddOrUpdatePut(requestParameters: ApiVerificationIncomeStatementIncomesAddOrUpdatePutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IncomeDocumentMonthlyIncomeVerificationDto>;
    public apiVerificationIncomeStatementIncomesAddOrUpdatePut(requestParameters: ApiVerificationIncomeStatementIncomesAddOrUpdatePutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IncomeDocumentMonthlyIncomeVerificationDto>>;
    public apiVerificationIncomeStatementIncomesAddOrUpdatePut(requestParameters: ApiVerificationIncomeStatementIncomesAddOrUpdatePutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IncomeDocumentMonthlyIncomeVerificationDto>>;
    public apiVerificationIncomeStatementIncomesAddOrUpdatePut(requestParameters: ApiVerificationIncomeStatementIncomesAddOrUpdatePutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const fileId = requestParameters.fileId;

        const incomeDocumentMonthlyIncomeVerificationDto = requestParameters.incomeDocumentMonthlyIncomeVerificationDto;


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (fileId !== undefined && fileId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fileId, 'fileId');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/incomeStatement/incomes/addOrUpdate`;
        return this.httpClient.request<IncomeDocumentMonthlyIncomeVerificationDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: incomeDocumentMonthlyIncomeVerificationDto,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Удалить доход по справке 2НДФЛ или справке по форме банка
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationIncomeStatementIncomesDeleteDelete(requestParameters: ApiVerificationIncomeStatementIncomesDeleteDeleteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public apiVerificationIncomeStatementIncomesDeleteDelete(requestParameters: ApiVerificationIncomeStatementIncomesDeleteDeleteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiVerificationIncomeStatementIncomesDeleteDelete(requestParameters: ApiVerificationIncomeStatementIncomesDeleteDeleteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiVerificationIncomeStatementIncomesDeleteDelete(requestParameters: ApiVerificationIncomeStatementIncomesDeleteDeleteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        const fileType = requestParameters.fileType;

        const incomeId = requestParameters.incomeId;


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (fileType !== undefined && fileType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fileType, 'fileType');
        }
        if (incomeId !== undefined && incomeId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>incomeId, 'incomeId');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/incomeStatement/incomes/delete`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить статистику по Верификаторам
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Statistics.R&lt;/b&gt;&lt;/p&gt;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationStatisticGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<VerificationStatisticDto>;
    public apiVerificationStatisticGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<VerificationStatisticDto>>;
    public apiVerificationStatisticGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<VerificationStatisticDto>>;
    public apiVerificationStatisticGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/statistic`;
        return this.httpClient.request<VerificationStatisticDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить список задач верификатора с вынесенным решением
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.Resolution.L&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskResolutionsGet(requestParameters: ApiVerificationTaskResolutionsGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<VerificationTaskResolutionDto>>;
    public apiVerificationTaskResolutionsGet(requestParameters: ApiVerificationTaskResolutionsGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<VerificationTaskResolutionDto>>>;
    public apiVerificationTaskResolutionsGet(requestParameters: ApiVerificationTaskResolutionsGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<VerificationTaskResolutionDto>>>;
    public apiVerificationTaskResolutionsGet(requestParameters: ApiVerificationTaskResolutionsGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const applicationIds = requestParameters.applicationIds;


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (applicationIds) {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>applicationIds, 'applicationIds');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/taskResolutions`;
        return this.httpClient.request<Array<VerificationTaskResolutionDto>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу Отказ от дополнительных услуг
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdAdditionalServicesRefusalGet(requestParameters: ApiVerificationTaskTaskIdAdditionalServicesRefusalGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<AdditionalServicesRefusalTaskDto>;
    public apiVerificationTaskTaskIdAdditionalServicesRefusalGet(requestParameters: ApiVerificationTaskTaskIdAdditionalServicesRefusalGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<AdditionalServicesRefusalTaskDto>>;
    public apiVerificationTaskTaskIdAdditionalServicesRefusalGet(requestParameters: ApiVerificationTaskTaskIdAdditionalServicesRefusalGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<AdditionalServicesRefusalTaskDto>>;
    public apiVerificationTaskTaskIdAdditionalServicesRefusalGet(requestParameters: ApiVerificationTaskTaskIdAdditionalServicesRefusalGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/additionalServicesRefusal`;
        return this.httpClient.request<AdditionalServicesRefusalTaskDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу Отказ от дополнительных услуг
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdAdditionalServicesRefusalPut(requestParameters: ApiVerificationTaskTaskIdAdditionalServicesRefusalPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationTaskTaskIdAdditionalServicesRefusalPut(requestParameters: ApiVerificationTaskTaskIdAdditionalServicesRefusalPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationTaskTaskIdAdditionalServicesRefusalPut(requestParameters: ApiVerificationTaskTaskIdAdditionalServicesRefusalPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationTaskTaskIdAdditionalServicesRefusalPut(requestParameters: ApiVerificationTaskTaskIdAdditionalServicesRefusalPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const additionalServicesRefusalTaskDtoBaseUpdateVerificationTask = requestParameters.additionalServicesRefusalTaskDtoBaseUpdateVerificationTask;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/additionalServicesRefusal`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: additionalServicesRefusalTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу Анкета заявления и согласия
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdApplicationAndConsentFormGet(requestParameters: ApiVerificationTaskTaskIdApplicationAndConsentFormGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<ApplicationAndConsentFormTaskDto>;
    public apiVerificationTaskTaskIdApplicationAndConsentFormGet(requestParameters: ApiVerificationTaskTaskIdApplicationAndConsentFormGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<ApplicationAndConsentFormTaskDto>>;
    public apiVerificationTaskTaskIdApplicationAndConsentFormGet(requestParameters: ApiVerificationTaskTaskIdApplicationAndConsentFormGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<ApplicationAndConsentFormTaskDto>>;
    public apiVerificationTaskTaskIdApplicationAndConsentFormGet(requestParameters: ApiVerificationTaskTaskIdApplicationAndConsentFormGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/applicationAndConsentForm`;
        return this.httpClient.request<ApplicationAndConsentFormTaskDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу Анкета заявление и согласия
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdApplicationAndConsentFormPut(requestParameters: ApiVerificationTaskTaskIdApplicationAndConsentFormPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationTaskTaskIdApplicationAndConsentFormPut(requestParameters: ApiVerificationTaskTaskIdApplicationAndConsentFormPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationTaskTaskIdApplicationAndConsentFormPut(requestParameters: ApiVerificationTaskTaskIdApplicationAndConsentFormPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationTaskTaskIdApplicationAndConsentFormPut(requestParameters: ApiVerificationTaskTaskIdApplicationAndConsentFormPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const applicationAndConsentFormTaskDtoBaseUpdateVerificationTask = requestParameters.applicationAndConsentFormTaskDtoBaseUpdateVerificationTask;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/applicationAndConsentForm`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: applicationAndConsentFormTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу Заявление на присоединение к условиям ДКО
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdApplicationForJoiningDKOGet(requestParameters: ApiVerificationTaskTaskIdApplicationForJoiningDKOGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<ApplicationForJoiningDKOTaskDto>;
    public apiVerificationTaskTaskIdApplicationForJoiningDKOGet(requestParameters: ApiVerificationTaskTaskIdApplicationForJoiningDKOGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<ApplicationForJoiningDKOTaskDto>>;
    public apiVerificationTaskTaskIdApplicationForJoiningDKOGet(requestParameters: ApiVerificationTaskTaskIdApplicationForJoiningDKOGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<ApplicationForJoiningDKOTaskDto>>;
    public apiVerificationTaskTaskIdApplicationForJoiningDKOGet(requestParameters: ApiVerificationTaskTaskIdApplicationForJoiningDKOGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/applicationForJoiningDKO`;
        return this.httpClient.request<ApplicationForJoiningDKOTaskDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу Заявление на присоединение к условиям ДКО
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdApplicationForJoiningDKOPut(requestParameters: ApiVerificationTaskTaskIdApplicationForJoiningDKOPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationTaskTaskIdApplicationForJoiningDKOPut(requestParameters: ApiVerificationTaskTaskIdApplicationForJoiningDKOPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationTaskTaskIdApplicationForJoiningDKOPut(requestParameters: ApiVerificationTaskTaskIdApplicationForJoiningDKOPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationTaskTaskIdApplicationForJoiningDKOPut(requestParameters: ApiVerificationTaskTaskIdApplicationForJoiningDKOPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const applicationForJoiningDKOTaskDtoBaseUpdateVerificationTask = requestParameters.applicationForJoiningDKOTaskDtoBaseUpdateVerificationTask;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/applicationForJoiningDKO`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: applicationForJoiningDKOTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу Заявление на перевод денежных средств за ТС
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdApplicationForTransferForVehicleGet(requestParameters: ApiVerificationTaskTaskIdApplicationForTransferForVehicleGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<ApplicationForTransferForVehicleTaskDto>;
    public apiVerificationTaskTaskIdApplicationForTransferForVehicleGet(requestParameters: ApiVerificationTaskTaskIdApplicationForTransferForVehicleGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<ApplicationForTransferForVehicleTaskDto>>;
    public apiVerificationTaskTaskIdApplicationForTransferForVehicleGet(requestParameters: ApiVerificationTaskTaskIdApplicationForTransferForVehicleGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<ApplicationForTransferForVehicleTaskDto>>;
    public apiVerificationTaskTaskIdApplicationForTransferForVehicleGet(requestParameters: ApiVerificationTaskTaskIdApplicationForTransferForVehicleGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/applicationForTransferForVehicle`;
        return this.httpClient.request<ApplicationForTransferForVehicleTaskDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу Заявление на перевод денежных средств за ТС
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdApplicationForTransferForVehiclePut(requestParameters: ApiVerificationTaskTaskIdApplicationForTransferForVehiclePutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationTaskTaskIdApplicationForTransferForVehiclePut(requestParameters: ApiVerificationTaskTaskIdApplicationForTransferForVehiclePutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationTaskTaskIdApplicationForTransferForVehiclePut(requestParameters: ApiVerificationTaskTaskIdApplicationForTransferForVehiclePutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationTaskTaskIdApplicationForTransferForVehiclePut(requestParameters: ApiVerificationTaskTaskIdApplicationForTransferForVehiclePutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const applicationForTransferForVehicleTaskDtoBaseUpdateVerificationTask = requestParameters.applicationForTransferForVehicleTaskDtoBaseUpdateVerificationTask;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/applicationForTransferForVehicle`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: applicationForTransferForVehicleTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу Заявление на перевод денежных средств за доп. Продукты
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdApplicationForTransferOfFundsGet(requestParameters: ApiVerificationTaskTaskIdApplicationForTransferOfFundsGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<ApplicationForTransferOfFundsTaskDto>;
    public apiVerificationTaskTaskIdApplicationForTransferOfFundsGet(requestParameters: ApiVerificationTaskTaskIdApplicationForTransferOfFundsGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<ApplicationForTransferOfFundsTaskDto>>;
    public apiVerificationTaskTaskIdApplicationForTransferOfFundsGet(requestParameters: ApiVerificationTaskTaskIdApplicationForTransferOfFundsGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<ApplicationForTransferOfFundsTaskDto>>;
    public apiVerificationTaskTaskIdApplicationForTransferOfFundsGet(requestParameters: ApiVerificationTaskTaskIdApplicationForTransferOfFundsGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/applicationForTransferOfFunds`;
        return this.httpClient.request<ApplicationForTransferOfFundsTaskDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу Заявление на перевод денежных средств за доп. Продукты
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdApplicationForTransferOfFundsPut(requestParameters: ApiVerificationTaskTaskIdApplicationForTransferOfFundsPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationTaskTaskIdApplicationForTransferOfFundsPut(requestParameters: ApiVerificationTaskTaskIdApplicationForTransferOfFundsPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationTaskTaskIdApplicationForTransferOfFundsPut(requestParameters: ApiVerificationTaskTaskIdApplicationForTransferOfFundsPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationTaskTaskIdApplicationForTransferOfFundsPut(requestParameters: ApiVerificationTaskTaskIdApplicationForTransferOfFundsPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const applicationForTransferOfFundsTaskDtoBaseUpdateVerificationTask = requestParameters.applicationForTransferOfFundsTaskDtoBaseUpdateVerificationTask;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/applicationForTransferOfFunds`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: applicationForTransferOfFundsTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу звонка по Работодателю
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdCallEmploymentGet(requestParameters: ApiVerificationTaskTaskIdCallEmploymentGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<CallEmploymentVerificationTaskDto>;
    public apiVerificationTaskTaskIdCallEmploymentGet(requestParameters: ApiVerificationTaskTaskIdCallEmploymentGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<CallEmploymentVerificationTaskDto>>;
    public apiVerificationTaskTaskIdCallEmploymentGet(requestParameters: ApiVerificationTaskTaskIdCallEmploymentGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<CallEmploymentVerificationTaskDto>>;
    public apiVerificationTaskTaskIdCallEmploymentGet(requestParameters: ApiVerificationTaskTaskIdCallEmploymentGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/callEmployment`;
        return this.httpClient.request<CallEmploymentVerificationTaskDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу звонка по Работодателю
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdCallEmploymentPut(requestParameters: ApiVerificationTaskTaskIdCallEmploymentPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationTaskTaskIdCallEmploymentPut(requestParameters: ApiVerificationTaskTaskIdCallEmploymentPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationTaskTaskIdCallEmploymentPut(requestParameters: ApiVerificationTaskTaskIdCallEmploymentPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationTaskTaskIdCallEmploymentPut(requestParameters: ApiVerificationTaskTaskIdCallEmploymentPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const callEmploymentVerificationTaskUpdateRequest = requestParameters.callEmploymentVerificationTaskUpdateRequest;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/callEmployment`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: callEmploymentVerificationTaskUpdateRequest,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу звонка
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdCallGet(requestParameters: ApiVerificationTaskTaskIdCallGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<CallVerificationTaskDto>;
    public apiVerificationTaskTaskIdCallGet(requestParameters: ApiVerificationTaskTaskIdCallGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<CallVerificationTaskDto>>;
    public apiVerificationTaskTaskIdCallGet(requestParameters: ApiVerificationTaskTaskIdCallGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<CallVerificationTaskDto>>;
    public apiVerificationTaskTaskIdCallGet(requestParameters: ApiVerificationTaskTaskIdCallGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/call`;
        return this.httpClient.request<CallVerificationTaskDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить настройки видимости Вопросов
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdCallPost(requestParameters: ApiVerificationTaskTaskIdCallPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<CallVerificationTaskDto>;
    public apiVerificationTaskTaskIdCallPost(requestParameters: ApiVerificationTaskTaskIdCallPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<CallVerificationTaskDto>>;
    public apiVerificationTaskTaskIdCallPost(requestParameters: ApiVerificationTaskTaskIdCallPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<CallVerificationTaskDto>>;
    public apiVerificationTaskTaskIdCallPost(requestParameters: ApiVerificationTaskTaskIdCallPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const callVerificationTaskUpdateRequest = requestParameters.callVerificationTaskUpdateRequest;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/call`;
        return this.httpClient.request<CallVerificationTaskDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: callVerificationTaskUpdateRequest,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу звонка
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdCallPut(requestParameters: ApiVerificationTaskTaskIdCallPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationTaskTaskIdCallPut(requestParameters: ApiVerificationTaskTaskIdCallPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationTaskTaskIdCallPut(requestParameters: ApiVerificationTaskTaskIdCallPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationTaskTaskIdCallPut(requestParameters: ApiVerificationTaskTaskIdCallPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const callVerificationTaskUpdateRequestBaseUpdateVerificationTask = requestParameters.callVerificationTaskUpdateRequestBaseUpdateVerificationTask;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/call`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: callVerificationTaskUpdateRequestBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу Фото Клиента
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdClientPhotoGet(requestParameters: ApiVerificationTaskTaskIdClientPhotoGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<ClientPhotoVerificationTaskDto>;
    public apiVerificationTaskTaskIdClientPhotoGet(requestParameters: ApiVerificationTaskTaskIdClientPhotoGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<ClientPhotoVerificationTaskDto>>;
    public apiVerificationTaskTaskIdClientPhotoGet(requestParameters: ApiVerificationTaskTaskIdClientPhotoGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<ClientPhotoVerificationTaskDto>>;
    public apiVerificationTaskTaskIdClientPhotoGet(requestParameters: ApiVerificationTaskTaskIdClientPhotoGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/clientPhoto`;
        return this.httpClient.request<ClientPhotoVerificationTaskDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу Фото Клиента
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdClientPhotoPut(requestParameters: ApiVerificationTaskTaskIdClientPhotoPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationTaskTaskIdClientPhotoPut(requestParameters: ApiVerificationTaskTaskIdClientPhotoPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationTaskTaskIdClientPhotoPut(requestParameters: ApiVerificationTaskTaskIdClientPhotoPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationTaskTaskIdClientPhotoPut(requestParameters: ApiVerificationTaskTaskIdClientPhotoPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const clientPhotoVerificationTaskDtoBaseUpdateVerificationTask = requestParameters.clientPhotoVerificationTaskDtoBaseUpdateVerificationTask;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/clientPhoto`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: clientPhotoVerificationTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу Водительского удостоверения
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdDriverLicenseGet(requestParameters: ApiVerificationTaskTaskIdDriverLicenseGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<DriverLicenseVerificationTaskDto>;
    public apiVerificationTaskTaskIdDriverLicenseGet(requestParameters: ApiVerificationTaskTaskIdDriverLicenseGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<DriverLicenseVerificationTaskDto>>;
    public apiVerificationTaskTaskIdDriverLicenseGet(requestParameters: ApiVerificationTaskTaskIdDriverLicenseGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<DriverLicenseVerificationTaskDto>>;
    public apiVerificationTaskTaskIdDriverLicenseGet(requestParameters: ApiVerificationTaskTaskIdDriverLicenseGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/driverLicense`;
        return this.httpClient.request<DriverLicenseVerificationTaskDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу Водительское удостоверение
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdDriverLicensePut(requestParameters: ApiVerificationTaskTaskIdDriverLicensePutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationTaskTaskIdDriverLicensePut(requestParameters: ApiVerificationTaskTaskIdDriverLicensePutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationTaskTaskIdDriverLicensePut(requestParameters: ApiVerificationTaskTaskIdDriverLicensePutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationTaskTaskIdDriverLicensePut(requestParameters: ApiVerificationTaskTaskIdDriverLicensePutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const driverLicenseVerificationTaskDtoBaseUpdateVerificationTask = requestParameters.driverLicenseVerificationTaskDtoBaseUpdateVerificationTask;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/driverLicense`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: driverLicenseVerificationTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу документа о занятости
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdEmploymentDocumentGet(requestParameters: ApiVerificationTaskTaskIdEmploymentDocumentGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<EmploymentDocumentVerificationTaskDto>;
    public apiVerificationTaskTaskIdEmploymentDocumentGet(requestParameters: ApiVerificationTaskTaskIdEmploymentDocumentGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<EmploymentDocumentVerificationTaskDto>>;
    public apiVerificationTaskTaskIdEmploymentDocumentGet(requestParameters: ApiVerificationTaskTaskIdEmploymentDocumentGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<EmploymentDocumentVerificationTaskDto>>;
    public apiVerificationTaskTaskIdEmploymentDocumentGet(requestParameters: ApiVerificationTaskTaskIdEmploymentDocumentGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const fileType = requestParameters.fileType;


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (fileType !== undefined && fileType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fileType, 'fileType');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/employmentDocument`;
        return this.httpClient.request<EmploymentDocumentVerificationTaskDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу трудовой книжки (employmentHistory) / трудового договора (employmentContract)
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdEmploymentDocumentPut(requestParameters: ApiVerificationTaskTaskIdEmploymentDocumentPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationTaskTaskIdEmploymentDocumentPut(requestParameters: ApiVerificationTaskTaskIdEmploymentDocumentPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationTaskTaskIdEmploymentDocumentPut(requestParameters: ApiVerificationTaskTaskIdEmploymentDocumentPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationTaskTaskIdEmploymentDocumentPut(requestParameters: ApiVerificationTaskTaskIdEmploymentDocumentPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const employmentDocumentVerificationTaskDtoBaseUpdateVerificationTask = requestParameters.employmentDocumentVerificationTaskDtoBaseUpdateVerificationTask;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/employmentDocument`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: employmentDocumentVerificationTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу трудовой книжки (employmentHistory) / трудового договора (employmentContract)
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdEmploymentHistoryPut(requestParameters: ApiVerificationTaskTaskIdEmploymentHistoryPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationTaskTaskIdEmploymentHistoryPut(requestParameters: ApiVerificationTaskTaskIdEmploymentHistoryPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationTaskTaskIdEmploymentHistoryPut(requestParameters: ApiVerificationTaskTaskIdEmploymentHistoryPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationTaskTaskIdEmploymentHistoryPut(requestParameters: ApiVerificationTaskTaskIdEmploymentHistoryPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const employmentDocumentVerificationTaskDtoBaseUpdateVerificationTask = requestParameters.employmentDocumentVerificationTaskDtoBaseUpdateVerificationTask;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/employmentHistory`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: employmentDocumentVerificationTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу верификации
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdGet(requestParameters: ApiVerificationTaskTaskIdGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<VerificationTaskInfoDto>;
    public apiVerificationTaskTaskIdGet(requestParameters: ApiVerificationTaskTaskIdGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<VerificationTaskInfoDto>>;
    public apiVerificationTaskTaskIdGet(requestParameters: ApiVerificationTaskTaskIdGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<VerificationTaskInfoDto>>;
    public apiVerificationTaskTaskIdGet(requestParameters: ApiVerificationTaskTaskIdGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<VerificationTaskInfoDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу верификации справки о доходах (2 НДФЛ или справки по форме банка)
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdIncomeStatementGet(requestParameters: ApiVerificationTaskTaskIdIncomeStatementGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IncomeDocumentVerificationTaskDto>;
    public apiVerificationTaskTaskIdIncomeStatementGet(requestParameters: ApiVerificationTaskTaskIdIncomeStatementGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IncomeDocumentVerificationTaskDto>>;
    public apiVerificationTaskTaskIdIncomeStatementGet(requestParameters: ApiVerificationTaskTaskIdIncomeStatementGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IncomeDocumentVerificationTaskDto>>;
    public apiVerificationTaskTaskIdIncomeStatementGet(requestParameters: ApiVerificationTaskTaskIdIncomeStatementGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const fileType = requestParameters.fileType;


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (fileType !== undefined && fileType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fileType, 'fileType');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/incomeStatement`;
        return this.httpClient.request<IncomeDocumentVerificationTaskDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу 2 НДФЛ
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdIncomeStatementPut(requestParameters: ApiVerificationTaskTaskIdIncomeStatementPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationTaskTaskIdIncomeStatementPut(requestParameters: ApiVerificationTaskTaskIdIncomeStatementPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationTaskTaskIdIncomeStatementPut(requestParameters: ApiVerificationTaskTaskIdIncomeStatementPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationTaskTaskIdIncomeStatementPut(requestParameters: ApiVerificationTaskTaskIdIncomeStatementPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const incomeDocumentVerificationTaskDtoBaseUpdateVerificationTask = requestParameters.incomeDocumentVerificationTaskDtoBaseUpdateVerificationTask;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/incomeStatement`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: incomeDocumentVerificationTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу Индивидуальные условия
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdIndividualConditionsGet(requestParameters: ApiVerificationTaskTaskIdIndividualConditionsGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IndividualConditionsTaskDto>;
    public apiVerificationTaskTaskIdIndividualConditionsGet(requestParameters: ApiVerificationTaskTaskIdIndividualConditionsGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IndividualConditionsTaskDto>>;
    public apiVerificationTaskTaskIdIndividualConditionsGet(requestParameters: ApiVerificationTaskTaskIdIndividualConditionsGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IndividualConditionsTaskDto>>;
    public apiVerificationTaskTaskIdIndividualConditionsGet(requestParameters: ApiVerificationTaskTaskIdIndividualConditionsGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/individualConditions`;
        return this.httpClient.request<IndividualConditionsTaskDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу Индивидуальные условия
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdIndividualConditionsPut(requestParameters: ApiVerificationTaskTaskIdIndividualConditionsPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationTaskTaskIdIndividualConditionsPut(requestParameters: ApiVerificationTaskTaskIdIndividualConditionsPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationTaskTaskIdIndividualConditionsPut(requestParameters: ApiVerificationTaskTaskIdIndividualConditionsPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationTaskTaskIdIndividualConditionsPut(requestParameters: ApiVerificationTaskTaskIdIndividualConditionsPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const individualConditionsTaskDtoBaseUpdateVerificationTask = requestParameters.individualConditionsTaskDtoBaseUpdateVerificationTask;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/individualConditions`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: individualConditionsTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу Полис страхования жизни
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdLifeInsuranceGet(requestParameters: ApiVerificationTaskTaskIdLifeInsuranceGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<LifeInsuranceVerificationTaskDto>;
    public apiVerificationTaskTaskIdLifeInsuranceGet(requestParameters: ApiVerificationTaskTaskIdLifeInsuranceGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<LifeInsuranceVerificationTaskDto>>;
    public apiVerificationTaskTaskIdLifeInsuranceGet(requestParameters: ApiVerificationTaskTaskIdLifeInsuranceGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<LifeInsuranceVerificationTaskDto>>;
    public apiVerificationTaskTaskIdLifeInsuranceGet(requestParameters: ApiVerificationTaskTaskIdLifeInsuranceGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/lifeInsurance`;
        return this.httpClient.request<LifeInsuranceVerificationTaskDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу Полис страхования жизни
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdLifeInsurancePut(requestParameters: ApiVerificationTaskTaskIdLifeInsurancePutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationTaskTaskIdLifeInsurancePut(requestParameters: ApiVerificationTaskTaskIdLifeInsurancePutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationTaskTaskIdLifeInsurancePut(requestParameters: ApiVerificationTaskTaskIdLifeInsurancePutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationTaskTaskIdLifeInsurancePut(requestParameters: ApiVerificationTaskTaskIdLifeInsurancePutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const lifeInsuranceVerificationTaskDtoBaseUpdateVerificationTask = requestParameters.lifeInsuranceVerificationTaskDtoBaseUpdateVerificationTask;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/lifeInsurance`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: lifeInsuranceVerificationTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу полного Паспорта
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPassportFullGet(requestParameters: ApiVerificationTaskTaskIdPassportFullGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<PassportVerificationTaskDto>;
    public apiVerificationTaskTaskIdPassportFullGet(requestParameters: ApiVerificationTaskTaskIdPassportFullGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<PassportVerificationTaskDto>>;
    public apiVerificationTaskTaskIdPassportFullGet(requestParameters: ApiVerificationTaskTaskIdPassportFullGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<PassportVerificationTaskDto>>;
    public apiVerificationTaskTaskIdPassportFullGet(requestParameters: ApiVerificationTaskTaskIdPassportFullGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/passportFull`;
        return this.httpClient.request<PassportVerificationTaskDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу полного Паспорта
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPassportFullPut(requestParameters: ApiVerificationTaskTaskIdPassportFullPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationTaskTaskIdPassportFullPut(requestParameters: ApiVerificationTaskTaskIdPassportFullPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationTaskTaskIdPassportFullPut(requestParameters: ApiVerificationTaskTaskIdPassportFullPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationTaskTaskIdPassportFullPut(requestParameters: ApiVerificationTaskTaskIdPassportFullPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const passportFullVerificationTaskDtoBaseUpdateVerificationTask = requestParameters.passportFullVerificationTaskDtoBaseUpdateVerificationTask;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/passportFull`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: passportFullVerificationTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу паспорта
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPassportGet(requestParameters: ApiVerificationTaskTaskIdPassportGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<PassportVerificationTaskDto>;
    public apiVerificationTaskTaskIdPassportGet(requestParameters: ApiVerificationTaskTaskIdPassportGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<PassportVerificationTaskDto>>;
    public apiVerificationTaskTaskIdPassportGet(requestParameters: ApiVerificationTaskTaskIdPassportGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<PassportVerificationTaskDto>>;
    public apiVerificationTaskTaskIdPassportGet(requestParameters: ApiVerificationTaskTaskIdPassportGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/passport`;
        return this.httpClient.request<PassportVerificationTaskDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу паспорта главный разворот
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPassportMainPageGet(requestParameters: ApiVerificationTaskTaskIdPassportMainPageGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<PassportMainPageVerificationTaskDto>;
    public apiVerificationTaskTaskIdPassportMainPageGet(requestParameters: ApiVerificationTaskTaskIdPassportMainPageGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<PassportMainPageVerificationTaskDto>>;
    public apiVerificationTaskTaskIdPassportMainPageGet(requestParameters: ApiVerificationTaskTaskIdPassportMainPageGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<PassportMainPageVerificationTaskDto>>;
    public apiVerificationTaskTaskIdPassportMainPageGet(requestParameters: ApiVerificationTaskTaskIdPassportMainPageGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/passportMainPage`;
        return this.httpClient.request<PassportMainPageVerificationTaskDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу паспорта главный разворот
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPassportMainPagePut(requestParameters: ApiVerificationTaskTaskIdPassportMainPagePutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationTaskTaskIdPassportMainPagePut(requestParameters: ApiVerificationTaskTaskIdPassportMainPagePutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationTaskTaskIdPassportMainPagePut(requestParameters: ApiVerificationTaskTaskIdPassportMainPagePutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationTaskTaskIdPassportMainPagePut(requestParameters: ApiVerificationTaskTaskIdPassportMainPagePutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const passportMainPageVerificationTaskDtoBaseUpdateVerificationTask = requestParameters.passportMainPageVerificationTaskDtoBaseUpdateVerificationTask;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/passportMainPage`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: passportMainPageVerificationTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу страницы паспорта
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPassportPageGet(requestParameters: ApiVerificationTaskTaskIdPassportPageGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<PassportPageVerificationTaskDto>;
    public apiVerificationTaskTaskIdPassportPageGet(requestParameters: ApiVerificationTaskTaskIdPassportPageGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<PassportPageVerificationTaskDto>>;
    public apiVerificationTaskTaskIdPassportPageGet(requestParameters: ApiVerificationTaskTaskIdPassportPageGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<PassportPageVerificationTaskDto>>;
    public apiVerificationTaskTaskIdPassportPageGet(requestParameters: ApiVerificationTaskTaskIdPassportPageGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const type = requestParameters.type;


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (type !== undefined && type !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>type, 'type');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/passportPage`;
        return this.httpClient.request<PassportPageVerificationTaskDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу страницы паспорта
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPassportPagePut(requestParameters: ApiVerificationTaskTaskIdPassportPagePutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationTaskTaskIdPassportPagePut(requestParameters: ApiVerificationTaskTaskIdPassportPagePutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationTaskTaskIdPassportPagePut(requestParameters: ApiVerificationTaskTaskIdPassportPagePutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationTaskTaskIdPassportPagePut(requestParameters: ApiVerificationTaskTaskIdPassportPagePutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const passportPageUpdateVerificationTaskDtoBaseUpdateVerificationTask = requestParameters.passportPageUpdateVerificationTaskDtoBaseUpdateVerificationTask;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/passportPage`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: passportPageUpdateVerificationTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу паспорта
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPassportPut(requestParameters: ApiVerificationTaskTaskIdPassportPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationTaskTaskIdPassportPut(requestParameters: ApiVerificationTaskTaskIdPassportPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationTaskTaskIdPassportPut(requestParameters: ApiVerificationTaskTaskIdPassportPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationTaskTaskIdPassportPut(requestParameters: ApiVerificationTaskTaskIdPassportPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const passportVerificationTaskDtoBaseUpdateVerificationTask = requestParameters.passportVerificationTaskDtoBaseUpdateVerificationTask;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/passport`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: passportVerificationTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу адреса паспорта
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPassportRegistrationGet(requestParameters: ApiVerificationTaskTaskIdPassportRegistrationGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<PassportAddressVerificationTaskDto>;
    public apiVerificationTaskTaskIdPassportRegistrationGet(requestParameters: ApiVerificationTaskTaskIdPassportRegistrationGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<PassportAddressVerificationTaskDto>>;
    public apiVerificationTaskTaskIdPassportRegistrationGet(requestParameters: ApiVerificationTaskTaskIdPassportRegistrationGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<PassportAddressVerificationTaskDto>>;
    public apiVerificationTaskTaskIdPassportRegistrationGet(requestParameters: ApiVerificationTaskTaskIdPassportRegistrationGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/passportRegistration`;
        return this.httpClient.request<PassportAddressVerificationTaskDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу адреса паспорта
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPassportRegistrationPut(requestParameters: ApiVerificationTaskTaskIdPassportRegistrationPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationTaskTaskIdPassportRegistrationPut(requestParameters: ApiVerificationTaskTaskIdPassportRegistrationPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationTaskTaskIdPassportRegistrationPut(requestParameters: ApiVerificationTaskTaskIdPassportRegistrationPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationTaskTaskIdPassportRegistrationPut(requestParameters: ApiVerificationTaskTaskIdPassportRegistrationPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const passportAddressVerificationTaskDtoBaseUpdateVerificationTask = requestParameters.passportAddressVerificationTaskDtoBaseUpdateVerificationTask;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/passportRegistration`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: passportAddressVerificationTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Поставить Задачу на Паузу
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.P&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPausePost(requestParameters: ApiVerificationTaskTaskIdPausePostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<number>;
    public apiVerificationTaskTaskIdPausePost(requestParameters: ApiVerificationTaskTaskIdPausePostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<number>>;
    public apiVerificationTaskTaskIdPausePost(requestParameters: ApiVerificationTaskTaskIdPausePostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<number>>;
    public apiVerificationTaskTaskIdPausePost(requestParameters: ApiVerificationTaskTaskIdPausePostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/pause`;
        return this.httpClient.request<number>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу Счёта на оплату
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPaymentInvoiceGet(requestParameters: ApiVerificationTaskTaskIdPaymentInvoiceGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<PaymentInvoiceVerificationTaskDto>;
    public apiVerificationTaskTaskIdPaymentInvoiceGet(requestParameters: ApiVerificationTaskTaskIdPaymentInvoiceGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<PaymentInvoiceVerificationTaskDto>>;
    public apiVerificationTaskTaskIdPaymentInvoiceGet(requestParameters: ApiVerificationTaskTaskIdPaymentInvoiceGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<PaymentInvoiceVerificationTaskDto>>;
    public apiVerificationTaskTaskIdPaymentInvoiceGet(requestParameters: ApiVerificationTaskTaskIdPaymentInvoiceGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/paymentInvoice`;
        return this.httpClient.request<PaymentInvoiceVerificationTaskDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу Счёта на оплату
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPaymentInvoicePut(requestParameters: ApiVerificationTaskTaskIdPaymentInvoicePutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationTaskTaskIdPaymentInvoicePut(requestParameters: ApiVerificationTaskTaskIdPaymentInvoicePutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationTaskTaskIdPaymentInvoicePut(requestParameters: ApiVerificationTaskTaskIdPaymentInvoicePutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationTaskTaskIdPaymentInvoicePut(requestParameters: ApiVerificationTaskTaskIdPaymentInvoicePutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const paymentInvoiceVerificationTaskDtoBaseUpdateVerificationTask = requestParameters.paymentInvoiceVerificationTaskDtoBaseUpdateVerificationTask;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/paymentInvoice`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: paymentInvoiceVerificationTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу Квитанции об оплате
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPaymentReceiptGet(requestParameters: ApiVerificationTaskTaskIdPaymentReceiptGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<PaymentReceiptVerificationTaskDto>;
    public apiVerificationTaskTaskIdPaymentReceiptGet(requestParameters: ApiVerificationTaskTaskIdPaymentReceiptGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<PaymentReceiptVerificationTaskDto>>;
    public apiVerificationTaskTaskIdPaymentReceiptGet(requestParameters: ApiVerificationTaskTaskIdPaymentReceiptGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<PaymentReceiptVerificationTaskDto>>;
    public apiVerificationTaskTaskIdPaymentReceiptGet(requestParameters: ApiVerificationTaskTaskIdPaymentReceiptGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/paymentReceipt`;
        return this.httpClient.request<PaymentReceiptVerificationTaskDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу Квитанции об оплате
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPaymentReceiptPut(requestParameters: ApiVerificationTaskTaskIdPaymentReceiptPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationTaskTaskIdPaymentReceiptPut(requestParameters: ApiVerificationTaskTaskIdPaymentReceiptPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationTaskTaskIdPaymentReceiptPut(requestParameters: ApiVerificationTaskTaskIdPaymentReceiptPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationTaskTaskIdPaymentReceiptPut(requestParameters: ApiVerificationTaskTaskIdPaymentReceiptPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const paymentReceiptVerificationTaskDtoBaseUpdateVerificationTask = requestParameters.paymentReceiptVerificationTaskDtoBaseUpdateVerificationTask;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/paymentReceipt`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: paymentReceiptVerificationTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу График платежей
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPaymentScheduleGet(requestParameters: ApiVerificationTaskTaskIdPaymentScheduleGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<PaymentScheduleTaskDto>;
    public apiVerificationTaskTaskIdPaymentScheduleGet(requestParameters: ApiVerificationTaskTaskIdPaymentScheduleGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<PaymentScheduleTaskDto>>;
    public apiVerificationTaskTaskIdPaymentScheduleGet(requestParameters: ApiVerificationTaskTaskIdPaymentScheduleGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<PaymentScheduleTaskDto>>;
    public apiVerificationTaskTaskIdPaymentScheduleGet(requestParameters: ApiVerificationTaskTaskIdPaymentScheduleGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/paymentSchedule`;
        return this.httpClient.request<PaymentScheduleTaskDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу График платежей
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPaymentSchedulePut(requestParameters: ApiVerificationTaskTaskIdPaymentSchedulePutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationTaskTaskIdPaymentSchedulePut(requestParameters: ApiVerificationTaskTaskIdPaymentSchedulePutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationTaskTaskIdPaymentSchedulePut(requestParameters: ApiVerificationTaskTaskIdPaymentSchedulePutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationTaskTaskIdPaymentSchedulePut(requestParameters: ApiVerificationTaskTaskIdPaymentSchedulePutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const paymentScheduleTaskDtoBaseUpdateVerificationTask = requestParameters.paymentScheduleTaskDtoBaseUpdateVerificationTask;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/paymentSchedule`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: paymentScheduleTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу документа о пенсии
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPensionCertificateGet(requestParameters: ApiVerificationTaskTaskIdPensionCertificateGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<PensionCertificateVerificationTaskDto>;
    public apiVerificationTaskTaskIdPensionCertificateGet(requestParameters: ApiVerificationTaskTaskIdPensionCertificateGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<PensionCertificateVerificationTaskDto>>;
    public apiVerificationTaskTaskIdPensionCertificateGet(requestParameters: ApiVerificationTaskTaskIdPensionCertificateGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<PensionCertificateVerificationTaskDto>>;
    public apiVerificationTaskTaskIdPensionCertificateGet(requestParameters: ApiVerificationTaskTaskIdPensionCertificateGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/pensionCertificate`;
        return this.httpClient.request<PensionCertificateVerificationTaskDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу по документу о пенсии
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPensionCertificatePut(requestParameters: ApiVerificationTaskTaskIdPensionCertificatePutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationTaskTaskIdPensionCertificatePut(requestParameters: ApiVerificationTaskTaskIdPensionCertificatePutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationTaskTaskIdPensionCertificatePut(requestParameters: ApiVerificationTaskTaskIdPensionCertificatePutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationTaskTaskIdPensionCertificatePut(requestParameters: ApiVerificationTaskTaskIdPensionCertificatePutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const pensionCertificateVerificationTaskDtoBaseUpdateVerificationTask = requestParameters.pensionCertificateVerificationTaskDtoBaseUpdateVerificationTask;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/pensionCertificate`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: pensionCertificateVerificationTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу ранее выданного паспорта
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPreviousPassportGet(requestParameters: ApiVerificationTaskTaskIdPreviousPassportGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<PreviousPassportVerificationTaskDto>;
    public apiVerificationTaskTaskIdPreviousPassportGet(requestParameters: ApiVerificationTaskTaskIdPreviousPassportGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<PreviousPassportVerificationTaskDto>>;
    public apiVerificationTaskTaskIdPreviousPassportGet(requestParameters: ApiVerificationTaskTaskIdPreviousPassportGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<PreviousPassportVerificationTaskDto>>;
    public apiVerificationTaskTaskIdPreviousPassportGet(requestParameters: ApiVerificationTaskTaskIdPreviousPassportGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/previousPassport`;
        return this.httpClient.request<PreviousPassportVerificationTaskDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу ранее выданного паспорта
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPreviousPassportPut(requestParameters: ApiVerificationTaskTaskIdPreviousPassportPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationTaskTaskIdPreviousPassportPut(requestParameters: ApiVerificationTaskTaskIdPreviousPassportPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationTaskTaskIdPreviousPassportPut(requestParameters: ApiVerificationTaskTaskIdPreviousPassportPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationTaskTaskIdPreviousPassportPut(requestParameters: ApiVerificationTaskTaskIdPreviousPassportPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const previousPassportVerificationTaskDtoBaseUpdateVerificationTask = requestParameters.previousPassportVerificationTaskDtoBaseUpdateVerificationTask;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/previousPassport`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: previousPassportVerificationTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу справки о профессиональном доходе
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdProfessionalIncomeCertificateGet(requestParameters: ApiVerificationTaskTaskIdProfessionalIncomeCertificateGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<ProfessionalIncomeCertificateTaskDto>;
    public apiVerificationTaskTaskIdProfessionalIncomeCertificateGet(requestParameters: ApiVerificationTaskTaskIdProfessionalIncomeCertificateGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<ProfessionalIncomeCertificateTaskDto>>;
    public apiVerificationTaskTaskIdProfessionalIncomeCertificateGet(requestParameters: ApiVerificationTaskTaskIdProfessionalIncomeCertificateGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<ProfessionalIncomeCertificateTaskDto>>;
    public apiVerificationTaskTaskIdProfessionalIncomeCertificateGet(requestParameters: ApiVerificationTaskTaskIdProfessionalIncomeCertificateGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const fileType = requestParameters.fileType;


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (fileType !== undefined && fileType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fileType, 'fileType');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/professionalIncomeCertificate`;
        return this.httpClient.request<ProfessionalIncomeCertificateTaskDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу справки о профессиональном доходе
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdProfessionalIncomeCertificatePut(requestParameters: ApiVerificationTaskTaskIdProfessionalIncomeCertificatePutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationTaskTaskIdProfessionalIncomeCertificatePut(requestParameters: ApiVerificationTaskTaskIdProfessionalIncomeCertificatePutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationTaskTaskIdProfessionalIncomeCertificatePut(requestParameters: ApiVerificationTaskTaskIdProfessionalIncomeCertificatePutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationTaskTaskIdProfessionalIncomeCertificatePut(requestParameters: ApiVerificationTaskTaskIdProfessionalIncomeCertificatePutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const professionalIncomeCertificateTaskRequestBaseUpdateVerificationTask = requestParameters.professionalIncomeCertificateTaskRequestBaseUpdateVerificationTask;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/professionalIncomeCertificate`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: professionalIncomeCertificateTaskRequestBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdPut(requestParameters: ApiVerificationTaskTaskIdPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationTaskTaskIdPut(requestParameters: ApiVerificationTaskTaskIdPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationTaskTaskIdPut(requestParameters: ApiVerificationTaskTaskIdPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationTaskTaskIdPut(requestParameters: ApiVerificationTaskTaskIdPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const updateVerificationTaskRequest = requestParameters.updateVerificationTaskRequest;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateVerificationTaskRequest,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу Полис помощи при ДТП
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdRoadAccidentGet(requestParameters: ApiVerificationTaskTaskIdRoadAccidentGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<RoadAccidentVerificationTaskDto>;
    public apiVerificationTaskTaskIdRoadAccidentGet(requestParameters: ApiVerificationTaskTaskIdRoadAccidentGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<RoadAccidentVerificationTaskDto>>;
    public apiVerificationTaskTaskIdRoadAccidentGet(requestParameters: ApiVerificationTaskTaskIdRoadAccidentGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<RoadAccidentVerificationTaskDto>>;
    public apiVerificationTaskTaskIdRoadAccidentGet(requestParameters: ApiVerificationTaskTaskIdRoadAccidentGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/roadAccident`;
        return this.httpClient.request<RoadAccidentVerificationTaskDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу Полис страхования жизни
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdRoadAccidentPut(requestParameters: ApiVerificationTaskTaskIdRoadAccidentPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationTaskTaskIdRoadAccidentPut(requestParameters: ApiVerificationTaskTaskIdRoadAccidentPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationTaskTaskIdRoadAccidentPut(requestParameters: ApiVerificationTaskTaskIdRoadAccidentPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationTaskTaskIdRoadAccidentPut(requestParameters: ApiVerificationTaskTaskIdRoadAccidentPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const roadAccidentVerificationTaskDtoBaseUpdateVerificationTask = requestParameters.roadAccidentVerificationTaskDtoBaseUpdateVerificationTask;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/roadAccident`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: roadAccidentVerificationTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу ДКП ТС (Договор купли-продажи Авто)
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdSaleContractVehicleGet(requestParameters: ApiVerificationTaskTaskIdSaleContractVehicleGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<SaleContractVehicleVerificationTaskDto>;
    public apiVerificationTaskTaskIdSaleContractVehicleGet(requestParameters: ApiVerificationTaskTaskIdSaleContractVehicleGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<SaleContractVehicleVerificationTaskDto>>;
    public apiVerificationTaskTaskIdSaleContractVehicleGet(requestParameters: ApiVerificationTaskTaskIdSaleContractVehicleGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<SaleContractVehicleVerificationTaskDto>>;
    public apiVerificationTaskTaskIdSaleContractVehicleGet(requestParameters: ApiVerificationTaskTaskIdSaleContractVehicleGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/saleContractVehicle`;
        return this.httpClient.request<SaleContractVehicleVerificationTaskDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу ДКП ТС (Договор купли-продажи Авто)
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdSaleContractVehiclePut(requestParameters: ApiVerificationTaskTaskIdSaleContractVehiclePutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationTaskTaskIdSaleContractVehiclePut(requestParameters: ApiVerificationTaskTaskIdSaleContractVehiclePutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationTaskTaskIdSaleContractVehiclePut(requestParameters: ApiVerificationTaskTaskIdSaleContractVehiclePutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationTaskTaskIdSaleContractVehiclePut(requestParameters: ApiVerificationTaskTaskIdSaleContractVehiclePutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const saleContractVehicleVerificationTaskDtoBaseUpdateVerificationTask = requestParameters.saleContractVehicleVerificationTaskDtoBaseUpdateVerificationTask;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/saleContractVehicle`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: saleContractVehicleVerificationTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу СНИЛС
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdSnilsGet(requestParameters: ApiVerificationTaskTaskIdSnilsGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<SnilsVerificationTaskDto>;
    public apiVerificationTaskTaskIdSnilsGet(requestParameters: ApiVerificationTaskTaskIdSnilsGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<SnilsVerificationTaskDto>>;
    public apiVerificationTaskTaskIdSnilsGet(requestParameters: ApiVerificationTaskTaskIdSnilsGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<SnilsVerificationTaskDto>>;
    public apiVerificationTaskTaskIdSnilsGet(requestParameters: ApiVerificationTaskTaskIdSnilsGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/snils`;
        return this.httpClient.request<SnilsVerificationTaskDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу СНИЛС
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdSnilsPut(requestParameters: ApiVerificationTaskTaskIdSnilsPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationTaskTaskIdSnilsPut(requestParameters: ApiVerificationTaskTaskIdSnilsPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationTaskTaskIdSnilsPut(requestParameters: ApiVerificationTaskTaskIdSnilsPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationTaskTaskIdSnilsPut(requestParameters: ApiVerificationTaskTaskIdSnilsPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const snilsVerificationTaskDtoBaseUpdateVerificationTask = requestParameters.snilsVerificationTaskDtoBaseUpdateVerificationTask;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/snils`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: snilsVerificationTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу СОПД
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdSopdGet(requestParameters: ApiVerificationTaskTaskIdSopdGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<SopdVerificationTaskDto>;
    public apiVerificationTaskTaskIdSopdGet(requestParameters: ApiVerificationTaskTaskIdSopdGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<SopdVerificationTaskDto>>;
    public apiVerificationTaskTaskIdSopdGet(requestParameters: ApiVerificationTaskTaskIdSopdGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<SopdVerificationTaskDto>>;
    public apiVerificationTaskTaskIdSopdGet(requestParameters: ApiVerificationTaskTaskIdSopdGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/sopd`;
        return this.httpClient.request<SopdVerificationTaskDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу СОПД
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdSopdPut(requestParameters: ApiVerificationTaskTaskIdSopdPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationTaskTaskIdSopdPut(requestParameters: ApiVerificationTaskTaskIdSopdPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationTaskTaskIdSopdPut(requestParameters: ApiVerificationTaskTaskIdSopdPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationTaskTaskIdSopdPut(requestParameters: ApiVerificationTaskTaskIdSopdPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const sopdVerificationTaskDtoBaseUpdateVerificationTask = requestParameters.sopdVerificationTaskDtoBaseUpdateVerificationTask;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/sopd`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: sopdVerificationTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу ИНН
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdTaxNumberGet(requestParameters: ApiVerificationTaskTaskIdTaxNumberGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<TaxNumberVerificationTaskDto>;
    public apiVerificationTaskTaskIdTaxNumberGet(requestParameters: ApiVerificationTaskTaskIdTaxNumberGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<TaxNumberVerificationTaskDto>>;
    public apiVerificationTaskTaskIdTaxNumberGet(requestParameters: ApiVerificationTaskTaskIdTaxNumberGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<TaxNumberVerificationTaskDto>>;
    public apiVerificationTaskTaskIdTaxNumberGet(requestParameters: ApiVerificationTaskTaskIdTaxNumberGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/taxNumber`;
        return this.httpClient.request<TaxNumberVerificationTaskDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу ИНН
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdTaxNumberPut(requestParameters: ApiVerificationTaskTaskIdTaxNumberPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationTaskTaskIdTaxNumberPut(requestParameters: ApiVerificationTaskTaskIdTaxNumberPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationTaskTaskIdTaxNumberPut(requestParameters: ApiVerificationTaskTaskIdTaxNumberPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationTaskTaskIdTaxNumberPut(requestParameters: ApiVerificationTaskTaskIdTaxNumberPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const taxNumberVerificationTaskDtoBaseUpdateVerificationTask = requestParameters.taxNumberVerificationTaskDtoBaseUpdateVerificationTask;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/taxNumber`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: taxNumberVerificationTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Назначить Задачу на себя
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdTransitionsAssignTaskToMyselfPost(requestParameters: ApiVerificationTaskTaskIdTransitionsAssignTaskToMyselfPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<number>;
    public apiVerificationTaskTaskIdTransitionsAssignTaskToMyselfPost(requestParameters: ApiVerificationTaskTaskIdTransitionsAssignTaskToMyselfPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<number>>;
    public apiVerificationTaskTaskIdTransitionsAssignTaskToMyselfPost(requestParameters: ApiVerificationTaskTaskIdTransitionsAssignTaskToMyselfPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<number>>;
    public apiVerificationTaskTaskIdTransitionsAssignTaskToMyselfPost(requestParameters: ApiVerificationTaskTaskIdTransitionsAssignTaskToMyselfPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/transitions/assignTaskToMyself`;
        return this.httpClient.request<number>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Назначение задачи в статусе Pending на Верификатора
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Verifier.A&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdTransitionsAssignTaskToVerifierVerifierIdPost(requestParameters: ApiVerificationTaskTaskIdTransitionsAssignTaskToVerifierVerifierIdPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<number>;
    public apiVerificationTaskTaskIdTransitionsAssignTaskToVerifierVerifierIdPost(requestParameters: ApiVerificationTaskTaskIdTransitionsAssignTaskToVerifierVerifierIdPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<number>>;
    public apiVerificationTaskTaskIdTransitionsAssignTaskToVerifierVerifierIdPost(requestParameters: ApiVerificationTaskTaskIdTransitionsAssignTaskToVerifierVerifierIdPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<number>>;
    public apiVerificationTaskTaskIdTransitionsAssignTaskToVerifierVerifierIdPost(requestParameters: ApiVerificationTaskTaskIdTransitionsAssignTaskToVerifierVerifierIdPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const verifierId = requestParameters.verifierId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/transitions/assignTaskToVerifier/${this.configuration.encodeParam({name: "verifierId", value: verifierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<number>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Подтвердить/отклонить перевод задачи в статус RedirectedToSupervision
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Supervision.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdTransitionsRedirectedToSupervisionReviewPut(requestParameters: ApiVerificationTaskTaskIdTransitionsRedirectedToSupervisionReviewPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public apiVerificationTaskTaskIdTransitionsRedirectedToSupervisionReviewPut(requestParameters: ApiVerificationTaskTaskIdTransitionsRedirectedToSupervisionReviewPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiVerificationTaskTaskIdTransitionsRedirectedToSupervisionReviewPut(requestParameters: ApiVerificationTaskTaskIdTransitionsRedirectedToSupervisionReviewPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiVerificationTaskTaskIdTransitionsRedirectedToSupervisionReviewPut(requestParameters: ApiVerificationTaskTaskIdTransitionsRedirectedToSupervisionReviewPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const supervisorUpdateTaskRequest = requestParameters.supervisorUpdateTaskRequest;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/transitions/redirectedToSupervision/review`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: supervisorUpdateTaskRequest,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Поставить Задачу на Паузу
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.P&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdUnPausePost(requestParameters: ApiVerificationTaskTaskIdUnPausePostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<number>;
    public apiVerificationTaskTaskIdUnPausePost(requestParameters: ApiVerificationTaskTaskIdUnPausePostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<number>>;
    public apiVerificationTaskTaskIdUnPausePost(requestParameters: ApiVerificationTaskTaskIdUnPausePostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<number>>;
    public apiVerificationTaskTaskIdUnPausePost(requestParameters: ApiVerificationTaskTaskIdUnPausePostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/unPause`;
        return this.httpClient.request<number>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу Информационного письма
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdVehicleInformationLetterGet(requestParameters: ApiVerificationTaskTaskIdVehicleInformationLetterGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<VehicleInformationLetterVerificationTaskDto>;
    public apiVerificationTaskTaskIdVehicleInformationLetterGet(requestParameters: ApiVerificationTaskTaskIdVehicleInformationLetterGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<VehicleInformationLetterVerificationTaskDto>>;
    public apiVerificationTaskTaskIdVehicleInformationLetterGet(requestParameters: ApiVerificationTaskTaskIdVehicleInformationLetterGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<VehicleInformationLetterVerificationTaskDto>>;
    public apiVerificationTaskTaskIdVehicleInformationLetterGet(requestParameters: ApiVerificationTaskTaskIdVehicleInformationLetterGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/vehicleInformationLetter`;
        return this.httpClient.request<VehicleInformationLetterVerificationTaskDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу Информационного письма
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdVehicleInformationLetterPut(requestParameters: ApiVerificationTaskTaskIdVehicleInformationLetterPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationTaskTaskIdVehicleInformationLetterPut(requestParameters: ApiVerificationTaskTaskIdVehicleInformationLetterPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationTaskTaskIdVehicleInformationLetterPut(requestParameters: ApiVerificationTaskTaskIdVehicleInformationLetterPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationTaskTaskIdVehicleInformationLetterPut(requestParameters: ApiVerificationTaskTaskIdVehicleInformationLetterPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const vehicleInformationLetterVerificationTaskDtoBaseUpdateVerificationTask = requestParameters.vehicleInformationLetterVerificationTaskDtoBaseUpdateVerificationTask;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/vehicleInformationLetter`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: vehicleInformationLetterVerificationTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачу ПТС
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdVehiclePassportGet(requestParameters: ApiVerificationTaskTaskIdVehiclePassportGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<VehiclePassportVerificationTaskDto>;
    public apiVerificationTaskTaskIdVehiclePassportGet(requestParameters: ApiVerificationTaskTaskIdVehiclePassportGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<VehiclePassportVerificationTaskDto>>;
    public apiVerificationTaskTaskIdVehiclePassportGet(requestParameters: ApiVerificationTaskTaskIdVehiclePassportGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<VehiclePassportVerificationTaskDto>>;
    public apiVerificationTaskTaskIdVehiclePassportGet(requestParameters: ApiVerificationTaskTaskIdVehiclePassportGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/vehiclePassport`;
        return this.httpClient.request<VehiclePassportVerificationTaskDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить задачу ПТС
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTaskTaskIdVehiclePassportPut(requestParameters: ApiVerificationTaskTaskIdVehiclePassportPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationTaskTaskIdVehiclePassportPut(requestParameters: ApiVerificationTaskTaskIdVehiclePassportPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationTaskTaskIdVehiclePassportPut(requestParameters: ApiVerificationTaskTaskIdVehiclePassportPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationTaskTaskIdVehiclePassportPut(requestParameters: ApiVerificationTaskTaskIdVehiclePassportPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskId = requestParameters.taskId;

        const vehiclePassportVerificationTaskDtoBaseUpdateVerificationTask = requestParameters.vehiclePassportVerificationTaskDtoBaseUpdateVerificationTask;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/task/${this.configuration.encodeParam({name: "taskId", value: taskId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/vehiclePassport`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: vehiclePassportVerificationTaskDtoBaseUpdateVerificationTask,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Удаление задач верификации
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.D&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTasksDeleteDelete(requestParameters: ApiVerificationTasksDeleteDeleteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<number>>;
    public apiVerificationTasksDeleteDelete(requestParameters: ApiVerificationTasksDeleteDeleteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<number>>>;
    public apiVerificationTasksDeleteDelete(requestParameters: ApiVerificationTasksDeleteDeleteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<number>>>;
    public apiVerificationTasksDeleteDelete(requestParameters: ApiVerificationTasksDeleteDeleteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const verificationTaskDeleteRequest = requestParameters.verificationTaskDeleteRequest;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/tasks/delete`;
        return this.httpClient.request<Array<number>>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: verificationTaskDeleteRequest,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Возвращает список признаков фальсификаций по документу с определёнными типом задачи верификации
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTasksFalsificationFlagsForTaskTypeGet(requestParameters: ApiVerificationTasksFalsificationFlagsForTaskTypeGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<DocumentResolutionSettingsDto>>;
    public apiVerificationTasksFalsificationFlagsForTaskTypeGet(requestParameters: ApiVerificationTasksFalsificationFlagsForTaskTypeGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<DocumentResolutionSettingsDto>>>;
    public apiVerificationTasksFalsificationFlagsForTaskTypeGet(requestParameters: ApiVerificationTasksFalsificationFlagsForTaskTypeGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<DocumentResolutionSettingsDto>>>;
    public apiVerificationTasksFalsificationFlagsForTaskTypeGet(requestParameters: ApiVerificationTasksFalsificationFlagsForTaskTypeGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskType = requestParameters.taskType;


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (taskType !== undefined && taskType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>taskType, 'taskType');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/tasks/falsificationFlags/forTaskType`;
        return this.httpClient.request<Array<DocumentResolutionSettingsDto>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Возвращает список признаков фальсификаций по документам для всех видов задач верификации
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTasksFalsificationFlagsGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<VerificationTaskFalsificationFlagsDto>>;
    public apiVerificationTasksFalsificationFlagsGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<VerificationTaskFalsificationFlagsDto>>>;
    public apiVerificationTasksFalsificationFlagsGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<VerificationTaskFalsificationFlagsDto>>>;
    public apiVerificationTasksFalsificationFlagsGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/tasks/falsificationFlags`;
        return this.httpClient.request<Array<VerificationTaskFalsificationFlagsDto>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить задачи с фильтрацией по параметрам запроса
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.L&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTasksGet(requestParameters: ApiVerificationTasksGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<VerificationPagedResultDto>;
    public apiVerificationTasksGet(requestParameters: ApiVerificationTasksGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<VerificationPagedResultDto>>;
    public apiVerificationTasksGet(requestParameters: ApiVerificationTasksGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<VerificationPagedResultDto>>;
    public apiVerificationTasksGet(requestParameters: ApiVerificationTasksGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const page = requestParameters.page;

        const perPage = requestParameters.perPage;

        const sortFieldsOrderBy = requestParameters.sortFieldsOrderBy;

        const sortFieldsFieldBy = requestParameters.sortFieldsFieldBy;

        const filters = requestParameters.filters;


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (perPage !== undefined && perPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>perPage, 'perPage');
        }
        if (sortFieldsOrderBy !== undefined && sortFieldsOrderBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortFieldsOrderBy, 'sortFields.orderBy');
        }
        if (sortFieldsFieldBy !== undefined && sortFieldsFieldBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortFieldsFieldBy, 'sortFields.fieldBy');
        }
        if (filters !== undefined && filters !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filters, 'filters');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/tasks`;
        return this.httpClient.request<VerificationPagedResultDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Отправить повторно все Заявки, которые ранее не отправились
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTasksResendAllFailedPost(requestParameters: ApiVerificationTasksResendAllFailedPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<SendResultDto>>;
    public apiVerificationTasksResendAllFailedPost(requestParameters: ApiVerificationTasksResendAllFailedPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<SendResultDto>>>;
    public apiVerificationTasksResendAllFailedPost(requestParameters: ApiVerificationTasksResendAllFailedPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<SendResultDto>>>;
    public apiVerificationTasksResendAllFailedPost(requestParameters: ApiVerificationTasksResendAllFailedPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const resendRequest = requestParameters.resendRequest;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/tasks/resendAllFailed`;
        return this.httpClient.request<Array<SendResultDto>>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: resendRequest,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Возвращает список вариантов решений по документу с определёнными типом задачи верификации
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTasksResolutionTypesForTaskTypeGet(requestParameters: ApiVerificationTasksResolutionTypesForTaskTypeGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<DocumentResolutionSettingsDto>>;
    public apiVerificationTasksResolutionTypesForTaskTypeGet(requestParameters: ApiVerificationTasksResolutionTypesForTaskTypeGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<DocumentResolutionSettingsDto>>>;
    public apiVerificationTasksResolutionTypesForTaskTypeGet(requestParameters: ApiVerificationTasksResolutionTypesForTaskTypeGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<DocumentResolutionSettingsDto>>>;
    public apiVerificationTasksResolutionTypesForTaskTypeGet(requestParameters: ApiVerificationTasksResolutionTypesForTaskTypeGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const taskType = requestParameters.taskType;


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (taskType !== undefined && taskType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>taskType, 'taskType');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/tasks/resolutionTypes/forTaskType`;
        return this.httpClient.request<Array<DocumentResolutionSettingsDto>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Возвращает список вариантов решений по документам для всех видов задач верификации
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTasksResolutionTypesGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<VerificationTaskResolutionTypesDto>>;
    public apiVerificationTasksResolutionTypesGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<VerificationTaskResolutionTypesDto>>>;
    public apiVerificationTasksResolutionTypesGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<VerificationTaskResolutionTypesDto>>>;
    public apiVerificationTasksResolutionTypesGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/tasks/resolutionTypes`;
        return this.httpClient.request<Array<VerificationTaskResolutionTypesDto>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить количество задач по статусам документа
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.L&lt;/b&gt;&lt;/p&gt;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationTasksStatusesCountGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<VerificationPagedResultDto>;
    public apiVerificationTasksStatusesCountGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<VerificationPagedResultDto>>;
    public apiVerificationTasksStatusesCountGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<VerificationPagedResultDto>>;
    public apiVerificationTasksStatusesCountGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/tasksStatusesCount`;
        return this.httpClient.request<VerificationPagedResultDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить информацию о верификаторе по текущему пользователю
     * &lt;p&gt;Required permission: &lt;b&gt;VER.R&lt;/b&gt;&lt;/p&gt;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationVerifierGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<VerifierInfoDto>;
    public apiVerificationVerifierGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<VerifierInfoDto>>;
    public apiVerificationVerifierGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<VerifierInfoDto>>;
    public apiVerificationVerifierGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/verifier`;
        return this.httpClient.request<VerifierInfoDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить список задач верификатора
     * &lt;p&gt;Required permission: &lt;b&gt;VER.Task.R&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationVerifierIdTaskGet(requestParameters: ApiVerificationVerifierIdTaskGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<VerificationTaskInfoDto>>;
    public apiVerificationVerifierIdTaskGet(requestParameters: ApiVerificationVerifierIdTaskGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<VerificationTaskInfoDto>>>;
    public apiVerificationVerifierIdTaskGet(requestParameters: ApiVerificationVerifierIdTaskGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<VerificationTaskInfoDto>>>;
    public apiVerificationVerifierIdTaskGet(requestParameters: ApiVerificationVerifierIdTaskGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const verifierId = requestParameters.verifierId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/${this.configuration.encodeParam({name: "verifierId", value: verifierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/task`;
        return this.httpClient.request<Array<VerificationTaskInfoDto>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Создание оператора
     * &lt;p&gt;Required permission: &lt;b&gt;VER.C&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationVerifierPost(requestParameters: ApiVerificationVerifierPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationVerifierPost(requestParameters: ApiVerificationVerifierPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationVerifierPost(requestParameters: ApiVerificationVerifierPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationVerifierPost(requestParameters: ApiVerificationVerifierPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const verifierCreateRequest = requestParameters.verifierCreateRequest;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/verifier`;
        return this.httpClient.request<IdInfoDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: verifierCreateRequest,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить информацию о верификаторе
     * &lt;p&gt;Required permission: &lt;b&gt;VER.R&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationVerifierVerifierIdGet(requestParameters: ApiVerificationVerifierVerifierIdGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<VerifierInfoDto>;
    public apiVerificationVerifierVerifierIdGet(requestParameters: ApiVerificationVerifierVerifierIdGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<VerifierInfoDto>>;
    public apiVerificationVerifierVerifierIdGet(requestParameters: ApiVerificationVerifierVerifierIdGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<VerifierInfoDto>>;
    public apiVerificationVerifierVerifierIdGet(requestParameters: ApiVerificationVerifierVerifierIdGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const verifierId = requestParameters.verifierId;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/verifier/${this.configuration.encodeParam({name: "verifierId", value: verifierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<VerifierInfoDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Обновить оператора
     * &lt;p&gt;Required permission: &lt;b&gt;VER.U&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationVerifierVerifierIdPut(requestParameters: ApiVerificationVerifierVerifierIdPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<IdInfoDto>;
    public apiVerificationVerifierVerifierIdPut(requestParameters: ApiVerificationVerifierVerifierIdPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<IdInfoDto>>;
    public apiVerificationVerifierVerifierIdPut(requestParameters: ApiVerificationVerifierVerifierIdPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<IdInfoDto>>;
    public apiVerificationVerifierVerifierIdPut(requestParameters: ApiVerificationVerifierVerifierIdPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const verifierId = requestParameters.verifierId;

        const verifierUpdateRequest = requestParameters.verifierUpdateRequest;


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/Verification/verifier/${this.configuration.encodeParam({name: "verifierId", value: verifierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<IdInfoDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: verifierUpdateRequest,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить список операторов
     * &lt;p&gt;Required permission: &lt;b&gt;VER.L&lt;/b&gt;&lt;/p&gt;
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVerificationVerifiersGet(requestParameters: ApiVerificationVerifiersGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<VerifierInfoDto>>;
    public apiVerificationVerifiersGet(requestParameters: ApiVerificationVerifiersGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<VerifierInfoDto>>>;
    public apiVerificationVerifiersGet(requestParameters: ApiVerificationVerifiersGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<VerifierInfoDto>>>;
    public apiVerificationVerifiersGet(requestParameters: ApiVerificationVerifiersGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const filters = requestParameters.filters;


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (filters !== undefined && filters !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filters, 'filters');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/Verification/verifiers`;
        return this.httpClient.request<Array<VerifierInfoDto>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
