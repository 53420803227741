<div *ngIf="appearance !== 'link' && appearance !== 'hidden'" class="d-inline-flex align-items-center gap-2">
  <fnip-button
    *ngIf="callback || link"
    btnSize="s"
    [appearance]="appearance"
    [icon]="icon"
    [label]="actionLabel"
    [actionCallback$]="callback"
    [btnRouterLink]="link"
    [btnRouterQueryParams]="queryParams"
    [btnLinkTarget]="target"
    [btnHint]="hint"
    [isDisabled]="!!(isDisabled$ | async)"
  />
  <span *ngIf="label">{{ label }}</span>
</div>

<a
  *ngIf="appearance === 'link' && actionLabel && link"
  [routerLink]="link"
  [queryParams]="queryParams"
  [target]="target"
>
  {{ actionLabel }}
</a>
