import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LanguageSwitcherComponent } from '@lib-features/translate';
import {
  IN_APP_NOTIFICATIONS_FEATURE_FLAG,
  NotificationListComponent,
  provideInAppNotificationSource,
} from '@lib-notifications/notification-list';
import { TranslateModule } from '@ngx-translate/core';
import { TuiSidebarModule } from '@taiga-ui/addon-mobile';
import { TuiActiveZoneModule, TuiLetModule } from '@taiga-ui/cdk';
import { TuiTooltipModule } from '@taiga-ui/core';
import { ExecuteWithPipeModule } from '@lib-utils';
import { ButtonModule } from '@lib-widgets/core';
import { provideMortgageNotificationSource } from './notification-source';
import { ToolbarComponent } from './toolbar.component';

const checkInAppNotificationsFeatureFlag = () => {
  const value = localStorage.getItem(IN_APP_NOTIFICATIONS_FEATURE_FLAG);
  return value === 'true';
};

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    ExecuteWithPipeModule,
    NotificationListComponent,
    RouterModule,
    TuiLetModule,
    TuiTooltipModule,
    TuiSidebarModule,
    TuiActiveZoneModule,
    LanguageSwitcherComponent,
    TranslateModule,
  ],
  providers: [
    checkInAppNotificationsFeatureFlag() ? provideInAppNotificationSource() : provideMortgageNotificationSource(),
  ],
  exports: [ToolbarComponent],
  declarations: [ToolbarComponent],
})
export class ToolbarModule {}
