/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BankStatementDeductTaxType } from './bankStatementDeductTaxType';
import { DateOnly } from '@lib-utils';
import { IncomeDocumentMonthlyIncomeVerificationDto } from './incomeDocumentMonthlyIncomeVerificationDto';


export interface IncomeDocumentFileVerificationTaskDto { 
    year?: number | null;
    date?: DateOnly | null;
    organizationName?: string | null;
    organizationTaxNumber?: string | null;
    lastName?: string | null;
    firstName?: string | null;
    middleName?: string | null;
    birthDate?: DateOnly | null;
    position?: string | null;
    fromPreviousJob?: boolean;
    incomes?: Array<IncomeDocumentMonthlyIncomeVerificationDto> | null;
    fileId?: number;
    rate?: number;
    manualTaxAmount?: number;
    employerPhone?: string | null;
    deductTaxType?: BankStatementDeductTaxType;
}
export namespace IncomeDocumentFileVerificationTaskDto {
}


