/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VerificationSupervisionDataDto } from './verificationSupervisionDataDto';
import { SourceType } from './sourceType';
import { DateOnly } from '@lib-utils';
import { EmployerPhoneInfoDto } from './employerPhoneInfoDto';
import { FileInfoDto } from './fileInfoDto';


export interface SopdVerificationTaskDto { 
    source?: SourceType;
    files?: Array<FileInfoDto> | null;
    relevantFiles?: Array<FileInfoDto> | null;
    verificationSupervisionDataList?: Array<VerificationSupervisionDataDto> | null;
    lastName?: string | null;
    firstName?: string | null;
    middleName?: string | null;
    birthdate?: DateOnly | null;
    birthplace?: string | null;
    passportSeries?: string | null;
    passportNumber?: string | null;
    passportIssuedBy?: string | null;
    passportIssueDepCode?: string | null;
    passportIssueDate?: DateOnly;
    registrationAddress?: string | null;
    factualAddress?: string | null;
    phone?: string | null;
    email?: string | null;
    employerInfo?: Array<EmployerPhoneInfoDto> | null;
}
export namespace SopdVerificationTaskDto {
}


