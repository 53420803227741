import { NgModule } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TuiRootModule } from '@taiga-ui/core';
import { SidebarModule } from '@lib-widgets/sidebar';
import { ApplicationRootComponent } from './application-root.component';

@NgModule({
  imports: [TuiRootModule, RouterOutlet, SidebarModule],
  exports: [ApplicationRootComponent],
  declarations: [ApplicationRootComponent],
})
export class ApplicationRootModule {}
