import { inject, NgModule } from '@angular/core';
import { ConfigService } from '@lib-config';
import { MortgageConfiguration, SuggestApiService } from '@lib-mortgage/api';
import { createBearerCredentials } from '@lib-mortgage/features/authorization';
import { FilesRecognitionService } from './files-recognition.service';

@NgModule({
  providers: [
    FilesRecognitionService,

    // // API
    SuggestApiService,
    {
      provide: MortgageConfiguration,
      useFactory: () =>
        new MortgageConfiguration({
          basePath: inject(ConfigService).apiUrl,
          credentials: createBearerCredentials(),
        }),
    },
  ],
})
export class FilesRecognitionModule {}
