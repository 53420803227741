export const VehicleCategory = {
  A: 'A',
  A1: 'A1',
  B: 'B',
  B1: 'B1',
  C: 'C',
  C1: 'C1',
  D: 'D',
  D1: 'D1',
  E: 'E',
} as const;

export type VehicleCategory = (typeof VehicleCategory)[keyof typeof VehicleCategory];
export const VehicleCategoryMap: Record<VehicleCategory, string> = {
  [VehicleCategory.A]: 'A',
  [VehicleCategory.A1]: 'A1',
  [VehicleCategory.B]: 'B',
  [VehicleCategory.B1]: 'B1',
  [VehicleCategory.C]: 'C',
  [VehicleCategory.C1]: 'C1',
  [VehicleCategory.D]: 'D',
  [VehicleCategory.D1]: 'D1',
  [VehicleCategory.E]: 'E',
};
