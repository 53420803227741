import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TuiHintModule, TuiSvgModule } from '@taiga-ui/core';
import { TuiTagModule } from '@taiga-ui/kit';
import { TagListCellComponent } from './tag-list-cell.component';

@NgModule({
  declarations: [TagListCellComponent],
  imports: [CommonModule, TuiTagModule, TuiSvgModule, TuiHintModule],
  exports: [TagListCellComponent],
})
export class TagListCellModule {}
