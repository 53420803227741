import { FileInfo, FromGeneratedEnum } from '@lib-utils';
import {
  ClientType,
  DocumentType,
  DueDiligenceTaskStatus,
  EmploymentDto,
  NonStandardType,
  TaskSource as OldTaskSource,
  PhysicalPersonDto,
  TaskDto,
  VerificationDocumentStatus,
} from '@lib-mortgage/api';

export interface FeEmploymentDto
  extends Omit<EmploymentDto, 'startDate' | 'endWorkingAgreement' | 'startWorkingAgreement' | 'phones'> {
  juristicAddressStr?: string | null;
  factualAddressStr?: string | null;
  startDate?: Date | null;
  endWorkingAgreement?: Date | null;
  startWorkingAgreement?: Date | null;
  isCoborrower?: boolean | null;

  EmploymentHistory?: FileInfo[] | null;
  ElectronicEmploymentHistory?: FileInfo[] | null;
  EmploymentContractMainJob?: FileInfo[] | null;
  EmploymentContract?: FileInfo[] | null;
  EmploymentContract2?: FileInfo[] | null;
  EmploymentContract3?: FileInfo[] | null;
  PensionCertificate?: FileInfo[] | null;
  PFR?: FileInfo[] | null;
  IncomeStatement?: FileInfo[] | null;
  IncomeStatementPartTime?: FileInfo[] | null;
  IncomeStatementPartTime2?: FileInfo[] | null;
  IncomeStatementPartTime3?: FileInfo[] | null;
  IncomeStatementBankFormat?: FileInfo[] | null;
  IncomeStatementBankFormatPartTime?: FileInfo[] | null;
  IncomeStatementBankFormatPartTime2?: FileInfo[] | null;
  IncomeStatementBankFormatPartTime3?: FileInfo[] | null;
  TaxReturn?: FileInfo[] | null;
  TaxReturnPartTime?: FileInfo[] | null;
  TaxReturnPartTime2?: FileInfo[] | null;
  TaxReturnPartTime3?: FileInfo[] | null;
  SelfEmployedCertificate?: FileInfo[] | null;
  SelfEmployedCertificatePartTime?: FileInfo[] | null;
  SelfEmployedCertificatePartTime2?: FileInfo[] | null;
  SelfEmployedCertificatePartTime3?: FileInfo[] | null;
  ProfessionalIncomeTaxCertificate?: FileInfo[] | null;
  ProfessionalIncomeTaxCertificatePartTime?: FileInfo[] | null;
  ProfessionalIncomeTaxCertificatePartTime2?: FileInfo[] | null;
  ProfessionalIncomeTaxCertificatePartTime3?: FileInfo[] | null;
  OtherMainJob?: FileInfo[] | null;
  OtherPartTime?: FileInfo[] | null;
  OtherPartTime2?: FileInfo[] | null;
  OtherPartTime3?: FileInfo[] | null;
  AccountStatementMainJob?: FileInfo[] | null;
  AccountStatementPartTime?: FileInfo[] | null;
  AccountPartStatementTime2?: FileInfo[] | null;
  AccountPartStatementTime3?: FileInfo[] | null;
}

// TODO: интерфейсы копируют интерфейсы бэка
export type VerificationAllDocumentStatus = VerificationDocumentStatus | 'AllTasks' | 'MyTasks';
export const VerificationAllDocumentStatus = {
  ...VerificationDocumentStatus,
  AllTasks: 'AllTasks' as VerificationAllDocumentStatus,
  MyTasks: 'MyTasks' as VerificationAllDocumentStatus,
};

export type VerificationGeneralTaskType = 'All' | 'Document' | 'Call';
export const VerificationGeneralTaskType = {
  All: 'All' as VerificationGeneralTaskType,
  Document: 'Document' as VerificationGeneralTaskType,
  Call: 'Call' as VerificationGeneralTaskType,
};

export const ClientTypeMap: FromGeneratedEnum<ClientType> = {
  [ClientType.Borrower]: 'Заемщик',
  [ClientType.Coborrower]: 'Созаемщик',
  [ClientType.Guarantor]: 'Поручитель',
};

export const DueDiligenceTaskStatusMap: Record<DueDiligenceTaskStatus, string> = {
  Pending: 'В ожидании',
  Active: 'В работе',
  Processed: 'Обработано',
  ForRevision: 'На доработку',
  Rejected: 'Отказ',
};

export const NonStandardTypeMap = new Map<NonStandardType, string>([
  [NonStandardType.HouseWithLand, 'Дом с земельным участком'],
  [NonStandardType.Other, 'Другое'],
]);

// Интерфейс, обслуживающий форму задач верификатора и ААА, основанный на ManualReviewClientInfoDto | VerificationClientDto
export interface FeApplicationTaskDto {
  clientId?: number;
  fullName?: string;
  tasks?: FeTaskDto[];
}

//TODO: После полноценного выноса верификатора в отдельное приложение, удалить `OldTaskSource`.
// Наследоваться только от актуального `TaskDto`
export interface FeTaskDto extends Omit<TaskDto, 'source'> {
  source?: OldTaskSource;
}

export interface ClientIdName {
  fullName: string;
  clientId: number;
  physicalPerson?: PhysicalPersonDto;
  role?: string;
}

export const PREVIOUS_PASSPORT_TYPES = [
  DocumentType.PreviousPassportRu,
  DocumentType.PreviousPassportRu2,
  DocumentType.PreviousPassportRu3,
];
