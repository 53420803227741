import { FromGeneratedEnum } from '@lib-utils';
import { IncomeSource } from '@lib-mortgage/api';

export const IncomeSourceMap: FromGeneratedEnum<IncomeSource> = {
  [IncomeSource.Form]: 'Заявленный доход',
  [IncomeSource.HeadHunter]: 'Доход по HH.RU',
  [IncomeSource.Underwriter]: 'Подтверждено андеррайтером',
  [IncomeSource.Authorizer]: 'Подтверждено авторизатором',
  [IncomeSource.Document]: 'Доход по справке',
  [IncomeSource.Pfr]: 'Доход по выписке из ПФР',
};
