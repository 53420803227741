/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PersonDto } from './personDto';
import { DateOnly } from '@lib-utils';


export interface DriverLicenseDto { 
    series: string | null;
    number: string | null;
    issuedOn: DateOnly;
    expireDate?: DateOnly | null;
    issuedBy?: string | null;
    person?: PersonDto;
}

