<tui-block-status *ngIf="getConfig | executeWith as config">
  <tui-svg
    tuiSlot="top"
    [src]="config.icon ?? 'tuiIconAlertCircle'"
    [class]="config.iconClass ?? 'color-red font-size-32'"
  />
  <h4 class="my-3">{{ config.title ?? 'У Вас недостаточно прав для доступа к этой странице' }}</h4>
  <p>{{ config.message ?? 'Обратитесь к администратору' }}</p>

  @if (config.btnHref && config.btnLabel) {
    <fnip-button class="my-3" [label]="config.btnLabel" appearance="primary" btnSize="l" [btnHref]="config.btnHref" />
  }
</tui-block-status>
