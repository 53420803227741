<div *ngIf="appearance === 'default'; else horizontalTempl" class="file" [class.file_pending]="isPending">
  <tui-svg
    *ngIf="getStateIcon | executeWith: state as icon"
    class="file__icon file__icon_state"
    [className]="'file__icon_' + state"
    [src]="icon"
  />

  <fnip-button
    *ngIf="allowPreview; else fileIconTempl"
    appearance="icon"
    btnSize="s"
    class="file__btn file__btn_preview"
    icon="tuiIconEyeLarge"
    tuiHintAppearance="onDark"
    [class.file__btn_loading]="isPreviewLoading"
    [actionCallback$]="onFilePreview"
    [tuiHint]="previewHint"
    [tuiHintManual]="allowPreview && (isMouseOnEye || isMouseOnTooltip)"
    (mouseenter)="changeMouseFlag('eye', true)"
    (mouseleave)="changeMouseFlag('eye', false)"
  />

  <ng-template #fileIconTempl>
    <img
      *ngIf="thumbnailSrc; else iconTemplate"
      [src]="thumbnailSrc"
      [width]="32"
      [height]="32"
      [style.object-fit]="'contain'"
    />
    <ng-template #iconTemplate>
      @if (!disableIcon) {
        <tui-svg src="tuiIconFileLarge" class="file__icon file__icon_file" />
      }
    </ng-template>
  </ng-template>

  <ng-template #previewHint>
    <div (mouseenter)="changeMouseFlag('tooltip', true)" (mouseleave)="changeMouseFlag('tooltip', false)">
      <ng-container *ngIf="onFilePreview | executeWith: false" />
      <fnip-loader [isLoading]="isPreviewLoading" [showContent]="!!cacheBlob">
        <div *tuiLet="isFilePdf | executeWith: { name, cacheBlob } as isPdf" class="file__preview">
          <ng-container *tuiLet="getPreviewFileUrl | executeWith: { cacheBlob } : sanitizer : true as fileUrl">
            <img *ngIf="!isPdf" class="file__preview__content" [src]="fileUrl" [alt]="name" />
            <iframe *ngIf="isPdf" class="file__preview__content" [src]="fileUrl" [title]="name"></iframe>
          </ng-container>

          <fnip-button
            appearance="secondary-light-grey"
            btnSize="s"
            label="Полный предпросмотр"
            [actionCallback$]="openFullPreview"
          />
        </div>
      </fnip-loader>
    </div>
  </ng-template>

  <fnip-button
    *ngIf="allowDownload"
    appearance="icon"
    btnSize="s"
    class="file__btn"
    icon="tuiIconDownloadLarge"
    [actionCallback$]="onFileDownload"
  />

  @if (allowSignature && !signatureFile) {
    <fnip-button
      btnHint="Загрузите ЭП документа"
      appearance="flat"
      btnSize="s"
      icon="tuiIconPlus"
      label="SIG"
      [actionCallback$]="onFileSignatureUpload"
    />
  }

  <span class="file__title" [title]="name">{{ name }}</span>

  <span *ngIf="rejectedReason" class="file_rejected-reason">{{ rejectedReason }}</span>

  <span *ngIf="state === 'uploading' && !!progress; else sizeTempl" class="file__progress">
    {{ formatFileProgress | executeWith: progress }}%
  </span>

  <ng-template #sizeTempl>
    <span *ngIf="showSize" class="file__size">{{ getReadableSize | executeWith: size }}</span>
  </ng-template>

  <fnip-button
    *ngIf="allowRemove"
    appearance="icon"
    btnSize="s"
    icon="tuiIconCloseLarge"
    class="file__btn file__btn_remove"
    [actionCallback$]="onFileRemove"
  />
</div>

@if (appearance === 'default' && allowSignature && signatureFile) {
  <div class="pl-10">
    <ng-container *ngTemplateOutlet="signatureTempl"></ng-container>
  </div>
}

<ng-template #horizontalTempl>
  <div class="file">
    <div class="file__details">
      <div class="thumbnail">
        <img *ngIf="thumbnailSrc; else iconTemplate" [src]="thumbnailSrc" />
        <ng-template #iconTemplate>
          <tui-svg *ngIf="getThumbnailIcon | executeWith: name : state as src" class="thumbnail__icon" [src]="src" />
        </ng-template>
      </div>

      <div *ngIf="allowPreview || allowDownload" class="actions">
        <div *ngIf="allowPreview" class="action action_preview" (click)="onFilePreview()">
          <tui-svg class="action__icon" [src]="horizontalPreviewIcon" />
          <span class="action__name">Просмотреть</span>
        </div>

        <div *ngIf="allowDownload" class="action action_download" (click)="onFileDownload()">
          <tui-svg class="action__icon" [src]="horizontalDownloadIcon" />
          <span class="action__name">Скачать</span>
        </div>
      </div>

      <tui-hosted-dropdown class="file__details__more-btn" [content]="contentTemplate">
        <fnip-button appearance="icon" btnSize="s" icon="tuiIconMoreVertical" />

        <ng-template #contentTemplate>
          <div class="d-flex flex-column p-1">
            <fnip-button
              *ngIf="allowRemove"
              appearance="flat"
              btnSize="s"
              icon="tuiIconCloseLarge"
              label="Удалить файл"
              [actionCallback$]="onFileRemove"
            />

            @if (allowSignature) {
              @if (!signatureFile) {
                <fnip-button
                  appearance="flat"
                  btnSize="s"
                  icon="tuiIconPenTool"
                  label="Добавить ЭП документа"
                  [actionCallback$]="onFileSignatureUpload"
                />
              } @else {
                <div class="mt-2">
                  <fnip-divider />
                  <p class="font-weight-bold mt-2 ml-2">Файл подписи</p>
                  <ng-container *ngTemplateOutlet="signatureTempl"></ng-container>
                </div>
              }
            }
          </div>
        </ng-template>
      </tui-hosted-dropdown>
    </div>

    <span class="file__title" [title]="name">{{ name }}</span>
  </div>
</ng-template>

<ng-template #signatureTempl>
  @if (signatureFile) {
    <fnip-file
      class="flex-1"
      disableIcon
      [state]="signatureFile.state"
      [name]="signatureFile.name"
      [contentType]="signatureFile.contentType"
      [rejectedReason]="signatureFile.rejectedReason"
      [progress]="signatureFile.progress"
      [isPending]="signatureFile.isPending"
      [isPreviewLoading]="signatureFile.isPreviewLoading"
      [cacheBlob]="signatureFile.cacheBlob"
      [size]="signatureFile.size"
      [thumbnailSrc]="signatureFile.thumbnailSrc"
      [showSize]="showSize"
      [allowDownload]="allowDownload && !!signatureFile.id"
      [allowRemove]="allowRemove || !signatureFile.id"
      (fileDownload)="fileSignatureDownload.emit()"
      (fileRemove)="fileSignatureRemove.emit()"
    />
  }
</ng-template>
