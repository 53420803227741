<div class="h-100 d-flex flex-column">
  <fnip-toolbar application="Кабинет верификатора" [logoutCallBack$]="logout$">
    @if ([RoleType.Verificator, RoleType.VerifierSupervisor, RoleType.VerifierHead] | hasRoles) {
      <fnip-verificator-status
        class="align-self-center"
        [verificator$]="verificator$"
        [updateStatus$]="updateStatus$"
      />
    }
  </fnip-toolbar>

  <fnip-breadcrumb class="py-5 pl-10" [hostUrl]="hostUrl" />

  <div class="flex-1 px-10">
    <router-outlet />
  </div>

  <fnip-privacy-policy appName="Верификатор" class="mt-auto pt-6" [version]="verificationVersion" />
</div>
