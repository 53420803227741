import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'fnip-checkbox-cell',
  templateUrl: './checkbox-cell.component.html',
  styleUrls: ['./checkbox-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxCellComponent implements ICellRendererAngularComp {
  value = false;

  agInit(params: ICellRendererParams) {
    this.refreshView(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.refreshView(params);
    return true;
  }

  private refreshView(params: ICellRendererParams) {
    this.value = !!params.value;
  }
}
