import { TuiDay } from '@taiga-ui/cdk';
import { FileInfo } from '@lib-utils';
import { DealDocumentsDto, FileType } from '@lib-mortgage/api';

export interface FeDealDocumentsDto
  extends Omit<
    DealDocumentsDto,
    'agreementDate' | 'dealAgreementDate' | 'letterOfCreditOpeningDate' | 'letterOfCreditEndDate' | 'dealDocumentFiles'
  > {
  agreementDate?: TuiDay;
  dealAgreementDate?: TuiDay;
  letterOfCreditOpeningDate?: TuiDay;
  letterOfCreditEndDate?: TuiDay;
}

export type FeDealDocumentsFilesDto = Partial<Record<FileType, FileInfo<number>[]>>;
