import { APP_INITIALIZER, ErrorHandler, inject, ModuleWithProviders, NgModule } from '@angular/core';
import { Router, TitleStrategy } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalErrorHandler, LoggerService } from '@lib-utils';
import { CLEAR_TITLE_DESCRIPTION_ON_NAVIGATE_FACTORY, OctasoftTitleStrategy } from '../title-strategy';
import { FNIP_APP_NAME } from './app-name.token';
import { ConfigService } from './config.service';
import { getCurrentEnv } from './utils';

@NgModule()
export class InitConfigModule {
  static forRoot(appName: string, appVersion: string): ModuleWithProviders<InitConfigModule> {
    return {
      ngModule: InitConfigModule,
      providers: [
        { provide: FNIP_APP_NAME, useValue: appName },
        { provide: TitleStrategy, useClass: OctasoftTitleStrategy },
        { provide: APP_INITIALIZER, useFactory: CLEAR_TITLE_DESCRIPTION_ON_NAVIGATE_FACTORY, multi: true },
        {
          multi: true,
          provide: APP_INITIALIZER,
          deps: [TitleStrategy, Router],
          useFactory: (titleStrategy: OctasoftTitleStrategy, router: Router) => {
            const translateService = inject(TranslateService, { optional: true });
            return () =>
              translateService?.onLangChange.subscribe(() => {
                titleStrategy.updateTitle(router.routerState.snapshot);
              });
          },
        },
        {
          provide: APP_INITIALIZER,
          useFactory: (configService: ConfigService, loggerService: LoggerService) => () => {
            // ставим favicon в зависимости от стенда
            const link = document.querySelector(`link[rel*='icon']`) as HTMLLinkElement;
            if (link) {
              link.type = 'image/x-icon';
              link.rel = 'shortcut icon';
              link.href = `/assets/icons/logo_${getCurrentEnv(window.location.hostname)}.svg`;
              document.getElementsByTagName('head')[0].appendChild(link);
            }

            if (!configService.logCollectorUrl) return;
            loggerService.initialize({
              url: configService.logCollectorUrl,
              apiKey: configService.logCollectorKey,
              app: {
                name: appName,
                version: appVersion,
                environment: configService.currentEnv,
              },
            });
          },
          deps: [ConfigService, LoggerService],
          multi: true,
        },
        {
          provide: ErrorHandler,
          useClass: GlobalErrorHandler,
        },
      ],
    };
  }
}
