/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type StaffNumber = 'LessThan10' | 'Between10And50' | 'Between50And100' | 'Between100And200' | 'Between200And500' | 'MoreThan500' | 'NoData';

export const StaffNumber = {
    LessThan10: 'LessThan10' as StaffNumber,
    Between10And50: 'Between10And50' as StaffNumber,
    Between50And100: 'Between50And100' as StaffNumber,
    Between100And200: 'Between100And200' as StaffNumber,
    Between200And500: 'Between200And500' as StaffNumber,
    MoreThan500: 'MoreThan500' as StaffNumber,
    NoData: 'NoData' as StaffNumber
};

