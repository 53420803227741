/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InitialPaymentType } from './initialPaymentType';
import { CalculationType } from './calculationType';
import { ManualPti } from './manualPti';
import { AuthorizerClientCalculationRequest } from './authorizerClientCalculationRequest';


export interface AuthorizerCalculationRequest { 
    calculationType?: CalculationType;
    calculationValue?: number;
    initialPaymentOwnFundsType?: InitialPaymentType;
    initialPaymentOwnFunds?: number;
    creditPeriod?: number;
    manualPti?: ManualPti;
    manualCreditMax?: number | null;
    clients?: Array<AuthorizerClientCalculationRequest> | null;
}
export namespace AuthorizerCalculationRequest {
}


