/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type VerificationStatus = 'Pending' | 'ForRevision' | 'Processed' | 'ApplicationArchived' | 'Archived' | 'RedirectedToSupervisor' | 'Postponed' | 'Cancel' | 'Pause';

export const VerificationStatus = {
    Pending: 'Pending' as VerificationStatus,
    ForRevision: 'ForRevision' as VerificationStatus,
    Processed: 'Processed' as VerificationStatus,
    ApplicationArchived: 'ApplicationArchived' as VerificationStatus,
    Archived: 'Archived' as VerificationStatus,
    RedirectedToSupervisor: 'RedirectedToSupervisor' as VerificationStatus,
    Postponed: 'Postponed' as VerificationStatus,
    Cancel: 'Cancel' as VerificationStatus,
    Pause: 'Pause' as VerificationStatus
};

