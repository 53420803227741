/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PhoneSource = 'Anketa' | 'Spark' | 'Verus' | 'Grifid' | 'Documents';

export const PhoneSource = {
    Anketa: 'Anketa' as PhoneSource,
    Spark: 'Spark' as PhoneSource,
    Verus: 'Verus' as PhoneSource,
    Grifid: 'Grifid' as PhoneSource,
    Documents: 'Documents' as PhoneSource
};

