@if (breadcrumbs$ | async; as breadcrumbs) {
  <fnip-button
    appearance="link-primary"
    class="wrapper-font-w400"
    data-testid="host-breadcrumb"
    [isDisabled]="false"
    [label]="hasTranslations() ? ('BASE.BREADCRUMBS.UNIFIED_SECTION' | translate) : 'Единый кабинет'"
    [btnHref]="hostUrl()"
  />

  @if (breadcrumbs.length) {
    <span [style.font-size.px]="15">/</span>
  }

  @for (breadcrumb of breadcrumbs; track $index; let last = $last) {
    <fnip-button
      appearance="link-primary"
      class="wrapper-font-w400"
      [isDisabled]="last"
      [label]="hasTranslations() ? (breadcrumb?.title ?? '' | translate) : breadcrumb.title"
      [btnRouterLink]="breadcrumb.url"
    />

    @if (!last) {
      <span [style.font-size.px]="15">/</span>
    }
  }
}
