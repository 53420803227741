import { HttpErrorResponse } from '@angular/common/http';
import { DestroyRef, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import isNil from 'lodash-es/isNil';
import {
  BehaviorSubject,
  exhaustMap,
  filter,
  finalize,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
  takeWhile,
  timer,
} from 'rxjs';
import { tap } from 'rxjs/operators';
import { NotificationService, Nullable } from '@lib-utils';
import {
  ClientFileInfoDto,
  FileInfoDto,
  FileType,
  RecognitionDto,
  RecognitionJobTaskDto,
  SuggestApiService,
} from '@lib-mortgage/api';

export interface FilesRecognitionResult {
  recognitionInfo?: RecognitionDto;
  fileType?: FileType;
  errorCode: string | null;
  recognitionJobTaskId?: number;
  fileId?: number;
  clientId: number;
}

export interface ComplexRecognitionResult {
  inProgress: Nullable<boolean>;
  files: FileInfoDto[];
  name: string;
}

const emptyComplexRecognition = { inProgress: null, files: [], name: '' };

/**
 * Сервис распознавания файлов.
 */
@Injectable()
export class FilesRecognitionService {
  recognitionResult$ = new Subject<FilesRecognitionResult>();

  complexRecognition$ = new BehaviorSubject<ComplexRecognitionResult>(emptyComplexRecognition);

  activeRecognitionJobTasks$ = new BehaviorSubject<RecognitionJobTaskDto[]>([]);

  stopRecognitionByFileId$ = new Subject<number>();

  recognitionEnabledForCurrentUser$ = new BehaviorSubject<Nullable<boolean>>(null);

  // Типы файлов, которые нужно распознавать.
  recognitionTypes = [
    FileType.PassportFirstPage,
    FileType.PassportRegistrationPage,
    FileType.Passport18And19Page,
    FileType.Passport6And7Page,
    FileType.Passport8And9Page,
    FileType.Passport10And11Page,
    FileType.Passport12And13Page,
    FileType.Inn,
    FileType.IncomeStatement,
    FileType.IncomeStatementPartTime,
    FileType.IncomeStatementPartTime2,
    FileType.IncomeStatementPartTime3,
    FileType.IncomeStatementBankFormat,
    FileType.IncomeStatementBankFormatPartTime,
    FileType.IncomeStatementBankFormatPartTime2,
    FileType.IncomeStatementBankFormatPartTime3,
    FileType.Archive,
    FileType.ComplexPdf,
    FileType.EmploymentHistory,
  ];

  readonly employmentRecignitionTypes: FileType[] = [
    FileType.IncomeStatement,
    FileType.IncomeStatementPartTime,
    FileType.IncomeStatementPartTime2,
    FileType.IncomeStatementPartTime3,
    FileType.IncomeStatementBankFormat,
    FileType.IncomeStatementBankFormatPartTime,
    FileType.IncomeStatementBankFormatPartTime2,
    FileType.IncomeStatementBankFormatPartTime3,
    FileType.EmploymentHistory,
  ];

  constructor(
    private readonly suggestApiService: SuggestApiService,
    private readonly notificationService: NotificationService,
    private readonly destroyRef: DestroyRef,
  ) {}

  reInitSubjects() {
    this.activeRecognitionJobTasks$.value.forEach((t) => {
      this.stopRecognitionByFileId$.next(t.fileId!);
    });
    this.activeRecognitionJobTasks$.next([]);
    this.complexRecognition$.next(emptyComplexRecognition);
    this.recognitionResult$.unsubscribe();

    this.recognitionResult$ = new Subject<{
      recognitionInfo?: RecognitionDto;
      fileType?: FileType;
      errorCode: string | null;
      recognitionJobTaskId?: number;
      fileId?: number;
      clientId: number;
    }>();
  }

  /**
   * Запустить распознавание
   * @param applicationId OrderId
   * @param fileInfos Информация о загруженных файлах.
   * @param clientId  ClientId.
   */
  startRecognition(applicationId: number, fileInfos: ClientFileInfoDto[], clientId: number) {
    fileInfos = fileInfos.filter((fileInfo) => fileInfo.type && this.recognitionTypes.includes(fileInfo.type));
    return fileInfos.length ? this.execute(fileInfos, applicationId, clientId) : of([]);
  }

  /**
   * Продолжить распознавание (например, после перезагрузки страницы)
   * @param fileInfo Информация о загруженном файле.
   * @param recognitionTaskId Идентификатор активной задачи распознавания на сервере.
   * @param clientId Идентификатор клиента.
   * @returns
   */
  continueRecognition(fileInfo: ClientFileInfoDto, recognitionTaskId: number, clientId: number): void {
    if (!fileInfo.type || !fileInfo.id) return;
    this.continue(recognitionTaskId, fileInfo.type, fileInfo.id, clientId);
  }

  stopRecognition(fileId: number) {
    return this.suggestApiService
      .apiSuggestRecognitionStopPost(fileId)
      .pipe(tap(() => this.stopRecognitionByFileId$.next(fileId)));
  }

  // Запуск задачи на распознавание.
  private execute(fileInfo: ClientFileInfoDto[], applicationId: number, clientId: number) {
    if (isNil(this.recognitionEnabledForCurrentUser$.value)) {
      throw new Error('recognition status for current user is undefined');
    }
    if (!this.recognitionEnabledForCurrentUser$.value) return of([]);
    // 1. Поставить задачу на распознавание.
    return this.suggestApiService.apiSuggestUploadClientFilesForRecognitionPost(applicationId, fileInfo).pipe(
      map((response) => response?.data ?? []),
      // 2. Опрашивать сервер по результатам распознавания.
      tap((jobTasks) => {
        this.activeRecognitionJobTasks$.next([...this.activeRecognitionJobTasks$.value, ...jobTasks]);
        jobTasks.forEach((jobTask) => {
          if (jobTask.id && jobTask.fileType && jobTask.fileId) {
            this.continue(jobTask.id, jobTask.fileType, jobTask.fileId, clientId);
          }
        });
      }),
      tap(this.notificationService.onError()),
      tap({ error: () => this.onError(clientId, 'Ошибка распознавания') }),
    );
  }

  // Продолжение распознавания.
  private continue(recognitionTaskId: number, fileType: FileType, fileId: number, clientId: number) {
    this.serverPolling(recognitionTaskId, fileType, fileId)
      .pipe(
        switchMap((response) =>
          response.recognitionInfo
            ? this.suggestApiService
                .apiSuggestRecognitionValidateResultPost({
                  jobTaskIdToValidate: recognitionTaskId,
                  clientId,
                })
                .pipe(map(() => response))
            : of(response),
        ),
        tap((response) => this.onSuccess(clientId, response, recognitionTaskId)),
        tap(this.notificationService.onError()),
        tap({
          error: (error: HttpErrorResponse) =>
            this.onError(clientId, error.error.code, recognitionTaskId, fileType, fileId),
        }),
        takeUntilDestroyed(this.destroyRef),
        finalize(() => {
          this.activeRecognitionJobTasks$.next(
            this.activeRecognitionJobTasks$.value.filter((value) => value.id !== recognitionTaskId),
          );
        }),
      )
      .subscribe();
  }

  // Опрос сервера.
  private serverPolling(recognitionTaskId: number, fileType: FileType, fileId: number) {
    return timer(100, 5000).pipe(
      takeUntil(this.stopRecognitionByFileId$.pipe(filter((stopFileId) => stopFileId === fileId))),
      exhaustMap(() => this.suggestApiService.apiSuggestRecognitionGet(recognitionTaskId)),
      map((r) => r.data!),
      takeWhile((response) => !response, true),
      map((response) => ({ recognitionInfo: response, fileType, fileId })),
    );
  }

  private onSuccess(
    clientId: number,
    response: { recognitionInfo: RecognitionDto; fileType: FileType; fileId: number },
    recognitionJobTaskId: number,
  ) {
    if (isNil(response.recognitionInfo)) return;
    this.recognitionResult$.next({
      recognitionInfo: response.recognitionInfo,
      fileType: response.fileType,
      errorCode: null,
      recognitionJobTaskId,
      fileId: response.fileId,
      clientId,
    });
  }

  private onError(
    clientId: number,
    errorCode: string | null,
    recognitionJobTaskId?: number,
    fileType?: FileType,
    fileId?: number,
  ) {
    const code = errorCode ?? 'Непредвиденная ошибка';
    this.recognitionResult$.next({
      recognitionInfo: undefined,
      fileType,
      errorCode: code,
      recognitionJobTaskId,
      fileId,
      clientId,
    });
  }

  checkActiveRecognition(fileInfos: Nullable<ClientFileInfoDto[]>, clientId: number) {
    if (fileInfos?.length) {
      this.getRunningRecognitionTasks(fileInfos.map((x) => x.id!)).subscribe((recognitionTasks) => {
        this.activeRecognitionJobTasks$.next([...this.activeRecognitionJobTasks$.value, ...recognitionTasks]);
        recognitionTasks.forEach((x) => {
          const fileInfo = fileInfos.find((f) => f.id === x.fileId);
          if (fileInfo) this.continueRecognition(fileInfo, x.id!, clientId);
          if (fileInfo?.type === FileType.Archive || fileInfo?.type === FileType.ComplexPdf)
            this.complexRecognition$.next({
              inProgress: true,
              files: [],
              name: fileInfo.name || '',
            });
        });
      });
    }
  }

  getRunningRecognitionTasks(fileIds: Nullable<number[]>): Observable<RecognitionJobTaskDto[]> {
    return fileIds?.length
      ? this.suggestApiService.apiSuggestGetRunningRecognitionTasksGet(fileIds.join(',')).pipe(
          map((r) => r.data!),
          takeUntilDestroyed(this.destroyRef),
        )
      : of([]);
  }
}
