import { RoleType } from '@lib-utils';
import { ApplicationModulesMeta } from '@lib-mortgage/widgets/default';

export const ApplicationModules = {
  Admin: 'Admin',
  Orders: 'Orders',
  Authorizer: 'Authorizer',
  Underwriter: 'Underwriter',
  Lawyer: 'Lawyer',
  DealDocumentsVerificator: 'DealDocumentsVerificator',
  Gozo: 'Gozo',
} as const;

export type ApplicationModules = (typeof ApplicationModules)[keyof typeof ApplicationModules];

const LAWYER_SPECIAL_ROLES: RoleType[] = [RoleType.Lawyer, RoleType.LawyerSupervisor];

export const ApplicationModulesMetaMap: Record<ApplicationModules, ApplicationModulesMeta> = {
  [ApplicationModules.Admin]: {
    name: 'Кабинет администратора',
    link: 'admin',
    description:
      'Настройка параметров калькулятора, создание и редактирование орг.структуры, ' +
      'ролевой модели и страховых продуктов',
    permissionOperation: 'AdminModuleAvailable',
  },
  [ApplicationModules.Orders]: {
    name: 'Кабинет заявок',
    link: 'orders',
    description: 'Создание заявок, расчёт параметров кредита',
    permissionOperation: 'ordersModuleAvailable',
  },
  [ApplicationModules.Authorizer]: {
    name: 'Кабинет авторизатора',
    link: 'authorizer',
    description: 'Авторизация',
    permissionOperation: 'AuthorizerModuleAvailable',
  },
  [ApplicationModules.Underwriter]: {
    name: 'Кабинет андеррайтера',
    link: 'underwriter',
    description: 'Андеррайтинг',
    permissionOperation: 'UnderwriterModuleAvailable',
  },
  [ApplicationModules.Lawyer]: {
    getName: ({ hasRoles }) => (hasRoles(LAWYER_SPECIAL_ROLES) ? 'Кабинет Юриста' : 'Кабинет УРЗ'),
    link: 'lawyer',
    description: 'Управление по работе с залогами',
    permissionOperation: 'LawyerModuleAvailable',
  },
  [ApplicationModules.DealDocumentsVerificator]: {
    name: 'Кабинет верификации сделки и выдачи',
    link: 'deal-documents-verificator',
    description: 'Верификация документов по сделке и выдачи',
    permissionOperation: 'DealDocumentsVerificatorModuleAvailable',
  },
  [ApplicationModules.Gozo]: {
    name: 'Кабинет ГОЗО',
    description: '',
    permissionOperation: null,
  },
};
