import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TuiSvgModule } from '@taiga-ui/core';
import { TuiBlockStatusModule } from '@taiga-ui/layout';
import { ExecuteWithPipeModule } from '@lib-utils';
import { ButtonModule } from '../button';
import { ErrorPageService } from './services';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'fnip-error-page',
  templateUrl: 'error-page.component.html',
  styleUrls: ['error-page.component.scss'],
  standalone: true,
  imports: [TuiBlockStatusModule, NgIf, TuiSvgModule, ExecuteWithPipeModule, ButtonModule],
})
export class ErrorPageComponent {
  readonly errorPageService = inject(ErrorPageService);
  readonly router = inject(Router);

  getConfig = () => {
    if (!this.errorPageService.config) this.router.navigate(['/']);
    return this.errorPageService.config;
  };
}
