/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface VerificationTasksFilterTypeFilterValuesRequestFilters { 
    TaskTypes?: string;
    DocumentStatuses?: string;
    VerifierIds?: string;
    From?: string;
    To?: string;
    FirstName?: string;
    MiddleName?: string;
    LastName?: string;
    TaskId?: string;
    ApplicationId?: string;
    ClientId?: string;
    GeneralTaskType?: string;
    Sources?: string;
    MyTasks?: string;
}

