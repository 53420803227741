/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UpdateOwnerNominalAccountOnPrepareForDealStateRequest } from './updateOwnerNominalAccountOnPrepareForDealStateRequest';
import { PaymentType } from './paymentType';
import { CreateOrUpdateOwnerRequisitesRequest } from './createOrUpdateOwnerRequisitesRequest';


export interface UpdateFirstPaymentInfoOnPrepareForDealStateRequest { 
    id?: number | null;
    paymentType: PaymentType;
    paymentSum: number;
    requisites?: CreateOrUpdateOwnerRequisitesRequest;
    nominalAccount?: UpdateOwnerNominalAccountOnPrepareForDealStateRequest;
}
export namespace UpdateFirstPaymentInfoOnPrepareForDealStateRequest {
}


