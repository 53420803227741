import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, DestroyRef, ElementRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TUI_SWIPE_OPTIONS, TuiDialog, TuiSwipeService } from '@taiga-ui/cdk';
import { TuiAlertOptions } from '@taiga-ui/core';

/**
 * TODO: Убрать после перехода на 4.x версию Taiga UI
 * Внутри @taiga-ui/cdk еще смотрит на PolymorpheusComponent из @tinkoff/ng-polymorpheus
 */
// eslint-disable-next-line import/no-extraneous-dependencies
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { filter } from 'rxjs';

@Component({
  standalone: true,
  selector: 'fnip-notification',
  template: `<p [innerHTML]="context.data"></p>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent<I, O> implements AfterViewInit {
  document = inject<Document>(DOCUMENT);
  swipeOptions = inject(TUI_SWIPE_OPTIONS);
  elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
  context = inject<TuiDialog<TuiAlertOptions<I>, O>>(POLYMORPHEUS_CONTEXT);
  destroyRef = inject(DestroyRef);

  ngAfterViewInit() {
    /** Ищем tui-notification в DOM, чтобы подписаться на событие swipe по всему алерту */
    const tuiNotificationElement = this.elementRef.nativeElement.parentElement?.parentElement?.parentElement;
    if (!tuiNotificationElement) return;

    const tuiNotificationElementRef = new ElementRef(tuiNotificationElement);
    const swipeService = new TuiSwipeService(tuiNotificationElementRef, this.swipeOptions, this.document);

    swipeService
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        /** Закрываем алерт при смахивании влево */
        filter((event) => event.direction === 'right'),
      )
      .subscribe(() => this.context.$implicit.complete());
  }
}
