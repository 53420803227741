import { Params } from '@angular/router';
import { TuiAppearance } from '@taiga-ui/core';
import { Observable } from 'rxjs';
import { LinkTarget, Nullable } from '@lib-utils';
import { CustomActionAppearance } from './custom-action-appearance';

type DataFn<TData, TReturn> = (data: TData | undefined) => Nullable<TReturn>;

export interface ActionCellParams<TData = unknown> {
  appearance?: Nullable<TuiAppearance | CustomActionAppearance>;
  getAppearance?: DataFn<TData, TuiAppearance | CustomActionAppearance>;
  actionLabel?: Nullable<string>;
  getActionLabel?: DataFn<TData, string>;
  label?: Nullable<string>;
  getLabel?: DataFn<TData, string>;
  icon?: Nullable<string>;
  getIcon?: DataFn<TData, string>;
  getAction$?: DataFn<TData, () => Observable<unknown> | void>;
  getLink?: DataFn<TData, Array<number | string> | string>;
  getLinkParams?: DataFn<TData, Params>;
  linkTarget?: LinkTarget;
  hint?: Nullable<string>;
  getHint?: DataFn<TData, string>;
  isDisabledCallback$?: DataFn<TData, Observable<boolean>>;
}
