import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { Nullable } from '@lib-utils';
import { GridId, GridSavedState, GridState, GridStateService } from '@lib-widgets/grid';
import { GridStateApiService } from '@lib-mortgage/api';

@Injectable()
export class IpotekaGridStateService extends GridStateService {
  gridStateApiService = inject(GridStateApiService);

  getGridState$(gridId: GridId): Observable<Nullable<GridSavedState>> {
    return this.gridStateApiService.apiGridStateGet(gridId).pipe(
      map(({ data }) => (data?.data ? this.decode(data.data) : null)),
      catchError(() => of(null)),
    );
  }

  saveGridState$(gridId: GridId, state: Nullable<GridState>) {
    return this.gridStateApiService.apiGridStateGridNamePost(gridId, {
      data: this.encode(state),
    });
  }

  removeGridState$(gridId: GridId) {
    return this.gridStateApiService.apiGridStateGridNameDelete(gridId);
  }

  private encode(state: Nullable<GridState>): string {
    return btoa(
      encodeURIComponent(
        JSON.stringify({
          state,
          createDate: new Date(),
        }),
      ),
    );
  }

  private decode(state: string): Nullable<GridSavedState> {
    try {
      return JSON.parse(decodeURIComponent(atob(state)));
    } catch (e) {
      return null;
    }
  }
}
