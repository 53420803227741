<ag-grid-angular
  *ngIf="gridOptions"
  class="ag-theme-alpine"
  [modules]="modules"
  [class]="getGridClass | executeWith: gridClass"
  [gridOptions]="gridOptions"
  [columnDefs]="colDefs"
  [rowData]="rowData"
  [defaultColDef]="assign | executeWith: gridOptions.defaultColDef : defaultColDef"
  [domLayout]="'autoHeight'"
  [animateRows]="true"
  [getRowId]="gridOptions.getRowId ?? defaultGetRowId"
  [suppressDragLeaveHidesColumns]="true"
  [enableCellTextSelection]="true"
  [scrollbarWidth]="0"
  [suppressRowClickSelection]="true"
  [suppressMultiSort]="true"
  [stopEditingWhenCellsLoseFocus]="true"
  [tooltipShowDelay]="gridOptions.tooltipShowDelay ?? defaultTooltipDelay"
  [tooltipMouseTrack]="true"
  [rowClassRules]="rowClassRules"
  [localeText]="assign | executeWith: gridOptions.localeText : localeText"
  [class.hide-scroll-bar-on-no-rows]="!rowData?.length"
  (cellValueChanged)="cellValueChanged.emit($event)"
  (sortChanged)="sortChanged.emit($event)"
  (selectionChanged)="selectionChanged.emit($event); updateScrollbar()"
  (gridReady)="gridReady.emit($event)"
  (gridSizeChanged)="updateScrollbar()"
  (rowDataUpdated)="rowDataUpdated.emit($event); updateScrollbar()"
  (firstDataRendered)="updateScrollbar()"
  (filterChanged)="updateScrollbar()"
  (columnVisible)="updateScrollbar()"
  (columnPinned)="updateScrollbar()"
  (columnResized)="updateScrollbar()"
  (columnMoved)="columnMoved.emit($event); updateScrollbar()"
  (columnGroupOpened)="updateScrollbar()"
/>
