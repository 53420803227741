/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IncomesPeriod } from './incomesPeriod';
import { AddressData } from './addressData';
import { WorkingPeriod } from './workingPeriod';
import { IncomeStatementBankFormatItem } from './incomeStatementBankFormatItem';


export interface IncomeStatementBankFormatPage { 
    fullName?: string | null;
    lastName?: string | null;
    firstName?: string | null;
    secondName?: string | null;
    position?: string | null;
    innEmployer?: string | null;
    employerName?: string | null;
    employerPhone?: string | null;
    incomes?: Array<IncomeStatementBankFormatItem> | null;
    salaryTotal?: string | null;
    incomesPeriod?: IncomesPeriod;
    statementDate?: string | null;
    workingPeriod?: WorkingPeriod;
    incomesMonthsCount?: string | null;
    workingMonthsCount?: string | null;
    signChiefAccountant?: string | null;
    employerLegalAddress?: AddressData;
    employerActualAddress?: AddressData;
    stamp?: string | null;
    hasCorrections?: boolean | null;
    fileId?: string | null;
    taskId?: string | null;
}

