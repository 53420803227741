import { inject } from '@angular/core';
import { ResolveFn, Router, TitleStrategy } from '@angular/router';
import { Nullable } from '@lib-utils';
import { CABINET_TITLE, ROUTE_TITLE_DESCRIPTION } from './constants';

export function withCabinetTitle(title: ResolveFn<string> | string) {
  return { [CABINET_TITLE]: title };
}

/**
 * Используется для добавления в заголовок страницы (title) дополнительной информации,
 * которая будет получена или обновлена внутри компонента страницы и не может быть известна на уровне резолвера рута.
 *
 * Например, если надо вывести ФИО из заявки в title.
 * ```typescript
 * @Component()
 * export class ContractViewComponent {
 *  updateRouteTitleDescription = getUpdateRouteTitleDescriptionFn();
 *
 *  getClientData$ = (clientId: Nullable<number>) => {
 *     if (!clientId) return of(null);
 *     return this.clientApiService.apiClientIdGet(clientId).pipe(
 *       map((res) => res.data),
 *       tap((client) => this.updateRouteTitleDescription(getFullName(client)))
 *     );
 *  };
 * }
 * ```
 */
export function getUpdateRouteTitleDescriptionFn() {
  const router = inject(Router);
  const titleStrategy = inject(TitleStrategy);
  const routeTitleDescription = inject(ROUTE_TITLE_DESCRIPTION);
  return (description: Nullable<string>) => {
    routeTitleDescription.set(description);
    titleStrategy.updateTitle(router.routerState.snapshot);
  };
}
