<ng-container *ngIf="columnsOrderChanged$ | async" />

<tui-tiles class="tui-tiles-s" [(order)]="columnsOrder" (orderChange)="columnsOrder$.next($event)">
  @for (column of columns; track column; let index = $index) {
    @if (column.lockPinned) {
      <div class="d-flex gap-2 align-items-center" [style.order]="columnsOrder.get(index)">
        <tui-svg src="tuiIconLock" />

        <fnip-reactive-checkbox
          [fieldId]="'col' + column.colId"
          [checkboxLabel]="column.name ?? ''"
          [control]="column.control"
          [isDisabled]="!!column.lockVisible"
          (valueChange)="columnVisibilityChange.emit({ colId: column.colId, hide: !$event })"
        />
      </div>
    } @else {
      <tui-tile [style.order]="columnsOrder.get(index)">
        <div class="d-flex gap-2 align-items-center">
          <tui-svg tuiTileHandle src="tuiIconDrag" class="cursor-ns-resize" />

          <fnip-reactive-checkbox
            [fieldId]="'col' + column.colId"
            [checkboxLabel]="column.name ?? ''"
            [control]="column.control"
            [isDisabled]="!!column.lockVisible"
            (valueChange)="columnVisibilityChange.emit({ colId: column.colId, hide: !$event })"
          />
        </div>
      </tui-tile>
    }
  }
</tui-tiles>
