import { Component, ViewEncapsulation } from '@angular/core';
import { LoggerService, NavigationService } from '@lib-utils';
import { AuthorizationStorageService } from '@lib-mortgage/features/authorization';

@Component({
  selector: 'fnip-root',
  template: ` <tui-root>
    <div class="layout">
      <router-outlet></router-outlet>

      <!-- Контейнер для сайдбара -->
      <fnip-sidebar></fnip-sidebar>
    </div>
  </tui-root>`,
  styleUrls: ['./application-root.component.scss'],
})
export class ApplicationRootComponent {
  constructor(
    private readonly authorizationStorageService: AuthorizationStorageService,
    private readonly loggerService: LoggerService,
    private readonly navigationService: NavigationService,
  ) {
    this.navigationService.startSavingHistory$();
    this.loggerService.setActiveUser(this.authorizationStorageService.user);
  }
}
