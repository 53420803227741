/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SpouseSocialStatus = 'Working' | 'NotWorking' | 'Retired';

export const SpouseSocialStatus = {
    Working: 'Working' as SpouseSocialStatus,
    NotWorking: 'NotWorking' as SpouseSocialStatus,
    Retired: 'Retired' as SpouseSocialStatus
};

