/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AnswerCallDto } from './answerCallDto';
import { AnswerType } from './answerType';


export interface QuestionCallDto { 
    externalId?: number;
    text?: string | null;
    answerType?: AnswerType;
    priority?: number;
    isVisible?: boolean;
    answer?: AnswerCallDto;
    possibleAnswers?: Array<AnswerCallDto> | null;
}
export namespace QuestionCallDto {
}


