/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RealEstateDto } from './realEstateDto';
import { CreditDataDto } from './creditDataDto';
import { BuyerInfoDto } from './buyerInfoDto';
import { InseparableImprovementsType } from './inseparableImprovementsType';
import { OwnerDto } from './ownerDto';
import { RegistrationType } from './registrationType';
import { TransferType } from './transferType';
import { DateTime } from '@lib-utils';
import { DealRegistrationType } from './dealRegistrationType';
import { DepartmentInfoDto } from './departmentInfoDto';
import { OwnerRequisitesDto } from './ownerRequisitesDto';
import { SignatoryDto } from './signatoryDto';


export interface InfoOnPrepareForDealStateDto { 
    id?: number;
    creditData?: CreditDataDto;
    agreementType?: DealRegistrationType;
    transferType?: TransferType;
    propertyValue?: number | null;
    cftDepartmentId?: number | null;
    cftDepartment?: DepartmentInfoDto;
    dealDepartmentId?: number | null;
    dealDepartment?: DepartmentInfoDto;
    bankSignerId?: number | null;
    bankSigner?: SignatoryDto;
    date?: DateTime | null;
    registrationType?: RegistrationType;
    inseparableImprovements?: InseparableImprovementsType;
    inseparableImprovementsCost?: number | null;
    contractOfSaleCost?: number | null;
    ownerRequisites?: OwnerRequisitesDto;
    electronicRegistrationCost?: number | null;
    realEstates?: Array<RealEstateDto> | null;
    owners?: Array<OwnerDto> | null;
    buyers?: Array<BuyerInfoDto> | null;
}
export namespace InfoOnPrepareForDealStateDto {
}


