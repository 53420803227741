import { withCabinetTitle } from '@lib-config';
import { ExtendedRoute, RouteBreadcrumbData } from '@lib-utils';
import { NoPageComponent } from '@lib-widgets/core';
import {
  authorizationGuardFn,
  getAuthorizationRoutes,
  permissionGuardFn,
  RoutePermissionData,
} from '@lib-mortgage/features/authorization';
import { DefaultComponent, DefaultPageData } from '@lib-mortgage/widgets/default';
import { ApplicationModules, ApplicationModulesMetaMap } from '@lib-verification/utils';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';

export const appRoutes: ExtendedRoute<RouteBreadcrumbData & RoutePermissionData & DefaultPageData>[] = [
  {
    path: '',
    title: 'Верификатор',
    children: [
      {
        path: '',
        pathMatch: 'full',
        canActivate: [authorizationGuardFn],
        component: DefaultComponent,
        data: {
          moduleMeta: ApplicationModulesMetaMap,
          hasTranslation: false,
        },
      },
      {
        path: ApplicationModulesMetaMap[ApplicationModules.Verificator].link,
        title: 'Кабинет верификатора',
        canActivate: [authorizationGuardFn, permissionGuardFn],
        canActivateChild: [authorizationGuardFn],
        data: {
          ...withCabinetTitle('Кабинет верификатора'),
          requiredPermission: ApplicationModulesMetaMap[ApplicationModules.Verificator].permissionOperation,
        },
        children: [
          {
            path: '',
            component: AppComponent,
            children: [
              {
                path: '',
                title: 'Список задач',
                loadComponent: () =>
                  import('./views/operator-list/operator-list.component').then((c) => c.OperatorListComponent),
              },
              {
                path: ':id',
                title: ({ params }) => `Задача №${params.id}`,
                loadComponent: () =>
                  import('./views/operator-view/operator-view.component').then((c) => c.OperatorViewComponent),
              },
            ],
          },
        ],
      },
      {
        path: ApplicationModulesMetaMap[ApplicationModules.VerificatorManager].link,
        title: 'Кабинет администратора верификаторов',
        canActivate: [authorizationGuardFn, permissionGuardFn],
        canActivateChild: [authorizationGuardFn],
        data: {
          ...withCabinetTitle('Кабинет администратора верификаторов'),
          requiredPermission: ApplicationModulesMetaMap[ApplicationModules.VerificatorManager].permissionOperation,
        },
        children: [
          {
            path: '',
            component: AppComponent,
            children: [
              {
                path: '',
                title: 'Список задач',
                loadComponent: () =>
                  import('./views/operator-list/operator-list.component').then((c) => c.OperatorListComponent),
              },
              {
                path: ':id',
                title: ({ params }) => `Задача №${params.id}`,
                loadComponent: () =>
                  import('./views/operator-view/operator-view.component').then((c) => c.OperatorViewComponent),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'auth',
    children: getAuthorizationRoutes(environment.frontVersion, 'Верификатор'),
  },
  {
    path: 'unsupported-browser',
    title: 'Ваш браузер не поддерживается',
    loadComponent: () => import('@lib-widgets/unsupported-browser').then((m) => m.UnsupportedBrowserComponent),
  },
  {
    path: '**',
    title: 'Страница не найдена',
    component: NoPageComponent,
  },
];
