import { Injectable, isDevMode } from '@angular/core';
import { BeApiEnum } from '@lib-utils';
import { Config, Env, getCurrentEnv, RemoteKeys } from './utils';

const CONFIG_KEY = 'OCTASOFT_CONFIG';

@Injectable({ providedIn: 'root' })
export class ConfigService {
  private env!: Config;

  constructor() {
    const config: Config | undefined = (window as never)[CONFIG_KEY];
    if (config) {
      this.setConfig(config);
      return;
    }

    if (!isDevMode()) throw new Error('Config is not found');
    // eslint-disable-next-line no-console
    console.warn('Config is not found, default settings will be used');
    this.setConfig({
      remotes: {
        'crm-collection': 'https://dev.collection.absolut.octasoft.ru',
        auto: 'https://dev.auto.absolut.octasoft.ru',
        archive: 'https://dev.archive.absolut.octasoft.ru',
        verification: 'https://dev.verification-mortgage.absolut.octasoft.ru',
        mortgage: 'https://dev.ipoteka.absolut.octasoft.ru',
      },
    } as Config);
  }

  isProdOrPreprod(): boolean {
    return [Env.preprod, Env.stage, Env.prod].includes(this.currentEnv);
  }

  isDevOrStage(): boolean {
    return [Env.localhost, Env.dev, Env.stage].includes(this.currentEnv);
  }

  isProduction() {
    return this.currentEnv === Env.prod;
  }

  setConfig(config: Config) {
    this.env = config;
  }

  get currentEnv(): Env {
    // TODO: window??? move to provider?
    return getCurrentEnv(window.location.hostname);
  }

  get apiUrl(): string {
    return this.env.apiUrl || 'https://dev.api.ipoteka.absolut.octasoft.ru';
  }

  get archiveApiUrl(): string {
    return this.env.archiveApiUrl || 'https://dev.api.archive.absolut.octasoft.ru';
  }

  get autoApiUrl(): string {
    return this.env.autoApiUrl || 'https://dev.bff.auto.absolut.octasoft.ru';
  }

  get autoLookupApiUrl(): string {
    return this.env.autoLookupApiUrl || 'https://dev.lookup.auto.absolut.octasoft.ru';
  }

  get autoSprApiUrl(): string {
    return this.env.autoSprApiUrl || 'https://dev.spr.auto.absolut.octasoft.ru';
  }

  get autoReportsApiUrl(): string {
    return this.env.autoReportsApiUrl || 'https://dev.reporting.auto.absolut.octasoft.ru';
  }

  get collectionApiUrl(): string {
    return this.env.collectionApiUrl || 'https://dev.api.collection.absolut.octasoft.ru';
  }

  get verificationApiUrl(): string {
    return this.env.verificationApiUrl || 'https://dev.api.verification.absolut.octasoft.ru';
  }

  get notificationsApiUrl(): string {
    return this.env.notificationsApiUrl || 'https://dev.inapp.notification.k8s.octasoft.ru';
  }

  get hostUrl(): string {
    return this.env.hostUrl || 'https://dev.absolut.octasoft.ru';
  }

  get logCollectorUrl() {
    return this.env.logCollectorUrl;
  }

  get logCollectorKey() {
    return this.env.logCollectorKey;
  }

  get feedbackUrl() {
    return this.env.feedbackUrl || 'https://feedback-temp.mr.front.ipoteka.absolut.octasoft.ru';
  }

  get bffUrl() {
    return this.env.bffUrl || location.origin + '/bff';
  }

  get tokenCookieName() {
    return this.env.tokenCookieName || 'token_dev';
  }

  getRemoteUrl(remoteName: RemoteKeys) {
    return this.env.remotes?.[remoteName] ?? null;
  }

  getApiUrlByName(module: BeApiEnum) {
    switch (module) {
      case BeApiEnum.Auto:
        return this.autoApiUrl;
      case BeApiEnum.AutoLookup:
        return this.autoLookupApiUrl;
      case BeApiEnum.AutoSpr:
        return this.autoSprApiUrl;
      case BeApiEnum.Mortgage:
        return this.apiUrl;
      case BeApiEnum.Archive:
        return this.archiveApiUrl;
      case BeApiEnum.Collection:
        return this.collectionApiUrl;
      case BeApiEnum.Verification:
        return this.verificationApiUrl;
      case BeApiEnum.Bff:
        return this.bffUrl;
    }
  }
}
