import {
  CellValueChangedEvent,
  ColDef,
  ColumnState,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ProcessCellForExportParams,
  RowClassRules,
  RowDataUpdatedEvent,
  SelectionChangedEvent,
  SortChangedEvent,
} from '@ag-grid-community/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { tuiCapitalizeFirstLetter } from '@taiga-ui/core';
import isNil from 'lodash-es/isNil';
import pick from 'lodash-es/pick';
import { isObservable, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  BreakpointService,
  byInterval,
  createOptionsForLocalizedEnum,
  createTranslatedOptionsForLocalizedEnum,
  Nullable,
} from '@lib-utils';
import type { ButtonComponent } from '@lib-widgets/core';
import { RequestWrapperComponent } from '@lib-widgets/request-wrapper';
import { SidebarDirective } from '@lib-widgets/sidebar';
import { GridStateService } from './grid-state.service';
import {
  ColumnsCheckVisibleParams,
  GridGetDataCallback,
  GridOrder,
  GridPagination,
  OrderByOptions,
  PaginatedResult,
  RowDataSource,
} from './grid.interfaces';
import { GridCompactType, GridCompactTypeMap, GridCompactTypeTranslateMap } from './grid.utils';
import { ActionCellComponent } from './renderers/action-cell';
import { GridId, GridSavedState, GridTheme } from './utils';

interface FetchDataOptions {
  resetPage?: boolean;
}

/**
 * Свойства, которые будут применены к таблице из сохраненного состояния
 */
const GRID_STATE_PICK_PROPS: (keyof ColumnState)[] = ['colId', 'hide', 'pinned', 'flex', 'width', 'sort'];

@Component({
  selector: 'fnip-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BreakpointService],
})
export class GridComponent<TModel = unknown, TModelFields = unknown> implements OnChanges {
  @Input() gridClass: Nullable<GridTheme>;
  @Input() gridOptions: Nullable<GridOptions>;
  @Input() rowClassRules?: RowClassRules<TModel>;
  @Input() colDefs: Nullable<GridOptions['columnDefs']>;
  @Input() columnsCheckVisibleParams: Nullable<ColumnsCheckVisibleParams<TModel>[]>;
  @Input() rowData: Nullable<RowDataSource<TModel, TModelFields>>;
  @Input() backButtonCallback$: ButtonComponent['actionCallback$'];
  @Input() perPageOptionsShown = false;
  @Input() hasModeSelection = true;
  @Input() useByInterval = true;
  @Input() hasLocalisation = false;
  @Input()
  set perPageOptions(value: Nullable<number[]>) {
    if (!value?.length) return;
    this.perPage = value[0];
    this._perPageOptions = value;
  }
  @Input()
  set compactMode(value: Nullable<GridCompactType>) {
    if (isNil(value)) return;

    this.compactSelectControl.patchValue(value);
  }
  @Input() gridId: Nullable<GridId>;
  @Input() excelExport$: Nullable<() => Observable<unknown>> = this.defaultExcelExport.bind(this);

  @Output() paginationChange = new EventEmitter<GridPagination>();
  @Output() orderChange = new EventEmitter<GridOrder<TModelFields>>();
  // From base component
  @Output() cellValueChanged = new EventEmitter<CellValueChangedEvent>();
  @Output() selectionChanged = new EventEmitter<SelectionChangedEvent<TModel>>();
  @Output() sortChanged = new EventEmitter<SortChangedEvent<TModel>>();
  @Output() compactModeChanged = new EventEmitter<Nullable<GridCompactType>>();
  @Output() gridReady = new EventEmitter<GridReadyEvent<TModel>>();
  @Output() rowDataUpdated = new EventEmitter<RowDataUpdatedEvent<TModel>>();

  get perPageOptions(): number[] {
    return this._perPageOptions;
  }

  get pagination(): GridPagination {
    const { pageIndex, perPage } = this;

    return {
      page: pageIndex + 1, // Taiga uses index in pagination
      perPage,
    };
  }

  get order() {
    if (this.gridOptions?.columnApi) {
      const { sort, colId } = this.gridOptions?.columnApi?.getColumnState()?.find((x) => x.sort) || {};
      const order: GridOrder<TModelFields> | undefined =
        sort && colId
          ? {
              orderBy: { asc: OrderByOptions.Asc, desc: OrderByOptions.Desc }[sort],
              fieldBy: tuiCapitalizeFirstLetter(colId) as unknown as TModelFields,
            }
          : undefined;

      return order;
    }
    const initialColDefs = this.gridOptions?.columnDefs?.map((colDef: ColDef) =>
      typeof colDef.type === 'string'
        ? {
            ...colDef,
            ...this.gridOptions?.columnTypes?.[colDef.type],
          }
        : colDef,
    );
    const { initialSort, colId, field } = initialColDefs?.find((x: Nullable<ColDef>) => x?.initialSort) || {};
    const sortField = colId ?? field;
    const order: GridOrder<TModelFields> | undefined =
      initialSort && sortField
        ? {
            orderBy: { asc: OrderByOptions.Asc, desc: OrderByOptions.Desc }[initialSort],
            fieldBy: tuiCapitalizeFirstLetter(sortField) as unknown as TModelFields,
          }
        : undefined;

    return order;
  }

  @ViewChild(RequestWrapperComponent)
  public dataRs?: RequestWrapperComponent<TModel[]>;

  @Input()
  set page(value: number) {
    this.pageIndex = value - 1;
  }

  @Input() perPage = 10;
  pageIndex = 0;
  pages = 0;

  data$?: Observable<TModel[]>;

  compactSelectControl = new FormControl(GridCompactType.compact);

  applySavedState$: Nullable<Observable<unknown>>;

  initialColumnState: Nullable<ColumnState[]>;

  initialFilterModel: Nullable<{ [key: string]: unknown }>;

  private _perPageOptions = [10, 20, 50];

  private cdr = inject(ChangeDetectorRef);
  readonly breakpointService = inject(BreakpointService);
  readonly gridStateService = inject(GridStateService, { optional: true });
  readonly translateService = inject(TranslateService, { optional: true });

  readonly compactOptions$ = of(createOptionsForLocalizedEnum(GridCompactTypeMap));
  readonly compactTranslatedOptions$ = createTranslatedOptionsForLocalizedEnum(GridCompactTypeTranslateMap);

  public fetchData(options: FetchDataOptions = {}) {
    if (!this.rowData) return;
    if (options.resetPage) this.resetPage();

    const source = this.getSource();

    this.data$ = source.pipe(
      tap((res) => {
        if (!res || !this.isPaginatedResult(res)) return;
        this.pages = res.page?.pages ?? 0;
        this.perPageOptionsShown = true;
      }),
      map((res) => {
        if (!res) return [];
        return this.isPaginatedResult(res) ? res.data ?? [] : res;
      }),
      tap((data) => {
        if (this.columnsCheckVisibleParams?.some(({ updateWithFetch }) => updateWithFetch))
          this.applyColumnsCheckVisible(data);
      }),
    );

    // in case of call from ref
    this.cdr.markForCheck();
  }

  public resetPage() {
    this.pageIndex = 0;

    // in case of call from ref
    this.cdr.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('rowData' in changes && this.rowData) this.fetchData();
    if ('columnsCheckVisibleParams' in changes && this.columnsCheckVisibleParams) this.applyColumnsCheckVisible();
  }

  onLocalisationChange$ = this.translateService
    ? this.translateService.onLangChange.pipe(
        tap(() => this.gridOptions?.api?.refreshHeader()),
        tap(() =>
          this.gridOptions?.api?.refreshCells({
            force: true,
            suppressFlash: true,
            columns:
              this.gridOptions?.columnApi
                ?.getAllDisplayedColumns()
                ?.filter(
                  (column) =>
                    column.getColDef()?.cellRenderer ||
                    column.getColDef()?.valueGetter ||
                    column.getColDef()?.valueFormatter,
                )
                ?.map((column) => column.getColId()) ?? [],
          }),
        ),
      )
    : of(null);

  onGridSortChanged(event: SortChangedEvent<TModel>) {
    this.sortChanged.emit(event);
    this.orderChange.emit(this.order);

    if (this.isSourceFunction()) this.fetchData();
  }

  onGridReady() {
    this.initialColumnState = this.gridOptions?.columnApi?.getColumnState();
    this.initialFilterModel = this.gridOptions?.api?.getFilterModel();

    this.applyColumnsCheckVisible();

    if (this.gridId && this.gridStateService) {
      this.applySavedState$ = this.gridStateService.getGridState$(this.gridId).pipe(
        tap((state) => {
          this.applyGridState(state);
          this.gridReady.emit();
        }),
      );
    } else {
      this.gridReady.emit();
    }
  }

  onGridCompactChange = (gridApi: Nullable<GridApi>, compactSelectValue: Nullable<GridCompactType>) => {
    if (!gridApi) return;

    const result = compactSelectValue === GridCompactType.full;
    const currentColDefs = gridApi.getColumnDefs();

    if (!currentColDefs) return;

    const newColDefs = currentColDefs.map((colDef) => ({
      ...colDef,
      wrapText: result,
      autoHeight: result,
    }));

    gridApi.setColumnDefs(newColDefs);
    gridApi.redrawRows();

    this.compactModeChanged.emit(compactSelectValue);
  };

  pageIndexChange() {
    this.paginationChange.emit(this.pagination);

    if (this.isSourceFunction()) this.fetchData();
  }

  perPageChange(value: number) {
    this.perPage = value;
    this.paginationChange.emit(this.pagination);
    if (this.isSourceFunction()) this.fetchData({ resetPage: true });
  }

  toggleSidebar = (sidebar: SidebarDirective) => () => {
    if (sidebar.visible) sidebar.close();
    else sidebar.open();
  };

  setOrder(order: GridOrder<TModelFields>) {
    if (!this.gridOptions?.columnApi) return;

    const decapitalizedField = (order.fieldBy as string).charAt(0).toLowerCase() + (order.fieldBy as string).slice(1);
    // Find column by field name
    const column = this.gridOptions.columnApi.getColumns()?.find((col) => {
      const colId = col.getColId();
      const colDef = col.getColDef();
      return !!colDef.sortable && colId === decapitalizedField;
    });

    column &&
      this.gridOptions.columnApi.applyColumnState({
        state: [{ colId: column.getColId(), sort: order.orderBy.toLowerCase() as 'asc' | 'desc' }],
        defaultState: { sort: null },
      });
  }

  private applyGridState(state: Nullable<GridSavedState>) {
    if (!(state && this.gridOptions?.api && this.gridOptions.columnApi)) return;
    this.gridOptions.columnApi.applyColumnState({
      state: state.state.columnState?.map((colState) => pick(colState, GRID_STATE_PICK_PROPS)),
      applyOrder: true,
    });

    this.gridOptions.api.setFilterModel(state.state.filterModel);
  }

  private applyColumnsCheckVisible(data?: Nullable<TModel[]>) {
    if (!(this.columnsCheckVisibleParams && this.gridOptions?.api && this.gridOptions.columnApi)) return;

    this.columnsCheckVisibleParams.forEach(({ colId, isVisible }) =>
      this.gridOptions?.columnApi?.setColumnVisible(colId, isVisible(data)),
    );

    this.gridStateService?.onStateChange(this.gridId);
  }

  private getSource() {
    if (isObservable(this.rowData)) return this.rowData;
    if (this.isSourceFunction(this.rowData))
      return this.useByInterval
        ? byInterval(this.rowData(this.pagination, this.order))
        : this.rowData(this.pagination, this.order);

    return of(this.rowData);
  }

  private isPaginatedResult(res: PaginatedResult<TModel> | TModel[]): res is PaginatedResult<TModel> {
    return !Array.isArray(res);
  }

  private isSourceFunction(
    source: Nullable<RowDataSource<TModel, TModelFields>> = this.rowData,
  ): source is GridGetDataCallback<TModel, TModelFields> {
    return typeof source === 'function';
  }

  private defaultExcelExport() {
    const columnSeparator = ';';

    this.gridOptions?.api?.exportDataAsCsv({
      columnSeparator,
      processCellCallback: (params: ProcessCellForExportParams): string => {
        const colDef = params.column.getColDef();
        const { cellRenderer, refData, valueFormatter } = colDef ?? {};
        // Пропускаем колонку с действиями, если не указали valueFormatter
        if (cellRenderer === ActionCellComponent && typeof valueFormatter !== 'function') return '';

        // Получаем читаемое значение из refData и valueFormatter
        if (refData) return refData[params.value] ?? params.value;
        if (typeof valueFormatter === 'function')
          return valueFormatter({
            value: params.value,
            node: params.node ?? null,
            column: params.column,
            api: params.api,
            columnApi: params.columnApi,
            context: params.context ?? null,
            colDef,
            data: params.node?.data ?? null,
          });

        return params.value;
      },
    });

    return of(null);
  }
}
