import {
  CallVerificationTaskEmploymentPhoneUpdateRequest,
  EmploymentPhoneDto,
  PhoneSource,
} from '@lib-verification/api';

export interface FeCallVerificationTask extends CallVerificationTaskEmploymentPhoneUpdateRequest {
  number?: string | null;
  source?: PhoneSource;
}

export interface FeEmploymentCallDto {
  [key: number]: Array<EmploymentPhoneDto> | null;
}
