import { flatMap } from 'lodash-es';
import { Nullable } from '@lib-utils';
import { JobType } from '@lib-mortgage/api';

export const jobTypeOrder: JobType[] = [
  JobType.MainJob,
  JobType.PartTimeJob,
  JobType.SecondPartTimeJob,
  JobType.ThirdPartTimeJob,
];

export const sortByJobType = <T extends { jobType?: JobType }>(employments: Nullable<T[]>) => {
  if (!employments) return [];
  return flatMap(jobTypeOrder, (jobType) => employments.filter((j) => j.jobType === jobType));
};
