import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { TuiStatus } from '@taiga-ui/kit';
import { Nullable } from '@lib-utils';

export interface TagCellConfig<TData = unknown> {
  labelMap: Nullable<Record<string, string>>;
  valueAsLabel?: boolean;
  tagStatusMap?: Record<string, TuiStatus>;
  getDefaultTagClass?: (params: ICellRendererParams<TData>) => string;
  styles?: { [prop: string]: string | number } | null;
  icon?: Nullable<string>;
  iconStyles?: { [prop: string]: string | number } | null;
}

@Component({
  selector: 'fnip-tag-cell',
  templateUrl: './tag-cell.component.html',
  styleUrls: ['./tag-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagCellComponent implements ICellRendererAngularComp {
  icon: Nullable<string>;

  value: Nullable<string>;

  status: TuiStatus = 'default';

  tagClass = '';

  iconStyles: { [prop: string]: unknown } | null = null;

  styles: { [prop: string]: unknown } | null = null;

  cdr = inject(ChangeDetectorRef);

  agInit(params: ICellRendererParams & TagCellConfig) {
    this.refreshView(params);
  }

  refresh(params: ICellRendererParams & TagCellConfig): boolean {
    this.refreshView(params);
    return true;
  }

  private refreshView(params: ICellRendererParams & TagCellConfig) {
    this.icon = params.icon;
    this.value = params.valueAsLabel ? params.value : params.labelMap?.[params.value];
    this.status = params.tagStatusMap?.[params.value] ?? 'default';
    this.tagClass = this.status === 'default' ? params.getDefaultTagClass?.(params) ?? '' : '';
    this.styles = params.styles ?? null;
    this.iconStyles = params.iconStyles ?? null;
    this.cdr.markForCheck();
  }
}
