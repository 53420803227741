import { ColDef } from '@ag-grid-community/core';
import { Nullable } from '@lib-utils';
import { TagCellListValue, TagListCellConfig } from './tag-list-cell-config';
import { TagListCellComponent } from './tag-list-cell.component';

export const tagListCellDef = (
  config?: Nullable<TagListCellConfig>,
): Pick<ColDef, 'cellRenderer' | 'cellRendererParams' | 'valueFormatter'> => ({
  cellRenderer: TagListCellComponent,
  cellRendererParams: config,
  valueFormatter: ({ value }) => value && (value as TagCellListValue).items.map((tag) => tag.value).join(', '),
});
