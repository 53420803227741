@if (notificationSource) {
  <fnip-request-wrapper appearance="inline" [request$]="loadInitialHistory$ | executeWith">
    <div
      class="d-flex br-3 w-fit-content bg-color-background-color cursor-pointer"
      [style.overflow]="'hidden'"
      [tuiDropdown]="dropdownContentTemplate"
      [tuiDropdownManual]="isDropdownOpen()"
      [tuiDropdownLimitWidth]="'min'"
      (click)="isDropdownOpen.set(!isDropdownOpen())"
      (tuiActiveZoneChange)="onActiveZone($event)"
      (tuiObscured)="onObscured($event)"
    >
      <div class="d-flex align-items-start content-center py-2 bg-color-light-orange" [style.width.px]="50">
        <tui-svg src="assets/icons/notification.svg" />
        @if (unreadNotificationsCount(); as count) {
          <span data-testid="unread-notifications-count">{{ count }}</span>
        }
      </div>

      @if (!compactMode()) {
        <div class="d-flex flex-1" [style.width.px]="500">
          <div class="flex-1 align-self-center px-4 py-2" [style.overflow]="'hidden'">
            @if (mostRecentUnreadNotification(); as item) {
              <fnip-notification-list-item
                [isShort]="true"
                [isRead]="item.isRead"
                [shortText]="item.shortText"
                [date]="item.createdAt"
              />
            } @else {
              <span>Новых уведомлений нет</span>
            }
          </div>

          <fnip-button
            class="flex-shrink-0"
            appearance="default"
            [style.height.px]="40"
            [icon]="isDropdownOpen() ? 'tuiIconChevronUpLarge' : 'tuiIconChevronDownLarge'"
          />
        </div>
      }

      <ng-template #dropdownContentTemplate>
        <fnip-notification-history
          [notificationList]="displayedNotifications()"
          [hasLoadMoreButton]="nextCursor() !== null"
          [loadMoreHistory$]="loadMoreHistory$"
          (markAsRead)="markAsRead($event)"
        />
      </ng-template>
    </div>
  </fnip-request-wrapper>
}
