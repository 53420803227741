import { DIALOG_DATA } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  inject,
  Injector,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { timer } from 'rxjs';
import { ButtonModule } from '@lib-widgets/core';
import { FnipDialogRef } from '../dialog-ref';
import { DIALOG_CLOSE_ANIMATION_DELAY } from '../dialog.constants';
import { DialogComponentType, DialogHostOptions, DialogHostSize } from '../dialog.model';
import { DIALOG_COMPONENT, DIALOG_HOST_OPTIONS, DIALOG_MODULE_REF, FNIP_DIALOG_REF } from '../dialog.tokens';

@Component({
  selector: 'fnip-dialog-host',
  standalone: true,
  imports: [CommonModule, ButtonModule],
  templateUrl: './dialog-host.component.html',
  styleUrls: ['./dialog-host.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogHostComponent implements AfterViewInit {
  @ViewChild('dialogContentView', { read: ViewContainerRef })
  dialogContentViewRef?: ViewContainerRef;

  private moduleRef = inject(DIALOG_MODULE_REF);
  private injector = inject(Injector);

  readonly dialogRef = inject<FnipDialogRef<unknown>>(FNIP_DIALOG_REF);
  readonly dialogContext = inject(DIALOG_DATA, { optional: true });
  readonly dialogHostOptions = inject<DialogHostOptions>(DIALOG_HOST_OPTIONS);
  readonly contentComponent = inject<DialogComponentType>(DIALOG_COMPONENT);
  readonly changeDetectorRef = inject(ChangeDetectorRef);

  @HostBinding('class.closed')
  isClosed = false;

  ngAfterViewInit() {
    this.createContentComponent();
  }

  get isFullscreen() {
    return this.dialogHostOptions.size === 'fullscreen';
  }

  get sizeClass() {
    const size: DialogHostSize = this.dialogHostOptions.size || 'm';
    return `size_${size}`;
  }

  public runCloseAnimation$() {
    this.isClosed = true;
    this.changeDetectorRef.detectChanges();
    return timer(DIALOG_CLOSE_ANIMATION_DELAY);
  }

  readonly closeCb = () => {
    if (!this.dialogHostOptions.isCloseable) return;
    this.dialogRef.close();
  };

  readonly dismissCb = () => {
    if (!this.dialogHostOptions.isDismissible) return;
    this.dialogRef.close();
  };

  private createContentComponent() {
    if (!this.contentComponent) return;
    if (this.contentComponent instanceof TemplateRef) {
      this.dialogContentViewRef?.createEmbeddedView(this.contentComponent, this.dialogContext, {
        injector: this.injector,
      });
    } else {
      this.dialogContentViewRef?.createComponent(this.contentComponent, {
        injector: this.injector,
        ngModuleRef: this.moduleRef,
      });
    }
    this.changeDetectorRef.detectChanges();
  }
}
