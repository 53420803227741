import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { inject } from '@angular/core';
import { Nullable } from '@lib-utils';
import { PermissionOperationMapKeys, PermissionService } from '@lib-mortgage/features/authorization';

export const hasPermissionCellDefs = <T = unknown>(
  operation: Nullable<PermissionOperationMapKeys>,
  colDef: ColDef<T> | ColGroupDef<T>,
  permissionService = inject(PermissionService),
) => {
  if (!permissionService.checkExactPermissionFor(operation)) return [];

  return [colDef] as const;
};
