import { VerificationCallResult } from '@lib-verification/api';

export const VerificationCallResultMap: Record<VerificationCallResult, string> = {
  Answer: 'Звонок состоялся',
  NonExistingNumber: 'Номер не существует или нет гудков',
  BlockedNumber: 'Номер заблокирован',
  Unavailable: 'Абонент недоступен',
  NoAnswer: 'Абонент сбрасывает и/или не отвечает',
  RefusedToTalk: 'Отказ разговаривать',
  WrongNumber: 'Номер принадлежит другому человеку / компании',
  LoanNotNeeded: 'Клиент отказался от кредита',
  CallBack: 'Перезвонить',
};
