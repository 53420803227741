<ng-container *ngIf="disablePercentControlHandler | executeWith: control?.disabled" />
<ng-container *ngIf="onTotalChange | executeWith: total" />
<ng-container *ngIf="onPercentRangeChange | executeWith: minPercent : maxPercent" />

<fnip-reactive-label
  *ngIf="control && percentControl"
  hintPosition="bottom"
  [class.inactive]="isInactive"
  [hasRequiredValidator]="hasRequiredValidator"
  [label]="label"
  [size]="labelSize"
  [isBold]="isLabelBold"
  [hint]="getHint | executeWith: mode : control.value : percentControl.value : min : minPercent"
  [noBottomHint]="noBottomHint"
>
  <div class="group d-flex">
    <tui-input-slider
      *ngIf="mode === PercentageInputMode.Percent"
      class="slider flex-1"
      [nativeId]="fieldId"
      [min]="minPercent"
      [max]="maxPercent"
      [steps]="getSteps | executeWith: minPercent : maxPercent"
      [quantum]="quantum"
      [readOnly]="isReadOnly"
      [tuiTextfieldSize]="textFieldSize"
      [tuiTextfieldLabelOutside]="true"
      [tuiTextfieldCustomContent]="textfieldCustomContent"
      [formControl]="percentControl"
      (tuiValueChanges)="onPercentChange(control, percentControl)"
    >
      {{ placeholder }}
    </tui-input-slider>

    <tui-input-slider
      *ngIf="mode === PercentageInputMode.Number"
      class="slider flex-1"
      [nativeId]="fieldId"
      [min]="getNumberLimit | executeWith: total : minPercent"
      [max]="getNumberLimit | executeWith: total : maxPercent"
      [steps]="getSteps | executeWith: min : total"
      [quantum]="quantum"
      [readOnly]="isReadOnly"
      [tuiTextfieldSize]="textFieldSize"
      [tuiTextfieldLabelOutside]="true"
      [tuiTextfieldCustomContent]="textfieldCustomContent"
      [formControl]="control"
      (tuiValueChanges)="onValueChange(control, percentControl)"
    >
      {{ placeholder }}
    </tui-input-slider>

    <tui-select
      class="mode-selector"
      [nativeId]="fieldId + 'mode'"
      [tuiTextfieldLabelOutside]="true"
      [readOnly]="isReadOnly"
      [tuiTextfieldSize]="textFieldSize"
      [valueContent]="modeMap[mode]"
      [(ngModel)]="mode"
      [disabled]="control.disabled"
      (ngModelChange)="modeChange.emit($event)"
    >
      <ng-template tuiDataList>
        <tui-data-list>
          <button tuiOption [value]="PercentageInputMode.Number">{{ modeMap[PercentageInputMode.Number] }}</button>
          <button tuiOption [value]="PercentageInputMode.Percent">{{ modeMap[PercentageInputMode.Percent] }}</button>
        </tui-data-list>
      </ng-template>
    </tui-select>
  </div>

  <fnip-reactive-field-error
    *ngIf="!noValidationMark"
    [control]="mode === PercentageInputMode.Number ? control : percentControl"
    [errorLabel]="errorLabel"
    (isErrorShow)="noBottomHint = $event"
  />
</fnip-reactive-label>
