import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BeApiEnum } from '../enums/be-api-enum';
import { Nullable } from '../interfaces/types/nullable';

export interface FeHttpErrorMessage {
  code: string;
  message: string;
}

interface FeHttpErrorOptions<T = unknown> {
  url?: string | null;
  status?: number;
  headers?: HttpHeaders;

  exceptionCode?: string | null;
  beApi: BeApiEnum | null;
  messages: FeHttpErrorMessage[];
  technicalMessages: FeHttpErrorMessage[];
  originalError: Nullable<T>;
}

const DEFAULT_ERROR_CODE = 'UNKNOWN';
const DEFAULT_ERROR_MESSAGE = 'Неизвестная ошибка';

export class FeHttpError<T = unknown> extends HttpErrorResponse {
  static readonly DEFAULT_ERROR_CODE = DEFAULT_ERROR_CODE;
  static readonly DEFAULT_ERROR_MESSAGE = DEFAULT_ERROR_MESSAGE;

  override message: string = DEFAULT_ERROR_MESSAGE;
  override readonly error: Nullable<T>;

  public readonly beApi: BeApiEnum | null;
  public readonly technicalMessages: FeHttpErrorMessage[];

  #messages: FeHttpErrorMessage[] = [];
  #knownExceptionCode: string | null;

  set messages(messages: FeHttpErrorMessage[]) {
    this.#messages = messages;

    // В typescript нельзя переопределить свойство, наследуемое от родителя, сеттером
    this.message = messages?.map((item) => item.message)?.join(', ') ?? DEFAULT_ERROR_MESSAGE;
  }

  get messages() {
    return this.#messages;
  }

  get exceptionCode() {
    return this.#knownExceptionCode || (this.technicalMessages[0] ?? this.messages[0])?.code;
  }

  constructor(options: FeHttpErrorOptions<T>) {
    super({
      url: options.url ?? undefined,
      error: options.originalError,
      headers: options.headers,
      status: options.status,
    });

    this.error = options.originalError;
    this.beApi = options.beApi;
    this.messages = options.messages;
    this.technicalMessages = options.technicalMessages;
    this.#knownExceptionCode = options.exceptionCode ?? null;
  }

  /**
   * Создает экземпляр ошибки из текста
   * Необходим в тех местах, где нужно создать ошибку на фронте
   */
  static fromText<T = unknown>(message: string, code = DEFAULT_ERROR_CODE, status?: number) {
    return new FeHttpError<T>({
      messages: [{ message, code }],
      technicalMessages: [],
      beApi: null,
      originalError: null,
      status,
    });
  }
}
