import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Nullable } from '@lib-utils';
import { ReactiveButtonCheckboxCellParams } from './reactive-button-checkbox-cell-params';

@Component({
  selector: 'fnip-reactive-button-checkbox-cell',
  templateUrl: './reactive-button-checkbox-cell.component.html',
  styleUrls: ['./reactive-button-checkbox-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReactiveButtonCheckboxCellComponent implements ICellRendererAngularComp {
  fieldId = '';

  params: Nullable<ICellRendererParams & ReactiveButtonCheckboxCellParams>;

  valueChange(val: boolean) {
    this.params?.onValueChange?.(this.params, val);
  }

  agInit(params: ICellRendererParams & ReactiveButtonCheckboxCellParams) {
    this.refreshView(params);
  }

  refresh(params: ICellRendererParams & ReactiveButtonCheckboxCellParams): boolean {
    this.refreshView(params);
    return true;
  }

  private refreshView(params: ICellRendererParams & ReactiveButtonCheckboxCellParams) {
    this.params = params;
    this.fieldId = params.getFieldId(params);
  }
}
