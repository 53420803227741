import { ColDef } from '@ag-grid-community/core';

export const widthColDef = (
  initialWidth: number,
  minWidth?: number,
  maxWidth?: number,
): Pick<ColDef, 'initialWidth' | 'minWidth' | 'maxWidth'> => ({
  initialWidth,
  minWidth,
  maxWidth,
});
