/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CallTaskType = 'Borrower' | 'Coworker' | 'Employer' | 'Living' | 'Registration' | 'Spouse' | 'Ndfl';

export const CallTaskType = {
    Borrower: 'Borrower' as CallTaskType,
    Coworker: 'Coworker' as CallTaskType,
    Employer: 'Employer' as CallTaskType,
    Living: 'Living' as CallTaskType,
    Registration: 'Registration' as CallTaskType,
    Spouse: 'Spouse' as CallTaskType,
    Ndfl: 'Ndfl' as CallTaskType
};

