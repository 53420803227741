/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VerificationSupervisionDataDto } from './verificationSupervisionDataDto';
import { SourceType } from './sourceType';
import { DateOnly } from '@lib-utils';
import { FileInfoDto } from './fileInfoDto';


export interface DriverLicenseVerificationTaskDto { 
    source?: SourceType;
    files?: Array<FileInfoDto> | null;
    relevantFiles?: Array<FileInfoDto> | null;
    verificationSupervisionDataList?: Array<VerificationSupervisionDataDto> | null;
    series?: string | null;
    number?: string | null;
    issuedBy?: string | null;
    issueDate?: DateOnly | null;
    expiryDate?: DateOnly | null;
    firstName?: string | null;
    lastName?: string | null;
    middleName?: string | null;
    birthDate?: DateOnly | null;
}
export namespace DriverLicenseVerificationTaskDto {
}


