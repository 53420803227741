import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EMPTY, Observable, tap } from 'rxjs';
import { createOptionsForLocalizedEnum, ExecuteWithPipeModule, Nullable } from '@lib-utils';
import { ReactiveButtonDropdownModule } from '@lib-widgets/reactive-fields';
import { RequestWrapperModule } from '@lib-widgets/request-wrapper';
import { VerificatorInfoDto, VerificatorStatus } from '@lib-mortgage/api';
import { VerificatorStatusNameMap } from '@lib-mortgage/utils';

@Component({
  selector: 'fnip-verificator-status',
  standalone: true,
  imports: [CommonModule, ExecuteWithPipeModule, ReactiveButtonDropdownModule, RequestWrapperModule],
  templateUrl: './verificator-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerificatorStatusComponent {
  @Input() verificator$: Nullable<Observable<Nullable<VerificatorInfoDto>>>;
  @Input() updateStatus$: Nullable<(id: Nullable<number>, status: VerificatorStatus) => Observable<unknown>>;

  update$: Nullable<Observable<unknown>>;

  readonly options = createOptionsForLocalizedEnum(VerificatorStatusNameMap);
  readonly verificatorStatusControl = new FormControl<Nullable<VerificatorStatus>>(null);

  readonly getData$ = (verificator$: typeof this.verificator$) => {
    if (!verificator$) return EMPTY;

    return verificator$.pipe(tap((data) => this.verificatorStatusControl.setValue(data?.status)));
  };
}
