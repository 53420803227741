import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  effect,
  EventEmitter,
  inject,
  Input,
  Output,
  QueryList,
  Signal,
  signal,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaskitoModule } from '@maskito/angular';
import { MaskitoOptions } from '@maskito/core';
import { TuiLetModule, TuiStringHandler, TuiValueChangesModule } from '@taiga-ui/cdk';
import {
  TuiDataListModule,
  TuiDropdownModule,
  TuiDropdownWidth,
  TuiHorizontalDirection,
  TuiLoaderModule,
  TuiOptionComponent,
  TuiScrollbarModule,
  TuiSizeL,
  TuiSizeM,
  TuiSizeS,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { TuiComboBoxModule, TuiSelectModule } from '@taiga-ui/kit';
import { PolymorpheusContent, PolymorpheusOutlet } from '@taiga-ui/polymorpheus';
import {
  ControlValueAccessorDirective,
  IndexChangeDirective,
  Nullable,
  reactiveTestAttributesHostDirective,
  SelectOption,
} from '@lib-utils';
import { ReactiveFieldErrorModule } from '../../../reactive-field-error';
import { ReactiveLabelModule } from '../../../reactive-label';
import { useWithOptions, WithOptions } from '../../options';
import { LoadingState } from '../../options/options.utils';
import { REACTIVE_SELECT_NEW_TOKEN } from '../../reactive-select-token';

@Component({
  standalone: true,
  selector: 'fnip-reactive-select-new',
  templateUrl: './reactive-select-new.component.html',
  styleUrls: ['./reactive-select-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [ControlValueAccessorDirective, reactiveTestAttributesHostDirective],
  providers: [{ provide: REACTIVE_SELECT_NEW_TOKEN, useExisting: ReactiveSelectNewComponent }],
  imports: [
    ReactiveLabelModule,
    TuiSelectModule,
    TuiTextfieldControllerModule,
    TuiDataListModule,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    TuiLetModule,
    IndexChangeDirective,
    CdkVirtualForOf,
    TuiLoaderModule,
    NgIf,
    TuiComboBoxModule,
    ReactiveFormsModule,
    TuiScrollbarModule,
    TuiValueChangesModule,
    MaskitoModule,
    ReactiveFieldErrorModule,
    PolymorpheusOutlet,
    FormsModule,
    TuiDropdownModule,
  ],
})
export class ReactiveSelectNewComponent<T = unknown> implements AfterViewInit, WithOptions {
  @Input({ required: true }) fieldId!: string;
  @Input() label?: Nullable<string>;
  @Input() labelSize: 's' | 'm' | 'l' | 'xl' = 's';
  @Input() hint: Nullable<string>;
  @Input() noBottomHint = false;
  @Input() isLabelBold = false;
  @Input() optionContent: Nullable<PolymorpheusContent>;
  @Input() selectedOptionContent: Nullable<PolymorpheusContent>;

  @Input() textFieldSize: TuiSizeS | TuiSizeM | TuiSizeL = 'm';
  @Input() dropdownAlign: TuiHorizontalDirection = 'right';
  @Input() dropdownWidth: TuiDropdownWidth = 'fixed';
  @Input() textfieldLabelOutside = true;
  @Input() textfieldCleaner = false;
  @Input() placeholder: Nullable<string>;
  @Input() textfieldCustomContent?: PolymorpheusContent;
  @Input() isReadOnly = false;
  @Input() textMask: Nullable<MaskitoOptions>;
  @Input() pseudoPressed = false;
  @Input() noValidationMark?: boolean;
  @Input() errorLabel?: Nullable<string>;
  @Input() optionHeight = 48;

  @Output() valueChange = new EventEmitter(true);
  @Output() selectedOptionChange = new EventEmitter<Nullable<SelectOption<T>>>();

  @Input() optionListEmptyMessage = 'Ничего не найдено';

  @ViewChildren(TuiOptionComponent) tuiOptions: Nullable<QueryList<TuiOptionComponent>>;
  @ViewChild(CdkVirtualScrollViewport) cdkScroll: Nullable<CdkVirtualScrollViewport>;

  readonly destroyRef = inject(DestroyRef);
  readonly controlValueAccessor = inject(ControlValueAccessorDirective);

  controlValue = this.controlValueAccessor.value;

  selectedOption!: Signal<SelectOption<T> | null>;
  optionLoadingMessage!: Signal<string | null | undefined>;
  optionList!: Signal<SelectOption<T>[]>;
  optionListLoading!: Signal<LoadingState>;
  optionListErrorMessage!: Signal<string | null>;
  stringifyText!: TuiStringHandler<string | number>;

  hasRenderedTuiOptions$ = signal(false);

  constructor() {
    useWithOptions(this);

    effect(() => this.selectedOptionChange.emit(this.selectedOption()));

    /**
     * При открытии списка опции и наличии выбранной опции и самого списка опций, автоматически скроллим к выбранной опции
     */
    effect(() => {
      const controlValue = this.controlValue();
      const optionList = this.optionList();

      if (!this.hasRenderedTuiOptions$() && controlValue) return;

      const selectedIndex = optionList?.findIndex(({ value }) => value === controlValue);

      if (selectedIndex && selectedIndex > -1) this.cdkScroll?.scrollToIndex(selectedIndex);
    });
  }

  ngAfterViewInit() {
    this.tuiOptions?.changes
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((options: QueryList<TuiOptionComponent>) => this.hasRenderedTuiOptions$.set(options.length > 0));
  }

  resetControl() {
    this.controlValueAccessor.setControlValue(null);
  }
}
