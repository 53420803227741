import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TuiLetModule } from '@taiga-ui/cdk';
import { TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiDataListWrapperModule, TuiPaginationModule, TuiSelectModule } from '@taiga-ui/kit';
import { ExecuteWithPipeModule } from '@lib-utils';
import { ButtonModule } from '@lib-widgets/core';
import { ReactiveSelectModule } from '@lib-widgets/reactive-fields';
import { RequestWrapperModule } from '@lib-widgets/request-wrapper';
import { SidebarModule } from '@lib-widgets/sidebar';
import { GridBaseModule } from './grid-base';
import { GridExtraActionsDirective } from './grid-extra-actions.directive';
import { GridStateToolbarComponent } from './grid-state-toolbar';
import { GridComponent } from './grid.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    ExecuteWithPipeModule,
    FormsModule,
    SidebarModule,
    RequestWrapperModule,
    GridExtraActionsDirective,
    GridStateToolbarComponent,
    GridBaseModule,
    TuiPaginationModule,
    TuiSelectModule,
    TuiDataListWrapperModule,
    TuiTextfieldControllerModule,
    ReactiveSelectModule,
    TuiLetModule,
    TranslateModule,
  ],
  declarations: [GridComponent],
  exports: [GridComponent, GridExtraActionsDirective],
})
export class GridModule {}
