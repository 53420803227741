<ng-container *ngIf="hasLocalisation && (onLocalisationChange$ | async)" />

<ng-container *tuiLet="breakpointService.isMobile$ | async as isMobile">
  <fnip-request-wrapper #dataList [request$]="data$" [backButtonCallback$]="backButtonCallback$">
    <ng-container *ngIf="applySavedState$ | async" />

    <fnip-grid-base
      [gridClass]="isMobile ? [gridClass, 'mobile'] : gridClass"
      [gridOptions]="gridOptions"
      [rowClassRules]="rowClassRules"
      [colDefs]="colDefs"
      [rowData]="$any(dataList.data)"
      (gridReady)="onGridReady()"
      (sortChanged)="onGridSortChanged($event)"
      (columnMoved)="gridStateService?.onStateChange(this.gridId)"
      (cellValueChanged)="cellValueChanged.emit($event)"
      (selectionChanged)="selectionChanged.emit($event)"
      (rowDataUpdated)="rowDataUpdated.emit($event)"
    />
  </fnip-request-wrapper>

  <div class="d-flex gap-2 mt-5 content-center flex-wrap" *ngIf="dataList.hasFirstRs && !dataList.error">
    <div class="d-flex gap-2 align-items-center">
      <ng-container
        *ngIf="!isMobile"
        [ngTemplateOutlet]="pageSizer"
        [ngTemplateOutletContext]="{ isMobile, dataList }"
      />
      <tui-pagination
        *ngIf="pages > 1"
        [sidePadding]="1"
        [length]="pages"
        [(index)]="pageIndex"
        (indexChange)="pageIndexChange()"
      />
    </div>
    <div class="d-flex gap-2 align-items-center">
      <ng-container
        *ngIf="isMobile"
        [ngTemplateOutlet]="pageSizer"
        [ngTemplateOutletContext]="{ isMobile, dataList }"
      />

      <ng-container *ngIf="onGridCompactChange | executeWith: gridOptions?.api : compactSelectControl.value" />
      <fnip-reactive-select
        *ngIf="hasModeSelection"
        fieldId="compactSelectControl"
        textFieldSize="s"
        [style.width.px]="160"
        [control]="compactSelectControl"
        [initialOptions]=""
        [options]="hasLocalisation ? compactTranslatedOptions$ : compactOptions$"
      />
      <fnip-button
        *ngIf="!gridOptions?.suppressExcelExport"
        appearance="whiteblock"
        btnSize="s"
        icon="tuiIconDownload"
        [label]="isMobile ? '' : hasLocalisation ? ('BASE.BTN_LABELS.EXCEL_EXPORT' | translate) : 'Скачать excel'"
        [actionCallback$]="excelExport$"
      />
      <fnip-button
        *ngIf="gridId"
        appearance="whiteblock"
        btnSize="s"
        icon="tuiIconSettings"
        [actionCallback$]="toggleSidebar | executeWith: sidebar"
      />
      <ng-content select="[fnipGridExtraActions]" />
    </div>
  </div>
</ng-container>

<ng-template #sidebar="sidebar" fnipSidebar [sidebarId]="gridId!">
  <fnip-grid-state-toolbar
    [gridId]="gridId!"
    [gridOptions]="gridOptions"
    [initialFilterModel]="initialFilterModel"
    [initialColumnState]="initialColumnState"
    (closed)="sidebar.close()"
  />
</ng-template>

<ng-template #pageSizer let-isMobile="isMobile" let-dataList="dataList">
  <ng-container *ngIf="perPageOptionsShown && dataList.data?.length">
    <span *ngIf="!isMobile" class="text-nowrap">
      {{ hasLocalisation ? ('BASE.GRID.TOTAL_ITEMS' | translate) : 'Элементов на странице:' }}
    </span>
    <tui-select
      tuiTextfieldSize="s"
      [style.min-width.px]="80"
      [tuiTextfieldLabelOutside]="true"
      [ngModel]="perPage"
      (ngModelChange)="perPageChange($event)"
    >
      <tui-data-list-wrapper *tuiDataList [items]="perPageOptions" />
    </tui-select>
  </ng-container>
</ng-template>
