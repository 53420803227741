import { ActivatedRoute } from '@angular/router';
import isNil from 'lodash-es/isNil';
import { Breadcrumb, Nullable } from '@lib-utils';

/**
 * Позволяет пропустить в Breadcrumb заголовок страницы
 */
export const BREADCRUMB_HIDE_TITLE = 'BREADCRUMB_HIDE_TITLE';
export const withHiddenBreadcrumb = () => ({
  [BREADCRUMB_HIDE_TITLE]: true,
});

export const mapRouteChildren = (route: Nullable<ActivatedRoute>, url = '', items: Breadcrumb[] = []): Breadcrumb[] => {
  if (isNil(route)) return items;

  const currentUrl = [url, ...route.snapshot.url.map(({ path }) => path)].join('/');
  const breadcrumbData =
    route.snapshot.title && !route.snapshot.data[BREADCRUMB_HIDE_TITLE]
      ? ({ title: route.snapshot.title, url: currentUrl } satisfies Breadcrumb)
      : null;

  const breadcrumbs = isNil(breadcrumbData) ? [] : [breadcrumbData];

  return mapRouteChildren(route.firstChild, currentUrl, [...items, ...breadcrumbs]);
};
