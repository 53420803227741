/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type VerifierStatus = 'NotReady' | 'Ready' | 'Lunch' | 'Break' | 'InProgress' | 'OutgoingCall';

export const VerifierStatus = {
    NotReady: 'NotReady' as VerifierStatus,
    Ready: 'Ready' as VerifierStatus,
    Lunch: 'Lunch' as VerifierStatus,
    Break: 'Break' as VerifierStatus,
    InProgress: 'InProgress' as VerifierStatus,
    OutgoingCall: 'OutgoingCall' as VerifierStatus
};

