/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ReasonForResidence = 'Property' | 'SocialHousing' | 'Rent' | 'MilitaryUnit' | 'RelativesProperty';

export const ReasonForResidence = {
    Property: 'Property' as ReasonForResidence,
    SocialHousing: 'SocialHousing' as ReasonForResidence,
    Rent: 'Rent' as ReasonForResidence,
    MilitaryUnit: 'MilitaryUnit' as ReasonForResidence,
    RelativesProperty: 'RelativesProperty' as ReasonForResidence
};

