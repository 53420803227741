/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type OrganizationLifetime = 'UnderTwoYears' | 'BetweenTwoAndFiveYears' | 'MoreThanFiveYears';

export const OrganizationLifetime = {
    UnderTwoYears: 'UnderTwoYears' as OrganizationLifetime,
    BetweenTwoAndFiveYears: 'BetweenTwoAndFiveYears' as OrganizationLifetime,
    MoreThanFiveYears: 'MoreThanFiveYears' as OrganizationLifetime
};

