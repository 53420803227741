/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface IncomeDocumentMonthlyIncomeVerificationDto { 
    id?: number;
    month?: string | null;
    code?: string | null;
    sum?: number;
    rate?: number | null;
    year?: string | null;
    manualTaxAmount?: number | null;
}

