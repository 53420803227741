<tui-tag
  *ngIf="value && !icon"
  class="text-ellipsis"
  [ngClass]="tagClass"
  [ngStyle]="styles"
  [hoverable]="false"
  [editable]="false"
  [removable]="false"
  [status]="status"
  [value]="value"
/>

<tui-tag
  *ngIf="value && icon"
  class="text-ellipsis"
  [ngStyle]="styles"
  [hoverable]="false"
  [editable]="false"
  [removable]="false"
  [leftContent]="iconTmp"
  [status]="status"
  [value]="value"
/>

<ng-template #iconTmp>
  <tui-svg *ngIf="icon" class="tag-icon" [ngStyle]="iconStyles" [src]="icon" />
</ng-template>
