@if (rows?.length) {
  <div [class]="rowsContainerClass ?? 'd-flex flex-column gap-1'">
    @for (row of rows; track row) {
      @if (row.value) {
        @switch (row.type) {
          @case (MultiRowType.Text) {
            <p [class]="row.class">{{ row.value }}</p>
          }
          @case (MultiRowType.Tag) {
            <tui-tag
              class="text-ellipsis w-fit-content"
              [hoverable]="false"
              [editable]="false"
              [removable]="false"
              [ngClass]="row.class"
              [ngStyle]="row.styles"
              [status]="row.tagStatus ?? 'default'"
              [value]="row.value"
            />
          }
        }
      }
    }
  </div>
}
