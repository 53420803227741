import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormControlsOf, FormGroupOf, Nullable } from '@lib-utils';
import {
  CallEmploymentVerificationTaskDto,
  CallVerificationTaskVerificationCallUpdateRequest,
  EmploymentPhoneDto,
} from '@lib-verification/api';
import { FeCallVerificationTask, FeEmploymentCallDto } from '@lib-verification/api-middleware';

export function getCallsFormArrayForNew(data: Nullable<CallEmploymentVerificationTaskDto>) {
  return new FormArray<FormGroupOf<FeCallVerificationTask>>(data?.employment?.phones?.map(getCallFormForNew) ?? []);
}

export function getOldCallsFormGroupForNew(data: Nullable<CallEmploymentVerificationTaskDto>) {
  const formControls: FormControlsOf<FeEmploymentCallDto> = {};

  data?.client?.employments?.forEach((employment) => {
    if (employment.id)
      formControls[employment.id] = new FormArray<FormGroupOf<EmploymentPhoneDto>>(
        employment?.phones?.map(getCallFormForNew) ?? [],
      );
  });

  return new FormGroup<FormControlsOf<FeEmploymentCallDto>>(formControls);
}

export function getCallFormForNew(phone: Nullable<EmploymentPhoneDto>) {
  return new FormGroup<FormControlsOf<FeCallVerificationTask>>({
    id: new FormControl(phone?.id, Validators.required),
    number: new FormControl(phone?.number),
    source: new FormControl(phone?.source),
    verificationCall: new FormGroup<FormControlsOf<CallVerificationTaskVerificationCallUpdateRequest>>({
      getThrough: new FormControl(phone?.verificationCall?.getThrough, Validators.required),
      reachedAccounting: new FormControl(phone?.verificationCall?.reachedAccounting),
      employmentConfirmed: new FormControl(phone?.verificationCall?.employmentConfirmed),
      callResult: new FormControl(phone?.verificationCall?.callResult),
      comment: new FormControl(phone?.verificationCall?.comment),
    }),
  });
}
