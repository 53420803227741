/**
 * Octasoft.Notification.InApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ListMessagesItemMetadata } from './listMessagesItemMetadata';


export interface ListMessagesItem { 
    id: number;
    title: string | null;
    body: string | null;
    created: string;
    read: boolean;
    metadata: ListMessagesItemMetadata;
}

