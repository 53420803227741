import { inject, Injectable } from '@angular/core';
import {
  FnipNotification,
  FnipPaginatedNotificationList,
  NotificationSource,
} from '@lib-notifications/notification-list';
import addSeconds from 'date-fns/addSeconds';
import { catchError, EMPTY, exhaustMap, Observable, of, timer } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { NotificationService } from '@lib-utils';
import {
  NotificationApiService,
  NotificationDto,
  ReadNotificationDto,
  UserNotificationDtoPageResultData,
} from '@lib-mortgage/api';

const UPDATE_INTERVAL = 10_000;

@Injectable()
export class MortgageNotificationSource extends NotificationSource<NotificationDto, number, number> {
  private fromDate: Date | null = null;

  private readonly notificationService = inject(NotificationService);
  private readonly notificationApiService = inject(NotificationApiService);

  getListBefore$(
    page: number | null,
    count: number,
  ): Observable<FnipPaginatedNotificationList<number, NotificationDto, number>> {
    if (!this.fromDate) this.fromDate = new Date();

    const query = {
      filters: {
        IsReaded: 'false',
        ReceiveOnSite: 'true',
        CreatedBefore: this.fromDate.toISOString().replace(/\..+/, ''),
      },
    };

    return this.notificationApiService
      ?.apiNotificationUserNotificationsGet(page ?? 1, count, query, 'DESC', 'Created')
      .pipe(map((result) => this.mapMortgageResult(result.data)));
  }

  getSubscription$(): Observable<FnipNotification<number, NotificationDto>[]> {
    return this.startNotificationsPolling((fromDate) => {
      const query = {
        filters: {
          IsReaded: 'false',
          ReceiveOnSite: 'true',
          CreatedAfter: fromDate.toISOString().replace(/\..+/, ''),
        },
      };

      return this.notificationApiService?.apiNotificationUserNotificationsGet(1, 500, query, 'DESC', 'Created').pipe(
        map((result) => this.mapMortgageResult(result.data)),
        map((data) => data.data),
      );
    });
  }

  markAsRead$(notification: FnipNotification<number, NotificationDto>): Observable<unknown> {
    return this.notificationApiService?.apiNotificationUserMarkAsReadPost([notification.id!]);
  }

  private mapMortgageResult(
    mortgageResult?: UserNotificationDtoPageResultData,
  ): FnipPaginatedNotificationList<number, NotificationDto, number> {
    if (!mortgageResult?.data || !mortgageResult.page) return { data: [], nextCursor: null, totalUnread: 0 };

    const { page, pages, items } = mortgageResult.page;
    const hasNextPage = (pages ?? 0) > (page ?? 0);
    const nextPage = (page ?? 0) + 1;

    return {
      nextCursor: hasNextPage ? nextPage : null,
      totalUnread: items ?? 0,
      data: [
        ...mortgageResult.data.map((data: ReadNotificationDto) => ({
          id: data.id ?? 0,
          shortText: data.eventDescription ?? data.notificationText ?? '',
          fullText: data.notificationText ?? '',
          isRead: data.isReaded ?? false,
          createdAt: new Date(data.created ?? ''),
          data,
        })),
      ].reverse(),
    };
  }

  // Начиная с текущей даты, раз в UPDATE_INTERVAL секунд вызываем pullCb и получаем новые уведомления
  // Затем сохраняем последнюю дату, чтобы в следующий раз запрашивать новые уведомления после этой даты
  // И возвращаем последний результат pullCb или пустой массив, если pullCb вернул ошибку
  private startNotificationsPolling<T extends FnipNotification>(pullCb: (fromDate: Date) => Observable<T[]>) {
    let lastDate = new Date();
    return timer(0, UPDATE_INTERVAL).pipe(
      // Получаем новые уведомления, у которых дата создания больше чем у последнего полученного
      exhaustMap(
        () =>
          pullCb(lastDate).pipe(
            tap(this.notificationService.onError('Ошибка при получении уведомлений')),
            catchError(() => of([])),
          ) ?? EMPTY,
      ),
      tap((notifications) => {
        // Поскольку в фильтрах по дате равнение >=, добавляем одну секунду к дате последнего уведомления
        if (notifications.length) lastDate = addSeconds(notifications[notifications.length - 1].createdAt, 1);
      }),
    );
  }
}
