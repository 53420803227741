import { inject, InjectionToken, signal } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { Nullable } from '@lib-utils';

export const CABINET_TITLE = 'cabinet_title';
export const ROUTE_TITLE_DESCRIPTION = new InjectionToken('', {
  providedIn: 'root',
  factory: () => signal<Nullable<string>>(null),
});

export const CLEAR_TITLE_DESCRIPTION_ON_NAVIGATE_FACTORY = () => {
  const router = inject(Router);
  const titleDescription = inject(ROUTE_TITLE_DESCRIPTION);
  return () => {
    router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => titleDescription.set(null));
  };
};
