import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { Nullable } from '@lib-utils';
import { TagCellListValue, TagListCellConfig } from './tag-list-cell-config';

@Component({
  selector: 'fnip-tag-list-cell',
  templateUrl: './tag-list-cell.component.html',
  styleUrls: ['./tag-list-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagListCellComponent implements ICellRendererAngularComp {
  value: Nullable<TagCellListValue>;
  joinBy: Nullable<string>;

  readonly cdr = inject(ChangeDetectorRef);

  agInit(params: ICellRendererParams & TagListCellConfig) {
    this.refreshView(params);
  }

  refresh(params: ICellRendererParams & TagListCellConfig): boolean {
    this.refreshView(params);
    return true;
  }

  private refreshView(params: ICellRendererParams<unknown, TagCellListValue> & TagListCellConfig) {
    this.value = params.value;
    this.joinBy = params.joinBy;
    this.cdr.markForCheck();
  }
}
