export const EngineType = {
  Diesel: 'Diesel',
  Gas: 'Gas',
  Hybrid: 'Hybrid',
  Electric: 'Electric',
  Petrol: 'Petrol',
} as const;

export type EngineType = (typeof EngineType)[keyof typeof EngineType];

export const EngineTypeMap: Record<EngineType, string> = {
  [EngineType.Diesel]: 'Дизель',
  [EngineType.Gas]: 'Газ',
  [EngineType.Hybrid]: 'Гибрид',
  [EngineType.Electric]: 'Электро',
  [EngineType.Petrol]: 'Бензин',
};
