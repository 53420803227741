/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Ndfl2Model } from './ndfl2Model';


export interface Ndfl2IncomeModel { 
    id?: number;
    ndfl2Id?: number;
    month?: number;
    code?: string | null;
    sum?: number;
    rate?: number;
    isActive?: boolean;
    ndfl2?: Ndfl2Model;
    externalId?: string;
}

