import { ColumnState, GridOptions } from '@ag-grid-community/core';
import { Nullable } from '@lib-utils';

export interface GridState {
  columnState?: ColumnState[];
  filterModel?: { [key: string]: unknown };
}

export interface GridSavedState {
  createDate: Date;
  state: GridState;
}

export function getGridState(gridOptions: Nullable<GridOptions>): Nullable<GridState> {
  return gridOptions
    ? {
        columnState: gridOptions.columnApi?.getColumnState() ?? [],
        filterModel: gridOptions.api?.getFilterModel(),
      }
    : null;
}
