/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreditDebtLoadSummaryDto } from './creditDebtLoadSummaryDto';
import { DateOnly } from '@lib-utils';


export interface PdnCalculationResultDto { 
    pdn?: number;
    usedIncome?: number;
    usedRegionalIncome?: boolean;
    calculationDate?: DateOnly;
    creditDebtLoadSummary?: CreditDebtLoadSummaryDto;
}

