/* eslint-disable @typescript-eslint/no-explicit-any */
import { Directive, EventEmitter, inject, NgZone, signal } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NgControl } from '@angular/forms';

/**
 * Директива для удобной реализации controlValueAccessor в кастомных компонентах
 */
@Directive({
  selector: '[fnipControlValueAccessor]',
  exportAs: 'fnipControlValueAccessor',
  standalone: true,
  providers: [],
})
export class ControlValueAccessorDirective<T> implements ControlValueAccessor {
  #zone = inject(NgZone);
  #ngControl = inject(NgControl, { optional: true });
  #touched = signal(false);
  #disabled = signal(false);
  #value = signal<T | null>(null);
  #changedFromOutside = new EventEmitter<T>();

  constructor() {
    if (!this.#ngControl) {
      // throw new Error('ControlValueAccessorDirective: Must be used with NgModel or FormControl');
      return;
    }

    this.#ngControl.valueAccessor = this;
    // RAUTO-322: Добавляем коллбэк чтобы обновить валидность контрола после дестроя директивы
    this.#ngControl['_onDestroyCallbacks'].push(() => this.control?.updateValueAndValidity());
  }

  /**
   * Болванки, куда будут по ссылке "зарегистрированы" коллбеки,
   * позволяющие компоненту сообщить об изменениях родительской форме
   *
   * ! onChange - Необходимо вызывать при любом изменении значения **внутри компонента**
   * ! onTouched - Необходимо вызывать при любом прикосновении **внутри компонента**
   */
  /** @internal */
  onChange = (_changedValue: T) => {}; // eslint-disable-line @typescript-eslint/no-empty-function
  /** @internal */
  onTouched = () => {}; // eslint-disable-line @typescript-eslint/no-empty-function

  public isDisabled = this.#disabled.asReadonly();
  public isTouched = this.#touched.asReadonly();
  public value = this.#value.asReadonly();
  public changedFromOutside = this.#changedFromOutside.asObservable();

  public get control() {
    return (this.#ngControl?.control as FormControl) ?? null;
  }

  public get isRequired() {
    if (!this.control?.validator) return false;
    return this.control.validator?.({} as AbstractControl)?.['required'];
  }

  /**
   * Публичное API для задания значения контролу
   */
  public setControlValue(newValue: T) {
    this.#value.set(newValue);
    this.markAsTouched();
    this.onChange(newValue);
  }

  public markAsTouched() {
    this.#touched.set(true);
    this.onTouched();
  }

  /**
   * Происходит когда извне в контрол попадает значение
   * @param value - значение
   * @internal
   */
  writeValue(value: T): void {
    this.#zone.runOutsideAngular(() => {
      queueMicrotask(() => {
        this.#value.set(value);
        this.#changedFromOutside.emit(value);
      });
    });
  }

  /**
   * Происходит когда извне выключают или включают контрол
   * @param isDisabled
   * @internal
   */
  setDisabledState?(isDisabled: boolean): void {
    this.#zone.runOutsideAngular(() => {
      queueMicrotask(() => {
        this.#disabled.set(isDisabled);
      });
    });
  }

  /** @internal */
  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }
  /** @internal */
  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }
}
