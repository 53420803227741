import { HttpErrorResponse } from '@angular/common/http';
import { DestroyRef, Inject, Injectable, Injector } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TuiAlertService, TuiNotificationT } from '@taiga-ui/core';

/**
 * TODO: Убрать после перехода на 4.x версию Taiga UI
 * Внутри @taiga-ui/cdk еще смотрит на PolymorpheusComponent из @tinkoff/ng-polymorpheus
 */
// eslint-disable-next-line import/no-extraneous-dependencies
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import remove from 'lodash-es/remove';
import { Observer, Subscription } from 'rxjs';
import { FeHttpError } from '../../classes/fe-http-error';
import { defaultTransformErrorMessage } from '../../functions';
import { NotificationComponent } from './notification.component';
import { DEFAULT_ERROR_MESSAGE } from './utils/notification-constants';

interface NotificationInfo {
  notification: Subscription;
  key?: string;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  subscriptionNotificationList: NotificationInfo[] = [];

  constructor(
    @Inject(TuiAlertService) private readonly alertService: TuiAlertService,
    private readonly destroyRef: DestroyRef,
    private readonly injector: Injector,
  ) {}

  /**
   * Вывести предупреждение
   * @param text Текст сообщения
   * @param key Ключ для идентификации нотификации
   */
  showWarning(text: string, key?: string): void {
    this.show(text, 'warning', false, undefined, key);
  }

  /**
   * Вывести сообщение об ошибке
   * @param text Текст сообщения
   * @param key Ключ для идентификации нотификации
   */
  showError(text: string | undefined, label?: string, key?: string): void {
    this.show(text || DEFAULT_ERROR_MESSAGE, 'error', false, label, key);
  }

  /**
   * Вывести сообщение об успехе
   * @param text Текст сообщения
   * @param key Ключ для идентификации нотификации
   */
  showSuccess(text: string, key?: string): void {
    this.show(text, 'success', true, undefined, key);
  }

  /**
   * Вывести сообщение
   * @param text Текст сообщения
   * @param status Статус
   * @param autoClose Закрыть высплывающее сообщение автоматически
   * @param label Заголовок сообщения
   * @param key Ключ для идентификации нотификации
   */
  show(text: string, status: TuiNotificationT = 'info', autoClose = true, label?: string, key?: string) {
    let notification: Subscription | null = this.alertService
      .open(new PolymorpheusComponent(NotificationComponent, this.injector), {
        autoClose,
        status,
        label,
        data: text,
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();

    if (!autoClose) {
      this.subscriptionNotificationList.push({ notification, key });
    }

    notification = null;
  }

  /**
   * Закрыть сообщения с определенным ключом
   * @param key Ключ для поиска нотификаций
   */
  close(key: string) {
    const subscriptionsToClose = remove(
      this.subscriptionNotificationList,
      (notificationInfo) => notificationInfo.key === key,
    );
    subscriptionsToClose.forEach(({ notification }) => notification.unsubscribe());
  }

  allClose(): void {
    this.subscriptionNotificationList.forEach(({ notification }) => notification.unsubscribe());
    this.subscriptionNotificationList = [];
  }

  /**
   * Нотификация об успешной операции в потоке
   *
   * @example
   * tap(this.notificationService.onSuccess('Кредитные договора обновлены')) // Выведет нотификацию с текстом успеха
   *
   * @param text Текст нотификации
   */
  onSuccess = <T>(text: string, key?: string): Partial<Observer<T>> => ({
    next: () => this.showSuccess(text, key),
  });

  /**
   * Нотификация об ошибке в потоке
   *
   * @example
   * tap(this.notificationService.onError()), // Выведет ошибку error.error.message
   *
   * @example
   * tap(this.notificationService.onError('Отправка файла')), // Выведет ошибку error.error.message c заголовком 'Отправка файла'
   *
   * @param label Текст заголовка
   * @param key Ключ для идентификации нотификации
   */
  onError = <T>(label?: string, key?: string): Partial<Observer<T>> => ({
    error: (error: Partial<HttpErrorResponse>) => {
      if (error instanceof FeHttpError) return this.showError(defaultTransformErrorMessage(error.messages), label, key);

      this.showError(error?.error?.message, label);
    },
  });
}
