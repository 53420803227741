import { ElementRef } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { Nullable } from '@lib-utils';

export function getGridScrollbar(
  gridElementRef: Nullable<ElementRef<Element>>,
  selector: string,
): Nullable<PerfectScrollbar> {
  const el = gridElementRef?.nativeElement?.querySelector(selector);
  if (!el) return null;
  const ps = new PerfectScrollbar(el, {
    suppressScrollY: true,
  });
  ps.update();
  return ps;
}
