import { ColDef } from '@ag-grid-community/core';
import { exactWidth } from '../../utils';
import { ActionCellParams } from './action-cell-params';
import { ActionCellComponent } from './action-cell.component';

export const getActionCellDef = <TData = unknown>(params: ActionCellParams<TData>) =>
  ({
    field: undefined,
    cellRenderer: ActionCellComponent,
    cellRendererParams: params,
    suppressCellFlash: true,
    sortable: false,

    ...(!params.getActionLabel && !params.actionLabel && !params.getLabel && !params.label
      ? { ...exactWidth(44), cellStyle: { padding: '0 5px' } as const }
      : ({} as const)),
  }) as const satisfies ColDef<TData>;
