import { inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Nullable } from '@lib-utils';
import { FNIP_APP_NAME } from '../config';
import { CABINET_TITLE, ROUTE_TITLE_DESCRIPTION } from './constants';

@Injectable()
export class OctasoftTitleStrategy extends TitleStrategy {
  private readonly titleService = inject(Title);
  private readonly applicationName = inject(FNIP_APP_NAME);
  private readonly routeTitleDescription = inject(ROUTE_TITLE_DESCRIPTION);
  private readonly translateService = inject(TranslateService, { optional: true });

  override updateTitle(routerState: RouterStateSnapshot) {
    let routeTitle = this.buildTitle(routerState);
    if (!routeTitle) return;

    const data = this.reduceDataFromRoute(routerState.root);
    const titleDescription = this.routeTitleDescription();

    let cabinet = data[CABINET_TITLE] ?? null;
    if (this.translateService) {
      if (cabinet) cabinet = this.translateService.instant(cabinet);
      routeTitle = this.translateService.instant(routeTitle) as string;
    }

    const title = this.makeTitle(routeTitle, titleDescription, cabinet);
    this.titleService.setTitle(title);
  }

  private makeTitle(routeTitle: string, titleDescription: Nullable<string>, cabinetTitle: Nullable<string>) {
    return [
      routeTitle ? routeTitle : null,
      titleDescription ? `(${titleDescription})` : null,
      cabinetTitle && routeTitle !== cabinetTitle ? `- Octasoft. ${cabinetTitle}` : null,
      this.applicationName ? `- ${this.applicationName}` : null,
    ]
      .filter(Boolean)
      .join(' ');
  }

  private reduceDataFromRoute(
    route: ActivatedRouteSnapshot | null,
    parentData: Record<string, string> = {},
  ): Record<string, string> {
    if (!route) return parentData;
    return this.reduceDataFromRoute(route.firstChild, { ...parentData, ...(route.data ?? {}) });
  }
}
