<h1 class="mb-4 tui-text_h4">
  Назначить {{ dialogContext.data?.role }} для заявки №{{ dialogContext.data?.applicationId }}
</h1>

<fnip-reactive-select
  fieldId="underwriterId"
  class="mb-5"
  [label]="dialogContext.data?.role"
  [requiredIf]="true"
  [control]="executorControl"
  [options]="dialogContext.data?.selectOptions$"
/>

<div class="d-flex gap-2">
  <fnip-button class="ml-auto" label="Отмена" [actionCallback$]="close" />
  <fnip-button
    appearance="primary"
    label="Назначить"
    [isDisabled]="executorControl.invalid"
    [actionCallback$]="setUnderwriter$"
  />
</div>
