import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from '@lib-config';
import { BeApiEnum, getBeApiModule, Nullable } from '@lib-utils';
import { SKIP_TRIM_DATA_TOKEN } from './skip-trim';

@Injectable()
export class WihoutEmptyFieldsHttpInterceptor implements HttpInterceptor {
  private readonly configService = inject(ConfigService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const { body } = request;
    const beApi = getBeApiModule(request.url, this.configService);
    // FNAUTO-399: Отключаем очистку полей для апи Авто
    if (body && !request.context?.get(SKIP_TRIM_DATA_TOKEN) && beApi !== BeApiEnum.Auto) {
      const data = this.withoutEmpty(body);
      const isBodyRequired = ['POST', 'PUT', 'PATCH'].includes(request.method.toUpperCase());
      const r = request.clone({ body: isBodyRequired && !data ? {} : data });
      return next.handle(r);
    }
    return next.handle(request);
  }

  // TODO: убрать
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private withoutEmpty(obj: any): any {
    if (this.isNull(obj)) return null;
    if (obj instanceof FormData) return obj;
    if (obj instanceof Array) {
      if (obj.length === 0) return null;
      return obj.map((e) => this.withoutEmpty(e)).filter((e) => Boolean(e));
    }
    if (obj instanceof Date) return this.convertDate(obj);
    if (typeof obj === 'object') {
      const map = {} as Record<string, unknown>;
      Object.keys(obj).forEach((key) => {
        const value = this.withoutEmpty(obj[key]);
        if (!this.isNull(value)) {
          map[key] = value;
        }
      });
      return Object.keys(map).length > 0 ? map : null;
    }
    return obj;
  }

  private isNull<T>(value: Nullable<T> | string | T[]): boolean {
    return value === null || value === undefined || value === '' || (Array.isArray(value) && value.length === 0);
  }

  private convertDate(value: Date): string {
    return value?.toISOString();
  }
}
