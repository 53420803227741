<tui-data-list class="px-3" [style.width.px]="548">
  <button
    *ngFor="let item of notificationList; trackBy: trackByFn"
    tuiOption
    class="w-100 my-2 p-0"
    (click)="!item.isRead && markAsRead.emit(item)"
  >
    <fnip-notification-list-item
      class="w-100"
      [isRead]="item.isRead"
      [fullText]="item.fullText"
      [date]="item.createdAt"
    />
  </button>
</tui-data-list>

<fnip-button
  *ngIf="hasLoadMoreButton"
  data-testid="load-more-button"
  class="w-fit-content mb-3 mx-auto"
  appearance="flat"
  label="Загрузить еще"
  [actionCallback$]="loadMoreHistory$"
/>
