/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UpdateBuyerInfoOnPrepareForDealStateRequest } from './updateBuyerInfoOnPrepareForDealStateRequest';
import { UpdateRealEstateOnPrepareForDealStateRequest } from './updateRealEstateOnPrepareForDealStateRequest';
import { InseparableImprovementsType } from './inseparableImprovementsType';
import { UpdateFirstPaymentInfoOnPrepareForDealStateRequest } from './updateFirstPaymentInfoOnPrepareForDealStateRequest';
import { RegistrationType } from './registrationType';
import { DateOnly } from '@lib-utils';
import { CreateOrUpdateOwnerRequisitesRequest } from './createOrUpdateOwnerRequisitesRequest';
import { DateTime } from '@lib-utils';


export interface UpdateOnPrepareForDealStateRequest { 
    paymentDate: DateOnly;
    firstPaymentInfos: Array<UpdateFirstPaymentInfoOnPrepareForDealStateRequest>;
    creditTransferPeriod: number;
    cftDepartmentId: number;
    dealDepartmentId: number;
    bankSignerId: number;
    date: DateTime;
    registrationType: RegistrationType;
    inseparableImprovements?: InseparableImprovementsType;
    inseparableImprovementsCost?: number | null;
    contractOfSaleCost?: number | null;
    ownerRequisites: CreateOrUpdateOwnerRequisitesRequest;
    electronicRegistrationCost?: number | null;
    realEstates: Array<UpdateRealEstateOnPrepareForDealStateRequest>;
    buyers: Array<UpdateBuyerInfoOnPrepareForDealStateRequest>;
}
export namespace UpdateOnPrepareForDealStateRequest {
}


