import { FromGeneratedEnum } from '@lib-utils';
import { ProofOfIncome } from '@lib-mortgage/api';

export const ProofOfIncomeMap: FromGeneratedEnum<ProofOfIncome> = {
  [ProofOfIncome.IncomeTaxRate]: '2-НДФЛ',
  [ProofOfIncome.PensionFundReference]: 'Выписка из ПФР',
  [ProofOfIncome.PensionCertificate]: 'Справка о размере пенсии',
  [ProofOfIncome.BankFormReference]: 'Справка по форме банка',
  [ProofOfIncome.AccountStatement]: 'Выписка по счету',
  [ProofOfIncome.BusinessOwner]: 'Владелец бизнеса',
  [ProofOfIncome.BusinessNoProof]: 'Бизнес - без подтверждения',
  [ProofOfIncome.EmploymentNoProof]: 'Найм - без подтверждения',
  [ProofOfIncome.ProfessionalIncomeTaxCertificate]: 'Справка о профдоходе (самозанятые)',
};
