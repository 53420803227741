import { inject, Provider } from '@angular/core';
import { ConfigService } from '@lib-config';
import { GridStateService } from '@lib-widgets/grid';
import { GridStateApiService, MortgageConfiguration } from '@lib-mortgage/api';
import { createBearerCredentials } from '@lib-mortgage/features/authorization';
import { IpotekaGridStateService } from './grid-state.service';

export const provideGridStateService = (): Provider[] => {
  return [
    GridStateApiService,
    { provide: GridStateService, useClass: IpotekaGridStateService },
    {
      provide: MortgageConfiguration,
      useFactory: () =>
        new MortgageConfiguration({
          basePath: inject(ConfigService).apiUrl,
          credentials: createBearerCredentials(),
        }),
    },
  ];
};
