<div *ngIf="isShort" class="d-flex gap-1 content-space-between align-items-center">
  <span class="ellipsis" [class.font-weight-bold]="!isRead" [innerHTML]="shortText"></span>
  <span class="ml-1 flex-shrink-0" [class.font-weight-bold]="!isRead">{{ date | date: 'dd.MM.YYYY HH:mm' }}</span>
</div>

<tui-notification *ngIf="!isShort" [icon]="false" [status]="isRead ? 'neutral' : 'info'" (click)="isExpand = !isExpand">
  <div class="d-flex flex-column gap-2" [class.font-weight-bold]="!isRead">
    <div class="font-size-14 content-wrapper" [class.ellipsis-full]="!isExpand" [innerHTML]="fullText"></div>
    <fnip-divider />
    <span class="font-size-12 line-height-16">
      {{ date | date: 'dd.MM.YYYY HH:mm' }}
    </span>
  </div>
</tui-notification>
