/**
 * Octasoft.Notification.InApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ListMessagesItem } from './listMessagesItem';


export interface ListMessagesResponse { 
    items: Array<ListMessagesItem> | null;
    hasNext: boolean;
    nextCursor: string | null;
    size: number;
    total: number;
    totalUnread: number;
}

