import { ColDef } from '@ag-grid-community/core';

export const exactWidth = (
  initialWidth: number,
): Pick<ColDef, 'minWidth' | 'maxWidth' | 'initialWidth' | 'resizable' | 'initialFlex'> => ({
  minWidth: initialWidth,
  maxWidth: initialWidth,
  initialWidth,
  resizable: false,
  initialFlex: undefined,
});
