import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { MortgageConfiguration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AdminApiService } from './api/admin.service';
import { ApplicationApiService } from './api/application.service';
import { ApplicationChangesApiService } from './api/applicationChanges.service';
import { AuthorizerApiService } from './api/authorizer.service';
import { BankApiService } from './api/bank.service';
import { BlacklistApiService } from './api/blacklist.service';
import { ClientApiService } from './api/client.service';
import { DealVerificationApiService } from './api/dealVerification.service';
import { DirectoryApiService } from './api/directory.service';
import { DocumentTemplateApiService } from './api/documentTemplate.service';
import { DueDiligenceApiService } from './api/dueDiligence.service';
import { ElectronicAchiveApiService } from './api/electronicAchive.service';
import { ExternalFileApiService } from './api/externalFile.service';
import { FileApiService } from './api/file.service';
import { FormulaApiService } from './api/formula.service';
import { GridStateApiService } from './api/gridState.service';
import { InsuranceApiService } from './api/insurance.service';
import { LoanCalculatorApiService } from './api/loanCalculator.service';
import { MultiRoleApiService } from './api/multiRole.service';
import { NotificationApiService } from './api/notification.service';
import { PermissionApiService } from './api/permission.service';
import { RealEstateApiService } from './api/realEstate.service';
import { ReportApiService } from './api/report.service';
import { ResidentialComplexApiService } from './api/residentialComplex.service';
import { SmartDealApiService } from './api/smartDeal.service';
import { SuggestApiService } from './api/suggest.service';
import { UnderwriterApiService } from './api/underwriter.service';
import { UserApiService } from './api/user.service';
import { VerificationApiService } from './api/verification.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AdminApiService,
    ApplicationApiService,
    ApplicationChangesApiService,
    AuthorizerApiService,
    BankApiService,
    BlacklistApiService,
    ClientApiService,
    DealVerificationApiService,
    DirectoryApiService,
    DocumentTemplateApiService,
    DueDiligenceApiService,
    ElectronicAchiveApiService,
    ExternalFileApiService,
    FileApiService,
    FormulaApiService,
    GridStateApiService,
    InsuranceApiService,
    LoanCalculatorApiService,
    MultiRoleApiService,
    NotificationApiService,
    PermissionApiService,
    RealEstateApiService,
    ReportApiService,
    ResidentialComplexApiService,
    SmartDealApiService,
    SuggestApiService,
    UnderwriterApiService,
    UserApiService,
    VerificationApiService ]
})
export class FnipApiModule {
    public static forRoot(configurationFactory: () => MortgageConfiguration): ModuleWithProviders<FnipApiModule> {
        return {
            ngModule: FnipApiModule,
            providers: [ { provide: MortgageConfiguration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: FnipApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('FnipApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
