/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AnswerType = 'OneFromList' | 'Number' | 'Integer' | 'Boolean' | 'String' | 'Position' | 'BusinessArea' | 'AdditionalIncomeType';

export const AnswerType = {
    OneFromList: 'OneFromList' as AnswerType,
    Number: 'Number' as AnswerType,
    Integer: 'Integer' as AnswerType,
    Boolean: 'Boolean' as AnswerType,
    String: 'String' as AnswerType,
    Position: 'Position' as AnswerType,
    BusinessArea: 'BusinessArea' as AnswerType,
    AdditionalIncomeType: 'AdditionalIncomeType' as AnswerType
};

