import { Observable } from 'rxjs';
import {
  FnipNotification,
  FnipPaginatedNotificationList,
} from '../notification-list.models';

/**
 * Протокол списка нотификаций для работы с BE
 */
export abstract class NotificationSource<TBeData = unknown, TID extends string | number = number, TCursor = unknown> {
  /**
   * Получение списка уведомлений, используя курсор как пагинацию, если он был передан
   * Предполагается, что список будет отсортирован по дате создания, от старого к новому
   */
  public abstract getListBefore$(cursor: TCursor | null, count: number): Observable<FnipPaginatedNotificationList<TID, TBeData, TCursor>>;

  /**
   * Подписка на новые уведомления
   * Предполагается, что список будет отсортирован по дате создания, от старому к новому
   */
  public abstract getSubscription$(): Observable<FnipNotification<TID, TBeData>[]>;

  /**
   * Пометка о прочтении нотификации
   */
  public abstract markAsRead$(notification: FnipNotification<TID, TBeData>): Observable<unknown>;
}
