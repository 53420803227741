<fnip-request-wrapper #gridStateRs [request$]="getToolbarData$ | executeWith: gridId : gridOptions">
  <div class="d-flex flex-column gap-4">
    <h3>Список столбцов</h3>

    <fnip-grid-column-list
      [columns]="columns"
      [columnsOrder]="columnsOrder"
      (columnVisibilityChange)="setColumnVisibility($event)"
      (columnOrderChange)="setColumnOrder($event)"
    />

    <fnip-divider />

    <div *ngIf="gridStateService" class="d-flex flex-column gap-2">
      <span *ngIf="gridStateRs.data">
        Сохраненная конфигурация от {{ gridStateRs.data.createDate | date: 'dd.MM.yyyy HH:mm' }}
      </span>
      <span *ngIf="!gridStateRs.data">Отсутствует сохраненная конфигурация таблицы</span>
      <fnip-button
        appearance="primary"
        class="w-100"
        btnSize="s"
        label="Сохранить"
        [actionCallback$]="saveGridState$ | executeWith: gridStateRs.data"
      />
      <fnip-button class="w-100" btnSize="s" label="Сбросить" [actionCallback$]="resetGridState" />
      <fnip-button class="w-100" btnSize="s" label="Закрыть" [actionCallback$]="closeToolbar" />
    </div>
  </div>
</fnip-request-wrapper>
