import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Nullable } from '@lib-utils';
import { ReactiveCheckboxCellParams } from './reactive-checkbox-cell-params';

@Component({
  selector: 'fnip-reactive-checkbox-cell',
  templateUrl: './reactive-checkbox-cell.component.html',
  styleUrls: ['./reactive-checkbox-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReactiveCheckboxCellComponent implements ICellRendererAngularComp {
  fieldId = '';

  params: Nullable<ICellRendererParams & ReactiveCheckboxCellParams>;

  constructor(private cdr: ChangeDetectorRef) {}

  valueChange(val: boolean) {
    this.params?.onValueChange?.(this.params, val);
  }

  agInit(params: ICellRendererParams & ReactiveCheckboxCellParams) {
    this.refreshView(params);
  }

  refresh(params: ICellRendererParams & ReactiveCheckboxCellParams): boolean {
    this.refreshView(params);
    return true;
  }

  private refreshView(params: ICellRendererParams & ReactiveCheckboxCellParams) {
    this.params = params;
    this.fieldId = params.getFieldId(params);
    this.cdr.markForCheck();
  }
}
