/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VerificationStatus } from './verificationStatus';
import { CallTaskType } from './callTaskType';
import { SourceType } from './sourceType';
import { TaskDocumentResolutionDto } from './taskDocumentResolutionDto';
import { DateTime } from '@lib-utils';
import { VerificationTaskType } from './verificationTaskType';


export interface VerificationTaskInfoDto { 
    id?: number;
    source?: SourceType;
    isActive?: boolean;
    clientId?: number | null;
    clientExternalId?: string | null;
    fullName?: string | null;
    type?: VerificationTaskType;
    created?: DateTime;
    verifierId?: number | null;
    verifierName?: string | null;
    applicationId?: number;
    applicationExternalId?: string;
    applicationInternalId?: number | null;
    status?: VerificationStatus;
    callType?: CallTaskType;
    nextCallDateTime?: DateTime | null;
    documentResolutions?: Array<TaskDocumentResolutionDto> | null;
    falsificationFlags?: Array<TaskDocumentResolutionDto> | null;
}
export namespace VerificationTaskInfoDto {
}


