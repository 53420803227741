import { inject } from '@angular/core';
import { TOKEN_STORE } from './token-store.provider';

/**
 * Создание фабрики для получения данных авторизации для API модулей, сгенерированных из Swagger
 */
export const createBearerCredentials = (token$ = inject(TOKEN_STORE)) => ({
  Bearer: () => {
    const token = token$.value;
    return token ? `Bearer ${token}` : undefined;
  },
  Basic: () => {
    const token = token$.value;
    return token ? `Bearer ${token}` : undefined;
  },
});
