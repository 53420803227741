/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type InsurancePackage = 'FullPacket' | 'RequiredPacket';

export const InsurancePackage = {
    FullPacket: 'FullPacket' as InsurancePackage,
    RequiredPacket: 'RequiredPacket' as InsurancePackage
};

