/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ExtensionType = 'Pdf' | 'Doc' | 'Docx' | 'Xls' | 'Xlsx' | 'Png' | 'Jpeg' | 'Jfif' | 'Tiff';

export const ExtensionType = {
    Pdf: 'Pdf' as ExtensionType,
    Doc: 'Doc' as ExtensionType,
    Docx: 'Docx' as ExtensionType,
    Xls: 'Xls' as ExtensionType,
    Xlsx: 'Xlsx' as ExtensionType,
    Png: 'Png' as ExtensionType,
    Jpeg: 'Jpeg' as ExtensionType,
    Jfif: 'Jfif' as ExtensionType,
    Tiff: 'Tiff' as ExtensionType
};

