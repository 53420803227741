import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Nullable } from '@lib-utils';

export interface ErrorPageConfig {
  message?: Nullable<string>;
  title?: Nullable<string>;
  icon?: Nullable<string>;
  iconClass?: Nullable<string>;
  btnLabel?: Nullable<string>;
  btnHref?: Nullable<string>;
}

@Injectable()
export class ErrorPageService {
  readonly _config$ = new BehaviorSubject<Nullable<ErrorPageConfig>>(null);

  readonly config$ = this._config$.asObservable();

  get config() {
    return this._config$.value;
  }

  clearConfig = () => {
    this._config$.next(null);
  };

  setConfig = (value: Nullable<ErrorPageConfig>) => {
    this._config$.next(value);
  };
}
