<ng-container *ngIf="previewFileRequest$ | async" />

<fnip-file
  *ngFor="let file of fileListWithoutSignatures()"
  class="file"
  [appearance]="appearance"
  [state]="file.state"
  [name]="file.name"
  [contentType]="file.contentType"
  [rejectedReason]="file.rejectedReason"
  [progress]="file.progress"
  [isPending]="file.isPending"
  [isPreviewLoading]="file.isPreviewLoading"
  [cacheBlob]="file.cacheBlob"
  [size]="file.size"
  [thumbnailSrc]="file.thumbnailSrc"
  [showSize]="showSize"
  [signatureFile]="signatureFileByFileIdMap()[file.id!]"
  [allowDownload]="allowDownload && !!file.id"
  [allowPreview]="isAllowPreview | executeWith: file"
  [allowRemove]="allowRemove || !file.id"
  [allowSignature]="allowSignature && !!file.id"
  (fileRemove)="fileRemove.emit(file)"
  (filePreview)="previewFile$.next({ fileInfo: file, isModalView: $event })"
  (fileDownload)="downloadFile(file)"
  (fileSignatureUpload)="fileSignatureUpload.emit(file)"
  (fileSignatureDownload)="downloadFile(signatureFileByFileIdMap()[file.id!]!)"
  (fileSignatureRemove)="fileRemove.emit(signatureFileByFileIdMap()[file.id!]!)"
></fnip-file>

<ng-template #previewTempl let-preview>
  <ng-container *ngIf="(previewFile$ | async)?.fileInfo as previewFile">
    <div *ngIf="isFilePdf | executeWith: previewFile; else imageTempl" class="pdf-preview">
      <div class="pdf-preview__header">
        <div class="pdf-preview__header-item">
          <span>{{ previewFile.name }}</span>
        </div>

        <div class="pdf-preview__header-item d-flex gap-2">
          <fnip-button
            label="Открыть в новом окне"
            appearance="accent"
            btnSize="s"
            icon="tuiIconExternalLink"
            [actionCallback$]="onNewWindowFileOpenClick | executeWith: previewFile"
          />

          <fnip-button
            tuiPreviewAction
            appearance="flat"
            btnSize="s"
            icon="tuiIconCloseLarge"
            (click)="preview.complete()"
          />
        </div>
      </div>
      <iframe
        class="iframe"
        [title]="previewFile.name"
        [src]="getPreviewFileUrl | executeWith: previewFile : sanitizer"
      ></iframe>
    </div>

    <ng-template #imageTempl>
      <tui-preview [rotatable]="true" [zoomable]="true" (tuiSwipe)="onPreviewSwipe($event, previewFile, fileList())">
        <tui-preview-title>{{ previewFile.name }}</tui-preview-title>
        <tui-preview-pagination
          [length]="fileList?.length ?? 0"
          [index]="(getPreviewFileIndex | executeWith: previewFile : fileList()) ?? 0"
          (indexChange)="onPreviewIndexChange($event, fileList())"
        ></tui-preview-pagination>

        <fnip-button
          label="Открыть в новом окне"
          tuiPreviewAction
          appearance="accent"
          btnSize="s"
          icon="tuiIconExternalLink"
          [actionCallback$]="onNewWindowFileOpenClick | executeWith: previewFile"
        />

        <fnip-button
          tuiPreviewAction
          appearance="flat"
          btnSize="s"
          icon="tuiIconCloseLarge"
          (click)="preview.complete()"
        />

        <div>
          <img [alt]="previewFile.name" [src]="getPreviewFileUrl | executeWith: previewFile : sanitizer" />
        </div>
      </tui-preview>
    </ng-template>
  </ng-container>
</ng-template>
