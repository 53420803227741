import { Dialog } from '@angular/cdk/dialog';
import { inject, Injectable, Injector, NgModuleRef } from '@angular/core';
import { isNil } from 'lodash-es';
import defaultsDeep from 'lodash-es/defaultsDeep';
import { DialogHostComponent } from './dialog-host/dialog-host.component';
import { FnipDialogRef } from './dialog-ref';
import { DEFAULT_DIALOG_HOST_OPTIONS } from './dialog.constants';
import { DialogComponentType, DialogOptions } from './dialog.model';
import { DIALOG_COMPONENT, DIALOG_HOST_OPTIONS, DIALOG_MODULE_REF, FNIP_DIALOG_REF } from './dialog.tokens';
import { ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogType } from './dialogs';

/**
 * NOTE:
 * При тестировании, где нужен DialogService, необходимо прокинуть LocationStrategy
 * `.provide(withLocationStrategy())`
 *
 * CDK внутри использует Location для опции disposeOnNavigation
 * https://github.com/angular/components/blob/main/src/cdk/overlay/overlay-ref.ts#L184
 *
 * Иначе возникает ошибка вида:
 * `this._locationStrategy.getBaseHref is not a function`
 */
@Injectable()
export class DialogService {
  private readonly dialogCdk = inject(Dialog, { optional: true });
  private readonly injector = inject(Injector);
  private readonly ngModuleRef = inject(NgModuleRef);

  public readonly open = <TResultData = unknown, TContextData = unknown, TComponent = unknown>(
    component: DialogComponentType<TComponent>,
    options: DialogOptions<TContextData> = {},
  ) => {
    const optionsWithDefaults = defaultsDeep({}, options, {
      hostOptions: DEFAULT_DIALOG_HOST_OPTIONS,
    }) as DialogOptions<TContextData>;
    const ref = this.openAndCreateDialogRef<TResultData, TContextData, TComponent>(component, optionsWithDefaults);

    return ref.afterClosed$;
  };

  public readonly openConfirmation = <TResultData = unknown, TInput = unknown>(
    options: DialogOptions<ConfirmationDialogData<TInput, TResultData>>,
  ) => {
    //Если тип диалога `Question` и явно не указали опцию фокуса, то ставим фокус на кнопку `Confirm`
    if (options.contextData?.type === ConfirmationDialogType.Question && isNil(options.contextData.focusOnConfirm))
      options.contextData.focusOnConfirm = true;

    if (options.contextData?.type === ConfirmationDialogType.Question && isNil(options.contextData.title))
      options.contextData.title = 'Подтвердите действие';

    return this.open<TResultData, ConfirmationDialogData<TInput, TResultData>>(ConfirmationDialogComponent, options);
  };

  private openAndCreateDialogRef<TResultData, TContextData, TComponent>(
    component: DialogComponentType<TComponent>,
    options: DialogOptions<TContextData>,
  ) {
    if (!this.dialogCdk) {
      throw new Error('DialogModule is not provided, please import DialogModule in your AppModule');
    }

    const fnipDialogRef = new FnipDialogRef<TResultData>();
    fnipDialogRef.cdkRef = this.dialogCdk?.open<TResultData, TContextData, DialogHostComponent>(DialogHostComponent, {
      injector: this.injector,
      providers: [
        { provide: DIALOG_HOST_OPTIONS, useValue: options.hostOptions ?? {} },
        { provide: DIALOG_COMPONENT, useValue: component },
        { provide: DIALOG_MODULE_REF, useValue: this.ngModuleRef },
        { provide: FNIP_DIALOG_REF, useValue: fnipDialogRef },
      ],
      data: options.contextData,
      // disable ESC key
      disableClose: !options.hostOptions?.isDismissible,
      // Если явно не переопределили или отключили `autoFocus`, то по умолчанию фокус ставится на первый интерактивный элемент в диалоге
      autoFocus: options.hostOptions?.autoFocus,
      closeOnNavigation: true,
    });

    return fnipDialogRef;
  }
}
