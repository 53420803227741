import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { NotificationsConfiguration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { MessagesApiService } from './api/messages.service';
import { NotificationsApiService } from './api/notifications.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    MessagesApiService,
    NotificationsApiService ]
})
export class NotificationsApiModule {
    public static forRoot(configurationFactory: () => NotificationsConfiguration): ModuleWithProviders<NotificationsApiModule> {
        return {
            ngModule: NotificationsApiModule,
            providers: [ { provide: NotificationsConfiguration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: NotificationsApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('NotificationsApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
