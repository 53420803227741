export interface FnipNotification<TID extends string | number = number, TBeData = unknown> {
  /** Уникальный Id для правильного рендера в списке */
  id: TID;

  /** Признак того, что уведомление было прочитано */
  isRead: boolean;
  /** Короткий текст для отображения со скрытым списком */
  shortText: string;
  /** Полный текст уведомления, который видно в раскрытом списке */
  fullText: string;
  /** Дата создания уведомления */
  createdAt: Date;

  /** Оригинал объекта с бека */
  data: TBeData;
}

export interface FnipPaginatedNotificationList<TID extends string | number = number, TBeData = unknown, TCursor = unknown> {
  totalUnread: number;
  nextCursor: TCursor | null; // null если нет следующей страницы
  data: FnipNotification<TID, TBeData>[];
}
