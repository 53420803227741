import { importProvidersFrom } from '@angular/core';
import { Routes } from '@angular/router';
import { AuthorizationComponent } from './authorization.component';
import { AuthorizationModule } from './authorization.module';
import { LoginComponent } from './views/login';
import { RecoverEmailComponent } from './views/recover-email';
import { RecoverPasswordComponent } from './views/recover-password';

export const getAuthorizationRoutes = (version: string, appName: string, isNew = false): Routes => [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',
  },
  {
    path: '',
    component: AuthorizationComponent,
    providers: [importProvidersFrom(AuthorizationModule)],
    data: { version, isNew, appName },
    children: [
      {
        path: 'login',
        title: 'Авторизация',
        component: LoginComponent,
        data: { isNew },
      },
      {
        path: 'recover',
        title: 'Восстановление пароля',
        component: RecoverEmailComponent,
        data: { isNew },
      },
      {
        path: 'recover/:token',
        title: 'Сброс пароля',
        component: RecoverPasswordComponent,
        data: { isNew },
      },
    ],
  },
];
