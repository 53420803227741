/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VerificationStatus } from './verificationStatus';
import { VerificationType } from './verificationType';
import { DateTime } from '@lib-utils';


export interface VerificationTasksReportRequest { 
    startDate: DateTime;
    endDate: DateTime;
    type: VerificationType;
    status: VerificationStatus;
}
export namespace VerificationTasksReportRequest {
}


