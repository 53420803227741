/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BasePassportDto } from './basePassportDto';
import { PersonDto } from './personDto';
import { DateOnly } from '@lib-utils';
import { PreviousNameDto } from './previousNameDto';


export interface PassportDto { 
    uid: string;
    series: string | null;
    number: string | null;
    issuedOn: DateOnly;
    issuedByCode: string | null;
    issuedBy?: string | null;
    person: PersonDto;
    birthplace: string | null;
    registrationAddressFull: string | null;
    previousPassports?: Array<BasePassportDto> | null;
    previousNames?: Array<PreviousNameDto> | null;
}

