import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { VerificationConfiguration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AutoApiService } from './api/auto.service';
import { DirectoryApiService } from './api/directory.service';
import { SprApiService } from './api/spr.service';
import { VerificationApiService } from './api/verification.service';
import { VerificationManagementApiService } from './api/verificationManagement.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AutoApiService,
    DirectoryApiService,
    SprApiService,
    VerificationApiService,
    VerificationManagementApiService ]
})
export class FxVerificationApiModule {
    public static forRoot(configurationFactory: () => VerificationConfiguration): ModuleWithProviders<FxVerificationApiModule> {
        return {
            ngModule: FxVerificationApiModule,
            providers: [ { provide: VerificationConfiguration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: FxVerificationApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('FxVerificationApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
