import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  inject,
  input,
  isDevMode,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TuiActiveZoneModule, TuiObscuredModule } from '@taiga-ui/cdk';
import { TuiDataListModule, TuiDropdownModule, TuiSvgModule } from '@taiga-ui/core';
import { EMPTY, tap } from 'rxjs';
import { ExecuteWithPipeModule } from '@lib-utils';
import { ButtonModule } from '@lib-widgets/core';
import { RequestWrapperModule } from '@lib-widgets/request-wrapper';
import { NotificationHistoryComponent } from './notification-history';
import { NotificationListItemComponent } from './notification-list-item';
import { FnipNotification, FnipPaginatedNotificationList } from './notification-list.models';
import { NotificationSource } from './sources';

const PAGE_SIZE = 10;

@Component({
  selector: 'fnip-notification-list',
  standalone: true,
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RequestWrapperModule,
    ExecuteWithPipeModule,
    ButtonModule,
    TuiSvgModule,
    TuiDropdownModule,
    TuiActiveZoneModule,
    TuiObscuredModule,
    TuiDataListModule,
    NotificationListItemComponent,
    NotificationHistoryComponent,
  ],
})
export class NotificationListComponent implements OnInit {
  compactMode = input(false);

  private readonly destroyRef = inject(DestroyRef);
  public readonly notificationSource = inject(NotificationSource, { optional: true });

  // Точка отсчета для получения истории уведомлений
  protected readonly totalUnread = signal(0);
  protected readonly nextCursor = signal<unknown | null>(null);
  private readonly notificationsMarkedAsRead = signal<FnipNotification[]>([]);
  private readonly notificationHistory = signal<FnipNotification[]>([]);
  private readonly notificationFromSubscription = signal<FnipNotification[]>([]);

  // Состояние дропдауна
  public readonly isDropdownOpen = signal(false);
  public readonly onObscured = (obscured: boolean) => (obscured ? this.isDropdownOpen.set(false) : undefined);
  public readonly onActiveZone = (active: boolean) => this.isDropdownOpen.update((open) => active && open);

  // Показываем общий список уведомлений из истории и новых, сначала новые
  readonly displayedNotifications = computed<FnipNotification[]>(() => {
    const markedAsRead = this.notificationsMarkedAsRead();
    return (
      [...this.notificationHistory(), ...this.notificationFromSubscription()]
        // Сохраняем ссылку на item, чтобы потом можно было делать сравнение с markedAsRead
        .map((item) => Object.assign(item, { isRead: markedAsRead.includes(item) || item.isRead }))
        .reverse()
    );
  });

  // Последнее непрочитанное уведомление
  readonly mostRecentUnreadNotification = computed(() => this.displayedNotifications().find((item) => !item.isRead));

  // Кол-во непрочитанных уведомлений (за исключением помеченных как прочитанных)
  readonly unreadNotificationsCount = computed(() => {
    const markedAsRead = this.notificationsMarkedAsRead();
    const totalUnread = this.totalUnread();
    const currentUnread = totalUnread - markedAsRead.length;
    return currentUnread > 0 ? currentUnread : 0;
  });

  ngOnInit() {
    if (this.notificationSource === null && isDevMode()) {
      // eslint-disable-next-line no-console
      console.warn('Для компонента уведомлений не был передан источник данных');
      return;
    }

    // Подписываемся на новые уведомления
    this.subscribeToNewNotifications$().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  public readonly loadInitialHistory$ = () => {
    if (this.notificationSource === null) return EMPTY;
    return this.notificationSource.getListBefore$(null, PAGE_SIZE).pipe(tap((result) => this.updateHistory(result)));
  };

  public readonly loadMoreHistory$ = () => {
    if (this.notificationSource === null) return EMPTY;
    return this.notificationSource
      .getListBefore$(this.nextCursor(), PAGE_SIZE)
      .pipe(tap((result) => this.updateHistory(result)));
  };

  public markAsRead(item: FnipNotification) {
    if (!this.notificationSource) return;
    // Помечаем локально, что уведомление прочитано
    this.notificationsMarkedAsRead.update((items) => [...items, item]);
    // Помечаем в бэке
    this.notificationSource.markAsRead$(item).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  public updateHistory({ totalUnread, nextCursor, data }: FnipPaginatedNotificationList) {
    this.totalUnread.set(totalUnread);
    this.nextCursor.set(nextCursor);
    this.notificationHistory.update((items) => [...data, ...items]);
  }

  public updateNewNotifications(notifications: FnipNotification[]) {
    this.totalUnread.update((total) => total + notifications.filter((item) => !item.isRead).length);
    this.notificationFromSubscription.update((items) => [...notifications, ...items]);
  }

  private subscribeToNewNotifications$() {
    if (this.notificationSource === null) return EMPTY;
    return this.notificationSource
      .getSubscription$()
      .pipe(tap((notifications) => this.updateNewNotifications(notifications)));
  }
}
