import { AgGridModule } from '@ag-grid-community/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ExecuteWithPipeModule } from '@lib-utils';
import { GridBaseComponent } from './grid-base.component';

@NgModule({
  imports: [CommonModule, ExecuteWithPipeModule, AgGridModule],
  declarations: [GridBaseComponent],
  exports: [GridBaseComponent],
})
export class GridBaseModule {}
