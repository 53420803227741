export * from './auto.service';
import { AutoApiService } from './auto.service';
export * from './directory.service';
import { DirectoryApiService } from './directory.service';
export * from './spr.service';
import { SprApiService } from './spr.service';
export * from './verification.service';
import { VerificationApiService } from './verification.service';
export * from './verificationManagement.service';
import { VerificationManagementApiService } from './verificationManagement.service';
export const APIS = [AutoApiService, DirectoryApiService, SprApiService, VerificationApiService, VerificationManagementApiService];
