/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type VerificationTaskSortFields = 'DocumentType' | 'DocumentStatus' | 'Created' | 'Id' | 'TaskType' | 'Source';

export const VerificationTaskSortFields = {
    DocumentType: 'DocumentType' as VerificationTaskSortFields,
    DocumentStatus: 'DocumentStatus' as VerificationTaskSortFields,
    Created: 'Created' as VerificationTaskSortFields,
    Id: 'Id' as VerificationTaskSortFields,
    TaskType: 'TaskType' as VerificationTaskSortFields,
    Source: 'Source' as VerificationTaskSortFields
};

