/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VerifierStatus } from './verifierStatus';
import { VerifierType } from './verifierType';


export interface VerifierInfoDto { 
    id?: number;
    status?: VerifierStatus;
    timeLeft?: string;
    isActive?: boolean;
    fullName?: string | null;
    documentsVerification?: boolean;
    callsVerification?: boolean;
    type?: VerifierType;
    tasksCount?: number;
}
export namespace VerifierInfoDto {
}


