import { TuiStatus } from '@taiga-ui/kit';
import { Nullable } from '@lib-utils';

export interface TagCellListValue {
  items: TagItem[];
  additionItem?: Nullable<TagItem>;
}

export interface TagItem {
  value: string;
  status?: Nullable<TuiStatus>;
  hint?: string;
}

export interface TagListCellConfig {
  joinBy?: string;
}
