import { inject, Injectable, Provider } from '@angular/core';
import {
  ListMessagesItem,
  ListSortDirection,
  MessagesApiService,
  NotificationsConfiguration,
} from '@lib-notifications/api';
import { Observable, retry } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigService } from '@lib-config';
import { createBearerCredentials, TOKEN_STORE } from '@lib-mortgage/features/authorization';
import { FnipNotification, FnipPaginatedNotificationList } from '../notification-list.models';
import { NotificationSource } from './notification-source';

const SSE_ENDPOINT = '/Api/Notifications/Subscribe';

interface SseMessage {
  Id: number;
  Title: string;
  Body: string;
  LinkUrl: string;
}

@Injectable()
export class InAppNotificationSource extends NotificationSource<ListMessagesItem, number, string> {
  private readonly token$ = inject(TOKEN_STORE);
  private readonly messagesApiService = inject(MessagesApiService);

  public override getListBefore$(cursor: string | null, count: number) {
    return this.messagesApiService
      .apiMessagesGet({
        cursor: cursor ?? undefined,
        size: count,
        orderByField: 'created',
        orderBySort: ListSortDirection.Descending,
      })
      .pipe(
        map(
          (result) =>
            ({
              nextCursor: result.nextCursor,
              totalUnread: result.totalUnread,
              data: (result.items ?? []).map(
                (item): FnipNotification<number, ListMessagesItem> => ({
                  id: item.id,
                  data: item,
                  isRead: item.read,
                  createdAt: new Date(item.created),
                  fullText: item.title ?? '',
                  shortText: item.title ?? '',
                }),
              ),
            }) satisfies FnipPaginatedNotificationList<number, ListMessagesItem, string>,
        ),
      );
  }

  public override getSubscription$(): Observable<FnipNotification<number, ListMessagesItem>[]> {
    return new Observable<FnipNotification<number, ListMessagesItem>[]>((observer) => {
      const source = new EventSource(
        this.messagesApiService.configuration.basePath + SSE_ENDPOINT + `?accessToken=${this.token$.getValue()}`,
      );

      source.addEventListener('message', (event) => {
        const now = new Date();
        const data = JSON.parse(event.data) as SseMessage;

        observer.next([
          {
            id: data.Id,
            data: {
              id: data.Id,
              title: data.Title,
              body: data.Body,
              created: now.toString(),
              read: false,
              metadata: { linkUrl: data.LinkUrl },
            },
            isRead: false,
            createdAt: new Date(),
            fullText: data.Body,
            shortText: data.Title,
          } satisfies FnipNotification<number, ListMessagesItem>,
        ]);
      });

      source.addEventListener('error', (event) => observer.error(event));
      return () => source.close();
    }).pipe(retry({ delay: 1000 }));
  }

  public override markAsRead$(notification: FnipNotification<number, ListMessagesItem>): Observable<unknown> {
    return this.messagesApiService.apiMessagesIdReadPut({
      id: notification.id,
    });
  }
}

export const IN_APP_NOTIFICATIONS_FEATURE_FLAG = 'in-app-notifications';
export function provideInAppNotificationSource(): Provider[] {
  return [
    MessagesApiService,
    {
      provide: NotificationsConfiguration,
      useFactory: () => {
        return new NotificationsConfiguration({
          basePath: inject(ConfigService).notificationsApiUrl,
          credentials: createBearerCredentials(),
        });
      },
    },
    {
      provide: NotificationSource,
      useClass: InAppNotificationSource,
    },
  ];
}
