/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ReferenceBookType = 'Position' | 'BusinessArea' | 'AdditionalIncomeType';

export const ReferenceBookType = {
    Position: 'Position' as ReferenceBookType,
    BusinessArea: 'BusinessArea' as ReferenceBookType,
    AdditionalIncomeType: 'AdditionalIncomeType' as ReferenceBookType
};

