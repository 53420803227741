/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DateOnly } from '@lib-utils';
import { DocumentType } from './documentType';


export interface PreviousPassportVerificationTaskPassportDto { 
    id?: number;
    externalId?: string | null;
    series?: string | null;
    number?: string | null;
    issueDepCode: string | null;
    issueDate?: DateOnly;
    documentType?: DocumentType;
}
export namespace PreviousPassportVerificationTaskPassportDto {
}


