import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import ru from '@angular/common/locales/ru';
import { APP_INITIALIZER, inject, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TuiPreviewModule } from '@taiga-ui/addon-preview';
import { TuiAlertModule, TuiDialogModule, TuiRootModule } from '@taiga-ui/core';
import { TuiPdfViewerModule } from '@taiga-ui/kit';
import { ConfigService, InitConfigModule, TaigaUiProvidersModule } from '@lib-config';
import {
  COMMON_ERROR_MESSAGES,
  ErrorInterceptorModule,
  MORTGAGE_ERROR_MESSAGES,
  MortgageLikeErrorMapper,
} from '@lib-error-interceptor';
import { checkBrowserSupport } from '@lib-utils';
import { ApplicationRootComponent, ApplicationRootModule } from '@lib-widgets/application-root';
import { FnipDialogModule } from '@lib-widgets/dialog';
import { SidebarModule } from '@lib-widgets/sidebar';
import { APIS as MortgageApiServices, MortgageConfiguration } from '@lib-mortgage/api';
import {
  AuthorizationInterceptorModule,
  AuthorizationModule,
  createBearerCredentials,
} from '@lib-mortgage/features/authorization';
import { WithoutEmptyFieldsHttpRequestInterceptorModule } from '@lib-mortgage/interceptors';
import { provideGridStateService } from '@lib-mortgage/widgets/grid';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';

registerLocaleData(ru);

const TUI_MODULES = [
  TuiRootModule,
  TuiDialogModule,
  TuiAlertModule,
  TuiPreviewModule, // Нет метода .forRoot(), необходим импорт модуля в руте. Следить за PreviewDialogService.open()
  TuiPdfViewerModule, // Нет метода .forRoot(), необходим импорт модуля в руте. Следить за TuiPdfViewerService.open()
];

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SidebarModule.forRoot(),
    TranslateModule.forRoot(), // Необходим для корректной работы `DefaultComponent`
    AuthorizationModule,
    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
    TUI_MODULES,
    InitConfigModule.forRoot('Ипотечный верификатор', environment.frontVersion),
    TaigaUiProvidersModule,
    FnipDialogModule,
    ApplicationRootModule,
    HttpClientModule,
    AuthorizationInterceptorModule,
    ErrorInterceptorModule.forRoot({
      messages: [COMMON_ERROR_MESSAGES, MORTGAGE_ERROR_MESSAGES],
      mappers: [MortgageLikeErrorMapper],
    }),
    WithoutEmptyFieldsHttpRequestInterceptorModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: checkBrowserSupport,
      deps: [Router],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'ru' },
    ...MortgageApiServices,
    {
      provide: MortgageConfiguration,
      useFactory: () =>
        new MortgageConfiguration({
          basePath: inject(ConfigService).apiUrl,
          credentials: createBearerCredentials(),
        }),
    },
    provideGridStateService(),
  ],
  bootstrap: [ApplicationRootComponent],
})
export class AppModule {}
