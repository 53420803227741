import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Nullable } from '@lib-utils';
import { GridId, GridSavedState, GridState } from './utils';

@Injectable()
export abstract class GridStateService {
  private _onStateChange$ = new Subject<GridId>();

  public getOnStateChange$(gridId: GridId) {
    return this._onStateChange$.asObservable().pipe(filter((id) => id === gridId));
  }

  public onStateChange(gridId: Nullable<GridId>) {
    gridId && this._onStateChange$.next(gridId);
  }

  abstract getGridState$(gridId: GridId): Observable<Nullable<GridSavedState>>;
  abstract saveGridState$(gridId: GridId, state: Nullable<GridState>): Observable<unknown>;
  abstract removeGridState$(gridId: GridId): Observable<unknown>;
}
