import { ColDef } from '@ag-grid-community/core';
import { formatDate } from '@angular/common';
import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import isToday from 'date-fns/isToday';
import isYesterday from 'date-fns/isYesterday';
import { Nullable } from '@lib-utils';

export const dateTimeCellDef = (timezone?: Nullable<string>): Pick<ColDef, 'valueFormatter'> => ({
  valueFormatter: ({ value }) => value && formatDate(value, 'dd.MM.yyyy HH:mm', 'ru', timezone ?? undefined),
});

export const dateTimeTextCellDef: Pick<ColDef, 'valueFormatter'> = {
  valueFormatter: ({ value }) => {
    const date = new Date(value);
    const datePart = isToday(date) ? 'Сегодня,' : isYesterday(date) ? 'Вчера,' : formatDate(date, 'dd.MM.yyyy', 'ru');
    return `${datePart} ${formatDate(date, 'HH:mm', 'ru')}`;
  },
};

export const dateTimeTranslateCellDef = (
  timezone?: Nullable<string>,
  translateService = inject(TranslateService),
): Pick<ColDef, 'valueFormatter'> => ({
  valueFormatter: ({ value }) =>
    value &&
    formatDate(
      value,
      'dd.MM.yyyy HH:mm',
      translateService.currentLang || translateService.getBrowserLang() || 'ru',
      timezone ?? undefined,
    ),
});

export const dateTimeTextTranslateCellDef = (
  translateService = inject(TranslateService),
): Pick<ColDef, 'valueFormatter'> => ({
  valueFormatter: ({ value }) => {
    const locale = translateService.currentLang || translateService.getBrowserLang() || 'ru';
    const date = new Date(value);
    const datePart = isToday(date)
      ? translateService.instant('BASE.DATE.TODAY')
      : isYesterday(date)
        ? translateService.instant('BASE.DATE.YESTERDAY')
        : formatDate(date, 'dd.MM.yyyy', locale);

    return `${datePart} ${formatDate(date, 'HH:mm', locale)}`;
  },
});
