/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type VerificationCallResult = 'NonExistingNumber' | 'BlockedNumber' | 'Unavailable' | 'NoAnswer' | 'RefusedToTalk' | 'WrongNumber' | 'LoanNotNeeded' | 'CallBack' | 'Answer';

export const VerificationCallResult = {
    NonExistingNumber: 'NonExistingNumber' as VerificationCallResult,
    BlockedNumber: 'BlockedNumber' as VerificationCallResult,
    Unavailable: 'Unavailable' as VerificationCallResult,
    NoAnswer: 'NoAnswer' as VerificationCallResult,
    RefusedToTalk: 'RefusedToTalk' as VerificationCallResult,
    WrongNumber: 'WrongNumber' as VerificationCallResult,
    LoanNotNeeded: 'LoanNotNeeded' as VerificationCallResult,
    CallBack: 'CallBack' as VerificationCallResult,
    Answer: 'Answer' as VerificationCallResult
};

