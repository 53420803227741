/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EmploymentType = 'BusinessOwner' | 'Employee' | 'Pensioner' | 'IndividualEntrepreneur' | 'SelfEmployed';

export const EmploymentType = {
    BusinessOwner: 'BusinessOwner' as EmploymentType,
    Employee: 'Employee' as EmploymentType,
    Pensioner: 'Pensioner' as EmploymentType,
    IndividualEntrepreneur: 'IndividualEntrepreneur' as EmploymentType,
    SelfEmployed: 'SelfEmployed' as EmploymentType
};

