/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DateTime } from '@lib-utils';


export interface FileSignInfoModel { 
    id?: number;
    clientFilesSignsMappingId?: number;
    signingTimeDocument?: DateTime | null;
    subject?: string | null;
    issuer?: string | null;
    startDate?: DateTime | null;
    endDate?: DateTime | null;
    serialNumber?: string | null;
    algorithm?: string | null;
    errorMessage?: string | null;
    success?: boolean;
}

