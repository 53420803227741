import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Nullable } from '@lib-utils';
import { ButtonModule } from '@lib-widgets/core';
import { injectDialogContext } from '@lib-widgets/dialog';
import { ReactiveSelectModule } from '@lib-widgets/reactive-fields';
import { ExecutorChangeDialogModel } from './executor-change-dialog.model';

@Component({
  selector: 'fnip-executor-change-dialog',
  templateUrl: './executor-change-dialog.component.html',
  styleUrls: ['./executor-change-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveSelectModule, ButtonModule],
})
export class ExecutorChangeDialogComponent {
  readonly dialogContext = injectDialogContext<Nullable<number>, ExecutorChangeDialogModel>();

  executorControl = new FormControl<Nullable<number>>(null);

  close = () => this.dialogContext.ref?.close(null);

  setUnderwriter$ = () => {
    const executorId = this.executorControl.value;
    if (!executorId) return close();

    return this.dialogContext.ref?.close(executorId);
  };
}
