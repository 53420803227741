/**
 * Fenix Verification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Education = 'AcademicDegree' | 'HigherEducation' | 'TwoAndMoreHigherEducations' | 'IncompleteHigherEducation' | 'SecondaryEducation' | 'SpecialSecondaryEducation' | 'LowerThanSecondaryEducation' | 'RussianMBA' | 'ForeignMBA' | 'Other';

export const Education = {
    AcademicDegree: 'AcademicDegree' as Education,
    HigherEducation: 'HigherEducation' as Education,
    TwoAndMoreHigherEducations: 'TwoAndMoreHigherEducations' as Education,
    IncompleteHigherEducation: 'IncompleteHigherEducation' as Education,
    SecondaryEducation: 'SecondaryEducation' as Education,
    SpecialSecondaryEducation: 'SpecialSecondaryEducation' as Education,
    LowerThanSecondaryEducation: 'LowerThanSecondaryEducation' as Education,
    RussianMba: 'RussianMBA' as Education,
    ForeignMba: 'ForeignMBA' as Education,
    Other: 'Other' as Education
};

