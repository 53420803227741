import isNil from 'lodash-es/isNil';
import { Nullable } from '@lib-utils';
import { ApplicationParticipantsDto, ClientDto } from '@lib-mortgage/api';

export const getOrderParticipant = (
  orderParticipants: Nullable<ApplicationParticipantsDto>,
  coborrowerId: Nullable<number>,
  guarantorId: Nullable<number>,
): Nullable<ClientDto> => {
  if (isNil(coborrowerId) && isNil(guarantorId)) return orderParticipants?.client;
  if (!isNil(coborrowerId)) return orderParticipants?.coborrowers?.find((coborrower) => coborrower.id === coborrowerId);
  return orderParticipants?.guarantors?.find((guarantor) => guarantor.id === guarantorId);
};

/**
 * Возвращает всех участников кредитования: клиент, заемщики, поручители
 */
export const getAllOrderParticipants = (orderParticipants: Nullable<ApplicationParticipantsDto>): ClientDto[] => {
  const participants = [...(orderParticipants?.coborrowers ?? []), ...(orderParticipants?.guarantors ?? [])];
  if (orderParticipants?.client) participants.unshift(orderParticipants.client);

  return participants;
};

/**
 * Возвращает клиента и заемщиков кредитования
 */
export const getOrderClientAndCoborrowers = (orderParticipants: Nullable<ApplicationParticipantsDto>): ClientDto[] => {
  const participants = [...(orderParticipants?.coborrowers ?? [])];
  if (orderParticipants?.client) participants.unshift(orderParticipants.client);

  return participants;
};
