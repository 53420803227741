/**
 * Fenix Ipoteka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DocumentRecognitionErrorDto } from './documentRecognitionErrorDto';
import { IncomesPeriodRecognitionDto } from './incomesPeriodRecognitionDto';
import { DateOnly } from '@lib-utils';
import { IncomeStatementBankFormatItemRecognitionDto } from './incomeStatementBankFormatItemRecognitionDto';
import { WorkingPeriodRecognitionDto } from './workingPeriodRecognitionDto';
import { AddressDto } from './addressDto';


export interface IncomeStatementBankFormatRecognitionDto { 
    errors?: Array<DocumentRecognitionErrorDto> | null;
    fullName?: string | null;
    lastName?: string | null;
    firstName?: string | null;
    secondName?: string | null;
    position?: string | null;
    innEmployer?: string | null;
    employerName?: string | null;
    employerPhone?: string | null;
    incomes?: Array<IncomeStatementBankFormatItemRecognitionDto> | null;
    salaryTotal?: number | null;
    incomesPeriod?: IncomesPeriodRecognitionDto;
    statementDate?: DateOnly | null;
    workingPeriod?: WorkingPeriodRecognitionDto;
    incomesMonthsCount?: number | null;
    workingMonthsCount?: number | null;
    signChiefAccountant?: boolean | null;
    employerLegalAddress?: AddressDto;
    employerActualAddress?: AddressDto;
    stamp?: boolean | null;
    hasCorrections?: boolean;
}

