import { CreditProductCode } from '@lib-utils';

export const CreditProductCodeMap = new Map<CreditProductCode, string>([
  [CreditProductCode.PrimaryFlatApartments, 'Квартиры/апартаменты'],
  [CreditProductCode.FromState, 'Госпрограмма 2020-2024'],
  [CreditProductCode.ITSpecialist, 'Госпрограмма ИТ-Специалист'],
  [CreditProductCode.ForFamily, 'Семейная ипотека'],
  [CreditProductCode.PIKPreferential, 'Госсубсидии ПИК - Льготная ипотека'],
  [CreditProductCode.PIKFamily, 'Госсубсидии ПИК - Семейная ипотека'],
  [CreditProductCode.Commercial, 'Коммерческая ипотека'],
  [CreditProductCode.ParkingPlace, 'Машино-место'],
]);
